import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  IAlertsTrendData,
  IDropdownItem,
  TagThresholdAlert,
} from '@intorqa-ui/core';
import {
  Condition_DataSource,
  Delays_DataSource,
  Priority_DataSource,
} from '@portal/notifications/const/alerts.const';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import {
  ChartOrientation,
  ChartType,
  LineMode,
} from '@portal/widgets/enums/chart.enum';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-tag-threshold-alert',
  templateUrl: './tag-threshold-alert.component.html',
  styleUrls: ['./tag-threshold-alert.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonModule,
    MatFormFieldModule,
    SharedModule,
    ChartComponent,
    MatTooltipModule,
  ],
})
export class TagThresholdAlertComponent implements OnInit {
  @Input() action: Actions;
  @Input() form: FormGroup;
  @Input() query: QueryBuilderModel;
  @Input() fields: Array<IFilterField>;
  @Input() tag: Tag;

  public conditionDataSource: Array<IDropdownItem>;
  public periodDataSource: Array<{ name: string; value: number }>;
  public priorityDataSource: Array<IDropdownItem>;
  public trendDataSource: IAlertsTrendData;
  public options = {
    orientation: ChartOrientation.VERTICAL,
    mode: LineMode.AREA,
    tooltip: {
      show: false,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: true,
    },
    series: {
      showSymbol: false,
      label: {
        show: false,
      },
      lineStyle: {
        width: 2,
        color: '#1de62a',
      },
      areaStyle: {
        color: '#1de62a',
      },
    },
  };
  private subscriptions = new Subscription();

  readonly Actions = Actions;
  readonly ChartType = ChartType;

  constructor(
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
    readonly userService: UserService,
  ) {
    this.conditionDataSource = Condition_DataSource;
    this.periodDataSource = Delays_DataSource;
    this.priorityDataSource = Priority_DataSource;
  }

  ngOnInit() {
    this.bindLoadAlertSubscription();
    this.createForm();
    this.onGetTrendData();
    this.onGetAlert();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    (this.form?.get('alerts') as FormGroup).removeControl('dailyAverage');
    (this.form?.get('alerts') as FormGroup).removeControl('priority');
    (this.form?.get('alerts') as FormGroup).removeControl('condition');
    (this.form?.get('alerts') as FormGroup).removeControl('count');
    (this.form?.get('alerts') as FormGroup).removeControl('period');
    (this.form?.get('alerts') as FormGroup).removeControl('shareSlack');
  }

  private onGetAlert(): void {
    if (
      (this.action === Actions.EDIT || this.action === Actions.CLONE) &&
      this.tag.alertTypeId
    ) {
      this.alertsService.getAlertByTagId(this.tag.tagId).subscribe();
    }
  }

  private onGetTrendData(): void {
    if (this.query) {
      this.alertsService
        .getTrend({
          ecosystemId: this.userService.userPreferences.defaultEcosystemId,
          query: this.query.convertToBackEndQuery(),
        })
        .subscribe((response: IAlertsTrendData) => {
          this.trendDataSource = response;
        });
    }
  }

  private bindLoadAlertSubscription(): void {
    this.subscriptions.add(
      this.alertsService.loadAlert$.subscribe((response: TagThresholdAlert) => {
        this.form.get('alerts.dailyAverage').setValue(response.dailyAverage);
        this.form.get('alerts.active').setValue(response.active);
        this.form.get('alerts.priority').setValue(response.priority);
        this.form.get('alerts.condition').setValue(response.condition);
        this.form.get('alerts.count').setValue(response.count);
        this.form.get('alerts.period').setValue(response.period);
        this.form.get('alerts.shareSlack').setValue(response.postSlack);
        if (response.dailyAverage) {
          this.form.get('alerts.count').disable();
          this.form.get('alerts.period').disable();
        }
      }),
    );
  }
  private createForm(): void {
    if (this.action !== Actions.CREATE && this.action !== Actions.CLONE) {
      (this.form.get('alerts') as FormGroup).addControl(
        'active',
        new FormControl(true),
      );
    }
    (this.form.get('alerts') as FormGroup).addControl(
      'dailyAverage',
      new FormControl(undefined),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'priority',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'condition',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'count',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'period',
      new FormControl(undefined, [Validators.required]),
    );

    if (this.form.get('save.sharedTag')?.value === true) {
      (this.form.get('alerts') as FormGroup).addControl(
        'shareSlack',
        new FormControl(undefined),
      );
    }
  }

  public onChangeDailyAverage(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.form?.get('alerts.count')?.disable();
      this.form?.get('alerts.period')?.disable();
      this.form
        ?.get('alerts.count')
        ?.setValue(this.trendDataSource?.dailyAverage);
      const hour = Delays_DataSource.find(
        (item: { name: string; value: number }) => item.name === 'Day',
      ).value;
      this.form?.get('alerts.period')?.setValue(hour);
    } else {
      this.form?.get('alerts.count')?.enable();
      this.form?.get('alerts.period')?.enable();
      this.form?.get('alerts.period')?.setValue(undefined);
      this.form?.get('alerts.count')?.setValue(undefined);
    }
  }
}
