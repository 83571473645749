import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  convertLocalTimezonePipe,
  CoreModule,
  FAwesomeModule,
  HighlightSearchPipe,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import {
  DocumentItem,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { FieldVendorsLinksComponent } from '@portal/profiles/components/field-vendors-links/field-vendors-links.component';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subscription } from 'rxjs';
import { DocumentItemActionsComponent } from '../document-item-actions/document-item-actions.component';
import { DocumentLinksComponent } from '../document-links/document-links.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import { IDrilldown } from '@portal/drilldown/interfaces/drilldown.interface';

@Component({
  selector: 'itq-document-item-tile',
  templateUrl: './document-item-tile.component.html',
  styleUrls: ['./document-item-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CoreModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,

    DocumentItemActionsComponent,
    DocumentLinksComponent,
    HighlightSearchPipe,
    MatRippleModule,
    MatProgressSpinnerModule,
    CommonModule,
    FieldVendorsLinksComponent,
    convertLocalTimezonePipe,
  ],
})
export class DocumentItemTileComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() query: string;
  @Input() parentDocument: string;
  @Input() isParent: boolean;
  @Input() drilldownSegment: string;
  @Input() shareByEmail = true;
  @Input() allowDrilldown = true;
  @Input() isParentExpanded: boolean;
  @Input() profile: Profile;
  @Input() activeDocumentId: string;

  @Output() drilldown = new EventEmitter<any>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();
  @Output() showParent = new EventEmitter<DocumentItem>();
  @Output() hideParent = new EventEmitter<void>();
  @Output() shareEmail = new EventEmitter<DocumentItem>();

  public isExpanded = false;
  public profileTypesDataSource: Array<ProfileType> = [];
  public profileInitialState: QueryFilters;
  private subscription = new Subscription();
  public translatedContent: string;
  public translatedTitle: string;
  public repliesCount: number;
  public showLoader = false;
  public showActions = false;
  public highlighted = false;
  public hasExpandedActions = false;

  readonly DrilldownScope = DrilldownScope;
  readonly ProfileDrildownScope = ProfileDrildownScope;
  readonly Sizes = Sizes;
  readonly Align = Align;

  constructor(
    private documentService: DocumentItemService,
    private profileService: ProfileService,
    private linkTagsService: LinkTagsService,
    private snackBar: MatSnackBar,
    readonly notificationService: NotificationsService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    public keycloakService: KeycloakService,
  ) {}

  @HostListener('mouseover')
  onMouseOver(): void {
    this.showActions = true;
    this.cdr.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (!this.hasExpandedActions) {
      this.showActions = false;
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.profileTypesDataSource = this.profileService.types;
    document.addEventListener('selectionchange', this.onSelectionChange);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.translatedTitle = undefined;
    this.translatedContent = undefined;
    document.removeEventListener('selectionchange', this.onSelectionChange);
  }

  onSelectionChange = (event: MouseEvent) => {
    const selection = document.getSelection();
    if (selection && selection.toString().length > 0) {
      this.highlighted = true;
    }
  };

  public onHideParent(): void {
    this.hideParent.emit();
  }

  public onShowParent(): void {
    this.showLoader = true;
    this.documentService
      .getParentDocument(this.document)
      .subscribe((response: DocumentItem) => {
        this.showLoader = false;
        this.showParent.emit(response);
      });
  }

  public onTranslate(): void {
    this.documentService
      .detectLanguage(this.document.emitBodyText)
      .then((response) => response.json())
      .then((result: any) => {
        if (result?.data?.detections?.length > 0) {
          const language = result.data.detections[0][0].language;
          if (language !== 'en') {
            this.translate(language, this.document.emitHeadline).subscribe({
              next: (response: string) => {
                this.translatedTitle = response;
                this.cdr.detectChanges();
              },
              error: (error) => {
                this.snackBar.open(
                  'Translation failed. Please try again.',
                  'Close',
                  {
                    horizontalPosition: 'right',
                    duration: 5000,
                    verticalPosition: 'top',
                  },
                );
              },
            });
            this.translate(language, this.document.emitBodyText).subscribe({
              next: (response: string) => {
                this.translatedContent = response;
                this.cdr.detectChanges();
              },
              error: (error) => {
                this.snackBar.open(
                  'Translation failed. Please try again.',
                  'Close',
                  {
                    horizontalPosition: 'right',
                    duration: 5000,
                    verticalPosition: 'top',
                  },
                );
              },
            });
          }
        }
      })
      .catch((error) => console.log('error', error));
  }

  private translate(lang: string, text: string): Observable<string> {
    return new Observable((observer) => {
      this.documentService
        .translate(text, lang)
        .then((response) => response.json())
        .then((response: any) => {
          observer.next(response.data.translations[0].translatedText);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public onExpand(): void {
    if (!this.highlighted) {
      this.isExpanded = !this.isExpanded;
    }
    this.highlighted = false;
  }

  public onDrilldownContext(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: DrilldownScope.CONTEXT,
      value: extensionField,
      context: { document: this.document },
    });
  }

  public onDrilldown(params: IDrilldown): void {
    this.drilldown.emit(params);
  }

  public onShareEmail(document: DocumentItem): void {
    this.shareEmail.emit(document);
  }

  public onDrilldownReplies(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: DrilldownScope.REPLIES,
      value: extensionField,
    });
  }

  onDrilldownActor(): void {
    this.drilldown.emit({
      scope: DrilldownScope.ACTOR,
      value: this.document.emitActor,
    });
  }

  onDrilldownChannel(): void {
    this.drilldown.emit({
      scope: DrilldownScope.CHANNEL,
      value: this.document.emitChannel,
    });
  }

  public getRepliesCount(): void {
    this.documentService
      .getRepliesCount(this.document)
      .subscribe((response: number) => {
        this.repliesCount = response;
      });
  }

  public onCreateProfile(
    scope: ProfileDrildownScope,
    profileType: ProfileType,
  ): void {
    this.createProfile.emit(
      new ProfileDrilldown(
        scope,
        profileType,
        scope === ProfileDrildownScope.ACTOR
          ? this.document.emitActor
          : this.document.emitChannel,
      ),
    );
  }

  public onLinkProfile(profile: Profile, scope: ProfileDrildownScope): void {
    const link = new LinkTag(
      undefined,
      undefined,
      scope === ProfileDrildownScope.ACTOR
        ? ProfileDrildownScope.ACTOR
        : ProfileDrildownScope.CHANNEL,
      true,
      scope === ProfileDrildownScope.ACTOR
        ? this.document.emitActor
        : this.document.emitChannel,
      undefined,
      undefined,
      undefined,
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${
          scope === ProfileDrildownScope.ACTOR
            ? this.document.emitActor
            : this.document.emitChannel
        } has been linked tp profile ${profile.name}!`,
        'Close',
        {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        },
      );
    });
  }
}
