import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  Actions,
  AlertTypesIcons,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  ErrorMessages,
  FAwesomeModule,
  IError,
  IsTagEnabledPipe,
  LabelComponent,
  PillType,
  PriorityColor,
  PriorityLabel,
  QueryFilters,
  Sizes,
  TransactionNotificationType,
  TransactionNotificationTypeText,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { TransactionalNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { BackEndQuery } from '@portal/shared/models/backend-query-model';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { ProfilesExploreComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-explore/profiles-explore.component';
import { ChartExploreTimelineComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/chart-explore-timeline.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { GetActionPipe } from './notifications-transactional.pipe';

@Component({
  selector: 'itq-notifications-transactional',
  templateUrl: './notifications-transactional.component.html',
  styleUrls: ['./notifications-transactional.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    GetActionPipe,
    LabelComponent,
  ],
})
export class NotificationsTransactionalComponent {
  @Input() notification: TransactionalNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly TransactionNotificationTypeText = TransactionNotificationTypeText;
  readonly TransactionNotificationType = TransactionNotificationType;
  readonly PillType = PillType;

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly profileService: ProfileService,
    readonly router: Router,
    readonly isTagEnabledPipe: IsTagEnabledPipe,
    readonly widgetService: WidgetService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.class = !this.notification.read ? 'unread' : '';
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TransactionalNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      });
  }

  public onLoadAction(): void {
    const { triggerMetadata } = this.notification;

    switch (triggerMetadata.action) {
      case TransactionNotificationType.TAG_CREATED:
        this.handleCreateTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_EDITED:
        this.handleEditTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_DELETED:
        this.handleDeleteTagAction();
        break;
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
        this.handleLoadProfileAction(triggerMetadata);
        break;
      case TransactionNotificationType.PROFILE_DELETED:
        this.handleDeleteProfileAction();
        break;
      default:
        break;
    }
  }

  private handleDeleteTagAction(): void {
    this.router.navigateByUrl('/tags');
    this.notificationService.hideNotifications$.next();
  }

  private handleDeleteProfileAction(): void {
    this.router.navigateByUrl('/profiles');
    this.notificationService.hideNotifications$.next();
  }

  private handleEditTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe({
      next: (response: Tag) => {
        const isTagEnabled = this.isTagEnabledPipe.transform(response);
        if (isTagEnabled) {
          this.customOverlayService.open({
            data: {
              componentConfig: {
                component: TagWizardComponent,
                inputs: {
                  tag: response,
                  action: Actions.EDIT,
                  queryModel: new BackEndQuery(
                    response.query.operator,
                    response.query.conditions,
                    response.query.queries,
                  ).convertFromBackEndQuery(),
                },
              },
            },
            closeBtnStyle: 'basic',
            closeBtnClass: 'hidden',
            type: CustomOverlayType['almost-full'],
            component: ModalContainerComponent,
            disposeOnNavigation: true,
          });
        } else {
          this.customOverlayService.openCustom(
            {
              title: 'Oooops!',
              message: "You don't have permissions to edit this tag",
              icon: ['far', 'exclamation-circle'],
              size: '4x',
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent,
          );
        }
      },
      error: (error: IError) => {
        this.customOverlayService.openCustom(
          {
            title: 'Oooops!',
            message:
              error.error === ErrorMessages.TAG_NOT_FOUND
                ? 'This tag cannot be edited because the tag has been deleted.'
                : error.error,
            icon: ['far', 'exclamation-circle'],
            size: '4x',
            dialog: {
              type: DialogTypes.ALERT,
            },
          },
          DialogComponent,
        );
      },
    });
  }

  private handleCreateTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe(
      (tag: Tag) => {
        const timeline = this.widgetService.mergeTagIntoTimeline(tag);
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: ChartExploreTimelineComponent,
              inputs: {
                widget: timeline,
              },
            },
          },
          closeBtnStyle: 'basic',
          closeBtnClass: 'hidden',
          type: CustomOverlayType['almost-full'],
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      },
      (error: IError) => {
        this.customOverlayService.openCustom(
          {
            title: 'Oooops!',
            message:
              error.error === ErrorMessages.TAG_NOT_FOUND
                ? 'This tag cannot be explored because the tag has been deleted.'
                : error.error,
            icon: ['far', 'exclamation-circle'],
            size: '4x',
            dialog: {
              type: DialogTypes.ALERT,
            },
          },
          DialogComponent,
        );
      },
    );
  }

  private handleLoadProfileAction(triggerMetadata: {
    [key: string]: any;
  }): void {
    const { id } = triggerMetadata;
    this.profileService.getProfileById(id).subscribe(
      (profile: Profile) => {
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: ProfilesExploreComponent,
              inputs: {
                profile,
                action: Actions.EXPLORE,
              },
            },
          },
          closeBtnStyle: 'basic',
          type: CustomOverlayType['almost-full'],
          size: 'lg',
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      },
      (error: IError) => {
        this.customOverlayService.openCustom(
          {
            title: 'Oooops!',
            message:
              error.error === ErrorMessages.PROFILE_NOT_FOUND
                ? 'This vendor explored be edited because the vendor has been deleted.'
                : error.error,
            icon: ['far', 'exclamation-circle'],
            size: '4x',
            dialog: {
              type: DialogTypes.ALERT,
            },
          },
          DialogComponent,
        );
      },
    );
  }
}
