<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center space-x-2">
      <i
        [ngClass]="{
          'ph text-3xl ph-bold': true,
          'ph-pencil': action === Actions.EDIT,
          'ph-plus': action === Actions.CREATE,
          'ph-copy': action === Actions.CLONE
        }"
      ></i>

      <h1 class="text-28 font-medium pt-[4px]">
        {{
          action === Actions.EDIT
            ? 'Edit tag'
            : action === Actions.CLONE
              ? 'Clone tag'
              : 'Create tag'
        }}
      </h1>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col w-full bg-background rounded-md h-full">
      <itq-tag-wizard-navigation
        class="flex bg-white min-w-[250px]"
        [active]="active"
        [completed]="completed"
        [form]="form"
        (change)="onNavigationChange($event)"
      ></itq-tag-wizard-navigation>
      <div class="flex flex-col flex-1 overflow-hidden border-t border-border">
        <div
          [ngClass]="{
            'flex-col flex-1 h-full': true,
            flex: active === TagWizardNavItem.FILTERS,
            hidden: active !== TagWizardNavItem.FILTERS
          }"
        >
          @if (fields?.length > 0) {
            @if (this.query) {
              <itq-tag-wizard-filters
                class="flex-1 flex overflow-hidden"
                [form]="form"
                [initialState]="initialState"
                [fields]="fields"
                [queryModel]="query"
                [tag]="tag"
              ></itq-tag-wizard-filters>
            }
          }
          <div class="flex bg-white p-4 border-t border-border justify-end">
            <itq-mat-button
              [materialStyle]="'flat'"
              [style]="'primary'"
              [type]="'button'"
              (clickEvent)="onNavigationNotifications()"
            >
              <div class="flex justify-center items-center space-x-1">
                <span class="whitespace-nowrap pt-[2px]">NEXT</span>
                <i class="ph-caret-right ph ph-bold"></i>
              </div>
            </itq-mat-button>
          </div>
        </div>
        @if (this.keycloakService.isUserInRole('saas-alerts')) {
          <div
            [ngClass]="{
              'flex-col flex-1 h-full': true,
              flex: active === TagWizardNavItem.NOTIFICATIONS,
              hidden: active !== TagWizardNavItem.NOTIFICATIONS
            }"
          >
            <itq-tag-wizard-alerts
              class="flex-1 flex overflow-auto"
              [action]="action"
              [fields]="this.fields"
              [form]="form"
              [tag]="tag"
            ></itq-tag-wizard-alerts>
            <div
              class="flex bg-white p-4 justify-between border-t border-border"
            >
              <itq-mat-button
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onNavigationFilters()"
              >
                <div class="flex justify-center items-center space-x-1">
                  <i class="ph-caret-left ph ph-bold"></i>
                  <span class="whitespace-nowrap pt-[2px]">PREV</span>
                </div>
              </itq-mat-button>
              <itq-mat-button
                [disabled]="
                  !form?.get('filters')?.valid || !form?.get('alerts')?.valid
                "
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onNavigationSave()"
              >
                <div class="flex justify-center items-center space-x-1">
                  <span class="whitespace-nowrap pt-[2px]">NEXT</span>
                  <i class="ph-caret-right ph ph-bold"></i>
                </div>
              </itq-mat-button>
            </div>
          </div>
        }
        <div
          [ngClass]="{
            'flex-col flex-1 h-full': true,
            flex: active === TagWizardNavItem.SAVE,
            hidden: active !== TagWizardNavItem.SAVE
          }"
        >
          <itq-tag-wizard-save
            class="flex-1 flex"
            [form]="form"
            [tag]="tag"
            [action]="action"
            [boardIds]="boardIds"
          ></itq-tag-wizard-save>
          <div class="flex bg-white p-4 justify-between border-t border-border">
            <itq-mat-button
              [materialStyle]="'flat'"
              [style]="'primary'"
              [type]="'button'"
              (clickEvent)="onNavigationNotifications()"
            >
              <div class="flex justify-center items-center space-x-1">
                <i class="ph-caret-left ph ph-bold"></i>
                <span class="whitespace-nowrap pt-[2px]">PREV</span>
              </div>
            </itq-mat-button>
            @if (this.action === Actions.CREATE) {
              <itq-mat-button
                [disabled]="!form.valid"
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onSubmit()"
              >
                FINISH
              </itq-mat-button>
            }

            @if (this.action === Actions.EDIT) {
              <itq-mat-button
                [disabled]="!form.valid || form.pristine"
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onSubmit()"
              >
                UPDATE
              </itq-mat-button>
            }
            @if (this.action === Actions.CLONE) {
              <itq-mat-button
                [disabled]="!form.valid"
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onSubmit()"
              >
                CLONE
              </itq-mat-button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader> </itq-loader>
}
