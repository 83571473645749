import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import {
  DateQueryType,
  DateRangeHelper,
  Event,
  EventTeams,
  EventTimelineItemPosition,
  GroupEvent,
  ICreateEvent,
  IEvent,
  IEventData,
  IEventResults,
  IEventType,
  MomentService,
  QueryFilters,
} from '@intorqa-ui/core';
import moment from 'moment';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  public drilldown$ = new Subject<Event>();
  public expandEvent$ = new Subject<void>();

  public events: Array<Event>;

  constructor(
    private apiRequestService: ApiRequestService,
    readonly momentService: MomentService,
  ) {}

  public getEvents(
    initialState: QueryFilters,
    ecosystemId: string,
  ): Observable<IEventData> {
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&pageSize=${initialState.pageSize}`;
    pageQuery += `&sortField=${initialState.sort?.active}`;
    pageQuery += `&sortOrder=${initialState.sort?.direction}`;
    pageQuery += `&ecosystemId=${ecosystemId}`;
    if (initialState?.query) {
      pageQuery += `&query=${initialState.query}`;
    }
    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${preset?.start.valueOf()}`;
        pageQuery += `&dateTo=${preset?.end.valueOf()}`;
      }
    }
    return this.apiRequestService
      .post(
        `/events/list?${pageQuery}`,
        new DTOTypeConverter<IEventResults>(),
        !initialState?.query
          ? initialState.columnFilters?.length > 0
            ? initialState.columnFilters
            : undefined
          : undefined,
      )
      .pipe(
        map((response: IEventResults) => {
          this.events = response.items.map(
            (item: IEvent) =>
              new Event(
                item.id,
                item.name,
                item.typeId,
                item.typeName,
                item.createdDate,
                item.ecosystemId,
                item.description,
                item.createdBy,
                item.date,
                item.updatedBy,
                item.updatedDate,
                item.team,
              ),
          );
          return { items: this.events, totalCount: response.totalCount };
        }),
      );
  }

  public getEventTypes(
    ecosystemId: string,
    team?: EventTeams,
  ): Observable<Array<IEventType>> {
    let pageQuery = `ecosystemId=${ecosystemId}`;
    if (team) {
      pageQuery += `&team=${team}`;
    }

    return this.apiRequestService.get(
      `/events/types?${pageQuery}`,
      new DTOTypeConverter<Array<IEventType>>(),
    );
  }

  public create(payload: ICreateEvent): Observable<Event> {
    return this.apiRequestService
      .post('/events', new DTOTypeConverter<IEvent>(), JSON.stringify(payload))
      .pipe(
        map((response: IEvent) => {
          return new Event(
            response.id,
            response.name,
            response.typeId,
            response.typeName,
            response.createdDate,
            response.ecosystemId,
            response.description,
            response.createdBy,
            response.date,
            response.updatedBy,
            response.updatedDate,
            response.team,
          );
        }),
      );
  }

  public update(
    attributes: { [key: string]: any },
    id: string,
  ): Observable<Event> {
    return this.apiRequestService.put(`/events/${id}`, attributes).pipe(
      map((response: IEvent) => {
        const event = new Event(
          response.id,
          response.name,
          response.typeId,
          response.typeName,
          response.createdDate,
          response.ecosystemId,
          response.description,
          response.createdBy,
          response.date,
          response.updatedBy,
          response.updatedDate,
          response.team,
        );
        this.events = this.events.map((item: Event) => {
          if (event.id === id) {
            return event;
          } else {
            return item;
          }
        });
        return event;
      }),
    );
  }

  public delete(id: string): Observable<boolean> {
    return this.apiRequestService.delete(`/events/${id}`).pipe(
      map((response: boolean) => {
        this.events = this.events.filter((item: Event) => item.id !== id);
        return response;
      }),
    );
  }

  public getTotalCount(ecosystemId: string): Observable<number> {
    return this.apiRequestService.get(
      `/events/count?ecosystemId=${ecosystemId}`,
      new DTOTypeConverter<number>(),
    );
  }

  public findEventById(id: string): Event {
    return this.events.find((event: Event) => event.id === id);
  }

  public getUsers(): Observable<Array<string>> {
    return this.apiRequestService.get(
      '/events/users',
      new DTOTypeConverter<Array<string>>(),
      undefined,
    );
  }

  public groupEventsByDate(events: Array<Event>): Array<GroupEvent> {
    const groupedEvents = events?.reduce((acc, event) => {
      const eventDate = this.momentService
        .toLocalTimezone(moment(event.date))
        .format('DD-MM-YYYY');
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
      return acc;
    }, {});

    const groupedEventsArray = Object.entries(groupedEvents).map(
      ([date, events]) => ({
        date,
        events: events as Array<Event>,
      }),
    );

    return groupedEventsArray.map(({ date, events }) => ({
      timestamp: date,
      title: events[0].name,
      description: events[0].description,
      id: events[0].id,
      itemPosition:
        events[0].team === EventTeams.SECURITY_TEAM
          ? EventTimelineItemPosition.ON_LEFT
          : EventTimelineItemPosition.ON_RIGHT,
      team: events[0].team,
      events: events,
    }));
  }
}
