<div
  [ngClass]="{
    input__wrapper: true,
    icon: icon ? true : false,
    hasValue: searchTerm && searchTerm !== '' ? true : false
  }"
  (click)="$event.stopImmediatePropagation()"
>
  @if (icon) {
    <i class="ph ph-magnifying-glass text-xl input__icon"></i>
  }

  <input
    #input
    class="form-control text-light"
    [type]="type"
    [required]="required"
    [placeholder]="placeholder"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
    [autofocus]="autoFocus"
    (blur)="onBlur()"
    (keyup.enter)="onSubmit($event)"
    (focus)="onFocus()"
    (click)="$event.stopPropagation()"
    (keydown)="dirty = true; $event.stopPropagation()"
  />
  @if (searchTerm && searchTerm !== '' && clearSelection) {
    <itq-mat-button
      [class]="'btn--delete'"
      [materialStyle]="'basic'"
      [type]="'button'"
      [disabled]="disabled"
      (clickEvent)="onClear($event)"
    >
      <i class="ph ph-x text-xl fill-current"></i>
    </itq-mat-button>
  }
</div>
