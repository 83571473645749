import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  ComponentType,
  ConvertToUDSPipe,
  CurrencyPrefixDescription,
  IPill,
  MomentService,
} from '@intorqa-ui/core';
import { cloneDeep } from 'lodash';
import { MetadataFields } from '../enums/profiles-metadata.enum';
import { ProfileMetadata } from '../models/profile-metadata';

import { UserService } from '@portal/shared/services/user.service';
import { ChartOrientation, LineMode } from '@portal/widgets/enums/chart.enum';
import moment from 'moment';
import { Cheat_Reputation_Map, VISIBLE_COLUNNS } from '../const/vendors.const';
import { IProfileDataSource } from '../interfaces/profile.interface';
import {
  IMetadataColumn,
  IMetadataField,
} from '../interfaces/vendors.interface';
import { Profile } from '../models/profile';
import { ProfileList } from '../models/profile-list';
import { ProfileService } from '../services/vendors.service';

@Pipe({
  name: 'transformMetadataValues',
  standalone: true,
})
export class TransformMetadataValuesPipe implements PipeTransform {
  constructor(private momentService: MomentService) {}

  transform(metadata: ProfileMetadata): Array<IPill> | undefined {
    if (!metadata?.values) {
      return undefined;
    }
    const values = JSON.parse(metadata?.values);
    if (metadata.component === ComponentType.MULTIPLE_DROPDOWN) {
      if (Array.isArray(values)) {
        return values?.map((item: any) => ({
          name: item?.name,
          tooltip: undefined,
        }));
      }
    }
    if (metadata.component === ComponentType.DROPDOWN) {
      return [
        {
          name: values,
          tooltip: undefined,
        },
      ];
    }
    if (metadata.component === ComponentType.DATE) {
      return [
        {
          name: this.momentService
            .toLocalTimezone(moment(values))
            .format('DD-MM-yyyy'),
          tooltip: undefined,
        },
      ];
    } else return undefined;
  }
}

@Pipe({
  name: 'getTrendIcon',
  standalone: true,
})
export class GetTrendIconPipe implements PipeTransform {
  constructor() {}

  transform(trend: number): IconName {
    if (trend === 0) {
      return 'equals';
    }
    if (trend < 0) {
      return 'chevron-down';
    }
    return 'chevron-up';
  }
}

@Pipe({
  name: 'getTrendColor',
  standalone: true,
})
export class GetTrendIconColor implements PipeTransform {
  constructor() {}

  transform(trend: number): string {
    if (trend === 0) {
      return 'black';
    }
    if (trend < 0) {
      return '#e85e6c';
    }
    return '#1de62a';
  }
}

@Pipe({
  name: 'getMetadataField',
})
export class GetMetadataFieldPipe implements PipeTransform {
  transform(
    metadata: Array<ProfileMetadata>,
    field: MetadataFields,
  ): ProfileMetadata {
    return metadata?.find((item: ProfileMetadata) => item.name === field);
  }
}
@Pipe({
  name: 'transformProfiles',
  standalone: true,
})
export class TransformProfilesPipe implements PipeTransform {
  constructor(
    private momentService: MomentService,
    private profileService: ProfileService,
    readonly userService: UserService,
  ) {}

  transform(data: Array<Profile>): Array<IProfileDataSource> {
    if (!data || data?.length === 0) return [];
    const profileTypeName = this.profileService.getProfileTypeById(
      data[0].profileTypeId,
    )?.name;
    const metadataColumns = VISIBLE_COLUNNS.find((item: IMetadataField) => {
      return item.fieldName === profileTypeName;
    });
    return cloneDeep(data)?.map((item: ProfileList) => {
      let metadataResult = {};
      metadataColumns?.columns?.forEach((column: IMetadataColumn) => {
        const metadata = item.metadata?.find(
          (metadataItem: ProfileMetadata) => {
            return metadataItem.name === column.dataField;
          },
        );
        if (metadata) {
          metadataResult = {
            ...metadataResult,
            ...{ [column.dataField]: metadata },
          };
        }
      });
      let result = {
        profileTypeId: item.profileTypeId,
        profileId: item.profileId,
        updatedBy: item.updatedBy,
        name: item.name,
        activity: item.activity,
        activityData: item.activityData,
        description: item.description,
        trend: item.trend,
        updatedDate: this.momentService
          .toLocalTimezone(moment(item.updatedDate))
          .format('DD-MM-yyyy'),
        options: {
          orientation: ChartOrientation.VERTICAL,
          mode: LineMode.LINE,
          tooltip: {
            show: false,
          },
          xAxis: {
            show: false,
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            show: false,
            splitLine: {
              show: false,
            },
          },
          series: {
            showSymbol: false,
            label: {
              show: false,
            },
            lineStyle: {
              width: 2,
            },
          },
        },
      };

      return { ...result, ...metadataResult };
    });
  }
}
@Pipe({
  name: 'adaptMetadatataValues',
  standalone: true,
})
export class AdaptMetadataValuesPipe implements PipeTransform {
  constructor(private convertToUSD: ConvertToUDSPipe) {}

  transform(
    dataset: IProfileDataSource,
    field: MetadataFields,
  ): ProfileMetadata {
    const data = cloneDeep(dataset);
    if (!data[field]) return data[field];
    const values = JSON.parse(data[field].values);
    if (data[field].name === MetadataFields.CHEAT_REPUTATION_SCORE) {
      const map = Cheat_Reputation_Map.find(
        (item: { name: string; value: number }) =>
          item.value === parseInt(values),
      );
      data[field].values = `${values} - ${map?.name}`;
    } else if (data[field].name === MetadataFields.PRICE_PER_MONTH) {
      let currencyValue = data[field].values;
      if (currencyValue) {
        const sourceCurrency = data[MetadataFields.PRICING_CURRENCY]?.values;
        if (sourceCurrency) {
          const price = parseInt(JSON.parse(currencyValue));
          data[field].values = this.convertToUSD.transform(
            price,
            CurrencyPrefixDescription[JSON.parse(sourceCurrency)],
          );
        }
        return data[field];
      }
    } else {
      data[field].values = values;
    }
    return data[field];
  }
}

@Pipe({
  name: 'pillFormat',
  standalone: true,
})
export class PillFormatPipe implements PipeTransform {
  constructor(readonly numberPipe: DecimalPipe) {}

  transform(data: IProfileDataSource): IPill {
    let activity: string;
    if (data.activity >= 1000000) {
      activity = (data.activity / 1000000).toFixed(1) + 'M';
    } else if (data.activity >= 1000) {
      activity = (data.activity / 1000).toFixed(1) + 'K';
    } else {
      activity = this.numberPipe.transform(data.activity).toString();
    }
    return {
      name: activity.toString(),
      tooltip: undefined,
    };
  }
}

@Pipe({
  name: 'getProfileTypeName',
  standalone: true,
})
export class GetProfileTypeNamePipe implements PipeTransform {
  constructor(readonly profileService: ProfileService) {}

  transform(typeId: string): string {
    return this.profileService.getProfileTypeById(typeId)?.name;
  }
}
