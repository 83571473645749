import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AlertTypesIcons,
  AlertTypesLabel,
  Condition_Notification,
  convertLocalTimezonePipe,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  LabelComponent,
  PillType,
  PriorityColor,
  PriorityLabel,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ThresholdNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { ChartExploreTimelineComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/chart-explore-timeline.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';
import { GetPeriodLabelPipe } from './notifications-tag-threshold.pipe';

@Component({
  selector: 'itq-notifications-tag-threshold',
  templateUrl: './notifications-tag-threshold.component.html',
  styleUrls: ['./notifications-tag-threshold.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    GetPeriodLabelPipe,
    LabelComponent,
    convertLocalTimezonePipe,
  ],
})
export class NotificationsTagThresholdComponent implements OnInit {
  @Input() notification: ThresholdNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly Condition_Notification = Condition_Notification;
  readonly PillType = PillType;

  private subscriptions = new Subscription();

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly widgetService: WidgetService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.bindUnredSubscritpion();
    this.class = !this.notification.read ? 'unread' : '';
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private bindUnredSubscritpion(): void {
    this.subscriptions.add(
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      }),
    );
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: ThresholdNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      });
  }

  public onExploreTag(): void {
    const { tagId } = this.notification;
    this.tagService.getTagById(tagId).subscribe((tag: Tag) => {
      const timeline = this.widgetService.mergeTagIntoTimeline(tag);
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: ChartExploreTimelineComponent,
            inputs: {
              widget: timeline.cloneDeep(),
            },
          },
        },
        closeBtnStyle: 'basic',
        closeBtnClass: 'hidden',
        type: CustomOverlayType['almost-full'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    });
  }
}
