<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <itq-widget-header
    [widget]="widget"
    [widgetMoreTemplate]="widgetMoreTemplate"
    [count]="count"
  ></itq-widget-header>
  <div
    class="flex flex-1 overflow-auto w-full border-t border-border p-4"
    #chartContainer
  >
    @if (dataSource) {
      <itq-events-timeline
        class="flex w-full h-full"
        [orientation]="this.widget?.options?.orientation"
        [dataSource]="dataSource | sortDescending"
        [allowDrilldown]="false"
        [widget]="widget"
      ></itq-events-timeline>
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate>
  <itq-widget-actions
    [selectedDisplayType]="selectedDisplayType"
    [widget]="eventTimelineWidget"
    [displayTypes]="displayTypes"
    (edit)="onEdit()"
    (explore)="onExplore($event)"
    (delete)="onDeleteWidget()"
    (export)="onExportWidget()"
    (changeDisplay)="onChangeDisplay($event)"
  ></itq-widget-actions>
</ng-template>
