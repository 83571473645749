import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ICustomTag } from '@portal/tags/interfaces/tag.interface';
import moment from 'moment';
import { MomentService } from '../services/moment.service';

@Pipe({
  name: 'isArray',
})
export class IsArrayPipe implements PipeTransform {
  transform(value: string): boolean {
    if (!value) return false;
    try {
      return typeof value === 'string'
        ? Array.isArray(JSON.parse(value))
        : Array.isArray(value);
    } catch (e) {
      return false;
    }
  }
}

@Pipe({
  name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return value;
    try {
      return JSON.parse(value);
    } catch (err: any) {
      return value;
    }
  }
}

@Pipe({
  name: 'castFormGroup',
  standalone: true,
})
export class CastFormGroupPipe implements PipeTransform {
  transform(form: FormGroup, control: string): FormGroup {
    if (form?.get(control)) {
      return form?.get(control) as FormGroup;
    }
    return undefined;
  }
}

@Pipe({
  name: 'convertLocalTimezone',
  standalone: true,
})
export class convertLocalTimezonePipe implements PipeTransform {
  constructor(readonly momentService: MomentService) {}

  transform(date: number, format: string): string {
    return this.momentService.toLocalTimezone(moment(date)).format(format);
  }
}

@Pipe({
  name: 'formatNumber',
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  constructor(readonly numberPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return this.numberPipe.transform(value).toString();
    }
  }
}

@Pipe({
  name: 'striphtml',
  pure: false,
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value && value.replace('↵', "'\n'"); // replace tags
  }
}

@Pipe({
  name: 'stripImg',
})
export class StripeImgPipe implements PipeTransform {
  transform(value: string): any {
    return value?.replace(new RegExp('<img.*?src="(.*?)"[^>]+>', 'g'), '');
  }
}

@Pipe({
  name: 'millisToDate',
  standalone: true,
})
export class MillisToDate implements PipeTransform {
  transform(value: number): Date {
    return new Date(value);
  }
}

@Pipe({
  name: 'timeAgo',
  pure: true,
})
export class TimeAgoPipe implements PipeTransform {
  constructor() {}

  transform(epochDate: number): any {
    return moment(epochDate).fromNow();
  }
}

@Pipe({ name: 'safe', pure: true })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'detecturl',
  pure: false,
})
export class DetectUrlPipe implements PipeTransform {
  transform(value: string): any {
    return value.replace(new RegExp('\n', 'g'), '<br />');
  }
}

@Pipe({
  name: 'getUsernameInitials',
  standalone: true,
})
export class GetUsernameInitialsPipe implements PipeTransform {
  constructor() {}
  transform(username: string): string {
    if (!username) return '';
    const splittedUsername = username.split(' ');
    return (
      splittedUsername[0]
        ?.substring(0, 1)
        ?.toUpperCase()
        ?.concat(splittedUsername[1]?.substring(0, 1)?.toUpperCase() || '') ||
      ''
    );
  }
}

@Pipe({
  name: 'urlify',
})
export class UrlifyPipe implements PipeTransform {
  transform(text: string): any {
    var urlRegex =
      /(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text?.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }
}

@Pipe({
  name: 'highlightSearch',
  pure: true,
  standalone: true,
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: string): string {
    if (!args) {
      return value;
    }
    if (!value) {
      return value;
    }
    let searchTerm = args
      .replaceAll('"', ' ')
      .replaceAll('|', ' ')
      .replaceAll('*', ' ')
      .replaceAll('+', ' ')
      .replaceAll('(', ' ')
      .replaceAll(')', ' ')
      .replaceAll('-', ' ');

    let regex = new RegExp('~[1-9]', 'gi');
    searchTerm = searchTerm.replaceAll(regex, ' ');

    const pattern = new RegExp(`(${searchTerm})`, 'gi');
    return value?.replace(
      pattern,
      (match) => `<span class='highlight'>${match}</span>`,
    );
  }
}
@Pipe({
  name: 'isSelected',
  standalone: true,
})
export class IsSelectedPipe implements PipeTransform {
  constructor() {}

  transform(item: ICustomTag, selections: Array<ICustomTag>): boolean {
    return selections?.find((tag: ICustomTag) => tag.name === item.name)
      ? true
      : false;
  }
}
