import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import {
  convertLocalTimezonePipe,
  CoreModule,
  DateRangeHelper,
  FAwesomeModule,
  IEventData,
  TimelineOrientation,
} from '@intorqa-ui/core';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { GroupByMonthPipe } from '@portal/shared/pipes/events.pipe';
import { ChartService } from '@portal/shared/services/chart.service';
import { EventItemComponent } from '../event-item/event-item.component';

@Component({
  selector: 'itq-events-timeline',
  templateUrl: './events-timeline.component.html',
  styleUrls: ['./events-timeline.component.scss'],
  standalone: true,
  imports: [
    EventItemComponent,
    CommonModule,
    GroupByMonthPipe,
    MatChipsModule,
    FAwesomeModule,
    CoreModule,
    convertLocalTimezonePipe,
  ],
})
export class EventsTimelineComponent implements OnInit {
  @Input() widget: EventTimeline;
  @Input() segment: any;
  @Input() orientation = TimelineOrientation.VERTICAL;
  @Input() dataSource: IEventData;
  @Input() allowDrilldown = true;

  readonly TimelineOrientation = TimelineOrientation;
  readonly DateRangeHelper = DateRangeHelper;

  constructor(readonly chartService: ChartService) {}

  ngOnInit() {}

  public onRemoveSegment(): void {
    this.chartService.removeSegment$.next();
  }
}
