import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  EventBusService,
  FAwesomeModule,
  PillType,
  QueryFilters,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  ISubChannelDrilldown,
  IRepliesDrilldown,
  IDrilldown,
  IDiscordActorDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { Query } from '@portal/shared/models/query-model';
import { UserService } from '@portal/shared/services/user.service';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { ChartExploreTimelineComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/chart-explore-timeline.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TaggingStatus } from 'projects/portal/src/app/tags/enums/tag.enum';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { TimelineWidgetHeaderComponent } from './components/timeline-widget-header/timeline-widget-header.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';

@Component({
  selector: 'itq-timeline-widget',
  templateUrl: './timeline-widget.component.html',
  styleUrls: ['./timeline-widget.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    MatTooltip,
    CommonModule,
    TimelineWidgetHeaderComponent,
    DocumentsFeedComponent,
  ],
  providers: [VirtualScrollService],
})
export class TimelineWidgetComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() board: Board;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public chartInstance: echarts.ECharts;
  public get data(): IData {
    return this._data;
  }

  public set data(value: IData) {
    if (this.initialState.page > 1) {
      this._data.result = [...this.data.result, ...value.result];
    } else {
      this._data = value;
    }
  }
  private _data: IData;
  public showLoader = false;
  public noContentTitle = 'No results found';
  public noContentMessage = 'Please update your filters and try again';
  public taggingStatus: TaggingStatus;
  private subscriptions = new Subscription();
  public initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @ViewChild(DocumentsFeedComponent) documentsFeedRef: DocumentsFeedComponent;

  readonly TaggingStatus = TaggingStatus;
  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    private widgetService: WidgetService,
    private tagService: TagService,
    private eventBusService: EventBusService,
    private customOverlayService: CustomOverlayService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly boardService: BoardService,
    readonly virtualScrollService: VirtualScrollService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.bindQueryParamsSubscription();
    this.bindRemoveWidgetSubscription();
    this.bindReloadWidgetSubscription();
    this.bindExploreWidgetSubscription();
    this.registerEventBusEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private bindExploreWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.explore$.subscribe((response: Widget) => {
        if (response.widgetId === this.widget.widgetId) {
          this.onExplore();
        }
      }),
    );
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterTaggingEventBusEvent();
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: Widget; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.widget.widgetId
          ) {
            this.initialState = params.initialState;
            this.onDataBound(this.initialState);
          }
        },
      ),
    );
  }

  private bindQueryParamsSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params?.widgetid && this.widget.widgetId === params.widgetid) {
          this.onExplore();
        }
      }),
    );
  }

  private bindRemoveWidgetSubscription(): void {
    this.subscriptions.add(
      this.boardService.removeTimelines$.subscribe((widget: Timeline) => {
        if (widget.widgetId === this.widget.widgetId) {
          this.onDeleteWidget();
        }
      }),
    );
  }

  public scrollToTop(): void {
    this.documentsFeedRef.documentContainer.nativeElement.scrollTop = 0;
  }

  public onUpdateRef(ref: echarts.ECharts): void {
    this.chartInstance = ref;
  }

  public onDeleteWidget(): void {
    this.unRegisterTaggingEventBusEvent();
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [this.widget.widgetId],
      })
      .subscribe();
  }

  public onExplore(): void {
    const initialState = this.initialState.cloneDeep();
    initialState.resetPagination();
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartExploreTimelineComponent,
          inputs: {
            widget: this.widget,
            initialState,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordActorComponent,
          inputs: {
            actor: extensionField,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownSubChannelComponent,
          inputs: {
            subChannel,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    const queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    const initialState = new QueryFilters(
      30,
      params?.page,
      params?.where,
      params.query,
      undefined,
      undefined,
    );
    if (params?.query) {
      queryModel.addRule({
        entity: 'content',
        field: 'content',
        operator: 'contains',
        value: [params?.query],
      });
    }
    this.tagService
      .execute(
        initialState,
        queryModel.convertToBackEndQuery(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IData) => {
        this.data = response;
        this.onCheckTagging();
        this.showLoader = false;
      });
  }

  private onCheckTagging(): void {
    this.taggingStatus =
      this.widget.lastTaggingTime > this.widget.updatedDate
        ? TaggingStatus.COMPLETED
        : TaggingStatus.IN_PROGRESS;
    if (this.taggingStatus === TaggingStatus.IN_PROGRESS) {
      this.noContentTitle = 'No results yet';
      this.noContentMessage =
        'Your tag is being built, please wait to see content.';

      this.registerEventBusTaggingEvent();
    }
    if (
      this.data?.count === 0 &&
      this.taggingStatus === TaggingStatus.COMPLETED
    ) {
      this.noContentTitle = 'No results found';
      this.noContentMessage = 'Please update your filters and try again';
    }
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private onDelete(): void {}

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.widgetService.delete$.next(this.widget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: Timeline;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: Timeline;
        address: string;
        type: string;
      },
    ) => {
      this.widget = WidgetFactory.createWidget(msg.body) as Timeline;
      this.initialState.resetPagination();
      this.onDataBound(this.initialState);
    };
  }

  private registerEventBusTaggingEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.dataSource[0]}`,
      this.handleTaggingMessages(),
    );
  }

  private unRegisterTaggingEventBusEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.dataSource[0]}`,
      this.handleTaggingMessages(),
    );
  }

  private handleTaggingMessages(): (
    err: Error,
    msg: {
      body: string;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: string;
        address: string;
        type: string;
      },
    ) => {
      if (msg.body === this.widget.dataSource[0]) {
        this.onDataBound(this.initialState);
        this.taggingStatus = TaggingStatus.COMPLETED;
        this.unRegisterTaggingEventBusEvent();
      }
    };
  }
}
