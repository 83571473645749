@if (currentBreakpoint === '2xl') {
  <itq-library-sidebar
    class="flex w-[350px] min-w-[250px] bg-background border-r border-border"
  ></itq-library-sidebar>
  <router-outlet></router-outlet>
} @else {
  <mat-drawer-container [hasBackdrop]="false" class="w-full">
    <mat-drawer #sidenav [mode]="'over'">
      <itq-library-sidebar
        class="flex h-full w-[350px] min-w-[250px] bg-background border-r border-border"
      ></itq-library-sidebar>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
}
