import { ChartType, FoamtreeMode } from '@portal/widgets/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const TOPIC_CLUSTERS_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'Polygonal',
    type: ChartType.FOAMTREE,
    options: {
      layout: FoamtreeMode.POLYGONAL,
    },
    svgIcon: 'ripl',
    tooltip: 'Polygonal',
  },
  {
    id: 'Rectangular',
    type: ChartType.FOAMTREE,
    options: {
      layout: FoamtreeMode.RECTANGULAR,
    },
    phosphorIcon: 'ph-grid-nine',
    tooltip: 'Rectangular',
  },
];
