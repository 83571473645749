<ng-container [formGroup]="form">
  <div class="connections__toolbar">
    <itq-pill
      [type]="PillType.CUSTOM"
      [class]="'secondary rounded-md h-[36px]'"
      >{{ dataSource?.totalCount || 0 | number }}</itq-pill
    >
    <div class="flex space-x-2" *ngIf="profileAction === Actions.EXPLORE">
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        (clickEvent)="onExit()"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="whitespace-nowrap">VIEW CONTENT</span>
      </itq-mat-button>
      <itq-mat-button
        [style]="'primary'"
        [type]="'button'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'cog']"></fa-icon>
        <span class="whitespace-nowrap">SETTINGS</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__wrapper">
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onAddConnection()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="whitespace-nowrap">ADD CONNECTION</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="flex flex-col">
    <itq-virtual-table
      [showFilters]="isFilterable"
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any connections yet!'"
      [emptyMessageDescription]="'To add a connection click the button below.'"
      (dataBound)="onDataBound($event)"
    ></itq-virtual-table>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <div class="flex justify-center w-full">
          <i class="ph ph-dots-three ph-bold"></i>
        </div>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex items-center flex-col w-full">
          <itq-mat-button
            class="w-full"
            [materialStyle]="'basic'"
            [size]="Sizes.LG"
            [align]="Align.START"
            (clickEvent)="onEditConnection(row)"
          >
            <itq-label
              [label]="'Edit'"
              [icon]="'ph-pencil'"
              class="font-normal"
            ></itq-label>
          </itq-mat-button>
          <itq-mat-button
            class="w-full"
            [size]="Sizes.LG"
            [align]="Align.START"
            [materialStyle]="'basic'"
            (clickEvent)="onDeleteConnection(row)"
          >
            <itq-label
              [label]="'Delete'"
              [icon]="'ph-trash'"
              class="font-normal"
            ></itq-label>
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row>
      <span [matTooltip]="row.updatedBy">
        {{ row.updatedDate | convertLocalTimezone: 'DD-MM-yyyy' }}
      </span>
    </ng-template>
    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
  </div>
</ng-container>
