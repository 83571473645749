import { Component, Inject, OnInit } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  PillType,
  Sizes,
} from '@intorqa-ui/core';
import { TagService } from '@portal/tags/services/tag.service';
import { ApiSettingsService } from './api-settings.service';

@Component({
  selector: 'itq-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.scss'],
  standalone: true,
  imports: [CoreModule, MatTooltipModule, CommonModule, ClipboardModule],
})
export class ApiSettingsComponent implements OnInit {
  public showLoader = true;
  public apiKey: string;
  public externalApiBase: string;
  public curlCommand: string;
  public baseUrl: string;
  public apiKeyPrefix: string;
  public tagId: string;

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    public apiSettingsService: ApiSettingsService,
    private snackBar: MatSnackBar,
    private tagService: TagService,
    private customOverlayRef: CustomOverlayRef,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.externalApiBase = window.location.origin.replace('saas', 'api');
    this.curlCommand = this.getCurrentDateEpochSec();
    this.baseUrl =
      this.externalApiBase +
      '/key/' +
      (this.apiKey ? this.apiKey : '<strong>%yourKey%<strong>');
  }

  ngOnInit(): void {
    this.tagId = this.config.data.timeline?.dataSource[0];
    this.onGetApiKeyPrefix();
  }

  private onGetApiKeyPrefix(): void {
    this.tagService.getApiKeyPrefix(this.tagId).subscribe({
      next: (response: string) => {
        this.apiKeyPrefix = response;
        if (!this.apiKeyPrefix) {
          this.onGenerateKey();
        } else {
          this.showLoader = false;
        }
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  getCurrentDateEpochSec(): string {
    const currentEpochSeconds = Math.trunc(new Date().getTime() / 1000);
    return (
      "curl -i -H 'Accept: application/json' -H 'Content-Type: application/json' -X GET '" +
      this.externalApiBase +
      '/key/' +
      (this.apiKey ? this.apiKey : '<strong>%yourKey%</strong>') +
      '?page=1&pageSize=100&fromEpochSeconds=0&toEpochSeconds=' +
      currentEpochSeconds +
      "'"
    );
  }

  onResetKey(): void {
    this.customOverlayService.openCustom(
      {
        title: 'Reset credentials?',
        message: 'This will delete the old key. Are you sure?',
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.showLoader = true;
          this.onGenerateKey();
        }
      },
    );
  }

  public onGenerateKey(): void {
    this.apiSettingsService.generateKey(this.tagId).subscribe({
      next: (response: { apiKey: string; prefix: string }) => {
        this.apiKey = response.apiKey;
        this.curlCommand = this.getCurrentDateEpochSec();
        this.baseUrl =
          this.externalApiBase +
          '/key/' +
          (this.apiKey ? this.apiKey : '<strong>%yourKey%<strong>');
        this.apiKeyPrefix = response.prefix;
        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  copy(scope: string): void {
    let title: string;
    let message: string;
    switch (scope) {
      case 'key':
        title = 'Api key copied!';
        message = 'Your Intorqa api key has been copied successfully!';
        break;
      case 'url':
        title = 'Url copied!';
        message = 'Url has been copied successfully!';
        break;
      default:
        title = 'Curl command copied!';
        message = 'The Curl command has been copied successfully!';
        break;
    }
    this.snackBar.open(message, 'Close', {
      horizontalPosition: 'right',
      duration: 55000,
      verticalPosition: 'top',
    });
  }
}
