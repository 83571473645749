import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  FieldSections,
  IPresetQuery,
  PillType,
  QueryFilters,
  TableColumn,
  UserTagCategory,
  Utils,
} from '@intorqa-ui/core';
import { EChartTypes } from '@portal/boards/const/widget.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { ChartType } from '@portal/widgets/enums/chart.enum';

import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TAG_ANALYSIS_CHART_TYPES } from '@portal/boards/const/tag-analysis.const';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
  ISearchResults,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
  IWidgetType,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import {
  combineLatest,
  delay,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';

@Component({
  selector: 'itq-chart-explore-tag-analysis',
  templateUrl: './chart-explore-tag-analysis.component.html',
  styleUrls: ['./chart-explore-tag-analysis.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ChartComponent,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,
    MapLabelPipe,
    TableChartComponent,
  ],
})
export class ChartExploreTagAnalysisComponent implements OnInit, AfterViewInit {
  @Input() segment: any;
  @Input() widget: TagAnalysis;
  @Input() initialState: QueryFilters;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;
  @ViewChild('chart') chart: ComponentRef<ChartComponent>;
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  public showLoader = false;
  public form: FormGroup;
  public widgetType: IWidgetType;
  public chartTypesDataSource = TAG_ANALYSIS_CHART_TYPES;
  public get documentsData(): IData {
    return this._documentsData;
  }

  public set documentsData(value: IData) {
    if (this.initialState.page > 1) {
      this._documentsData.result = [
        ...this.documentsData.result,
        ...value.result,
      ];
    } else {
      this._documentsData = value;
    }
  }
  public dataSource: IWidgetData;
  public chartInstance: ECharts;
  public tableColumns: Array<TableColumn>;
  private subscription = new Subscription();
  private _documentsData: IData;
  public count = 0;

  readonly PillType = PillType;
  readonly ChartType = ChartType;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly chartService: ChartService,
    readonly snackBar: MatSnackBar,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoryService: CategoryService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.bindLoaderSubscription();
    this.bindRemoveSegmentSubscription();
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private bindLoaderSubscription(): void {
    this.subscription.add(
      this.widgetService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.segment = undefined;
        this.cdr.detectChanges();
        this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
      }),
    );
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordActorComponent,
      inputs: {
        actor: extensionField,
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
      },
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
      },
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownSubChannelComponent,
      inputs: {
        subChannel,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
      },
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onChangeChartType(params: IDisplayType): void {
    if (
      this.widget.chartType === params.type &&
      JSON.stringify(this.widget.options) === JSON.stringify(params.options)
    ) {
      return;
    }
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.initTableColumns();
    this.dataSource.series = this.widget.transformData(this.dataSource.series);
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
  }

  private createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(this.initialState.query),
      chartType: new FormControl(this.widget.getDisplayType()?.id, [
        Validators.required,
      ]),
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private loadData(): void {
    this.widgetService.loader$.next(true);
    combineLatest([this.getData(), this.getDocuments()]).subscribe(() => {
      this.cdr.detectChanges();
      this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
      this.widgetService.loader$.next(false);
    });
  }

  public onDrilldownChart(segment: any): void {
    this.widgetService.loader$.next(true);
    this.segment = segment;
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
    this.initialState.resetPagination();

    this.getDocuments().subscribe(() => {
      this.widgetService.loader$.next(false);
    });
  }

  private getDocuments(): Observable<any> {
    if (!this.segment) return of(undefined).pipe(delay(0));
    const queryModel = new Query();
    return this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .pipe(
        map((response: Array<IFilterField>) => {
          const field = response.find((item: IFilterField) => {
            return (
              item.field === this.segment.data.field &&
              item.section === FieldSections.FILTER_TO
            );
          });
          if (this.initialState?.query) {
            queryModel.addRule({
              entity: 'content',
              field: 'content',
              operator: 'contains',
              value: [this.initialState?.query],
            });
          }
          queryModel.addRule({
            entity: field.field,
            field: field.label,
            operator: 'in',
            value: [this.segment?.data?.tagId || this.segment?.data?.name],
          });

          const query = queryModel.convertToBackEndQuery();

          return { query, field };
        }),
        switchMap(({ query, field }) =>
          this.widgetService.getWidgetDocuments(
            {
              widget: this.widget,
              filters: query,
            },
            {
              pageSize: 30,
              page: this.initialState?.page || 1,
              where: this.initialState?.where,
              dataValue: this.segment?.data?.tagId,
            },
          ),
        ),
        map((response: Array<ISearchResults>) => {
          this.documentsData = {
            result: response[0].items,
            count: response[0].totalHits,
          };
        }),
      );
  }

  private initTableColumns(): void {
    if (this.widget.chartType === ChartType.TABLE) {
      this.tableColumns = [
        {
          name: this.widget.dataSlicer?.label,
          dataKey: 'label',
          width: 'fit',
          isSortable: true,
        },
        {
          name: 'Count',
          dataKey: 'count',
          position: 'right',
          width: '100px',
          customRender: true,
          template: this.countTemplate,
          isSortable: true,
        },
      ];
    }
  }

  private addQueryRules(): Query {
    let queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    if (this.form?.get('query')?.value) {
      queryModel.addRule({
        entity: 'content',
        field: 'tag',
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    } else {
      queryModel.removeRuleByField('content');
    }
    return queryModel;
  }

  private getData(): Observable<IWidgetData> {
    const queryModel = this.addQueryRules();
    const query = queryModel.convertToBackEndQuery();
    return this.widgetService
      .getData(
        {
          widget: this.widget,
          filters: query,
        },
        this.initialState,
      )
      .pipe(
        map((response: IWidgetData) => {
          this.initTableColumns();
          this.dataSource = response;
          this.count = this.dataSource.totalHits;
          return response;
        }),
      );
  }

  public onDataBound(params: QueryFilters): void {
    this.initialState = params;
    this.loadData();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.segment = undefined;
    this.loadData();
  }

  public onExport(): void {
    this.widgetService.loader$.next(true);
    setTimeout(() => {
      if (EChartTypes.includes(this.widget.chartType)) {
        Utils.exportImage(
          this.chartInstance.getDataURL({
            pixelRatio: 2,
            backgroundColor: 'transparent',
          }),
          this.widget.name,
        );
        this.snackBar.open('Chart has been exported successfully!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.widgetService.loader$.next(false);
      } else {
        html2canvas(this.chartContainer.nativeElement).then((canvas: any) => {
          Utils.exportImage(canvas.toDataURL('image/png'), this.widget.name);
          this.snackBar.open('Chart has been exported successfully!', 'Close', {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          });
          this.widgetService.loader$.next(false);
        });
      }
    }, 100);
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onLoadCount(row: IDataPoint): void {
    this.widgetService.loader$.next(true);
    this.segment = {
      data: {
        tagId: row.tagId,
        name: row.label,
        value: row.count,
        field: row.field,
      },
    };

    this.initialState.resetPagination();
    this.getDocuments().subscribe(() => {
      this.widgetService.loader$.next(false);
    });
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
