import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CustomOverlayService,
  CustomOverlayType,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Profile } from '@portal/profiles/models/profile';
import { ScreenshotNote } from '@portal/profiles/models/screenshot';
import { FullViewScreenshotComponent } from './components/screenshot-view/full-view-screenshot.component';

@Component({
  selector: 'itq-view-screenshot',
  templateUrl: './view-screenshot.component.html',
  styleUrls: ['./view-screenshot.component.scss'],
})
export class ViewScreenshotComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: ScreenshotNote;

  readonly Sizes = Sizes;

  constructor(private customOverlayService: CustomOverlayService) {}

  ngOnInit() {}

  public onExpand(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: FullViewScreenshotComponent,
          inputs: { content: this.note.value },
        },
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
}
