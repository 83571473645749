<ng-container [formGroup]="form">
  <div class="flex h-full px-7 py-4 flex-col w-full" [formGroupName]="'save'">
    <div class="flex flex-col w-full">
      <h2 class="flex font-medium pb-4 text-xl">Save</h2>
    </div>
    <div
      class="flex mb-4 space-x-4 bg-white border border-border rounded-md p-4"
    >
      <div class="flex flex-1 flex-col">
        <span class="mb-1 text-13 font-medium flex"> NAME</span>
        <div class="flex space-x-4">
          <itq-input
            class="flex-1"
            [placeholder]="'Enter a tag name...'"
            formControlName="name"
          ></itq-input>
        </div>
        @if (
          form?.get('save.name')?.dirty &&
          form?.get('save.name')?.touched &&
          form?.get('save.name')?.getError('duplicateName')
        ) {
          <mat-error class="mt-2 flex items-center space-x-1 text-danger">
            <i class="ph ph-warning text-lg"></i>
            <span class="pt-[2px]"> Tag name already exists!</span>
          </mat-error>
        }
        @if (
          form?.get('save.name')?.dirty &&
          form?.get('save.name')?.touched &&
          (form?.get('save.name')?.getError('requiredField') ||
            form?.get('save.name')?.getError('required'))
        ) {
          <mat-error class="mt-2 flex items-center space-x-1 text-danger">
            <i class="ph ph-warning text-lg"></i>
            <span class="pt-[2px]"> Tag name is required!</span>
          </mat-error>
        }
      </div>
      <div class="flex flex-1 flex-col">
        <span class="mb-1 text-13 font-medium flex"> DESCRIPTION</span>
        <div class="flex space-x-4">
          <itq-input
            class="flex-1"
            [placeholder]="'Enter a tag description...'"
            formControlName="description"
          ></itq-input>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white border border-border rounded-md p-4">
      <div class="flex flex-col">
        <div class="flex space-x-4">
          <div class="flex flex-col flex-1">
            <span class="mb-1 text-13 font-medium flex"> ADD TAG TO BOARD</span>
            <itq-multiple-dropdown
              [dataSource]="boardsDataSource"
              [placeholder]="'Pick a board...'"
              [materialStyle]="'stroked'"
              [formControlName]="'boardIds'"
              [dataFields]="{ name: 'name', value: 'id' }"
            >
            </itq-multiple-dropdown>
          </div>
          <div class="flex items-center pt-[21px] flex-1">
            <div class="font-medium mr-2">IS TAG SHARED?</div>
            <mat-slide-toggle [formControlName]="'sharedTag'">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      @if (keycloakService.isUserInRole('super-admin')) {
        <div class="flex flex-col py-4">
          <p class="font-medium mb-1">IS SYSTEM TAG?</p>
          <mat-radio-group
            formControlName="system"
            class="flex"
            (change)="onChangeSystem($event)"
          >
            <mat-radio-button
              [ngClass]="{
                'flex-1 p-4 rounded-tl-md rounded-bl-md border border-primary bg-primary-over': true,
                'border-primary bg-primary-over':
                  form?.get('save.system')?.value === 'yes',
                'border-border bg-background-over border-r-0':
                  form?.get('save.system')?.value === 'no'
              }"
              [value]="'yes'"
              >Yes</mat-radio-button
            >
            <mat-radio-button
              [ngClass]="{
                'flex-1 p-4 rounded-tr-md rounded-br-md border': true,
                'border-border bg-background-over border-l-0':
                  form?.get('save.system')?.value === 'yes',
                'border-primary bg-primary-over':
                  form?.get('save.system')?.value === 'no'
              }"
              [value]="'no'"
              >No</mat-radio-button
            >
          </mat-radio-group>

          @if (form?.get('save.system')?.value === 'yes') {
            <div class="flex flex-col mt-4 w-[50%]">
              <span class="mb-1 text-13 font-medium flex">SYSTEM CATEGORY</span>
              <itq-dropdown
                [dataSource]="systemCategoriesDataSource"
                [placeholder]="'Pick a system category...'"
                [materialStyle]="'stroked'"
                [clearSelection]="false"
                [formControlName]="'categoryId'"
                [dataFields]="{ name: 'name', value: 'id' }"
              >
              </itq-dropdown>

              @if (form?.get('save.categoryId')?.getError('categoryNotSet')) {
                <mat-error class="mt-2 flex items-center space-x-1 text-danger">
                  <i class="ph ph-warning text-lg"></i>
                  <span class="pt-[2px]">Category has not been set!</span>
                </mat-error>
              }
            </div>
          }
        </div>
      }
      @if (form?.get('save.system')?.value === 'no') {
        <div class="flex flex-col w-[50%]">
          <span class="mb-1 text-13 font-medium flex">TAG TYPE</span>
          <itq-dropdown
            formControlName="userTagCategory"
            [dataSource]="userCategoriesDataSource"
            [clearSelection]="false"
            (changeValue)="onChangeUserCategory()"
            [dataFields]="{ name: 'name', value: 'name' }"
          ></itq-dropdown>
          @if (form?.get('save.userTagCategory')?.getError('categoryNotSet')) {
            <mat-error class="mt-2 flex items-center space-x-1 text-danger">
              <i class="ph ph-warning text-lg"></i>
              <span class="pt-[2px]">Tag type has not been set!</span>
            </mat-error>
          }
        </div>
      }
    </div>
  </div>
</ng-container>
