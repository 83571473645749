import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  FAwesomeModule,
  FieldSections,
  FieldTypes,
  IPresetQuery,
  QueryFilters,
  SystemTagCategory,
  UserTagCategory,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-timeline-vendor-filters.component',
  templateUrl: './timeline-vendor-filters.component.html',
  styleUrls: ['./timeline-vendor-filters.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    CommonModule,
  ],
})
export class TimelineVendorFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() userTagCategory: string;
  @Input() fields: Array<IFilterField>;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<Query>();

  public significatActorsDataSource: Array<ICustomTag>;
  public cheatFeaturesDataSource: Array<ICustomTag>;
  public customerSpecificDataSource: Array<ICustomTag>;
  public gameDataSource: Array<ICustomTag>;
  public cheatToolsDataSource: Array<ICustomTag>;
  private subscriptions = new Subscription();
  public cheatFeaturesInitialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public significatActorsInitialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public cheatToolsInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public customerSpecificInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public gameInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public queryModel = new Query([], 'and');

  constructor(
    public widgetService: WidgetService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    readonly tagService: TagService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.significatActorsInitialState.where = this.initialState.where;
    this.cheatToolsInitialState.where = this.initialState.where;
    this.cheatFeaturesInitialState.where = this.initialState.where;
    this.customerSpecificInitialState.where = this.initialState.where;
    this.gameInitialState.where = this.initialState.where;
    this.bindChangeDateSubscription();
    this.addControls();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onGetGame(): void {
    this.categoryService
      .getCategoryValues(
        this.gameInitialState.query,
        this.gameInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Game,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.gameInitialState.page > 1
          ? this.gameDataSource[this.gameDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.gameDataSource = response.items;
        },
      );
  }

  public onGetCustomerSpecific(): void {
    this.categoryService
      .getCategoryValues(
        this.customerSpecificInitialState.query,
        this.customerSpecificInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory['Customer Specific'],
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.customerSpecificInitialState.page > 1
          ? this.customerSpecificDataSource[
              this.customerSpecificDataSource.length - 1
            ].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.customerSpecificDataSource = response.items;
        },
      );
  }

  public onGetCheatFeatures(): void {
    this.categoryService
      .getCategoryValues(
        this.cheatFeaturesInitialState.query,
        this.cheatFeaturesInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory['Cheat Feature'],
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.cheatFeaturesInitialState.page > 1
          ? this.cheatFeaturesDataSource[
              this.cheatFeaturesDataSource.length - 1
            ].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.cheatFeaturesDataSource = response.items;
        },
      );
  }

  public onGetSignificantActors(): void {
    this.categoryService
      .getCategoryValues(
        this.cheatToolsInitialState.query,
        this.cheatToolsInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory['Significant Actor'],
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.significatActorsInitialState.page > 1
          ? this.significatActorsDataSource[
              this.significatActorsDataSource.length - 1
            ].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.significatActorsDataSource = response.items;
        },
      );
  }

  public onGetCheatTools(): void {
    this.categoryService
      .getCategoryValues(
        this.cheatToolsInitialState.query,
        this.cheatToolsInitialState,
        this.queryModel.convertToBackEndQuery(),
        SystemTagCategory['Cheat Tools'],
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.SYSTEM_TAG_CATEGORY,
        this.cheatToolsInitialState.page > 1
          ? this.cheatToolsDataSource[this.cheatToolsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.cheatToolsDataSource = response.items;
        },
      );
  }

  private bindChangeDateSubscription(): void {
    this.subscriptions.add(
      this.widgetService.changeDate$.subscribe((dates: IPresetQuery) => {
        this.initialState.where = dates;
        this.significatActorsInitialState.where = dates;
        this.cheatToolsInitialState.where = dates;
        this.cheatFeaturesInitialState.where = dates;
        this.customerSpecificInitialState.where = dates;
        this.gameInitialState.where = dates;
        this.initialState.resetPagination();
        this.significatActorsInitialState.resetPagination();
        this.cheatFeaturesInitialState.resetPagination();
        this.customerSpecificInitialState.resetPagination();
        this.gameInitialState.resetPagination();
        this.cheatToolsInitialState.resetPagination();
        this.cdr.detectChanges();
      }),
    );
  }

  private addControls(): void {
    this.form.addControl('cheatTools', new FormControl(undefined));
    this.form.addControl('significantActors', new FormControl(undefined));
    this.form.addControl('cheatFeatures', new FormControl(undefined));
    this.form.addControl('customerSpecific', new FormControl(undefined));
    this.form.addControl('game', new FormControl(undefined));
  }

  public onDataBound(): void {
    this.buildQuery();
    this.dataBound.emit(this.queryModel);
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  private buildQuery(): void {
    this.queryModel = new Query([], 'and');
    const cheatToolsField = this.getField(
      'Cheat Tools',
      FieldSections.FILTER_TO,
    );
    const significantActorsField = this.getField(
      'Significant Actor',
      FieldSections.FILTER_TO,
    );

    const cheatFeaturesField = this.getField(
      'Significant Actor',
      FieldSections.FILTER_TO,
    );
    const gameField = this.getField('Game', FieldSections.FILTER_TO);

    if (this.form?.get('cheatTools').value) {
      this.queryModel.addRule({
        entity: cheatToolsField.field,
        field: cheatToolsField.label,
        operator: 'in',
        value: this.form
          .get('cheatTools')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('significantActors').value) {
      this.queryModel.addRule({
        entity: significantActorsField.field,
        field: significantActorsField.label,
        operator: 'in',
        value: this.form
          .get('significantActors')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('cheatFeatures').value) {
      this.queryModel.addRule({
        entity: cheatFeaturesField.field,
        field: cheatFeaturesField.label,
        operator: 'in',
        value: this.form
          .get('cheatFeatures')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('game').value) {
      this.queryModel.addRule({
        entity: gameField.field,
        field: gameField.label,
        operator: 'in',
        value: this.form.get('game').value.map((item: ICustomTag) => item.id),
      });
    }
  }

  public onClearGame(): void {
    this.form.get('game').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearCheatFeatures(): void {
    this.form.get('cheatFeatures').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearSignificantActors(): void {
    this.form.get('significantActors').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearCheatTools(): void {
    this.form.get('cheatTools').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearCustomerSpecific(): void {
    this.form.get('customerSpecific').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }
}
