import { Pipe, PipeTransform } from '@angular/core';
import { DateQueryType, DateRangeHelper, QueryFilters } from '@intorqa-ui/core';
import { ChartTypes } from '@portal/boards/const/widget.const';
import { Widget } from '@portal/boards/models/widgets/widget';
import {
  AnalysisTypes,
  AnalysisTypesLabel,
} from '@portal/widgets/enums/widget.enum';
import {
  ICustomTag,
  ITagMetadata,
} from 'projects/portal/src/app/tags/interfaces/tag.interface';
import moment from 'moment';

@Pipe({
  name: 'filterTags',
  standalone: true,
})
export class FilterTagsPipe implements PipeTransform {
  constructor() {}

  transform(
    data: Array<ICustomTag>,
    selections: Array<ITagMetadata>,
  ): Array<ICustomTag> {
    if (!selections) return data;
    const ids = selections.map((item: ITagMetadata) => item.tagId);
    return data?.filter((item: ICustomTag) => !ids.includes(item.id));
  }
}

@Pipe({
  name: 'mapLabel',
  standalone: true,
})
export class MapLabelPipe implements PipeTransform {
  constructor() {}

  transform(type: AnalysisTypes): Array<ICustomTag> {
    return AnalysisTypesLabel[type];
  }
}

@Pipe({
  name: 'selectMetricsLabel',
  standalone: true,
})
export class SelectMetricsLabelPipe implements PipeTransform {
  constructor() {}

  transform(widget: Widget): string {
    switch (widget.type) {
      case AnalysisTypes.TIMELINE:
        return 'Pick a data source for your Timeline from the left hand menu.';
      case AnalysisTypes.TOP_CHANNELS:
      case AnalysisTypes.TOP_ACTORS:
        return 'Pick a data source from the left hand menu.';
      case AnalysisTypes.TAG_ANALYSIS:
        return 'Pick a data source from the left hand menu.</br>Use a data slicer to build your widget';
      case AnalysisTypes.EVENT_TIMELINE:
        return 'Please pick event types from the left to populate your cheat and security team timelines';
      case AnalysisTypes.TIME_SERIES:
      case AnalysisTypes.TAG_COMPARISON:
        return 'Pick a data source using the left hand menu.</br>Compare tag volume across all posts or filter to compare within a specific tag';
      case AnalysisTypes.TOPIC_CLUSTERS:
        return 'Pick a data source for your Topic clusters from the left hand menu';
    }
  }
}

@Pipe({
  name: 'hasDisplayTypes',
  standalone: true,
})
export class HasDisplayTypesPipe implements PipeTransform {
  constructor() {}

  transform(widget: Widget): boolean {
    return ChartTypes[widget?.type]?.length > 1;
  }
}
