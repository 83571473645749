@if (link) {
  <div #dropdownButton>
    <button
      [type]="'button'"
      class="text-link k hover:underline"
      (mousedown)="onToggle()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </div>
} @else {
  <div #dropdownButton>
    <itq-mat-button
      [ngClass]="{ isOpen: this.overlayRef?.hasAttached() }"
      [disabled]="disabled"
      [style]="color"
      [size]="size"
      [type]="'button'"
      [align]="align"
      [padding]="padding"
      [materialStyle]="materialStyle"
      (mouseDownEvent)="onToggle()"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </itq-mat-button>
  </div>
}
<ng-template #templateBody>
  <div
    #container
    class="navigation__container flex absolute min-w-[150px] bg-white rounded-md p-2 shadow-[0px_2px_8px_0px_rgba(0,0,0,0.2)]"
  >
    <ng-container
      *ngTemplateOutlet="template; context: { $implicit: templateData }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
