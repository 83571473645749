import { Component, Input, OnInit } from '@angular/core';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';

import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import {
  CoreModule,
  DateRangeComponent,
  EventTeams,
  IEventType,
  IPresetQuery,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { CategoryService } from '@portal/shared/services/category.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagPickerComponent } from '@portal/tags/components/tag-picker/tag-picker.component';
import { ICustomTag } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-event-timeline-metrics',
  templateUrl: './event-timeline-metrics.component.html',
  styleUrls: ['./event-timeline-metrics.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    DateRangeComponent,
    CommonModule,
    TagPickerComponent,
    MatListModule,
  ],
})
export class EventTimelineMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.eventTimelineWidget = widget as EventTimeline;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public selectedUserCategory: string;
  public cheaterTeamDataSource: Array<IEventType>;
  public securityTeamDataSource: Array<IEventType>;
  public cheaterTeamInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public securityTeamInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public eventTimelineWidget: EventTimeline;
  public tagsInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  private _tagsDataSource: { items: Array<ICustomTag>; totalCount: number };
  public get tagsDataSource(): {
    items: Array<ICustomTag>;
    totalCount: number;
  } {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: {
    items: Array<ICustomTag>;
    totalCount: number;
  }) {
    this._tagsDataSource = {
      items:
        this.tagsInitialState.page > 1
          ? [...this.tagsDataSource?.items, ...value.items]
          : value.items,
      totalCount: value.totalCount,
    };
  }

  readonly Sizes = Sizes;

  constructor(
    public widgetService: WidgetService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly tagService: TagService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.onGetSecurityEvents(this.securityTeamInitialState);
    this.onGetCheaterEvents(this.cheaterTeamInitialState);
    this.getTagById();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataCorrelation');
    this.form.removeControl('eventType.securityTeam');
    this.form.removeControl('eventType.cheaterTeam');
    this.form.removeControl('eventType.selectAllCheater');
    this.form.removeControl('eventType.selectAllSecurity');
  }

  public getTagById(): void {
    if (this.eventTimelineWidget?.dataCorrelation?.length > 0) {
      this.tagService
        .getTagById(this.eventTimelineWidget.dataCorrelation[0])
        .subscribe((tag: Tag) => {
          this.selectedUserCategory = tag.userTagCategory;
          this.form.patchValue({
            dataCorrelation: {
              name: tag.name,
              id: tag.tagId,
            },
          });
        });
    }
  }

  public onChangeDataCorrelation(): void {
    this.eventTimelineWidget.dataCorrelation = [
      this.form.get('dataCorrelation').value?.id,
    ];
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: false,
    });
  }

  public onClearDataCorrelation(): void {
    this.eventTimelineWidget.dataCorrelation = [];
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: false,
    });
  }

  private createForm(): void {
    this.form.addControl(
      'eventType',
      new FormGroup(
        {
          securityTeam: new FormControl([]),
          cheaterTeam: new FormControl([]),
          selectAllCheater: new FormControl(false),
          selectAllSecurity: new FormControl(false),
        },
        [this.eventTypeIsValid()],
      ),
    );

    this.form.addControl('dataCorrelation', new FormControl(undefined));
  }

  private eventTypeIsValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const securityTeam = control.get('securityTeam')?.value;
      const cheaterTeam = control.get('cheaterTeam')?.value;
      return securityTeam?.length > 0 || cheaterTeam?.length > 0
        ? null
        : { eventTypeIsRequired: true };
    };
  }

  public onGetCheaterEvents(params: QueryFilters): void {
    this.cheaterTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.cheaterTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.CHEATER_TEAM;
        });
        if (this.eventTimelineWidget.widgetId) {
          const widgetTypeIds = this.cheaterTeamDataSource.filter(
            (event: IEventType) => {
              return this.eventTimelineWidget.dataSource.includes(event.id);
            },
          );
          if (widgetTypeIds.length === this.cheaterTeamDataSource.length) {
            this.form.get('eventType.selectAllCheater').setValue(true);
          }
          this.form.get('eventType.cheaterTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onGetSecurityEvents(params: QueryFilters): void {
    this.securityTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.securityTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.SECURITY_TEAM;
        });
        if (this.eventTimelineWidget.widgetId) {
          const widgetTypeIds = this.securityTeamDataSource.filter(
            (event: IEventType) => {
              return this.eventTimelineWidget.dataSource.includes(event.id);
            },
          );

          if (widgetTypeIds.length === this.securityTeamDataSource.length) {
            this.form.get('eventType.selectAllSecurity').setValue(true);
          }
          this.form.get('eventType.securityTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onChangeSecurityEvents(): void {
    const securityTypeIds =
      this.form
        .get('eventType.securityTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const cheaterTypeIds =
      this.form
        .get('eventType.cheaterTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const eventTypeIds = [...securityTypeIds, ...cheaterTypeIds];
    this.eventTimelineWidget.dataSource = eventTypeIds;

    const widgetTypeIds = this.securityTeamDataSource.filter(
      (event: IEventType) => {
        return this.eventTimelineWidget.dataSource.includes(event.id);
      },
    );

    if (widgetTypeIds.length === this.securityTeamDataSource.length) {
      this.form.get('eventType.selectAllSecurity').setValue(true);
    } else {
      this.form.get('eventType.selectAllSecurity').setValue(false);
    }

    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: true,
    });
  }

  public onChangeCheaterEvents(): void {
    const securityTypeIds =
      this.form
        .get('eventType.securityTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const cheaterTypeIds =
      this.form
        .get('eventType.cheaterTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const eventTypeIds = [...securityTypeIds, ...cheaterTypeIds];
    this.eventTimelineWidget.dataSource = eventTypeIds;

    const widgetTypeIds = this.cheaterTeamDataSource.filter(
      (event: IEventType) => {
        return this.eventTimelineWidget.dataSource.includes(event.id);
      },
    );
    if (widgetTypeIds.length === this.cheaterTeamDataSource.length) {
      this.form.get('eventType.selectAllCheater').setValue(true);
    } else {
      this.form.get('eventType.selectAllCheater').setValue(false);
    }

    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: true,
    });
  }

  public onToggleCheater(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.cheaterTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('eventType.cheaterTeam').setValue(eventTypeIds);
    this.onChangeCheaterEvents();
  }

  public onToggleSecurity(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.securityTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('eventType.securityTeam').setValue(eventTypeIds);
    this.onChangeSecurityEvents();
  }

  public onChangeDate(params: IPresetQuery): void {
    this.cheaterTeamInitialState.where = params;
    this.securityTeamInitialState.where = params;
    this.dates = params;
    this.onGetSecurityEvents(this.securityTeamInitialState);
    this.onGetCheaterEvents(this.cheaterTeamInitialState);
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}
