import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { CoreModule, defaultTheme, FAwesomeModule } from '@intorqa-ui/core';
import { ChartFactory } from '@portal/boards/factories/chart';
import { Chart } from '@portal/boards/models/charts/chart';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { ChartService } from '@portal/shared/services/chart.service';
import { ECharts, EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { Subscription } from 'rxjs';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: true,
  imports: [CoreModule, NgxEchartsDirective, MatChipsModule, FAwesomeModule],
  providers: [provideEcharts()],
})
export class ChartComponent {
  @Input() id = '';
  @Input() type: ChartType;
  @Input() data: any;
  @Input() segment: any;
  @Input() initialOptions: EChartsOption;
  @Input() noResultsTitle = 'No results found!';
  @Input() noResultsMessage = 'Please update your settings and try again...';
  @Input() settings: { [key: string]: any };

  @Output() drilldown = new EventEmitter<any>();
  @Output() updateRef = new EventEmitter<ECharts>();

  @ViewChild('chartContainer') chartContainer: ElementRef;

  public chartInstance: ECharts;
  public theme = defaultTheme.themeName;
  private subscriptions = new Subscription();
  private chart: Chart;
  public options: EChartsOption;

  constructor(
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
  ) {
    echarts.registerTheme(defaultTheme.themeName, defaultTheme.theme);
    this.bindReloadChartSubscription();
  }

  ngOnInit(): void {
    this.reloadOptions();
  }

  ngOnDestroy(): void {
    this.chartInstance?.dispose();
    this.subscriptions.unsubscribe();
  }

  public onRemoveSegment(): void {
    this.chartService.removeSegment$.next();
  }

  private bindReloadChartSubscription(): void {
    this.subscriptions.add(
      this.chartService.reloadChart$.subscribe((widgetId: string) => {
        if (widgetId === this.id) {
          this.reloadOptions();
        }
      }),
    );
  }

  public onChartInit(instance: ECharts): void {
    this.chartInstance = instance;
    this.onUpdateRef();
  }

  onUpdateRef(): void {
    this.updateRef.emit(this.chartInstance);
  }

  private reloadOptions(): void {
    if (this.data?.series[0]?.data?.length > 0) {
      this.initEcharts();
    }
  }

  private initEcharts(): void {
    this.chart = ChartFactory.createChart(this.type);
    if (!this.initialOptions) {
      this.options = this.chart.getOptions(
        this.data?.series,
        this.settings,
        this.segment,
      );
    } else {
      this.options = this.initialOptions;
      this.initialOptions = null;
    }
  }

  public onChartClick(params: any): void {
    if (this.type === ChartType.NETWORK) {
      if (params.dataType === 'node' && params?.data?.target !== this.id) {
        this.drilldown.emit(params);
      }
    } else {
      this.drilldown.emit(params);
    }
  }
}
