<ng-container [formGroup]="form">
  <div class="flex flex-col">
    <itq-virtual-table
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items | transformNotes"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any notes yet!'"
      [emptyMessageDescription]="'To add a note click the button below.'"
    ></itq-virtual-table>
    <div class="flex border-border border border-t-0 bg-white py-2">
      <itq-mat-button
        class="ml-4"
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onAddNote()"
      >
        <div class="flex items-center">
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="whitespace-nowrap">ADD NOTE</span>
        </div>
      </itq-mat-button>
    </div>

    <ng-template #valueTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawValue | urlify"
        ></span>
      </div>
    </ng-template>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <div class="flex justify-center w-full">
          <i class="ph ph-dots-three ph-bold"></i>
        </div>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex flex-col w-full">
          <itq-mat-button
            [materialStyle]="'basic'"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            (clickEvent)="onEditNote(row)"
          >
            <fa-icon
              [icon]="['far', 'pencil']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Edit
          </itq-mat-button>
          <itq-mat-button
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [materialStyle]="'basic'"
            (clickEvent)="onDeleteNote(row)"
          >
            <fa-icon
              [icon]="['far', 'trash-alt']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Delete
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
  </div>
</ng-container>
