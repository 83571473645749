import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  IDropdownItem,
  TagMatchAlert,
} from '@intorqa-ui/core';
import {
  Delays_DataSource,
  Priority_DataSource,
} from '@portal/notifications/const/alerts.const';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-tag-match-alert',
  templateUrl: './tag-match-alert.component.html',
  styleUrls: ['./tag-match-alert.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    CommonModule,
    MatTooltipModule,
  ],
})
export class TagMatchAlertComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() action: Actions;
  @Input() tag: Tag;

  public delayDataSource: Array<{ name: string; value: number }>;
  public priorityDataSource: Array<IDropdownItem>;
  private subscriptions = new Subscription();

  readonly Actions = Actions;

  constructor(
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
  ) {
    this.priorityDataSource = Priority_DataSource;
    this.delayDataSource = Delays_DataSource;
  }

  ngOnInit() {
    this.bindLoadAlertSsubscription();
    this.createForm();
    this.onGetAlert();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    (this.form?.get('alerts') as FormGroup).removeControl('priority');
    (this.form?.get('alerts') as FormGroup).removeControl('delay');
    (this.form?.get('alerts') as FormGroup).removeControl('shareSlack');
  }

  private onGetAlert(): void {
    if (
      (this.action === Actions.EDIT || this.action === Actions.CLONE) &&
      this.tag.alertTypeId
    ) {
      this.alertsService.getAlertByTagId(this.tag.tagId).subscribe();
    }
  }

  private bindLoadAlertSsubscription(): void {
    this.subscriptions.add(
      this.alertsService.loadAlert$.subscribe((response: TagMatchAlert) => {
        this.form.get('alerts.delay').setValue(response.delay);
        this.form.get('alerts.active')?.setValue(response.active);
        this.form.get('alerts.priority').setValue(response.priority);
        this.form.get('alerts.shareSlack')?.setValue(response.postSlack);
      }),
    );
  }

  private createForm(): void {
    (this.form.get('alerts') as FormGroup).addControl(
      'priority',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'delay',
      new FormControl(undefined, [Validators.required]),
    );
    if (this.form.get('save.sharedTag')?.value === true) {
      (this.form.get('alerts') as FormGroup).addControl(
        'shareSlack',
        new FormControl(undefined),
      );
    }
    if (this.action !== Actions.CREATE && this.action !== Actions.CLONE) {
      (this.form.get('alerts') as FormGroup).addControl(
        'active',
        new FormControl(true),
      );
    }
  }
}
