import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Actions,
  Align,
  CustomOverlayService,
  CustomOverlayType,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import {
  INoteDataSource,
  INotesData,
} from '@portal/profiles/interfaces/profile-note.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddNotesWizardComponent } from '@portal/shared/components/add-notes/add-notes-wizard/add-notes-wizard.component';
import { UserService } from '@portal/shared/services/user.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-profiles-notes-wizard',
  templateUrl: './profiles-notes-wizard.component.html',
  styleUrls: ['./profiles-notes-wizard.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesNotesWizardComponent,
    },
  ],
})
export class ProfilesNotesWizardComponent implements OnInit, AfterViewInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() notes: Array<ProfileNote>;

  public dataSource: INotesData;
  public tableColumns: Array<TableColumn> = [];
  public notesSubscription: Subscription;
  private selection: Array<ProfileNote>;
  private touched = false;
  private disabled = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  private resetSubscription: Subscription;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly Align = Align;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('notesTemplate')
  notesTemplate: TemplateRef<unknown>;
  @ViewChild('valueTemplate')
  valueTemplate: TemplateRef<unknown>;

  constructor(
    private notesService: NotesService,
    readonly customOverlayService: CustomOverlayService,
    readonly profilesService: ProfileService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.resetSubscription = this.notesService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    this.notesSubscription = this.notesService.notes$.subscribe(
      (response: INotesData) => {
        this.dataSource = cloneDeep(response);
      },
    );
    if (this.notes?.length > 0) {
      this.notesService.notes = {
        items: this.notes,
        totalCount: this.notes.length,
      };
      this.dataSource = cloneDeep(this.notesService.notes);
    } else {
      this.notesService.notes = {
        items: [],
        totalCount: 0,
      };
      this.dataSource = cloneDeep(this.notesService.notes);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.notesSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileNote>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Type',
        dataKey: 'type',
        searchField: 'typeId',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Sub type',
        dataKey: 'subType',
        searchField: 'subType',
        isSortable: true,
        width: '150px',
        isFilterable: true,
      },
      {
        name: 'Value',
        dataKey: 'rawValue',
        searchField: 'rawValue',
        isSortable: true,
        width: 'fit',
        customRender: true,
        template: this.valueTemplate,
      },
      {
        name: 'Notes',
        dataKey: 'rawTextNote',
        searchField: 'textNote',
        isSortable: true,
        width: 'fit',
        customRender: true,
        template: this.notesTemplate,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
  }

  public onDeleteNote(note: ProfileNote): void {
    this.dataSource.items = this.dataSource.items.filter(
      (item: ProfileNote) => item.id !== note.id,
    );
    this.notesService.notes.items = this.notesService.notes.items.filter(
      (item: ProfileNote) => item.id !== note.id,
    );
  }

  public onAddNote(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesWizardComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            note: new ProfileNote(
              Utils.generateUUID(),
              this.profile.profileId,
              undefined,
              undefined,
              undefined,
              undefined,
              this.userService.userPreferences.defaultEcosystemId,
            ),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onEditNote(noteDataSource: INoteDataSource): void {
    const note = this.notesService.getNoteById(noteDataSource.id);
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesWizardComponent,
          inputs: {
            profile: this.profile,
            action: Actions.EDIT,
            note: cloneDeep(note),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
}
