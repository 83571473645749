<div class="flex items-center p-9 border-b border-border justify-between">
  <div class="flex items-center flex-col">
    <div class="flex items-center justify-start w-full">
      <h1 class="text-28 font-medium">Create new widget</h1>
    </div>
    <div class="text-xl justify-start w-full">
      What kind of widget would you like to create?
    </div>
  </div>
  <itq-mat-button
    [matTooltip]="'Close'"
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClose()"
  >
    <i class="ph ph-x text-2xl"></i
  ></itq-mat-button>
</div>
<div class="rounded-md bg-background justify-left px-9 pt-7 pb-9 w-full h-full">
  <itq-widget-type-thumbnail-list
    class="flex"
    (add)="onAdd($event)"
  ></itq-widget-type-thumbnail-list>
</div>
