import { Pipe, PipeTransform } from '@angular/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { KeycloakService } from 'keycloak-angular';

@Pipe({
  name: 'isWidgetDisabled',
  standalone: true,
})
export class IsWidgetDisabledPipe implements PipeTransform {
  constructor(private keycloakService: KeycloakService) {}

  transform(widget: Timeline): boolean {
    return !widget?.isEnabled(
      widget.categoryId,
      this.keycloakService.isUserInRole('super-admin'),
      this.keycloakService.getKeycloakInstance().profile.username,
      widget.username,
    );
  }
}

@Pipe({
  name: 'getWidgetTooltip',
  standalone: true,
})
export class GetWidgetTooltipPipe implements PipeTransform {
  constructor(private keycloakService: KeycloakService) {}

  transform(widget: Timeline): string {
    return !widget?.isEnabled(
      widget.categoryId,
      this.keycloakService.isUserInRole('super-admin'),
      this.keycloakService.getKeycloakInstance().profile.username,
      widget.username,
    )
      ? "You don't have permissions to edit this widget"
      : undefined;
  }
}
