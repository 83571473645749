<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <itq-widget-header
    [widget]="widget"
    [widgetMoreTemplate]="widgetMoreTemplate"
    [count]="count"
  ></itq-widget-header>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border p-4"
    #chartContainer
  >
    <itq-foamtree
      class="flex w-full h-full"
      [id]="widget?.widgetId"
      [settings]="widget?.options"
      [dataSource]="dataSource"
      [allowDrilldown]="false"
      [action]="WidgetViewMode.NORMAL"
      (drilldown)="onDrilldownFoamtree($event)"
    ></itq-foamtree>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate>
  <itq-widget-actions
    [selectedDisplayType]="selectedDisplayType"
    [widget]="topicClustersWidget"
    [displayTypes]="displayTypes"
    (edit)="onEdit()"
    (explore)="onExplore($event)"
    (delete)="onDeleteWidget()"
    (export)="onExportWidget()"
    (changeDisplay)="onChangeDisplay($event)"
  ></itq-widget-actions>
</ng-template>
