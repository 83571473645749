import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Router, RouterModule } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  HelpComponent,
  IconType,
  SharedService,
  Sizes,
} from '@intorqa-ui/core';
import { BoardService } from '@portal/boards/services/board.service';
import { NotificationsComponent } from '@portal/notifications/notifications.component';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-navigation-aside',
  templateUrl: './navigation-aside.component.html',
  styleUrls: ['./navigation-aside.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    CommonModule,
    RouterModule,
    FormsModule,
  ],
})
export class NavigationAsideComponent implements OnInit {
  readonly Sizes = Sizes;
  readonly IconType = IconType;

  private subscriptions = new Subscription();
  private interval: NodeJS.Timeout;
  private overlayRef: OverlayRef;
  public isOpen = false;
  public unreadStatus: boolean;

  constructor(
    public sharedService: SharedService,
    private customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    readonly keycloakService: KeycloakService,
    readonly ecosystemsService: EcosystemsService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly router: Router,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
  ) {}

  ngOnInit(): void {
    this.getUnreadStatus();
    this.bindUnreadCountSubscription();
    this.bindNotificationsSubscription();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions.unsubscribe();
  }

  public onToggleSidebar(): void {
    this.sharedService.toggleSidebar$.next();
  }

  private bindNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.openNotifications$.subscribe(
        (response: { raisedAlertId: string; researchAlertId: string }) => {
          this.onLoadNotifications(
            response.raisedAlertId,
            response.researchAlertId,
          );
        },
      ),
    );
  }

  private bindUnreadCountSubscription(): void {
    this.interval = setInterval(() => {
      this.getUnreadStatus();
    }, 30000);
  }

  private getUnreadStatus(): void {
    this.notificationsService.getUnread().subscribe((response: boolean) => {
      this.unreadStatus = response;
    });
  }

  public onLoadHelp(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .right('68px')
      .top('68px');
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'h-[100%]',
    });

    const tooltipPortal = new ComponentPortal(
      HelpComponent,
      this.viewContainerRef,
    );
    this.overlayRef.attach(tooltipPortal);
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
  }

  public toggleNotiications(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.onLoadNotifications();
    } else {
      this.overlayRef?.dispose();
    }
  }

  onLoadNotifications(raisedAlertId?: string, researchAlertId?: string): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .right('68px')
      .top('68px');
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'h-[100%]',
    });

    const tooltipPortal = new ComponentPortal(
      NotificationsComponent,
      this.viewContainerRef,
    );
    const notificationsRef = this.overlayRef.attach(tooltipPortal);
    notificationsRef.instance.raisedAlertId = raisedAlertId;
    notificationsRef.instance.researchAlertId = researchAlertId;
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.isOpen = false;
      this.overlayRef.dispose();
    }
  }

  removeRouteParam() {
    // Get the current route
    let currentRoute = this.router.url.split('/');
    let lastSegment = currentRoute[currentRoute.length - 1];

    // Check if the last segment is a UUID
    let uuidRegex =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(lastSegment)) {
      currentRoute.pop(); // Remove the last segment (id)

      // Navigate to the same route without the id
      this.router.navigate(currentRoute, { replaceUrl: true });
    }
  }
}
