<a
  class="flex justify-between items-center w-full px-4 h-full hover:bg-background-over"
  (click)="onSidebarItemClick()"
  [routerLinkActive]="'active font-medium'"
  [routerLink]="[url]"
>
  <div class="flex items-center space-x-1">
    @if (iconType === IconType.SVG) {
      <itq-svg class="w-5 h-5 fill-current" [icon]="icon"></itq-svg>
    } @else if (iconType === IconType.PHOSPHOR) {
      <i
        [ngClass]="['ph', 'text-lg', icon, isActiveRoute ? 'ph-bold' : '']"
      ></i>
    }
    <span class="whitespace-nowrap pt-[2px]">{{ title }}</span>
  </div>
  <span>{{ totalCount || 0 | number }}</span>
</a>
