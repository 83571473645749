import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateQueryType, IPresetQuery } from '@intorqa-ui/core';
import { Moment } from 'moment';

@Pipe({
  name: 'getSelected',
})
export class GetSelectedPipe implements PipeTransform {
  transform(item: DateQueryType, dateRange?: IPresetQuery): boolean {
    if (!dateRange) {
      return item === DateQueryType.Custom ? true : false;
    }
    return item === dateRange.label ? true : false;
  }
}
