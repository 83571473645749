import { ChartUtils, Utils } from '@intorqa-ui/core';
import { IDataPoint, ISerie } from '@portal/shared/interfaces/widget.interface';
import { ChartOrientation } from '@portal/widgets/enums/chart.enum';
import { EChartsOption } from 'echarts';
import { Chart } from './chart';

export class BarChart extends Chart {
  public defaultSettings = {
    yAxis: { splitLine: { show: true }, show: true },
    xAxis: { show: true },
    tooltip: { show: true },
    series: {
      label: { show: true },
    },
  };
  constructor() {
    super();
  }

  private getTooltip(data: Array<ISerie>): any {
    return {
      trigger: data?.length > 1 ? 'item' : 'axis',
      extraCssText: 'overflow: auto; max-height: 250px; border: 0;',
      enterable: true,
      axisPointer: {
        type: 'shadow',
      },
    };
  }

  private getXAxis(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): any {
    if (settings.orientation === ChartOrientation.HORIZONTAL) {
      return {
        show: settings.xAxis?.show,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      };
    } else {
      return {
        show: settings.xAxis?.show,
        data: data[0]?.data.map((item: IDataPoint) => {
          return item.label;
        }),
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: -320,
          formatter: (value: string) => {
            if (value.length > 10) {
              return (value.substring(0, 20) + '...').replace(/\n/g, ' ');
            } else {
              return value.replace(/\n/g, ' ');
            }
          },
          rich: {
            primary: {
              color: '#1de62a',
              fontWeight: 600,
            },
          },
        },
      };
    }
  }

  public getYAxis(data: Array<ISerie>, settings: { [key: string]: any }): any {
    if (settings.orientation === ChartOrientation.VERTICAL) {
      return {
        show: settings.yAxis?.show,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      };
    } else {
      return {
        show: settings.yAxis?.show,
        data: data[0].data.map((item: IDataPoint) => {
          return item.label;
        }),
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          formatter: (value: string) => {
            if (value.length > 10) {
              return (value.substring(0, 20) + '...').replace(/\n/g, ' ');
            } else {
              return value.replace(/\n/g, ' ');
            }
          },
        },
      };
    }
  }

  private getSeries(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any },
  ): Array<any> {
    return data.map((serie: ISerie, index: number) => {
      return {
        name: serie.name,
        stack: settings?.mode === 'stack' ? 'stack' : undefined,
        legendHoverLink: true,
        data: serie.data.map((item: IDataPoint) => {
          return ChartUtils.getDataPoint(item, segment, serie.tagId, index);
        }),
        emphasis: {
          focus: 'series',
        },
        type: 'bar',
        itemStyle: {
          borderRadius:
            data.length > 1
              ? undefined
              : settings.orientation === ChartOrientation.HORIZONTAL
                ? [0, 5, 5, 0]
                : [5, 5, 0, 0],
        },
        animation: false,
        label: {
          show: data?.length > 1 || serie.data?.length > 15 ? false : true,
          position:
            settings.orientation === ChartOrientation.VERTICAL
              ? 'insideTop'
              : 'insideRight',
          labelLayout: {
            hideOverlap: true,
          },
          formatter: (params: any) => {
            return params.value === 0 ? '' : params.value;
          },
        },
        tooltip: this.getSerieTooltip(data),
        markLine: settings.markLine ? this.getMarkLine(index, data) : undefined,
      };
    });
  }

  private getSerieTooltip(data: Array<ISerie>): any {
    return {
      confine: false,
      formatter: (value: any) => {
        let result = '<div>' + value.name + '</div>';
        let resultObj = data.map((item: ISerie, index: number) => {
          const dataPointValue = item.data.find(
            (dataPoint: IDataPoint) => dataPoint.label === value.name,
          );
          let tooltip = ChartUtils.buildTooltip(
            item,
            value,
            index,
            dataPointValue,
          );
          return {
            value: dataPointValue,
            text: tooltip,
          };
        });
        resultObj = resultObj.sort((a, b) => {
          return b.value.count > a.value.count ? 1 : -1;
        });
        return result + resultObj.map((item) => item.text).join('');
      },
    };
  }

  private getMarkLine(index: number, data: Array<ISerie>): any {
    return {
      lineStyle: {
        color: '#1de62a',
        width: 2,
      },
      label: {
        show: true,
        formatter: 'Custom Label',
        rich: {
          customStyle: {
            backgroundColor: '#1de62a',
            color: 'black',
            padding: [5, 10],
            borderRadius: 5,
            fontSize: 12,
            fontFamily: 'Mont',
            fontWeight: 600,
          },
        },
      },
      data: this.getEventsData(index, data),
    };
  }

  private getDataZoom(
    data: Array<ISerie>,
    settings: { [key: string]: any },
  ): any {
    if (settings.markLines) {
      if (settings.orientation === ChartOrientation.HORIZONTAL) {
        [
          {
            show: data[0].data.length > 10,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 20,
            height: '90%',
            showDataShadow: false,
            right: 15,
          },
        ];
      } else {
        return [
          {
            show: data[0].data.length > 10,
            realtime: true,
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ];
      }
    }
  }

  public getOptions(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): EChartsOption {
    settings = { ...this.defaultSettings, ...settings };
    return {
      tooltip: this.getTooltip(data),
      textStyle: ChartUtils.getTextStyle(),
      xAxis: this.getXAxis(data, settings, segment),
      yAxis: this.getYAxis(data, settings),
      series: this.getSeries(data, segment, settings),
      grid: ChartUtils.getGrid(
        data.length > 1,
        settings.yAxis?.show && settings.xAxis?.show,
      ),
      legend: ChartUtils.getLegend(data.length > 1),
      dataZoom: this.getDataZoom(data, settings),
    };
  }

  private getEventsData(index: number, series: Array<ISerie>): Array<any> {
    let events: any = [];
    series.forEach((serie: ISerie) => {
      events.push({
        xAxis: index,
        label: {
          formatter: '{customStyle|label1}',
        },
      });
    });
    return events;
  }
}
