import { Pipe, PipeTransform } from '@angular/core';
import { ProfileTypeIcon } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileType } from '@portal/profiles/models/profile-type';

@Pipe({
  name: 'getVendorTypeIcon',
  standalone: true,
})
export class GetVendorTypeIcon implements PipeTransform {
  constructor() {}

  transform(data: Array<ProfileType>, item: Profile): string {
    const profileType = data?.find(
      (profileType) => item.profileTypeId === profileType.id,
    );
    if (profileType) {
      return ProfileTypeIcon[profileType?.name];
    } else {
      return '';
    }
  }
}
