<div class="flex w-full max-h-[350px] overflow-hidden">
  <div class="flex flex-col w-[250px]">
    <mat-nav-list
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        [style.padding-right]="'0.5rem !important'"
        [style.padding-left]="'0.5rem !important'"
        [style.background-color]="
          selectedSection === 'view' ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange('displayType')"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': selectedSection === 'view'
          }"
        >
          <div class="flex items-center space-x-2">
            @if (selectedDisplayType?.icon) {
              <fa-icon
                class="w-[24px] h-[24px] flex justify-center items-center"
                [icon]="selectedDisplayType?.icon"
              ></fa-icon>
            } @else if (selectedDisplayType?.phosphorIcon) {
              <i
                [ngClass]="[
                  'ph',
                  'text-lg',
                  selectedDisplayType.phosphorIcon,
                  selectedSection === 'displayType' ? 'ph-bold' : ''
                ]"
              ></i>
            } @else {
              <itq-svg
                [icon]="selectedDisplayType?.svgIcon"
                class="h-5 w-5"
              ></itq-svg>
            }
            <span
              [ngClass]="{
                'font-medium': selectedSection === 'displayType',
                'whitespace-nowrap': true
              }"
              >Change view</span
            >
          </div>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down ph-bold': selectedSection === 'displayType',
              'ph-caret-right': selectedSection !== 'displayType'
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onEdit()"
    >
      <div class="flex space-x-2 justify-center items-center">
        <i class="ph ph-pencil text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Edit</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onExplore()"
    >
      <div class="flex space-x-2 justify-center items-center">
        <i class="ph ph-binoculars text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal"> Explore</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onDeleteWidget()"
    >
      <div class="flex space-x-2 justify-center items-center">
        <i class="ph ph-trash text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Remove</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onExportWidget()"
    >
      <div class="flex space-x-2 justify-center items-center">
        <i class="ph ph-export text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Export</span>
      </div>
    </itq-mat-button>
  </div>

  @if (selectedSection === 'displayType') {
    <div
      class="flex flex-col border-l pl-[8px] ml-[8px] w-[200px] border-border"
    >
      <itq-widget-display-types
        [displayTypes]="displayTypes"
        [selectedDisplayType]="selectedDisplayType"
        (changeDisplay)="onChangeDisplay($event)"
      ></itq-widget-display-types>
    </div>
  }
</div>
