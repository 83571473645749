<div class="dependencies__container" [formGroup]="form">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center flex-col bg-white">
      <div class="flex items-center justify-start w-full space-x-2">
        <i class="ph ph-tree-view ph-bold text-3xl"></i>
        <h1 class="text-28 font-medium">Tag dependencies</h1>
      </div>
      <div class="text-xl justify-start w-full">
        Visualization of tag dependencies
      </div>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>

  <div class="flex flex-col flex-1 p-7 bg-background">
    <itq-form-row class="flex-col">
      <itq-button-thumbnail
        formControlName="direction"
        [dataSource]="dependencies"
        (change)="onDataBound()"
      ></itq-button-thumbnail>
    </itq-form-row>
    <itq-form-row class="tree__container flex-col mt-4">
      <mat-tree
        class="overflow-auto flex-1"
        *ngIf="dataset?.size > 1; else noResultsTemplate"
        [dataSource]="dataSource"
        [treeControl]="treeControl"
      >
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>

          <ng-container
            [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            [attr.aria-label]="'Toggle ' + node.name"
            matTreeNodeToggle
          >
            <div class="flex items-center justify-center">
              <i
                [ngClass]="{
                  'ph text-lg': true,
                  'ph-caret-down': treeControl.isExpanded(node),
                  'ph-caret-right': !treeControl.isExpanded(node)
                }"
              ></i>
            </div>
          </button>

          <ng-container
            [ngTemplateOutlet]="nodeContainerTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container>
          <mat-progress-bar
            *ngIf="node.isLoading"
            mode="indeterminate"
            class="example-tree-progress-bar"
          ></mat-progress-bar>
        </mat-tree-node>
      </mat-tree>
    </itq-form-row>
  </div>
</div>

<ng-template #labelTemplate let-node>
  <ng-container
    [ngTemplateOutlet]="nodeContainerTemplate"
    [ngTemplateOutletContext]="{ $implicit: node }"
  ></ng-container>
</ng-template>

<ng-template #nodeContainerTemplate let-node>
  <div class="flex flex-col">
    <div class="flex items-center space-x-1">
      <itq-mat-button
        *ngIf="node.level > 0; else labelChildTemplate"
        [type]="'button'"
        [materialStyle]="'basic'"
        [matTooltip]="node | tooltipNode"
        (clickEvent)="onLoadNode(node)"
      >
        <div class="flex space-x-1 items-center font-normal">
          <ng-container
            [ngTemplateOutlet]="nodeTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container></div
      ></itq-mat-button>
      <ng-template #labelChildTemplate>
        <div
          class="flex items-center space-x-1"
          [matTooltip]="node | tooltipNode"
        >
          <ng-container
            [ngTemplateOutlet]="nodeTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container>
        </div>
      </ng-template>
      <itq-mat-button
        *ngIf="
          node.level > 0 && this.form?.get('direction')?.value === 'dependants'
        "
        class="ml-x-sm"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onUnlink(node)"
      >
        <span class="text-link">
          {{ node.linked ? 'Unlink' : 'Link' }}
        </span>
      </itq-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #noResultsTemplate>
  <itq-panel-action
    *ngIf="dataset"
    [label]="'No ' + this?.form.get('direction')?.value + ' found!'"
  ></itq-panel-action>
</ng-template>

<ng-template #nodeTemplate let-node>
  @if (node.type === TagNodeType.VENDOR) {
    <i class="ph ph-storefront text-lg"></i>
  } @else {
    <ng-container
      *ngIf="
        node.type === TagNodeType.TAG || node.type === TagNodeType.SYSTEM_TAG;
        else svgIconTemplate
      "
    >
      <i class="ph ph-tag text-lg"></i>
    </ng-container>
    <ng-template #svgIconTemplate>
      <itq-svg class="w-3 h-3" [icon]="'board'"></itq-svg>
    </ng-template>
  }
  <span class="whitespace-nowrap pt-[2px]">{{ node.name }}</span>
</ng-template>
