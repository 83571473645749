import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayRef,
  FAwesomeModule,
  IconType,
  LabelComponent,
  Sizes,
} from '@intorqa-ui/core';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { UserService } from '@portal/shared/services/user.service';
import { ICustomTag } from 'projects/portal/src/app/tags/interfaces/tag.interface';
import { LinkTagsTypeComponent } from '../link-tags-type/link-tags-type.component';

@Component({
  selector: 'itq-link-tags',
  templateUrl: './link-tags.component.html',
  styleUrls: ['./link-tags.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    LinkTagsTypeComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonModule,
    LabelComponent,
    MatTooltipModule,
  ],
})
export class LinkTagsComponent implements OnInit {
  @Input() totalCount: number;
  @Input() profile: Profile;
  @Input() action = Actions.EDIT;
  @Input() link: LinkTag;

  public tagsDataSource: Array<ICustomTag>;
  public form: FormGroup;

  readonly Actions = Actions;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly IconType = IconType;

  constructor(
    private linkTagsService: LinkTagsService,
    readonly userService: UserService,
    readonly customOverlayRef: CustomOverlayRef,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl('addTags');
  }

  private createForm(): void {
    this.form = new FormGroup({});
    const tag = {
      id: this.link?.tagId,
      name: this.link?.tagName,
      userTagCategory: this.link?.type,
    };
    this.form.addControl(
      'addTags',
      new FormGroup({
        linkType: new FormControl(
          this.action === Actions.EDIT ? tag : undefined,
          [Validators.required],
        ),
        description: new FormControl(this.link?.description),
      }),
    );
    (this.form.get('addTags') as FormGroup).addControl(
      'default',
      new FormControl({
        value: this.link?.isDefault,
        disabled: !this.isEditable(),
      }),
    );
  }

  public isEditable(): boolean {
    if (this.linkTagsService.links.items?.length > 0) {
      return true;
    }
  }

  public onChangeLinkType(): void {
    this.form.get('addTags.tag').enable();
    this.form.get('addTags.tag').setValue(undefined);
    this.form.get('addTags.description').setValue(undefined);
    this.link.type = this.form.get('addTags.linkType').value;
  }

  public onChangeDescription(): void {
    this.link.description = this.form.get('addTags.description').value;
  }

  public onChangeLink(tag: ICustomTag): void {
    this.link.tagId = tag.id;
    this.link.tagName = tag.name;
    this.link.type = tag.userTagCategory;
  }

  public onCancel(): void {
    this.customOverlayRef.close();
  }

  public onAdd(): void {
    this.linkTagsService
      .linkTags(this.profile.profileId, [this.link])
      .subscribe(() => {
        this.customOverlayRef.close({ refresh: true });
      });
  }

  public onUpdate() {
    this.linkTagsService
      .updateLink(this.profile.profileId, this.link)
      .subscribe(() => {
        this.customOverlayRef.close({ refresh: true });
      });
  }

  public onCreate(): void {
    this.form.get('addTags').reset();
    this.linkTagsService
      .linkTags(this.profile.profileId, [this.link])
      .subscribe(() => {
        this.linkTagsService.links$.next({
          link: this.link,
          data: this.linkTagsService.links,
        });
      });
  }

  public onToggleChange(): void {
    this.link.isDefault = this.form.get('addTags.default').value;
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
