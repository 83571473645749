<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="flex w-full flex-col h-full sm:min-w-[550px] md:min-w-[700px] lg:min-w-[900px]"
>
  <div class="flex items-center p-9 border-b border-border justify-between">
    <div class="flex items-center flex-col">
      <div class="flex items-center justify-start w-full">
        <h1 class="text-28 font-medium">Create new board</h1>
      </div>
      <div class="text-xl justify-start w-full">
        Add board details and create your first widget
      </div>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
  <div
    class="flex flex-col p-7 bg-background justify-between flex-1 overflow-auto"
  >
    <div class="flex flex-col">
      <div
        class="rounded-md border border-border bg-white flex justify-center p-7 items-center space-x-2"
      >
        <itq-svg [icon]="'board'" class="w-12 h-12"></itq-svg>
        <itq-input
          class="flex-1 board__name"
          [formControlName]="'name'"
          [autoFocus]="true"
          [placeholder]="'Please type to add board name'"
        ></itq-input>
        <itq-input
          class="flex-1"
          [formControlName]="'description'"
          [placeholder]="'Please type to add board description'"
        ></itq-input>
      </div>
      <span class="flex font-medium text-17 my-7"
        >Create your first widget...</span
      >
      <div
        class="rounded-md border border-border bg-white flex justify-left p-7 w-full"
      >
        <itq-widget-type-thumbnail-list
          class="flex w-full"
          [disabled]="form?.get('name').invalid"
          (add)="onAdd($event)"
        ></itq-widget-type-thumbnail-list>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <itq-mat-button
        class="ml-2"
        [disabled]="!form.valid"
        [padding]="Sizes.SM"
        [style]="'primary'"
        [materialStyle]="'flat'"
        [type]="'submit'"
      >
        SAVE
      </itq-mat-button>
    </div>
  </div>
</form>
