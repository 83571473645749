<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'filters'">
    <div
      class="flex w-full bg-background rounded-md flex-1 h-full overflow-auto"
      cdkScrollable
    >
      <div class="flex flex-col flex-[2] min-w-[690px]">
        <div
          class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
        >
          <span class="font-medium pr-2">Build your search query:</span>
          <div class="flex space-x-2">
            @if (queryModel?.hasRules()) {
              <itq-mat-button
                [materialStyle]="'stroked'"
                [type]="'button'"
                (clickEvent)="onResetQuery()"
              >
                <itq-label
                  [label]="' RESET QUERY'"
                  [icon]="'ph-funnel-x'"
                  [bold]="true"
                ></itq-label>
              </itq-mat-button>
            }
            <itq-date-range
              class="flex"
              [dates]="this.initialState?.where"
              (search)="onChangeDate($event)"
            ></itq-date-range>
          </div>
        </div>
        @if (fields) {
          <div class="flex justify-between flex-col relative overflow-hidden">
            @if (scrollableContainer) {
              <itq-query-builder
                class="px-7 py-4 overflow-auto"
                [fieldsDataSource]="fields"
                [formControlName]="'query'"
                [scrollableContainer]="scrollableContainer"
                [initialState]="initialState"
                [queryModel]="queryModel"
                [userTagCategory]="
                  tag.userTagCategory || UserTagCategory.Standard
                "
                (dataBound)="onDataBound($event)"
              ></itq-query-builder>
            }
          </div>
        }
      </div>
      <div
        class="flex flex-col overflow-hidden border-l border-border flex-1 min-w-[450px]"
      >
        <div
          class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
        >
          <span class="font-medium pr-2">Matching content</span>

          <itq-pill
            [type]="PillType.CUSTOM"
            [class]="'secondary rounded-md h-[36px]'"
            >{{ (dataSource?.count | number) || 0 }}</itq-pill
          >
        </div>
        <div class="flex flex-1 overflow-hidden">
          @if (queryModel?.hasRules()) {
            <itq-documents-feed
              class="h-full flex flex-col overflow-hidden w-full"
              [data]="dataSource"
              [initialState]="initialState"
              (shareEmail)="onShareEmail($event)"
              (scrollEvent)="onScroll($event)"
              (drilldown)="onDrilldown($event)"
              (createProfile)="onCreateProfile($event)"
            ></itq-documents-feed>
          } @else {
            <div class="flex min-h-[350px] w-full justify-center items-center">
              <itq-panel-action
                class="my-7 flex w-full px-7"
                [label]="'No filters selected!'"
                [description]="
                  'Type in the search box and add some filters for targeted results...'
                "
              ></itq-panel-action>
            </div>
          }
        </div>
      </div>
    </div>
  </ng-container>
  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</ng-container>
