// Angular modules
import { DatePipe, DecimalPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import {
  AngularModule,
  convertLocalTimezonePipe,
  CoreModule,
  FAwesomeModule,
  FoamtreeComponent,
  FormatDatePipe,
  HighlightSearchPipe,
  MaterialModule,
  SvgComponent,
} from '@intorqa-ui/core';
import { QuillModule } from 'ngx-quill';
import { DocumentItemDetailComponent } from '../document/component/document-item-detail/document-item-detail.component';
import {
  CustomSortExtensionFieldsPipe,
  FilterEmptyExtensionFieldsPipe,
  GetExtensionFieldNamePipe,
  GetExtensionFieldValuesPipe,
} from '../document/component/document-item-detail/document-item-detail.pipe';
import { AddNotesComponent } from './components/add-notes/add-notes.component';
import { SortTableDataPipe } from './components/chart/chart.pipe';
import { PostNotesWizardComponent } from './components/post-notes-wizard/post-notes-wizard.component';
import { TagsIncludeSelectionComponent } from './components/tags-include-selection/tags-include-selection.component';
import { DefaultImage } from './directives/image-default.directive';

@NgModule({
  imports: [
    FAwesomeModule,
    AngularModule,
    MaterialModule,
    CoreModule,
    YouTubePlayerModule,
    FormatDatePipe,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'formats/font',
          whitelist: [
            'mirza',
            'roboto',
            'aref',
            'serif',
            'sansserif',
            'monospace',
          ],
        },
      ],
    }),
    SvgComponent,
    FoamtreeComponent,
    HighlightSearchPipe,
    convertLocalTimezonePipe,
  ],
  declarations: [
    DocumentItemDetailComponent,
    DefaultImage,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    SortTableDataPipe,
    TagsIncludeSelectionComponent,
    GetExtensionFieldNamePipe,
    GetExtensionFieldValuesPipe,
    AddNotesComponent,
    PostNotesWizardComponent,
  ],
  exports: [
    DocumentItemDetailComponent,
    DefaultImage,
    CustomSortExtensionFieldsPipe,
    FilterEmptyExtensionFieldsPipe,
    TagsIncludeSelectionComponent,
    GetExtensionFieldValuesPipe,
    AddNotesComponent,
    PostNotesWizardComponent,
    GetExtensionFieldNamePipe,
  ],
  providers: [DatePipe, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {}
}
