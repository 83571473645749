import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Query } from '@portal/shared/models/query-model';
import { cloneDeep } from 'lodash';

import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  EventBusService,
  FAwesomeModule,
  FoamtreeService,
  QueryFilters,
  Sizes,
  Utils,
} from '@intorqa-ui/core';
import { TAG_TRENDS_CHART_TYPES } from '@portal/boards/const/time-series.const';
import { EChartTypes } from '@portal/boards/const/widget.const';
import { Board } from '@portal/boards/models/board';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { ChartService } from '@portal/shared/services/chart.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { ChartExploreTagTrendsComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-tag-trend/chart-explore-tag-trend.component';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { ChartComponent } from '../../../../../shared/components/chart/chart.component';
import { WidgetDisplayTypesComponent } from '../../../../../widgets/components/widget-display-types/widget-display-types.component';
import { WidgetHeaderComponent } from '../../../../../widgets/components/widget-header/widget-header.component';
import { ModalContainerComponent } from '../../../modal-container/modal-container.component';
import { WidgetActionsComponent } from '../widget-actions/widget-actions.component';
@Component({
  selector: 'itq-widget-tag-trends',
  templateUrl: './widget-tag-trends.component.html',
  styleUrls: ['./widget-tag-trends.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    ChartComponent,
    WidgetHeaderComponent,
    WidgetDisplayTypesComponent,
    WidgetActionsComponent,
  ],
})
export class WidgetTagTrendsComponent implements OnInit {
  @Input() segment: any;
  @Input() set widget(value: Widget) {
    this.tagTrendsWidget = value as TimeSeries;
  }
  get widget(): TimeSeries {
    return this.tagTrendsWidget;
  }
  @Input() action: Actions;
  @Input() board: Board;
  @Input() query: string;

  @ViewChild('chart') chart: any;
  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  public dataSource: IWidgetData;
  public showLoader = true;
  public chartInstance: ECharts;
  public displayTypes = TAG_TRENDS_CHART_TYPES;
  public selectedDisplayType: IDisplayType;
  private subscriptions = new Subscription();
  public initialState: QueryFilters;
  public tagTrendsWidget: TimeSeries;
  public count: number;

  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly Align = Align;

  constructor(
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly eventBusService: EventBusService,
    readonly boardService: BoardService,
    readonly chartService: ChartService,
    readonly eventsService: EventsService,
    readonly foamtreeService: FoamtreeService,
  ) {}

  ngOnInit(): void {
    this.bindReloadWidgetSubscription();
    this.registerEventBusEvents();
    this.selectedDisplayType = this.tagTrendsWidget.getDisplayType();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: TimeSeries; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.tagTrendsWidget.widgetId
          ) {
            this.initialState = params.initialState;
            this.getWidgetData();
          }
        },
      ),
    );
  }

  public onEdit(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartWizardComponent,
          inputs: {
            widget: cloneDeep(this.tagTrendsWidget),
            action: Actions.EDIT,
            initialState: this.initialState.cloneDeep(),
            options: this.chart.chartInstance.getOption(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDeleteWidget(): void {
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [this.tagTrendsWidget.widgetId],
      })
      .subscribe();
  }

  public onExportWidget(): void {
    if (EChartTypes.includes(this.tagTrendsWidget.chartType)) {
      Utils.exportImage(
        this.chartInstance.getDataURL({
          pixelRatio: 2,
          backgroundColor: 'transparent',
        }),
        this.tagTrendsWidget.name,
      );
    } else {
      const hiddenDiv = document.createElement('div');
      hiddenDiv.style.position = 'absolute';
      hiddenDiv.style.left = '-9999px';
      hiddenDiv.style.top = '-9999px';
      hiddenDiv.style.width =
        this.chartContainer.nativeElement.clientWidth + 'px';
      hiddenDiv.innerHTML = this.chartContainer.nativeElement.innerHTML;
      document.body.appendChild(hiddenDiv);

      html2canvas(hiddenDiv).then((canvas: any) => {
        Utils.exportImage(
          canvas.toDataURL('image/png'),
          this.tagTrendsWidget.name,
        );
        document.body.removeChild(hiddenDiv);
      });
    }
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.tagTrendsWidget.options = params.options;
    this.tagTrendsWidget.chartType = params.type;
    this.widgetService.updateWidget(this.tagTrendsWidget).subscribe();
  }

  public onLoadCount(row: IDataPoint): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartExploreTagTrendsComponent,
          inputs: {
            widget: this.tagTrendsWidget.cloneDeep(),
            initialState: this.initialState.cloneDeep(),
            segment: {
              data: {
                tagId: row.tagId,
                name: row.label,
                value: row.count,
                field: row.field,
              },
            },
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(segment?: any): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartExploreTagTrendsComponent,
          inputs: {
            widget: this.tagTrendsWidget.cloneDeep(),
            initialState: this.initialState.cloneDeep(),
            segment,
            options: this.chart.chartInstance.getOption(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private getWidgetData(): void {
    if (this.tagTrendsWidget.hasMetrics()) {
      this.showLoader = true;
      const queryModel = new Query();
      if (this.initialState?.query) {
        queryModel.addRule({
          entity: 'content',
          field: 'content',
          operator: 'contains',
          value: [this.initialState?.query],
        });
      }

      this.widgetService
        .getData(
          {
            widget: this.tagTrendsWidget,
            filters: queryModel.convertToBackEndQuery(),
          },
          this.initialState,
        )
        .subscribe((response: IWidgetData) => {
          this.dataSource = response;
          this.cdr.detectChanges();
          this.count = response.totalHits;
          this.chartService.reloadChart$.next(this.tagTrendsWidget.widgetId);
          this.showLoader = false;
        });
    }
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.widgetService.delete$.next(this.tagTrendsWidget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: TimeSeries;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: TimeSeries;
        address: string;
        type: string;
      },
    ) => {
      this.tagTrendsWidget = WidgetFactory.createWidget(msg.body) as TimeSeries;
      this.selectedDisplayType = this.tagTrendsWidget.getDisplayType();
      this.initialState.resetPagination();
      this.getWidgetData();
    };
  }
}
