import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import {
  Align,
  CoreModule,
  DateQueryLabel,
  DateQueryType,
  DateRangeHelper,
  DateRangeService,
  IPreset,
  IPresetQuery,
  MomentService,
  SharedService,
  Sizes,
} from '@intorqa-ui/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'itq-date-range-presets',
  templateUrl: './date-range-presets.component.html',
  styleUrls: ['./date-range-presets.component.scss'],
  standalone: true,
  imports: [CommonModule, CoreModule, ReactiveFormsModule, MatDatepickerModule],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
  ],
})
export class DateRangePresetsComponent implements OnInit {
  @Input() dates: IPresetQuery;

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly DateQueryLabel = DateQueryLabel;
  readonly DateQueryType = DateQueryType;

  public dateRange: DateRange<moment.Moment>;
  public customPresets: Array<IPreset>;
  public minDate = new Date(2020, 0, 1);
  public maxDate: Date;
  constructor(
    readonly dateRangeService: DateRangeService,
    readonly momentService: MomentService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
  ) {
    this.customPresets = DateRangeHelper.getDatePresets();
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.dateRange = new DateRange<Moment>(
      this.momentService.toLocalTimezone(moment(this.dates?.start)),
      this.momentService.toLocalTimezone(moment(this.dates?.end)),
    );
  }

  public rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection,
      );

    this.selectionModel.updateSelection(newSelection, this);
    const localStartDate = this.momentService.toLocalTimezone(
      moment(newSelection.start).startOf('day'),
    );
    const localEndDate = this.momentService.toLocalTimezone(
      moment(newSelection.end).endOf('day'),
    );
    this.dateRange = new DateRange<Moment>(localStartDate, localEndDate);
  }

  public selectRange(preset: IPreset): void {
    this.dateRange = new DateRange<Moment>(
      this.momentService.toLocalTimezone(preset.start),
      this.momentService.toLocalTimezone(preset.end),
    );
    this.onSetDates(preset.label, preset.start, preset.end, true);
  }

  public onSelectCustomRange(preset: IPreset): void {
    this.onSetDates(
      preset.label,
      this.dateRange.start,
      this.dateRange.end,
      false,
    );

    this.cdr.detectChanges();
    setTimeout(() => {
      this.sharedService.resizeDropdown$.next();
    }, 10);
  }

  private onSetDates(
    label: DateQueryType,
    start: Moment,
    end: Moment,
    refresh: boolean,
  ): void {
    const startEpochMillis = this.momentService.toEpochMillis(
      this.dateRange.start,
    );
    const endEpochMillis = this.momentService.toEpochMillis(this.dateRange.end);
    this.dateRangeService.changeDates$.next({
      refresh: refresh,
      preset: {
        label: label,
        start: start?.isValid() ? startEpochMillis : undefined,
        end: end?.isValid() ? endEpochMillis : undefined,
      },
    });
  }

  public onCancel(): void {
    this.closeCalendar();
  }

  public onApply(): void {
    this.closeCalendar();
    this.onSetDates(
      this.dates.label,
      this.dateRange.start,
      this.dateRange.end,
      true,
    );
  }

  private closeCalendar(): void {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(clickEvent);
  }
}
