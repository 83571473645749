<div class="flex flex-col space-y-4" [formGroup]="form">
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">TAG TYPE</span>
    <itq-dropdown
      formControlName="dataSlicer"
      [clearSelection]="false"
      [dataSource]="userCategoriesDataSource"
      [dataFields]="{ name: 'label', value: 'label' }"
      [placeholder]="'Pick a tag type...'"
      (changeValue)="onChangeDataSlicer()"
    ></itq-dropdown>
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATA SOURCE</span>
    <itq-multiple-dropdown
      formControlName="dataPoints"
      [dataSource]="dataPointsDataSource"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick tags...'"
      (changeValue)="onChangeDataPoints()"
    ></itq-multiple-dropdown>
  </div>
</div>
