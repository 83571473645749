import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';

@Component({
  selector: 'itq-raised-other-matches',
  templateUrl: './raised-other-matches.component.html',
  styleUrls: ['./raised-other-matches.component.scss'],
  standalone: true,
  imports: [CoreModule, DocumentsFeedComponent],
})
export class RaisedOtherMatchesComponent implements OnInit {
  @Input() raisedAlertId: string;

  @Output() showNotifications = new EventEmitter<void>();

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private _matchesDataSource: IData;

  public get matchesDataSource(): IData {
    return this._matchesDataSource;
  }

  public set matchesDataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._matchesDataSource = {
        result: this._matchesDataSource.result.concat(value.result),
        count: value.count,
      };
    } else {
      this._matchesDataSource = value;
    }
  }

  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly documentService: DocumentItemService,
    private customOverlayService: CustomOverlayService,
    private userService: UserService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.loadMatches();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.notificationsService.loader$.next(true);
    this.initialState = params;
    this.loadMatches();
  }

  public loadMatches(): void {
    this.notificationsService.loader$.next(true);
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(this.initialState, this.raisedAlertId)
        .subscribe(
          (matches: { items: Array<DocumentItem>; totalCount: number }) => {
            this.documentService.toggleDocument$.next({
              documentId: undefined,
              isExpanded: false,
            });
            this.matchesDataSource = {
              result: matches.items,
              count: matches.totalCount,
            };
            this.notificationsService.loader$.next(false);
          },
        );
    }
  }

  public onLoadNotifications(): void {
    this.showNotifications.emit();
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordActorComponent,
          inputs: {
            actor: extensionField,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownSubChannelComponent,
          inputs: {
            subChannel,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
}
