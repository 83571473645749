import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AlertTypesIcons,
  convertLocalTimezonePipe,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  LabelComponent,
  PillType,
  PriorityColor,
  PriorityLabel,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { DocumentItemTileComponent } from '@portal/document/component/document-item-tile/document-item-tile.component';
import { IDrilldown } from '@portal/drilldown/interfaces/drilldown.interface';
import { TagMatchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { PostNotesWizardComponent } from '@portal/shared/components/post-notes-wizard/post-notes-wizard.component';
import { SharedModule } from '@portal/shared/shared.module';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-notifications-tag-match',
  templateUrl: './notifications-tag-match.component.html',
  styleUrls: ['./notifications-tag-match.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    convertLocalTimezonePipe,
    DocumentItemTileComponent,
    LabelComponent,
  ],
})
export class NotificationsTagMatchComponent implements OnInit {
  @Input() notification: TagMatchNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  @Output() drilldown = new EventEmitter<IDrilldown>();

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly PillType = PillType;

  private subscriptions = new Subscription();
  public showDocument = false;

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit() {
    this.bindUnredSubscritpion();
    this.class = !this.notification.read ? 'unread' : '';
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private bindUnredSubscritpion(): void {
    this.subscriptions.add(
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      }),
    );
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TagMatchNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
      });
  }

  public onAddNote(params: IAddNote): void {
    let customOverlay: CustomOverlayRef;
    customOverlay = this.customOverlayService.open({
      data: params,
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      closeBtnClass: 'hidden',
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; profile: Profile; note: ProfileNote }) => {
        if (response?.profile && response.note) {
          customOverlay = this.customOverlayService.open({
            data: {
              componentConfig: {
                component: ProfilesWizardComponent,
                inputs: {
                  profile: response.profile,
                  notes: [response.note],
                },
              },
            },
            closeBtnStyle: 'basic',
            closeBtnClass: 'hidden',
            type: CustomOverlayType['almost-full'],
            component: ModalContainerComponent,
            disposeOnNavigation: true,
          });
        }
      },
    );
  }

  public onDrilldown(drilldown: IDrilldown): void {
    this.onToggleRead();
    this.drilldown.emit(drilldown);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    this.notificationService.createProfile$.next(profileDrilldown);
  }
}
