<div class="flex w-full max-h-[338px] overflow-hidden">
  <div class="flex flex-col">
    <div class="flex flex-col">
      <itq-mat-button
        [disabled]="this.widget | isWidgetDisabled"
        [matTooltipPosition]="'right'"
        [matTooltip]="this.widget | getWidgetTooltip"
        [size]="Sizes.LG"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onEdit()"
      >
        <div class="flex items-center space-x-2">
          <i class="ph ph-pencil text-lg"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">Edit</span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [disabled]="
          count === 0 ||
          (taggingStatus === TaggingStatus.IN_PROGRESS && count === 0)
        "
        [size]="Sizes.LG"
        [matTooltipPosition]="'right'"
        [matTooltip]="this.widget | getWidgetTooltip"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onExplore()"
      >
        <div class="flex items-center space-x-2">
          <i class="ph ph-binoculars text-lg"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">Explore</span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [disabled]="this.widget | isWidgetDisabled"
        [size]="Sizes.LG"
        [matTooltipPosition]="'right'"
        [matTooltip]="
          (this.widget | isWidgetDisabled)
            ? 'You don\'t have permissions to clone this tag'
            : undefined
        "
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onClone()"
      >
        <div class="flex items-center space-x-2">
          <i class="ph ph-copy text-lg"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">Clone</span>
        </div>
      </itq-mat-button>
    </div>
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        [style.padding-right]="'0.5rem !important'"
        [style.padding-left]="'0.5rem !important'"
        *ngFor="let item of sectionsDataSource"
        [style.background-color]="
          item.action === selectedSection?.action ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center space-x-2">
            <i
              [ngClass]="{
                'ph ph-plus text-lg': true,
                'ph-bold':
                  item.action === selectedSection?.action ? true : false
              }"
            ></i>
            <span
              [ngClass]="{
                'pt-[2px]': true,
                'font-medium ':
                  item.action === selectedSection?.action ? true : false
              }"
              >{{ item.name }}</span
            >
          </div>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down':
                item.action !== selectedSection?.action ? true : false,
              'ph-caret-right ph-bold':
                item.action === selectedSection?.action ? true : false
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  @if (selectedSection?.action === 'create') {
    <div class="flex-1 border-l pl-[8px] ml-[8px] border-border overflow-auto">
      <itq-mat-button
        *ngFor="let item of vendorTypesDataSurce"
        [size]="Sizes.LG"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onCreateProfile(item)"
      >
        <div class="flex items-center space-x-2">
          <i [ngClass]="['ph', 'text-lg', ProfileTypeIcon[item.name]]"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">{{
            item.name
          }}</span>
        </div>
      </itq-mat-button>
    </div>
  } @else if (selectedSection?.action === 'update') {
    <div class="flex flex-col border-border border-l ml-[8px]">
      <div class="flex items-center p-2 pt-1">
        <itq-input
          class="w-full"
          [(ngModel)]="query"
          [placeholder]="'Search...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
      </div>
      <mat-selection-list
        [style.margin-left]="'8px !important'"
        [(ngModel)]="selections"
        class="flex-1 w-[275px] overflow-auto"
        (selectionChange)="onAddProfile()"
      >
        <mat-list-option
          class="hover:bg-background-over rounded-md"
          *ngFor="let item of vendorsDataSource?.items"
          [value]="item"
        >
          <div class="flex items-center space-x-2">
            <i
              [ngClass]="['ph', vendorTypesDataSurce | getVendorTypeIcon: item]"
            >
            </i>
            <span
              class="overflow-hidden text-ellipsis"
              [innerHTML]="item.name | highlightSearch: query"
              [matTooltip]="item.name"
            ></span>
            @if (item.description) {
              <i class="ph ph-info-circle" [matTooltip]="item.description"></i>
            }
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  }
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
