<div class="p-2 search__container border-b border-border">
  <itq-input
    class="w-full"
    [(ngModel)]="query"
    [placeholder]="'Search...'"
    (search)="onDataBound()"
    (clear)="onDataBound()"
  ></itq-input>
</div>

<div
  class="dropdown-container w-full overflow-auto"
  #listSearch
  infiniteScroll
  (scrolledToEnd)="onScroll()"
>
  @if (filteredDataSource?.length > 0) {
    <ul class="overflow-auto max-h-[336px]">
      @for (item of filteredDataSource; track item[dataFields.value]) {
        <li
          (click)="onClick($event, item)"
          [ngClass]="{
            'bg-background text-primary':
              item[this.dataFields.value] === selection &&
              selection !== undefined,
            'hover:bg-background flex w-full border-b border-border items-center justify-between': true
          }"
        >
          <div [ngClass]="{ 'flex w-full': true, 'opacity-40': item.disabled }">
            <div class="flex justify-between items-center w-full">
              <div class="flex items-center justify-between w-full">
                <div
                  class="flex items-center flex-1 overflow-hidden text-ellipsis"
                >
                  @if (item?.icon) {
                    <fa-icon [icon]="item.icon" class="pr-2"></fa-icon>
                  }
                  <span
                    class="item"
                    [innerHTML]="item[dataFields?.name]"
                  ></span>
                </div>
                @if (item?.count) {
                  <span class="font-medium px-2">({{ item.count }})</span>
                }
              </div>
              @if (
                item[this.dataFields.value] === selection &&
                selection !== undefined
              ) {
                <i class="ph ph-check-circle text-lg"></i>
              }
            </div>
          </div>
          @if (item.template) {
            <ng-container
              *ngTemplateOutlet="item.template; context: { $implicit: item }"
            ></ng-container>
          }
        </li>
      }
    </ul>
  } @else {
    @if (filteredDataSource?.length === 0) {
      <span
        class="font-medium italic text-sm text-gray-700 m-2 flex items-center"
      >
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
        {{ emptyDataSourceMessage }}
      </span>
    } @else {
      <itq-loader class="p-4"></itq-loader>
    }
  }
</div>
@if (showLoader) {
  <itq-loader class="p-4"></itq-loader>
}
