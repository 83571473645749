import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'itq-full-view-screenshot',
  templateUrl: './full-view-screenshot.component.html',
  styleUrls: ['./full-view-screenshot.component.scss'],
  standalone: true,
})
export class FullViewScreenshotComponent implements OnInit {
  @Input() content: string;

  constructor() {}

  ngOnInit() {}
}
