import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { TimelineMetricsComponent } from '@portal/widgets/components/timeline-metrics/timeline-metrics.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';
import { Widget } from '@portal/boards/models/widgets/widget';

@Component({
  selector: 'itq-chart-wizard-timeline.component',
  templateUrl: './chart-wizard-timeline.component.html',
  styleUrls: ['./chart-wizard-timeline.component.scss'],
  standalone: true,
  imports: [
    LabelComponent,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MapLabelPipe,
    CommonModule,
    SelectMetricsLabelPipe,
    DocumentsFeedComponent,
    TimelineMetricsComponent,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTimelineComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() initialState: QueryFilters;
  @Input() action = Actions.CREATE;

  @Output() prevNavigation = new EventEmitter<void>();
  @Output() save = new EventEmitter<Widget>();

  public form: FormGroup;
  public showLoader = false;
  private subscription = new Subscription();
  public count: number;
  private _dataSource: IData;

  get dataSource(): IData {
    return this._dataSource;
  }

  set dataSource(data: IData) {
    this.count = data.count;
    if (this.initialState.page === 1) {
      this._dataSource = data;
    } else {
      this._dataSource.result = [...this._dataSource.result, ...data.result];
    }
  }

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;

  constructor(
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly profileService: ProfileService,
  ) {
    this.customOverlayService.setCssClass$.next('min-h-[90%]');
  }

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.getTimelineData();
    }
  }

  private getTimelineData(): void {
    const queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    this.tagService
      .execute(
        this.initialState,
        queryModel.convertToBackEndQuery(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IData) => {
        this.dataSource = response;
        this.showLoader = false;
      });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordActorComponent,
          inputs: {
            actor: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownSubChannelComponent,
          inputs: {
            subChannel,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: Timeline;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
  }

  public onPrev(): void {
    this.prevNavigation.emit();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.save.emit(this.widget);
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
