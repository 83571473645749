@if (builderConfig && userTagCategory) {
  <query-builder
    [(ngModel)]="queryModel"
    [config]="builderConfig"
    emptyMessage="A group cannot be empty. Please add a rule or remove it all together."
  >
    <ng-container
      *queryButtonGroup="
        let ruleset;
        let addRule = addRule;
        let addRuleSet = addRuleSet;
        let removeRuleSet = removeRuleSet
      "
    >
      <div class="flex items-center space-x-2" role="group">
        <itq-mat-button
          [disabled]="queryModel.hasEmptyRules()"
          [padding]="Sizes.SM"
          [materialStyle]="'basic'"
          (clickEvent)="addRule()"
        >
          <itq-label
            [label]="'ADD RULE'"
            [icon]="'ph-plus'"
            [bold]="true"
          ></itq-label>
        </itq-mat-button>
        @if (addRuleSet) {
          <itq-mat-button
            [disabled]="
              queryModel.hasEmptyRules() || queryModel.maxLevelNestingReached()
            "
            [padding]="Sizes.SM"
            [matTooltip]="
              queryModel.maxLevelNestingReached()
                ? 'Can\'t add more than three levels of nested queries'
                : ''
            "
            [materialStyle]="'basic'"
            (clickEvent)="addRuleSet(); addRuleToRuleSet()"
          >
            <itq-label
              [label]="'ADD GROUP'"
              [icon]="'ph-plus'"
              [bold]="true"
            ></itq-label>
          </itq-mat-button>
        }

        @if (removeRuleSet) {
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            (clickEvent)="removeRuleSet(); onRemoveRuleSet()"
          >
            <itq-label
              [label]="'DELETE GROUP'"
              [icon]="'ph-trash'"
              [bold]="true"
            ></itq-label>
          </itq-mat-button>
        }
      </div>
    </ng-container>

    <ng-container *querySwitchGroup="let ruleset; let onChange = onChange">
      @if (ruleset) {
        <mat-button-toggle-group
          name="operator"
          [(ngModel)]="ruleset.condition"
          (ngModelChange)="onChange($event); onChangeValue()"
          color="accent"
        >
          <mat-button-toggle value="and">And</mat-button-toggle>
          <mat-button-toggle value="or">Or</mat-button-toggle>
        </mat-button-toggle-group>
      }
    </ng-container>
    <ng-container *queryField="let rule">
      <div class="mr-2 min-w-[180px]">
        <span class="text-xs text-metadata uppercase">
          {{ getFieldLabel(rule.field) }}</span
        >
        <itq-nav-button
          #fieldButton
          [materialStyle]="'stroked'"
          [size]="Sizes.BLOCK"
          [template]="fieldsTemplate"
        >
          <div
            class="font-normal flex items-center justify-between w-full whitespace-nowrap"
          >
            {{ getLabelByFieldId(rule) }}
            <fa-icon
              class="border-l border-border pl-1 ml-2"
              [icon]="
                fieldButton.isOpen
                  ? ['far', 'chevron-up']
                  : ['far', 'chevron-down']
              "
            ></fa-icon>
          </div>
        </itq-nav-button>
        <ng-template #fieldsTemplate>
          <itq-field-filters
            class="flex"
            [dates]="initialState?.where"
            [queryModel]="queryModel"
            [field]="rule.field !== 'unknown' ? rule.field : null"
            (selectionChange)="onFieldFilterSelectionChange($event, rule)"
          ></itq-field-filters>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *queryOperator="let rule">
      <div class="mr-2 min-w-[180px]">
        <span class="text-xs text-metadata">OPERATOR</span>
        <itq-dropdown
          [(ngModel)]="rule.operator"
          [placeholder]="'Operator...'"
          [clearSelection]="false"
          [dataSource]="getFieldOperators(rule.field)"
          [dataFields]="{ name: 'name', value: 'value' }"
          (changeValue)="onChangeOperator(rule)"
        ></itq-dropdown>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'textarea'">
      <div class="w-full mt-2">
        <span class="text-xs text-metadata">SEARCH TERM</span>
        <itq-input
          [icon]="'search'"
          [(ngModel)]="rule.value"
          [placeholder]="'Add search term...'"
          [type]="'text'"
          (search)="onChangeQuery(rule)"
          (clear)="onChangeQuery(rule)"
        ></itq-input>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'tag'">
      <div class="w-full mt-2">
        <span class="text-xs text-metadata uppercase">
          {{ getValueLabel(rule.field) }} VALUES</span
        >
        <itq-qb-multiple-dropdown
          [(ngModel)]="rule.value"
          [dataFields]="{ name: 'name', value: 'id' }"
          [placeholder]="'Choose an option...'"
          [dataSource]="tagsDataSource?.items"
          [scrollableContainer]="scrollableContainer"
          [initialState]="tagsInitialState"
          (changeValue)="onChangeValue()"
          (dataBound)="onGetFieldValues(rule, $event)"
        >
        </itq-qb-multiple-dropdown>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'filter'">
      <div class="w-full mt-2">
        <span class="text-xs text-metadata uppercase"
          >{{ getValueLabel(rule.field) }} VALUES</span
        >
        <itq-rule-value
          class="w-full mt-2"
          [rule]="rule"
          [dataSource]="this.fieldsDataSource"
          [initialState]="this.initialState"
          [queryModel]="this.queryModel"
          (removeValue)="onRemoveValue($event)"
          (valuesChange)="onFilterValuesSelectionChange($event)"
        ></itq-rule-value>
      </div>
    </ng-container>
    <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
      @if (queryModel.getRulesCount() > 1) {
        <itq-mat-button
          class="mr-2"
          [padding]="Sizes.NONE"
          [matTooltip]="'Delete rule'"
          [materialStyle]="'basic'"
          (clickEvent)="
            $event.stopPropagation(); removeRule(rule); onChangeQuery(rule)
          "
        >
          <i class="ph ph-trash text-lg"></i>
        </itq-mat-button>
      }
    </ng-container>
  </query-builder>
}
