import { ChartOrientation, ChartType } from '@portal/widgets/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const TOP_ACTORS_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'Table',
    type: ChartType.TABLE,
    phosphorIcon: 'ph-table',
    tooltip: 'Table',
  },
  {
    id: 'VerticalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
    },
    phosphorIcon: 'ph-chart-bar',
    tooltip: 'Vertical Bar',
  },
  {
    id: 'HorizontalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.HORIZONTAL,
    },
    phosphorIcon: 'ph-chart-bar-horizontal',
    tooltip: 'Horizontal Bar',
  },
];
