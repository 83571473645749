<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col">
    <h2 class="flex font-medium pt-7 pb-2 text-xl">Scope</h2>
    <mat-radio-group
      formControlName="typeDataSource"
      (change)="onChangeDataSourceType($event)"
    >
      <mat-radio-button [value]="DataSourceType.ALL">
        Compare tag volumes across all posts
      </mat-radio-button>
      <mat-radio-button [value]="DataSourceType.SET">
        Filter to analyse within a specific tag
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Data source</h2>
  @if (this.form?.controls?.typeDataSource?.value === DataSourceType.SET) {
    <itq-specific-tag-datasource
      [form]="form"
      [widget]="tagComparisonWidget"
      [dates]="dates"
      (changeDataSource)="onChangeDataSources($event)"
      (changeDataType)="onChangeDataType($event)"
      (changeDataValue)="onChangeDataValues($event)"
    ></itq-specific-tag-datasource>
  } @else {
    <itq-all-tags-datasource
      [dates]="dates"
      [dates]=""
      [form]="form"
      [widget]="tagComparisonWidget"
      (changeDataValue)="onChangeDataValues($event)"
      (changeDataType)="onChangeDataType($event)"
    ></itq-all-tags-datasource>
  }
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      class="w-full"
      [dates]="this.dates"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>
