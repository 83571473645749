import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Actions,
  CoreModule,
  DateQueryType,
  DateRangeService,
  EventBusService,
  FAwesomeModule,
  QueryFilters,
  SharedService,
} from '@intorqa-ui/core';

import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import {
  KtdGridComponent,
  KtdGridLayout,
  KtdGridLayoutItem,
  KtdGridModule,
  ktdTrackById,
} from '@katoid/angular-grid-layout';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { Board } from '@portal/boards/models/board';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { TopChannel } from '@portal/boards/models/widgets/top-channel';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { IWidget } from '@portal/shared/interfaces/widget.interface';
import { UserService } from '@portal/shared/services/user.service';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { EChartsOption } from 'echarts';
import { KeycloakService } from 'keycloak-angular';
import { cloneDeep } from 'lodash';
import { Subscription, debounceTime, fromEvent, merge } from 'rxjs';
import { BoardHeaderComponent } from '../board-header/board-header.component';
import { BoardToolbarComponent } from '../board-toolbar/board-toolbar.component';
import { WidgetComponent } from '../widget/widget.component';

@Component({
  selector: 'itq-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
  standalone: true,
  imports: [
    KtdGridModule,
    BoardHeaderComponent,
    BoardToolbarComponent,
    CoreModule,
    WidgetComponent,
    MatTooltip,
    FAwesomeModule,
  ],
})
export class BoardComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: KtdGridComponent;
  @ViewChild('scrollRef') scrollRef: ElementRef;

  public board: Board;
  public options: EChartsOption;
  public columns: number;
  public initialState: QueryFilters;
  public cols: number = 100;
  public rowHeight: number = 50;
  public widgets: Array<
    | TagAnalysis
    | TagComparison
    | TimeSeries
    | Timeline
    | EventTimeline
    | TopicClustering
    | TopActor
    | TopChannel
  >;
  public layout: KtdGridLayout = [];
  public trackById = ktdTrackById;
  private subscriptions = new Subscription();
  public form: FormGroup;
  public element;
  public currentBreakpoint: string;

  readonly ChartType = ChartType;
  readonly Actions = Actions;
  readonly AnalysisTypes = AnalysisTypes;

  constructor(
    private boardService: BoardService,
    private widgetService: WidgetService,
    readonly notificationsService: NotificationsService,
    readonly cdr: ChangeDetectorRef,
    readonly sharedService: SharedService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly eventBusService: EventBusService,
    readonly userService: UserService,
    readonly keycloakService: KeycloakService,
    readonly dateRangeService: DateRangeService,
  ) {}
  ngOnInit(): void {
    this.element = document.querySelector('itq-board');
    this.createForm();
    this.addTimelineSubscription();
    this.bindResizeEvent();
    this.bindBreakpointsEvents();
    this.bindLoadBoardSubscription();
    this.bindQueryParamsSubscription();
    this.bindDeleteWidgetSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
    this.boardService.board = undefined;
  }

  private bindDeleteWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.delete$.subscribe((widget: Widget) => {
        this.onDeleteWidget(widget);
      }),
    );
  }

  private registerEventBusEvents(): void {
    this.unRegisterEventBusEvents();
    this.registerEventBusLayout();
    this.registerEventBusCreateWidget();
    this.registerEventBusUpdateBoard();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusCreateWidget();
    this.unRegisterEventBusLayout();
    this.unRegisterEventBusUpdateBoard();
  }

  private bindQueryParamsSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        const raisedAlertId = params?.raisedalertid;
        const researchAlertId = params?.researchAlertId;
        if (raisedAlertId) {
          this.removeQueryParam('raisedalertid');
          this.notificationsService.openNotifications$.next({
            raisedAlertId,
            researchAlertId: undefined,
          });
        }
        if (researchAlertId) {
          this.removeQueryParam('researchAlertId');
          this.notificationsService.openNotifications$.next({
            raisedAlertId: undefined,
            researchAlertId,
          });
        }
      }),
    );
  }

  private scrollToTop(): void {
    if (this.scrollRef?.nativeElement) {
      this.scrollRef.nativeElement.scrollTop = 0;
    }
  }

  private bindLoadBoardSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe((params) => {
        const boardId = this.activatedRoute.snapshot.paramMap.get('id');
        this.scrollToTop();
        this.loadBoard(boardId);
      }),
    );

    this.subscriptions.add(
      this.boardService.loadBoard$.subscribe(() => {
        this.scrollToTop();
        this.loadBoard(undefined);
      }),
    );
  }

  private loadBoard(boardId: string): void {
    if (this.board) {
      this.unRegisterEventBusEvents();
    }
    if (!boardId) {
      this.sharedService.loader$.next(true);
      this.boardService
        .getDefaultBoard(this.userService.userPreferences.defaultEcosystemId)
        .subscribe((response: Board) => {
          this.board = response;
          this.initializeBoard();
        });
    } else {
      if (this.boardService.boards.length === 0) {
        this.sharedService.loader$.next(true);
        this.boardService.getBoardById(boardId).subscribe((response: Board) => {
          this.board = response;
          this.initializeBoard();
        });
      } else {
        this.board = this.boardService.findBoardById(boardId);
        this.boardService.board = cloneDeep(this.board);
        this.registerEventBusEvents();
        this.onGetWidgets();
      }
    }
  }

  private initializeBoard(): void {
    this.boardService.changeBoard$.next(this.board);
    this.boardService.board = cloneDeep(this.board);
    this.registerEventBusEvents();
    this.boardService.loadTree$.next();
    this.onGetWidgets();
  }

  private createForm(): void {
    this.form = new FormGroup({});
  }

  private bindResizeEvent(): void {
    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(100))
        .subscribe(() => {
          if (
            this.currentBreakpoint === 'xl' ||
            this.currentBreakpoint === '2xl'
          ) {
            this.grid?.resize();
          }
        }),
    );
  }

  private bindBreakpointsEvents(): void {
    this.subscriptions.add(
      this.sharedService.currentBreakpoint$.subscribe((breakpoint) => {
        this.currentBreakpoint = breakpoint;
        if (this.currentBreakpoint == 'xl' || this.currentBreakpoint == '2xl') {
          if (!this.layout) {
            this.layout = cloneDeep(this.widgets)?.map(
              (
                item:
                  | TagAnalysis
                  | TagComparison
                  | TimeSeries
                  | Timeline
                  | EventTimeline
                  | TopicClustering
                  | TopActor
                  | TopChannel,
              ) => {
                return {
                  id: item.widgetId,
                  x: item.x,
                  y: item.y,
                  w: item.width,
                  h: item.height,
                  minW: 25,
                  minH: 5,
                };
              },
            );

            this.cdr.detectChanges();
            this.grid?.resize();
            this.onReloadDashboard();
          }
        } else {
          if (this.layout) {
            this.layout = undefined;
            this.cdr.detectChanges();
            this.onReloadDashboard();
          }
        }
      }),
    );
  }

  private addTimelineSubscription() {
    this.subscriptions.add(
      this.widgetService.addWidget$.subscribe(
        (widget: Timeline | TagAnalysis | TagComparison | TimeSeries) => {
          this.onAddWidget(widget);
        },
      ),
    );
  }

  public onAddWidget(
    widget:
      | Timeline
      | TagAnalysis
      | TagComparison
      | TimeSeries
      | EventTimeline
      | TopicClustering
      | TopActor
      | TopChannel,
  ): void {
    this.addWidgetToLayout(widget);
    this.widgets.push(widget);
    this.board.widgetIds = this.widgets.map((item: Widget) => item.widgetId);
    this.cdr.detectChanges();
    this.widgetService.reload$.next({
      widget,
      initialState: this.initialState,
    });
  }

  removeQueryParam(param: string): void {
    localStorage.removeItem('researchAlertId');
    // Get the current route without the removed query param
    let queryParams = { ...this.router.routerState.snapshot.root.queryParams };
    delete queryParams[param];
    // Navigate to the same route with the modified query params
    this.router.navigate([], {
      queryParams: queryParams,
      replaceUrl: true,
    });
  }

  private onGetWidgets(): void {
    this.sharedService.loader$.next(true);
    this.initialState = new QueryFilters(
      30,
      1,
      this.board.filter?.date,
      undefined,
      undefined,
      undefined,
    );
    this.boardService
      .getWidgets(this.board)
      .subscribe(
        (
          response: Array<TagAnalysis | TagComparison | TimeSeries | Timeline>,
        ) => {
          this.reDrawWidgets(response);
        },
      );
  }

  onLayoutUpdated(layout: KtdGridLayout) {
    let changedWidgets: Array<Widget> = [];
    layout.forEach((item: KtdGridLayoutItem, index: number) => {
      const widget = this.widgets.find(
        (widget: TagAnalysis | TagComparison | TimeSeries | Timeline) =>
          widget.widgetId === item.id,
      );
      if (widget) {
        if (
          widget.x !== item.x ||
          widget.y !== item.y ||
          widget.width !== item.w ||
          widget.height !== item.h
        ) {
          this.layout[index].w = item.w;
          this.layout[index].h = item.h;
          this.layout[index].x = item.x;
          this.layout[index].y = item.y;
          widget.x = item.x;
          widget.y = item.y;
          widget.width = item.w;
          widget.height = item.h;
          changedWidgets.push(widget);
        }
      }
    });
    this.boardService.updateLayout(changedWidgets).subscribe();
  }

  public onDeleteWidget(widget: Widget): void {
    this.layout = this.layout.filter((item: KtdGridLayoutItem) => {
      return item.id !== widget.widgetId;
    });
    this.widgets = this.widgets.filter((item: Widget) => {
      return item.widgetId !== widget.widgetId;
    });
    this.board.widgetIds = this.widgets.map((item: Widget) => item.widgetId);
  }

  public onUpdateWidget(
    widget: TagAnalysis | TagComparison | TimeSeries | Timeline,
  ): void {
    this.widgets = this.widgets.map(
      (item: TagAnalysis | TagComparison | TimeSeries | Timeline) => {
        return item.widgetId === widget.widgetId ? widget : item;
      },
    );
  }

  public onReloadDashboard(): void {
    this.widgetService.reload$.next({ initialState: this.initialState });
  }

  private addWidgetToLayout(
    widget:
      | Timeline
      | TagAnalysis
      | TagComparison
      | TimeSeries
      | EventTimeline
      | TopicClustering
      | TopActor
      | TopChannel,
  ): void {
    this.layout = [
      ...this.layout,
      {
        id: widget.widgetId,
        x: widget.x,
        y: widget.y,
        w: widget.width,
        h: widget.height,
        minW: 25,
        minH: 5,
      },
    ];
  }

  public onSearchQuery(): void {
    this.initialState.query = this.form.value.query;
  }

  public onSearch(params: QueryFilters): void {
    this.initialState = params;
    this.cdr.detectChanges();
    this.onReloadDashboard();
  }

  public getTimelineWidget(i: number): Timeline {
    return this.widgets[i] as Timeline;
  }

  public getWidget(i: number): TagAnalysis | TagComparison | TimeSeries {
    return this.widgets[i] as TagAnalysis | TagComparison | TimeSeries;
  }

  private reDrawWidgets(
    widgets: Array<
      | TagAnalysis
      | TagComparison
      | TimeSeries
      | Timeline
      | EventTimeline
      | TopicClustering
      | TopActor
      | TopChannel
    >,
  ): void {
    this.layout = cloneDeep(widgets)?.map(
      (
        item:
          | TagAnalysis
          | TagComparison
          | TimeSeries
          | Timeline
          | EventTimeline
          | TopicClustering
          | TopActor
          | TopChannel,
      ) => {
        return {
          id: item.widgetId,
          x: item.x,
          y: item.y,
          w: item.width,
          h: item.height,
          minW: 25,
          minH: 5,
        };
      },
    );
    this.widgets = widgets;
    this.cdr.detectChanges();
    this.onReloadDashboard();
    this.sharedService.loader$.next(false);
  }

  private registerEventBusLayout(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_LAYOUT}.${this.board.id}`,
      this.drawWidgetsCallback(),
    );
  }

  private unRegisterEventBusLayout(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_LAYOUT}.${this.board.id}`,
      this.drawWidgetsCallback(),
    );
  }

  private drawWidgetsCallback(): (
    err: Error,
    msg: {
      body: { board: Board; username: string };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: Board; widgets: Array<IWidget>; username: string };
        address: string;
        type: string;
      },
    ) => {
      const username = this.keycloakService.getUsername();
      if (username !== msg.body.username) {
        const widgets = msg.body.widgets.map((item: IWidget) =>
          WidgetFactory.createWidget(item),
        );

        this.reDrawWidgets(widgets);
      }
    };
  }

  private registerEventBusCreateWidget(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.CREATE_WIDGET}.${this.board.id}`,
      this.createWidgetCallback(),
    );
  }

  private unRegisterEventBusCreateWidget(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.CREATE_WIDGET}.${this.board.id}`,
      this.createWidgetCallback(),
    );
  }

  private createWidgetCallback(): (
    err: Error,
    msg: {
      body:
        | TagAnalysis
        | TimeSeries
        | TagComparison
        | Timeline
        | EventTimeline
        | TopicClustering
        | TopActor
        | TopChannel;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body:
          | TagAnalysis
          | TimeSeries
          | TagComparison
          | Timeline
          | EventTimeline
          | TopicClustering
          | TopActor
          | TopChannel;
        address: string;
        type: string;
      },
    ) => {
      const widget = WidgetFactory.createWidget(msg.body);
      this.onAddWidget(widget);
    };
  }

  private registerEventBusUpdateBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateBoardCallback(),
    );
  }

  private unRegisterEventBusUpdateBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateBoardCallback(),
    );
  }

  private updateBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard };
        address: string;
        type: string;
      },
    ) => {
      if (this.board.id === msg.body.board.id) {
        const board = new Board(
          msg.body.board.id,
          msg.body.board.name,
          msg.body.board.description,
          msg.body.board.defaultBoard,
          msg.body.board.filter,
          msg.body.board.widgetIds,
          msg.body.board.updatedDate || 0,
        );
        const boardDate = msg.body.board.filter?.date;
        if (
          JSON.stringify(boardDate) !== JSON.stringify(this.board.filter?.date)
        ) {
          this.initialState.page = 1;
          this.initialState.where = msg.body.board.filter?.date;
          if (boardDate) {
            const dates = {
              label: DateQueryType[boardDate?.label],
              start: boardDate?.start,
              end: boardDate?.end,
            };
            this.dateRangeService.changeDates$.next({
              refresh: false,
              preset: dates,
            });
          }
          this.board = board;

          this.widgetService.reload$.next({ initialState: this.initialState });
        } else {
          this.board = board;
        }
      }
    };
  }
}
