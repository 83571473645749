<div class="flex flex-col w-full">
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.LG"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (clickEvent)="$event.stopImmediatePropagation(); onTranslate()"
  >
    <div class="flex items-center space-x-2">
      <i class="ph ph-translate text-lg"></i>
      <span class="whitespace-nowrap font-normal">Translate</span>
    </div>
  </itq-mat-button>

  @if (allowDrilldown) {
    @if (document.id !== activeDocumentId) {
      @if (document?.emitSource === 'Discord') {
        <itq-mat-button
          [padding]="Sizes['X-SM']"
          [size]="Sizes.LG"
          [type]="'button'"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onDrilldownContext()"
        >
          <div class="flex items-center space-x-2">
            <i class="ph ph-tree-view text-lg"></i>
            <span class="whitespace-nowrap font-normal">View context</span>
          </div>
        </itq-mat-button>
      }
    }
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onAddNote($event)"
    >
      <div class="flex items-center space-x-2">
        <i class="ph ph-note text-lg"></i>
        <span class="whitespace-nowrap font-normal">Add note</span>
      </div>
    </itq-mat-button>
    @if (shareByEmail) {
      <itq-mat-button
        [padding]="Sizes['X-SM']"
        [size]="Sizes.LG"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onShareSlack($event)"
      >
        <div class="flex items-center space-x-2">
          <i class="ph ph-slack-logo text-lg"></i>
          <span class="whitespace-nowrap font-normal">Share to slack</span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.LG"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onShareEmail($event)"
      >
        <div class="flex items-center space-x-2">
          <i class="ph ph-envelope text-lg"></i>
          <span class="whitespace-nowrap font-normal">Share by email</span>
        </div>
      </itq-mat-button>
    }
  }
</div>
