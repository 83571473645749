export enum AlertTypes {
  TAG_MATCH = 'Tag Match Alert',
  THRESHOLD = 'Tag Threshold Alert',
  RESEARCH = 'Intorqa Research Alert',
  SYSTEM = 'System Alert',
}

export enum AlertTypesLabel {
  'Tag Match Alert' = 'TAG MATCH',
  'Tag Threshold Alert' = 'TAG THRESHOLD',
  'Intorqa Research Alert' = 'INTORQA RESEARCH',
  'System Alert' = 'SYSTEM',
}

export enum AlertTypesIcons {
  'Tag Match Alert' = 'tag',
  'Tag Threshold Alert' = 'chart-line-up',
  'Research' = 'chart-line-up',
}

export enum TransactionNotificationType {
  TAG_CREATED = 'New Tag Created',
  TAG_EDITED = 'Tag Edited',
  TAG_DELETED = 'Tag Deleted',
  PROFILE_CREATED = 'New Profile Created',
  PROFILE_DELETED = 'Profile Deleted',
  PROFILE_UPDATED = 'Profile Metadata Edited',
  PROFILE_NOTE_CREATED = 'New Note Added',
  PROFILE_NOTE_DELETED = 'Note Deleted',
  PROFILE_NOTE_UPDATED = 'Note Edited',
}

export enum TransactionNotificationTypeText {
  'New Tag Created' = 'Explore tag',
  'Tag Edited' = 'Edit tag',
  'Tag Deleted' = 'Open tag manager',
  'New Profile Created' = 'Load vendor',
  'Profile Deleted' = 'Open vendors list',
  'Profile Metadata Edited' = 'Edit vendor',
  'New Note Added' = 'Load vendor',
  'Note Deleted' = 'Load vendor',
  'Note Edited' = 'Load vendor',
}
