<form
  class="flex flex-col h-full"
  [formGroup]="form"
  (ngSubmit)="form.valid && onSubmit()"
>
  <div class="flex flex-col flex-1">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <i class="ph ph-sliders-horizontal text-5xl"></i>
          <h1 class="text-28 font-medium">Filter tags</h1>
        </div>
        <div class="text-xl justify-start w-full">
          Please add some filters to narrow your search results
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <i class="ph ph-x text-2xl"></i
      ></itq-mat-button>
    </div>
    <div class="flex flex-col p-7 bg-background flex-1 space-y-4">
      <div class="flex flex-col min-w-[300px]">
        <div
          [ngClass]="{
            'mb-1 text-13 font-medium w-full flex items-center': true,
            'justify-between': form?.get('userTagCategory')?.value,
            'justify-start': !form?.get('userTagCategory')?.value
          }"
        >
          TAG TYPE
          @if (form?.get('userTagCategory')?.value?.length > 0) {
            <button
              class="text-link hover:underline"
              [type]="'button'"
              (click)="onClearType()"
            >
              CLEAR
            </button>
          }
        </div>
        <itq-multiple-dropdown
          class="w-full"
          [placeholder]="'Pick a tag type...'"
          formControlName="userTagCategory"
          [dataSource]="userCategoriesDataSource"
          [dataFields]="{ name: 'name', value: 'name' }"
          (changeValue)="onAddFilter('userTagCategory', SearchFieldType.TEXT)"
        ></itq-multiple-dropdown>
      </div>
      <div class="flex flex-col">
        <div class="mb-1 text-13 font-medium w-full flex items-center">
          SHARED TAGS
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="sharedTag"
          [dataSource]="sharedOptions"
          [dataFields]="{ name: 'label', value: 'value' }"
          (changeValue)="onAddFilter('sharedTag', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>

      <div class="flex flex-col">
        <div class="mb-1 text-13 font-medium w-full flex items-center">
          HAS ALERTS
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="hasAlerts"
          [dataSource]="alertsOptions"
          [dataFields]="{ name: 'label', value: 'value' }"
          (changeValue)="onAddFilter('sharedTag', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>

      <div class="flex flex-col">
        <div class="mb-1 text-13 font-medium w-full flex items-center">
          SYSTEM TAGS
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="systemTag"
          [dataSource]="systemOptions"
          [dataFields]="{ name: 'label', value: 'value' }"
          (changeValue)="onAddFilter('systemTag', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>
    </div>
  </div>
  <footer
    class="flex justify-between px-7 py-4 bg-white border-t border-border space-x-2"
  >
    <itq-mat-button
      [disabled]="columnFilters?.length === 0"
      [materialStyle]="'stroked'"
      [type]="'button'"
      (clickEvent)="onClearFilters()"
    >
      <itq-label
        [label]="'CLEAR FILTERS'"
        [icon]="'ph-funnel-x'"
        [bold]="true"
      ></itq-label>
    </itq-mat-button>
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [disabled]="!form.valid"
    >
      APPLY FILTERS
    </itq-mat-button>
  </footer>
</form>
