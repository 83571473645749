<div class="flex flex-col w-full">
  <span class="px-4 py-2 bg-border font-medium mb-4 rounded-md"
    >WIDGET TYPE</span
  >
  <div
    class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  >
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-chat'"
      [title]="'Posts timeline'"
      [description]="'A stream of posts from a tag'"
      [iconType]="IconType.PHOSPHOR"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIMELINE)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-magnifying-glass'"
      [iconType]="IconType.PHOSPHOR"
      [title]="'Tag analysis'"
      [description]="'Analyse within specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_ANALYSIS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-chart-pie-slice'"
      [iconType]="IconType.PHOSPHOR"
      [title]="'Tag comparison'"
      [description]="'Compare one or more tags'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_COMPARISON)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-trend-up'"
      [iconType]="IconType.PHOSPHOR"
      [title]="'Tag trends'"
      [description]="'Analyse tag trends over time'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIME_SERIES)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-user-circle'"
      [iconType]="IconType.PHOSPHOR"
      [title]="'Top actors'"
      [description]="'Top actors within a specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOP_ACTORS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ph-hash'"
      [iconType]="IconType.PHOSPHOR"
      [title]="'Top channels'"
      [description]="'Top channels within a specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOP_CHANNELS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ripl'"
      [title]="'Topic clustering'"
      [iconType]="IconType.SVG"
      [description]="'Topics discussed within a tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOPIC_CLUSTERS)"
    ></itq-thumbnail>
    @if (userService.userPreferences.defaultEcosystemId | hasEventsEnabled) {
      <itq-thumbnail
        class="p-4 bg-white"
        [icon]="'timeline'"
        [title]="'Events timeline'"
        [description]="'Analyse timeline of events'"
        [disabled]="disabled"
        (add)="onAdd(AnalysisTypes.EVENT_TIMELINE)"
      ></itq-thumbnail>
    }
  </div>
</div>
