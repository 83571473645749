<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('actor')?.value,
        'justify-start': !form?.get('actor')?.value
      }"
    >
      ACTOR
      @if (form?.get('actor')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearActors()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-value-picker
      [queryModel]="this.queryModel"
      [dates]="this.initialState.where"
      [fields]="fields"
      [formControlName]="'actor'"
      [field]="this.actorField"
      (valueChange)="onDataBound()"
    ></itq-value-picker>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('channel')?.value,
        'justify-start': !form?.get('channel')?.value
      }"
    >
      CHANNEL
      @if (form?.get('channel')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearChannels()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-value-picker
      [queryModel]="this.queryModel"
      [dates]="this.initialState.where"
      [fields]="fields"
      [formControlName]="'channel'"
      [field]="channelField"
      (valueChange)="onDataBound()"
    ></itq-value-picker>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('source')?.value,
        'justify-start': !form?.get('source')?.value
      }"
    >
      SOURCE
      @if (form?.get('source')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearSource()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'source'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick a source...'"
      [dataSource]="sourcesDataSource"
      [initialState]="sourcesInitialState"
      (dataBound)="onGetSources()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('vendors')?.value,
        'justify-start': !form?.get('vendors')?.value
      }"
    >
      VENDORS
      @if (form?.get('vendors')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearVendor()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'vendors'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick a vendor...'"
      [dataSource]="vendorsDataSource"
      [initialState]="vendorsInitialState"
      (dataBound)="onGetVendors()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('cohort')?.value,
        'justify-start': !form?.get('cohort')?.value
      }"
    >
      COHORT
      @if (form?.get('cohort')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCohort()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'cohort'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick a vendor...'"
      [dataSource]="cohortsDataSource"
      [initialState]="cohortInitialState"
      (dataBound)="onGetCohorts()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>
</div>
