<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('cheatTools')?.value,
        'justify-start': !form?.get('cheatTools')?.value
      }"
    >
      CHEAT TOOLS
      @if (form?.get('cheatTools')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCheatTools()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'cheatTools'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick cheat tools...'"
      [dataSource]="cheatToolsDataSource"
      [initialState]="cheatToolsInitialState"
      (dataBound)="onGetCheatTools()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('glitch')?.value,
        'justify-start': !form?.get('glitch')?.value
      }"
    >
      GLITCH
      @if (form?.get('glitch')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearGlitch()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'glitch'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick glitches...'"
      [dataSource]="glitchesDataSource"
      [initialState]="glitchesInitialState"
      (dataBound)="onGetGlitches()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('assets')?.value,
        'justify-start': !form?.get('assets')?.value
      }"
    >
      ASSETS
      @if (form?.get('assets')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearAssets()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'assets'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick assets...'"
      [dataSource]="assetsDataSource"
      [initialState]="assetsInitialState"
      (dataBound)="onGetAssets()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('utilities')?.value,
        'justify-start': !form?.get('utilities')?.value
      }"
    >
      UTILITIES
      @if (form?.get('utilities')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearUtilities()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'utilities'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick utilities...'"
      [dataSource]="utilitiesDataSource"
      [initialState]="utilitiesIntialState"
      (dataBound)="onGetUtilities()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
</div>
