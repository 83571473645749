import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  FAwesomeModule,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
} from '@intorqa-ui/core';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TAG_ANALYSIS_CHART_TYPES } from '@portal/boards/const/tag-analysis.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { Widget } from '@portal/boards/models/widgets/widget';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { ChartService } from '@portal/shared/services/chart.service';
import { TagAnalysisMetricsComponent } from '@portal/widgets/components/tag-analysis-metrics/tag-analysis-metrics.component';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';

@Component({
  selector: 'itq-chart-wizard-tag-analysis.component',
  templateUrl: './chart-wizard-tag-analysis.component.html',
  styleUrls: ['./chart-wizard-tag-analysis.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MapLabelPipe,
    CommonModule,
    SelectMetricsLabelPipe,
    TagAnalysisMetricsComponent,
    ChartComponent,
    TableChartComponent,
    LabelComponent,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTagAnalysisComponent implements OnInit {
  @Input() widget: TagAnalysis;
  @Input() action = Actions.CREATE;
  @Input() initialState: QueryFilters;

  @Output() prevNavigation = new EventEmitter<void>();
  @Output() save = new EventEmitter<Widget>();

  public form: FormGroup;
  public showLoader = false;
  public chartTypesDataSource = TAG_ANALYSIS_CHART_TYPES;
  private subscription = new Subscription();
  public count: number;
  public tableColumns: Array<TableColumn>;
  public dataSource: IWidgetData;

  readonly ChartType = ChartType;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  constructor(
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly modalContainerService: ModalContainerService,
    readonly customOverlayService: CustomOverlayService,
    readonly categoriesService: CategoryService,
  ) {
    this.customOverlayService.setCssClass$.next('min-h-[90%]');
  }

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initTableColumns(): void {
    if (this.widget.chartType === ChartType.TABLE) {
      this.tableColumns = [
        {
          name: this.widget.dataSlicer?.label,
          dataKey: 'label',
          width: 'fit',
          isSortable: true,
        },
        {
          name: 'Count',
          dataKey: 'count',
          position: 'right',
          width: '100px',
          customRender: true,
          template: this.countTemplate,
          isSortable: true,
        },
      ];
    }
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.getWidgetData();
    }
  }

  private getWidgetData(): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.widgetService
        .getData(
          {
            widget: this.widget,
            filters: new Query().convertToBackEndQuery(),
          },
          this.initialState,
        )
        .subscribe((response: IWidgetData) => {
          this.initTableColumns();
          this.dataSource = response;
          this.count = response.totalHits;
          this.cdr.detectChanges();
          this.chartService.reloadChart$.next(
            this.widget.widgetId + '_expanded',
          );
          this.showLoader = false;
        });
    }
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagAnalysis;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    if (
      this.widget.chartType === params.type &&
      JSON.stringify(this.widget.options) === JSON.stringify(params.options)
    ) {
      return;
    }
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.initTableColumns();
    this.dataSource.series = this.widget.transformData(this.dataSource.series);
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
  }

  public onPrev(): void {
    this.prevNavigation.emit();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.save.emit(this.widget);
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
