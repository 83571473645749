import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Actions,
  Align,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  FieldSections,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  UserTagCategory,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ViewMode } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import moment from 'moment';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { ProfilesWizardComponent } from '../profiles-wizard/profiles-wizard.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';

@Component({
  selector: 'itq-profiles-tags',
  templateUrl: './profiles-tags.component.html',
  styleUrls: ['./profiles-tags.component.scss'],
})
export class ProfilesTagsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() link: LinkTag;
  @Input() action: Actions;
  @Input() showLinkTags = false;

  public initialState = new QueryFilters(
    30,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public timelineData: IData;
  public queryModel: Query;
  private showAddLinkSubscription: Subscription;

  readonly ViewMode = ViewMode;
  readonly ChartType = ChartType;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly Sizes = Sizes;

  constructor(
    private profilesService: ProfileService,
    private linkTagsService: LinkTagsService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly customOverlayService: CustomOverlayService,
    readonly categoriesService: CategoryService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.showAddLinkSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.getTimelineData();
        }
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.link?.previousValue !== changes?.link?.currentValue) {
      this.updateQueryModel();
    }
  }

  ngOnDestroy(): void {
    this.showAddLinkSubscription.unsubscribe();
  }

  public updateQueryModel(): void {
    this.queryModel = new Query();
    this.loadFields();
  }

  private loadFields(): void {
    this.categoriesService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard.toString(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((fields: IFilterField[]) => {
        const field = fields.find(
          (item: IFilterField) =>
            item.label === this.link.type &&
            item.section === FieldSections.FILTER_TO,
        );
        this.queryModel.addRule({
          entity: field.field,
          field: field.id,
          operator: 'in',
          value: [this.link.tagId || this.link.tagName],
        });
        this.getTimelineData();
      });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onDataBound(this.queryModel, this.initialState);
  }

  public onDataBound(query: Query, params: QueryFilters): void {
    this.queryModel = query;
    this.initialState = params;
    this.getTimelineData();
  }

  private getTimelineData(): void {
    if (this.queryModel.hasRules()) {
      this.profilesService.loader$.next(true);
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.timelineData = {
            result:
              this.initialState.page > 1
                ? [...this.timelineData.result, ...response.result]
                : response.result,
            count: response.count,
          };
          this.profilesService.loader$.next(false);
        });
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      default:
        break;
    }
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordActorComponent,
          inputs: {
            actor: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownSubChannelComponent,
          inputs: {
            subChannel,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onToggleSettings(): void {
    this.linkTagsService.showLinkTag$.next(true);
  }
}
