@if (this.keycloakService.isUserInRole('saas-alerts')) {
  <div class="flex relative">
    <itq-mat-button
      class="h-[48px] flex"
      [size]="Sizes.BLOCK"
      [padding]="Sizes.SM"
      [type]="'button'"
      [materialStyle]="'basic'"
      (clickEvent)="$event.stopImmediatePropagation(); toggleNotiications()"
    >
      <div class="flex flex-col relative justify-center space-y-1">
        <i class="ph ph-bell text-3xl"></i>
        <span class="text-xs font-medium">ALERTS</span>
      </div>
    </itq-mat-button>
    @if (unreadStatus) {
      <div class="circle"></div>
    }
  </div>
}

<itq-mat-button
  class="h-[48px] flex w-full"
  [size]="Sizes.BLOCK"
  [padding]="Sizes.SM"
  [type]="'button'"
  [materialStyle]="'basic'"
  (clickEvent)="onLoadHelp()"
>
  <div class="flex flex-col relative justify-center space-y-1">
    <i class="ph ph-question text-3xl"></i>
    <span class="text-xs font-medium"> HELP </span>
  </div>
</itq-mat-button>
