import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Align, Sizes } from '../../enums/shared.enum';
import { OverlayService } from '../../services/overlay.service';
import { IOpenDirection } from './nav-button.interface';
import { SharedService } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent implements OnInit {
  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('templateBody') templateBody: TemplateRef<unknown>;
  @ViewChild('container', { static: false }) container: ElementRef;

  @Input() size = Sizes.MD;
  @Input() link = false;
  @Input() template: TemplateRef<unknown>;
  @Input() templateData: any;
  @Input() disabled = false;
  @Input() color = '';
  @Input() openDirection: IOpenDirection = {
    vertical: 'down',
    horizontal: 'left',
  };
  @Input() openPosition: 'inside' | 'outside' = 'inside';
  @Input() materialStyle = 'basic';
  @Input() align = Align.START;
  @Input() padding = Sizes['X-SM'];

  @Output() opened = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>();

  public overlayRef: OverlayRef;
  public isOpen = false;
  private subscriptions = new Subscription();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    readonly overlayService: OverlayService,
    readonly sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.sharedService.resizeDropdown$.subscribe(() => {
        if (this.isOpen) {
          this.adjustDropdownPosition();
          this.checkBoundaries();
          this.updatePosition();
        }
      }),
    );
  }

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'resize', () => {
      if (this.isOpen) {
        this.adjustDropdownPosition();
        this.checkBoundaries();
        this.updatePosition();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  adjustDropdownPosition(): void {
    if (this.overlayRef) {
      const buttonRect =
        this.dropdownButton.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.dropdownButton.nativeElement,
        'minWidth',
        `${buttonRect.width}px`,
      );
      this.overlayRef.updatePosition();
    }
  }

  onToggle(): void {
    this.isOpen = true;
    this.opened.emit();
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      return;
    }
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
    });

    this.overlayService.setOverlayRef(this.overlayRef);

    // Create a portal to attach the template
    const templatePortal = new TemplatePortal(
      this.templateBody,
      this.viewContainerRef,
    );

    // Attach the portal to the overlay
    this.overlayRef.attach(templatePortal);

    // Handle backdrop click
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
    this.cdr.detectChanges();
    this.checkBoundaries();
    this.updatePosition();
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.isOpen = false;
      this.overlayRef.dispose();
      this.close.emit();
      this.overlayRef = null;
    }
  }

  private updatePosition(): void {
    const position = this.getContainerPosition();
    this.overlayRef.updatePositionStrategy(
      this.overlay
        .position()
        .global()
        .left(`${position.left}px`)
        .top(`${position.top}px`),
    );
  }

  private checkBoundaries(): void {
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();
    if (
      dropdownBoundingRect.left + this.container?.nativeElement?.clientWidth >
      window.innerWidth
    ) {
      this.openDirection.horizontal = 'right';
      this.openPosition = 'inside';
      if (
        dropdownBoundingRect.top - this.container.nativeElement.clientWidth <
        0
      ) {
        this.openDirection.vertical = 'down';
      }
    }
    if (
      dropdownBoundingRect.top + this.container?.nativeElement?.clientHeight >
      window.innerHeight
    ) {
      this.openDirection.vertical = 'up';
    }
  }

  private getContainerPosition(): {
    left: number;
    top: number;
  } {
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();

    const margin = 5;
    if (this.openDirection.horizontal === 'right') {
      if (this.openPosition === 'outside') {
        return {
          left: dropdownBoundingRect.right + margin,
          top:
            this.openDirection.vertical === 'down'
              ? dropdownBoundingRect.top
              : dropdownBoundingRect.bottom -
                this.container.nativeElement.clientHeight,
        };
      } else {
        return {
          left:
            dropdownBoundingRect.right -
            this.container.nativeElement.clientWidth,
          top:
            this.openDirection.vertical === 'down'
              ? dropdownBoundingRect.top + dropdownBoundingRect.height + margin
              : dropdownBoundingRect.top -
                this.container.nativeElement.clientHeight,
        };
      }
    }
    if (this.openDirection.horizontal === 'left') {
      if (this.openPosition === 'outside') {
        return {
          left: dropdownBoundingRect.left,
          top:
            this.openDirection.vertical === 'up'
              ? dropdownBoundingRect.top + 90
              : dropdownBoundingRect.bottom,
        };
      } else {
        return {
          left: dropdownBoundingRect.left,
          top:
            this.openDirection.vertical === 'up'
              ? dropdownBoundingRect.top -
                this.container.nativeElement.clientHeight -
                margin
              : dropdownBoundingRect.bottom + margin,
        };
      }
    }
  }
}
