<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('actor')?.value,
        'justify-start': !form?.get('actor')?.value
      }"
    >
      ACTOR
      @if (form?.get('actor')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearActors()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-value-picker
      [queryModel]="this.queryModel"
      [dates]="this.initialState.where"
      [formControlName]="'actor'"
      [field]="actorField"
      [fields]="fields"
      (valueChange)="onDataBound()"
    ></itq-value-picker>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('actorRoles')?.value,
        'justify-start': !form?.get('actorRoles')?.value
      }"
    >
      ACTOR ROLES
      @if (form?.get('actorRoles')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearActorRoles()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-value-picker
      [fields]="fields"
      [dates]="this.initialState.where"
      [queryModel]="this.queryModel"
      [field]="this.actorRolesField"
      [formControlName]="'actorRoles'"
      (valueChange)="onDataBound()"
    ></itq-value-picker>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('customerSpecific')?.value,
        'justify-start': !form?.get('customerSpecific')?.value
      }"
    >
      CUSTOMER SPECIFIC
      @if (form?.get('customerSpecific')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCustomerSpecific()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'customerSpecific'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick customer specific...'"
      [dataSource]="customerSpecificDataSource"
      [initialState]="customerSpecificInitialState"
      (dataBound)="onGetCustomerSpecific()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('game')?.value,
        'justify-start': !form?.get('game')?.value
      }"
    >
      GAME
      @if (form?.get('game')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearGame()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'game'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick game...'"
      [dataSource]="gameDataSource"
      [initialState]="gameInitialState"
      (dataBound)="onGetGame()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
</div>
