<itq-tag-wizard-nav-item
  [errors]="!form?.get('filters')?.valid"
  [label]="'Search & filters'"
  [icon]="'ph-sliders-horizontal'"
  [active]="active === TagWizardNavItem.FILTERS"
  [completed]="completed?.includes(TagWizardNavItem.FILTERS)"
  (click)="onLoadFilters()"
></itq-tag-wizard-nav-item>
@if (this.keycloakService.isUserInRole('saas-alerts')) {
  <itq-tag-wizard-nav-item
    [errors]="form?.get('alerts')?.errors?.length > 0"
    [label]="'Alerts'"
    [icon]="'ph-bell'"
    [active]="active === TagWizardNavItem.NOTIFICATIONS"
    [completed]="completed?.includes(TagWizardNavItem.NOTIFICATIONS)"
    (click)="onLoadNotifications()"
  ></itq-tag-wizard-nav-item>
}
<itq-tag-wizard-nav-item
  [ngClass]="{
    'opacity-50': !form?.get('filters')?.valid || !form?.get('alerts')?.valid
  }"
  [label]="'Save'"
  [icon]="'ph-check-circle'"
  [active]="active === TagWizardNavItem.SAVE"
  (click)="onLoadSave()"
></itq-tag-wizard-nav-item>
