<ng-container [formGroup]="form">
  <div class="flex flex-col" [formGroupName]="'alerts'">
    @if (trendDataSource?.totalHits > 0) {
      <itq-chart
        class="pt-4 min-h-[70px] w-full"
        [data]="trendDataSource"
        [settings]="options"
        [type]="ChartType.LINE"
      ></itq-chart>
    }
    <div class="flex my-7 space-x-4">
      @if (action !== Actions.CREATE && action !== Actions.CLONE) {
        <div class="flex">
          <span class="mr-2 font-medium">Active</span>
          <mat-slide-toggle
            [color]="'primary'"
            [formControlName]="'active'"
          ></mat-slide-toggle>
        </div>
      }
      <div class="flex">
        <span class="mr-2 font-medium">Use daily average?</span>
        <mat-slide-toggle
          [color]="'primary'"
          [formControlName]="'dailyAverage'"
          (change)="onChangeDailyAverage($event)"
        ></mat-slide-toggle>
      </div>
      @if (form?.get('save.sharedTag')?.value === true) {
        <div class="flex">
          <div class="font-medium mr-2">Share to slack?</div>
          <mat-slide-toggle
            [matTooltip]="
              form?.get('save.shared')?.value === false
                ? 'Only allowed for shared tags'
                : undefined
            "
            [formControlName]="'shareSlack'"
          >
          </mat-slide-toggle>
        </div>
      }
    </div>
    <div class="flex mb-7 flex-col">
      <div class="flex flex-col">
        <span class="mb-2 font-medium required">Priority</span>
        <itq-dropdown
          class="w-[250px]"
          [clearSelection]="false"
          [formControlName]="'priority'"
          [dataSource]="priorityDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </div>
      @if (
        form?.controls['priority']?.touched &&
        form?.controls['priority']?.hasError('required')
      ) {
        <mat-error>
          Priority is <span class="font-medium">required</span>
        </mat-error>
      }
    </div>
    <div class="flex flex-col">
      <span class="mb-2 font-medium required">Match condition</span>
      <div class="flex items-center space-x-2">
        <itq-dropdown
          class="w-[150px]"
          [clearSelection]="false"
          [formControlName]="'condition'"
          [placeholder]="'Condition'"
          [dataSource]="conditionDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
        <itq-input
          [type]="'number'"
          class="w-[75px]"
          formControlName="count"
          [clearSelection]="false"
        ></itq-input>
        <span>times in the last</span>
        <itq-dropdown
          [clearSelection]="false"
          [placeholder]="'Time range'"
          [formControlName]="'period'"
          [dataSource]="periodDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </div>
    </div></div
></ng-container>
