import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { UserService } from '@portal/shared/services/user.service';
import { TaggingStatus } from 'projects/portal/src/app/tags/enums/tag.enum';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { TimelineWidgetActionsComponent } from '../timeline-widget-actions/timeline-widget-actions.component';

@Component({
  selector: 'itq-timeline-widget-header',
  templateUrl: './timeline-widget-header.component.html',
  styleUrls: ['./timeline-widget-header.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    FAwesomeModule,
    CoreModule,
    CommonModule,
    TimelineWidgetActionsComponent,
  ],
})
export class TimelineWidgetHeaderComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() count: number;
  @Input() taggingStatus: TaggingStatus;
  @Input() displayTypesTemplate: TemplateRef<unknown>;
  @Input() selectedDisplayType: IDisplayType;
  @Input() board: Board;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() delete = new EventEmitter<void>();

  readonly PillType = PillType;
  readonly TaggingStatus = TaggingStatus;
  readonly Sizes = Sizes;
  readonly ProfileDrildownScope = ProfileDrildownScope;

  constructor(
    readonly userService: UserService,
    readonly tagService: TagService,
  ) {}

  ngOnInit() {}

  public onDeleteWidget(): void {
    this.delete.emit();
  }
}
