import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import {
  convertLocalTimezonePipe,
  CoreModule,
  FAwesomeModule,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { DateQueryLabel, DateQueryType } from '../../enums/date-range.enum';
import { Sizes } from '../../enums/shared.enum';
import { IPreset } from '../../interfaces/date-range.interface';
import { IPresetQuery } from '../../interfaces/query-filters';
import { DateRangeService } from '../../services/date-range.service';
import { DateRangePresetsComponent } from './components/date-range-presets/date-range-presets.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'itq-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  standalone: true,
  imports: [
    DateRangePresetsComponent,
    CoreModule,
    CommonModule,
    FAwesomeModule,
    convertLocalTimezonePipe,
  ],
  providers: [
    DateRangeService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeComponent,
    },
  ],
})
export class DateRangeComponent implements OnInit {
  @Input() dates: IPresetQuery;
  @Input() disabled: boolean;
  @Input() class = 'w-64';
  @Input() showClear = false;
  @Input() openDirection = {
    vertical: 'down',
    horizontal: 'left',
  };
  @Input() openPosition: 'inside' | 'outside' = 'inside';

  public error: boolean;
  public _dates: IPreset;

  @Output() search = new EventEmitter<IPresetQuery>();

  @ViewChild('picker') picker: MatDateRangePicker<Date>;

  private subscriptions = new Subscription();
  private touched = false;
  public value: IPresetQuery;

  readonly Sizes = Sizes;
  readonly DateQueryType = DateQueryType;
  readonly DateQueryLabel = DateQueryLabel;

  constructor(private dateRangeService: DateRangeService) {}

  ngOnInit(): void {
    this.bindChangeDatesSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChange = (items: IPresetQuery) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: IPresetQuery): void {
    this.value = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private bindChangeDatesSubscription(): void {
    this.subscriptions.add(
      this.dateRangeService.changeDates$.subscribe(
        (response: { refresh: boolean; preset: IPresetQuery }) => {
          this.dates = response.preset;
          if (response.refresh) {
            if (this.dates.start && this.dates.end) {
              this.onChange(this.dates);
              this.search.emit({
                label: this.dates.label,
                start: this.dates.start,
                end: this.dates.end,
              });
            }
          }
        },
      ),
    );
  }
}
