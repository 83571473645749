import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRangeComponent,
  FAwesomeModule,
  Fields,
  FieldSections,
  FieldTypes,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
  UserTagCategory,
} from '@intorqa-ui/core';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';

import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { IWidgetType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { ValuePickerComponent } from '@portal/tags/components/value-picker/value-picker.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { ECharts } from 'echarts';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { DrilldownChannelComponent } from '../drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '../drilldown-context/drilldown-context.component';
import { DrilldownRepliesComponent } from '../drilldown-replies/drilldown-replies.component';
import { DrilldownSubChannelComponent } from '../drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownDiscordActorComponent } from '../drilldown-discord-actor/drilldown-discord-actor.component';

@Component({
  selector: 'itq-drilldown-actor',
  templateUrl: './drilldown-actor.component.html',
  styleUrls: ['./drilldown-actor.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,

    ValuePickerComponent,
    LabelComponent,
  ],
})
export class DrilldownActorComponent implements OnInit {
  @Input() actor: string;
  @Input() allowDrilldown = true;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;

  public form: FormGroup;
  public widgetType: IWidgetType;
  public queryModel: Query;
  public actorRolesField: IFilterField;
  public channelField: IFilterField;
  public subChannelField: IFilterField;
  private _dataSource: IData;

  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource.result = this._dataSource.result.concat(value.result);
    } else {
      this._dataSource = value;
    }
  }
  public fields: Array<IFilterField>;
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );

  public chartInstance: ECharts;
  private subscription = new Subscription();
  public count = 0;
  public showLoader = true;
  public sourcesDataSource: Array<ICustomTag>;
  public vendorsDataSource: Array<ICustomTag>;

  public vendorsInitialState: QueryFilters;
  public sourcesInitialState: QueryFilters;

  readonly PillType = PillType;

  constructor(
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoriesService: CategoryService,
    readonly profileService: ProfileService,
    readonly categoryService: CategoryService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.initializeInitialState();
    this.createForm();
    this.loadFields();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadFields(): void {
    this.categoriesService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.actorRolesField = this.getField(
          'Actor Roles',
          FieldSections.FILTER_TO,
        );
        this.channelField = this.getField('Channel', FieldSections.FILTER_TO);
        this.subChannelField = this.getField(
          'Subchannel',
          FieldSections.FILTER_TO,
        );
        this.onDataBound(this.initialState);
      });
  }

  private initializeInitialState(): void {
    this.vendorsInitialState = new QueryFilters(
      1000,
      1,
      this.initialState.where,
      undefined,
      undefined,
      undefined,
    );
    this.sourcesInitialState = new QueryFilters(
      1000,
      1,
      this.initialState.where,
      undefined,
      undefined,
      undefined,
    );
  }

  public onClearChannel(): void {
    this.form.get('channel').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearActorRoles(): void {
    this.form.get('actorRoles').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearSource(): void {
    this.form.get('source').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearSubChannel(): void {
    this.form.get('subChannel').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearVendor(): void {
    this.form.get('vendor').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onGetSources(): void {
    this.tagService
      .getFieldValues(
        this.sourcesInitialState,
        this.queryModel.convertToBackEndQuery(),
        'source',
        this.userService.userPreferences.defaultEcosystemId,
        this.sourcesInitialState.page > 1
          ? this.sourcesDataSource[this.sourcesDataSource.length - 1].name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.sourcesDataSource = response.items;
        },
      );
  }

  public onGetVendors(): void {
    this.categoryService
      .getCategoryValues(
        this.vendorsInitialState.query,
        this.vendorsInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Vendor,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.vendorsInitialState.page > 1
          ? this.vendorsDataSource[this.vendorsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.vendorsDataSource = response.items;
        },
      );
  }

  public createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(''),
      channel: new FormControl(undefined),
      actorRoles: new FormControl(undefined),
      subChannel: new FormControl(undefined),
      source: new FormControl(undefined),
      vendors: new FormControl(undefined),
    });
  }

  public onCreateTag(): void {
    const queryBuilderModel = this.queryModel.convertToQueryBuilderModel();
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: TagFactory.createTagByType(UserTagCategory.Standard.toString()),
        queryModel: queryBuilderModel,
        dates: this.initialState.where,
        action: Actions.CREATE,
      },
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownSubChannelComponent,
      inputs: {
        subChannel,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordActorComponent,
      inputs: {
        actor: extensionField,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    this.initialState = params;
    this.addQueryRules();
    if (this.queryModel.hasRules()) {
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.dataSource = response;
          this.count = this.dataSource.count;
          this.showLoader = false;
        });
    }
  }

  public onChangeSources(): void {
    this.onGetSources();
    this.onDataBound(this.initialState);
  }

  public onChangeVendor(): void {
    this.onGetVendors();
    this.onDataBound(this.initialState);
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private addQueryRules(): void {
    const actorField = this.getField('Actor', FieldSections.FILTER_TO);
    const channelField = this.getField('Channel', FieldSections.FILTER_TO);
    const sourceField = this.getField('Source', FieldSections.FILTER_TO);
    const actorRolesField = this.getField(
      'Actor Roles',
      FieldSections.FILTER_TO,
    );
    const subChannelField = this.getField(
      'Subchannel',
      FieldSections.FILTER_TO,
    );

    const contentField = this.getField('All Text', FieldSections.SEARCH_WITHIN);

    this.queryModel = new Query([
      {
        entity: actorField.field,
        field: actorField.id,
        operator: 'in',
        value: [this.actor],
      },
    ]);
    this.addRuleIfValueExists('channel', channelField);
    this.addRuleIfValueExists('source', sourceField);
    this.addRuleIfValueExists('subChannel', subChannelField);
    this.addRuleIfValueExists('actorRoles', actorRolesField);

    if (this.form?.get('query')?.value) {
      this.queryModel.addRule({
        entity: contentField.field,
        field: contentField.id,
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    }
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  private addRuleIfValueExists(
    controlName: string,
    fieldInfo: IFilterField,
  ): void {
    const controlValue = this.form.get(controlName).value;
    if (controlValue?.length > 0) {
      this.queryModel.addRule({
        entity: fieldInfo.field,
        field: fieldInfo.id,
        operator: 'in',
        value: controlValue.map((item: ICustomTag) => item.id),
      });
    }
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.sourcesInitialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }

  public onSearch(): void {
    this.onDataBound(this.initialState);
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
