<form [formGroup]="form" class="flex flex-col px-7 py-4 bg-background w-full">
  <div class="flex w-full items-center justify-between flex-col">
    <div
      class="flex bg-white rounded-md border border-border w-full p-7 flex-col lg:flex-row items-start lg:items-center"
    >
      <div class="flex items-center flex-1 space-x-4">
        <i class="ph ph-tag text-6xl"></i>

        <span class="font-medium text-28 pt-[6px]">Tags</span>
        @if (dataSource?.totalCount) {
          <itq-pill
            class="pl-4"
            [class]="
              'rounded-2xl  py-2  shadow-none font-medium bg-primary-over h-[36px]'
            "
            [type]="PillType.CUSTOM"
            >{{ dataSource.totalCount | number }}</itq-pill
          >
        }
      </div>
      <div class="flex flex-[2] xl:flex-1">
        This area contains all the tags that have been created for this
        ecosystem. <br />
        Here you can search for the tag you need, create new tags or delete tags
        that are no longer required.
      </div>
    </div>

    <div class="flex justify-between my-7 w-full items-center">
      <div class="flex items-center justify-between space-x-2">
        <itq-input
          class="xl:w-[300px]"
          [type]="'text'"
          [icon]="'search'"
          formControlName="searchTerm"
          [placeholder]="'Search tags...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
        <itq-mat-button
          [materialStyle]="
            initialState.columnFilters?.length > 0 ? 'flat' : 'stroked'
          "
          [style]="
            initialState.columnFilters?.length > 0 ? 'primary' : undefined
          "
          [type]="'button'"
          (clickEvent)="onLoadFilters()"
        >
          <div class="flex items-center space-x-2">
            <itq-label
              [size]="'text-lg'"
              [label]="'FILTERS'"
              [icon]="'ph-sliders-horizontal'"
              [bold]="true"
            ></itq-label>
            @if (initialState.columnFilters?.length > 0) {
              <span
                class="bg-white rounded-full w-[20px] h-[20px] items-center flex justify-center pt-[2px] text-sm font-medium"
              >
                {{ initialState.columnFilters?.length }}</span
              >
            }
          </div>
        </itq-mat-button>
      </div>
      <itq-mat-button
        [materialStyle]="'flat'"
        [style]="'primary'"
        [type]="'button'"
        (clickEvent)="onCreate()"
      >
        <itq-label
          [label]="'CREATE TAG'"
          [size]="'text-lg'"
          [icon]="'ph-plus'"
          [bold]="true"
        ></itq-label>
      </itq-mat-button>
    </div>
  </div>
  @if (dataSource) {
    <div class="flex flex-1 overflow-auto">
      <itq-virtual-table
        [tableColumns]="tableColumns"
        [initialState]="initialState"
        [dataSource]="dataSource?.items | formatDates"
        [trackBy]="'tagId'"
        [emptyMessage]="'No tags found!'"
        [emptyMessageDescription]="'Please update search term.'"
        (dataBound)="onDataBound()"
      ></itq-virtual-table>
    </div>
  }
</form>

<ng-template #tagTypeTemplate let-row>
  @if (row.userTagCategory) {
    <itq-pill
      [class]="' rounded-full bg-link  h-[28px]'"
      [item]="{ name: row.userTagCategory }"
      [type]="PillType.CUSTOM"
    >
      <span class="text-white">
        {{ row.userTagCategory }}
      </span>
    </itq-pill>
  }
</ng-template>

<ng-template #sharedTickTemplate let-row>
  @if (row.sharedTag) {
    <i class="ph ph-check-circle text-2xl text-primary"></i>
  }
</ng-template>
<ng-template #alertsTemplate let-row>
  @if (row.alertTypeId) {
    <i class="ph ph-bell text-2xl"></i>
  }
</ng-template>
<ng-template #systemTickTemplate let-row>
  @if (row.categoryId) {
    <i class="ph ph-check-circle text-2xl text-primary"></i>
  }
</ng-template>
<ng-template #editTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [materialStyle]="'stroked'"
      [matTooltip]="
        (row | isTagEnabled)
          ? 'Edit tag'
          : 'You don\'t have permissions to edit this tag'
      "
      [matTooltipPosition]="'above'"
      [disabled]="!(row | isTagEnabled)"
      (clickEvent)="onEdit(row)"
    >
      <i class="ph ph-pencil text-xl"></i>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #actionsButtonTemplate let-row let-index="index">
  @if (
    this.keycloakService.isUserInRole('super-admin') ||
      this.keycloakService.isUserInRole('admin') ||
      row | isTagEnabled
  ) {
    <div class="flex justify-center">
      <itq-nav-button
        [materialStyle]="'stroked'"
        [template]="actionsTemplate"
        [matTooltip]="'More actions'"
        [matTooltipPosition]="'above'"
      >
        <div class="flex justify-center w-full">
          <i class="ph ph-dots-three ph-bold"></i>
        </div>
      </itq-nav-button>
    </div>
  }

  <ng-template #actionsTemplate>
    <div class="flex flex-col w-full">
      <ng-container *ngIf="row | isTagEnabled as isTagEnabled">
        <itq-mat-button
          [size]="Sizes.LG"
          [align]="Align.START"
          [materialStyle]="'basic'"
          [matTooltip]="
            isTagEnabled
              ? 'Clone tag'
              : 'You don\'t have permissions to clone this tag'
          "
          [disabled]="!isTagEnabled"
          [matTooltipPosition]="'below'"
          (clickEvent)="onClone(row)"
        >
          <div class="flex space-x-2 items-center">
            <i class="ph ph-copy text-lg"></i>
            <span class="whitespace-nowrap font-normal pt-[4px]">Clone </span>
          </div>
        </itq-mat-button>
        <itq-mat-button
          [size]="Sizes.LG"
          [align]="Align.START"
          [materialStyle]="'basic'"
          [matTooltipPosition]="'below'"
          [disabled]="!isTagEnabled"
          [matTooltip]="
            isTagEnabled
              ? 'Delete tag'
              : 'You don\'t have permissions to delete this tag'
          "
          (clickEvent)="onDelete(row)"
        >
          <div class="flex space-x-2 items-center">
            <i class="ph ph-trash text-lg"></i>
            <span class="whitespace-nowrap font-normal pt-[4px]">Delete </span>
          </div>
        </itq-mat-button>
      </ng-container>
      @if (
        this.keycloakService.isUserInRole('super-admin') ||
        this.keycloakService.isUserInRole('admin')
      ) {
        <itq-mat-button
          [size]="Sizes.LG"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onViewHierarchy(row)"
        >
          <div class="flex space-x-2 items-center">
            <i class="ph ph-tree-view text-lg"></i>
            <span class="whitespace-nowrap font-normal pt-[4px]"
              >View dependencies</span
            >
          </div>
        </itq-mat-button>
      }
    </div>
  </ng-template>
</ng-template>
