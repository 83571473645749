import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  FAwesomeModule,
  FieldSections,
  FieldTypes,
  IPresetQuery,
  QueryFilters,
  SystemTagCategory,
  UserTagCategory,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-timeline-asset-filters.component',
  templateUrl: './timeline-asset-filters.component.html',
  styleUrls: ['./timeline-asset-filters.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    CommonModule,
  ],
})
export class TimelineAssetFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() userTagCategory: string;
  @Input() fields: Array<IFilterField>;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<Query>();

  public glitchesDataSource: Array<ICustomTag>;
  public assetsDataSource: Array<ICustomTag>;
  public utilitiesDataSource: Array<ICustomTag>;
  public cheatToolsDataSource: Array<ICustomTag>;
  private subscriptions = new Subscription();
  public assetsInitialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public glitchesInitialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public cheatToolsInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public utilitiesIntialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public queryModel = new Query([], 'and');

  constructor(
    public widgetService: WidgetService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    readonly tagService: TagService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.glitchesInitialState.where = this.initialState.where;
    this.cheatToolsInitialState.where = this.initialState.where;
    this.assetsInitialState.where = this.initialState.where;
    this.utilitiesIntialState.where = this.initialState.where;
    this.bindChangeDateSubscription();
    this.addControls();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onGetUtilities(): void {
    this.categoryService
      .getCategoryValues(
        this.utilitiesIntialState.query,
        this.utilitiesIntialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Utilities,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.utilitiesIntialState.page > 1
          ? this.utilitiesDataSource[this.utilitiesDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.utilitiesDataSource = response.items;
        },
      );
  }

  public onGetAssets(): void {
    this.categoryService
      .getCategoryValues(
        this.assetsInitialState.query,
        this.assetsInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Asset,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.assetsInitialState.page > 1
          ? this.assetsDataSource[this.assetsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.assetsDataSource = response.items;
        },
      );
  }

  public onGetGlitches(): void {
    this.categoryService
      .getCategoryValues(
        this.cheatToolsInitialState.query,
        this.cheatToolsInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Glitch,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.glitchesInitialState.page > 1
          ? this.glitchesDataSource[this.glitchesDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.glitchesDataSource = response.items;
        },
      );
  }

  public onGetCheatTools(): void {
    this.categoryService
      .getCategoryValues(
        this.cheatToolsInitialState.query,
        this.cheatToolsInitialState,
        this.queryModel.convertToBackEndQuery(),
        SystemTagCategory['Cheat Tools'],
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.SYSTEM_TAG_CATEGORY,
        this.cheatToolsInitialState.page > 1
          ? this.cheatToolsDataSource[this.cheatToolsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.cheatToolsDataSource = response.items;
        },
      );
  }

  private bindChangeDateSubscription(): void {
    this.subscriptions.add(
      this.widgetService.changeDate$.subscribe((dates: IPresetQuery) => {
        this.initialState.where = dates;
        this.glitchesInitialState.where = dates;
        this.cheatToolsInitialState.where = dates;
        this.assetsInitialState.where = dates;
        this.utilitiesIntialState.where = dates;
        this.initialState.resetPagination();
        this.glitchesInitialState.resetPagination();
        this.assetsInitialState.resetPagination();
        this.utilitiesIntialState.resetPagination();
        this.cheatToolsInitialState.resetPagination();
        this.cdr.detectChanges();
      }),
    );
  }

  private addControls(): void {
    this.form.addControl('cheatTools', new FormControl(undefined));
    this.form.addControl('glitch', new FormControl(undefined));
    this.form.addControl('assets', new FormControl(undefined));
    this.form.addControl('utilities', new FormControl(undefined));
  }

  public onDataBound(): void {
    this.buildQuery();
    this.dataBound.emit(this.queryModel);
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  private buildQuery(): void {
    this.queryModel = new Query([], 'and');
    const glitchField = this.getField('Glitch', FieldSections.FILTER_TO);
    const cheatToolsFields = this.getField(
      'Cheat Tools',
      FieldSections.FILTER_TO,
    );
    const assetsField = this.getField('Assets', FieldSections.FILTER_TO);
    if (this.form?.get('cheatTools').value) {
      this.queryModel.addRule({
        entity: cheatToolsFields.field,
        field: cheatToolsFields.label,
        operator: 'in',
        value: this.form
          .get('cheatTools')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('glitch').value) {
      this.queryModel.addRule({
        entity: glitchField.field,
        field: glitchField.label,
        operator: 'in',
        value: this.form.get('glitch').value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('assets').value) {
      this.queryModel.addRule({
        entity: assetsField.field,
        field: assetsField.label,
        operator: 'in',
        value: this.form.get('assets').value.map((item: ICustomTag) => item.id),
      });
    }
  }

  public onClearAssets(): void {
    this.form.get('assets').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearGlitch(): void {
    this.form.get('glitch').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearCheatTools(): void {
    this.form.get('cheatTools').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearUtilities(): void {
    this.form.get('utilities').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }
}
