import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SharedService } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { LibrarySidebarComponent } from '../library-sidebar/library-sidebar.component';

@Component({
  selector: 'itq-library-manager',
  templateUrl: './library-manager.component.html',
  styleUrls: ['./library-manager.component.scss'],
  standalone: true,
  imports: [RouterModule, LibrarySidebarComponent, MatSidenavModule],
})
export class LibraryManagerComponent implements OnInit {
  public subscriptions = new Subscription();
  public currentBreakpoint: string;

  @ViewChild('sidenav') sidenav: MatDrawer;

  constructor(readonly sharedService: SharedService) {}

  ngOnInit() {
    this.bindToggleSidebarSubscription();
    this.bindResizeEvent();
  }

  private bindToggleSidebarSubscription(): void {
    this.subscriptions.add(
      this.sharedService.toggleSidebar$?.subscribe(() => {
        this.sidenav?.toggle();
      }),
    );
  }

  private bindResizeEvent(): void {
    this.subscriptions.add(
      this.sharedService.currentBreakpoint$.subscribe((breakpoint) => {
        this.currentBreakpoint = breakpoint;
      }),
    );
  }
}
