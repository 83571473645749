import { Injectable } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class MomentService {
  private browserTimezone: string;
  constructor() {
    const browserLocale = navigator.language || (navigator as any).userLanguage;
    // Set the locale in Moment.js
    moment.locale(browserLocale);
    // Get the browser's local timezone
    this.browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // Method to change the locale
  setLocale(locale: string): void {
    moment.locale(locale);
  }

  // Example method to format a date
  formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  // Method to convert a Moment.js date to epoch milliseconds
  toEpochMillis(date: moment.Moment): number | undefined {
    return date.isValid() ? date.valueOf() : undefined;
  }

  toLocalTimezone(date: moment.Moment): moment.Moment {
    return date.clone().tz(this.browserTimezone);
  }

  toUtc(date: moment.Moment): moment.Moment {
    return date.clone().utc();
  }
}
