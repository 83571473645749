export enum DateQueryLabel {
  'Last15Minutes' = 'Last 15 minutes',
  'LastHour' = 'Last hour',
  'Last12Hours' = 'Last 12 hours',
  'LastDay' = 'Last 24 hours',
  'LastWeek' = 'Last week',
  'LastFortnight' = 'Last fortnight',
  'LastMonth' = 'Last month',
  'LastQuarter' = 'Last quarter',
  'LastYear' = 'Last year',
  'LastThreeYears' = 'Last three years',
  'Custom' = 'Custom',
}

export enum DateQueryType {
  Last15Minutes = 'Last15Minutes',
  'LastHour' = 'LastHour',
  'Last12Hours' = 'Last12Hours',
  'LastDay' = 'LastDay',
  'LastWeek' = 'LastWeek',
  'LastFortnight' = 'LastFortnight',
  'LastMonth' = 'LastMonth',
  'LastQuarter' = 'LastQuarter',
  'LastYear' = 'LastYear',
  'LastThreeYears' = 'LastThreeYears',
  'Custom' = 'Custom',
}
