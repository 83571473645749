import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownItem, Utils } from '@intorqa-ui/core';
import { AliasNote } from '@portal/profiles/models/alias-note';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';

@Component({
  selector: 'itq-type-alias',
  templateUrl: './type-alias.component.html',
  styleUrls: ['./type-alias.component.scss'],
})
export class TypeAliasComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() note: AliasNote;
  @Input() profile: Profile;

  public subTypesDataSource: Array<IDropdownItem> = [];
  public editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Basic formatting options
      [{ header: [1, 2, 3, 4, 5, 6] }], // Header formatting option
      [{ list: 'ordered' }, { list: 'bullet' }], // List formatting options
      ['link', 'image', 'video'], // Insert link, image, and video options
    ],
  };
  constructor(private notesService: NotesService) {}

  ngOnInit() {
    const type = this.notesService.getTypeById(this.note.typeId);
    this.subTypesDataSource = type.subTypes.map((item: string) => ({
      name: item,
      value: item,
    }));
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.note?.previousValue?.type !== changes?.note?.currentValue?.type
    ) {
      this.form.get('addNote.provider')?.setValue(undefined);
      this.subTypesDataSource = this.notesService
        .getTypeById(this.note.typeId)
        .subTypes.map((item: string) => ({ name: item, value: item }));
    }
  }

  ngOnDestroy(): void {
    const formGroup = this.form.get('addNote') as FormGroup;
    formGroup?.removeControl('provider');
    formGroup?.removeControl('value');
    formGroup?.removeControl('description');
  }

  private createForm(): void {
    const formGroup = this.form.get('addNote') as FormGroup;
    formGroup.addControl(
      'provider',
      new FormControl(this.note?.subType, [Validators.required]),
    );
    formGroup.addControl(
      'value',
      new FormControl(this.note?.rawValue, [Validators.required]),
    );
    formGroup.addControl('description', new FormControl(this.note?.textNote));
  }

  public onContentChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id,
    );
    this.note.textNote = this.form.get('addNote.description').value;
    this.note.rawTextNote = Utils.removeHtmlTags(this.note.textNote);

    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          },
        );
      }
    }
  }

  public onValueChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id,
    );
    this.note.rawValue = this.form.get('addNote.value').value;
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          },
        );
      }
    }
  }

  public onChangeSubType(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id,
    );
    this.note.subType = this.form.get('addNote.provider').value;
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          },
        );
      }
    }
  }
}
