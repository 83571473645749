import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  DateQueryType,
  DynamicPlaceholderDirective,
  QueryFilters,
} from '@intorqa-ui/core';
import { BoardSettingsComponent } from '@portal/boards/components/board-settings/board-settings.component';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { Board } from '@portal/boards/models/board';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { EChartsOption } from 'echarts';
import moment from 'moment';

@Component({
  selector: 'itq-chart-wizard',
  templateUrl: './chart-wizard.component.html',
  styleUrls: ['./chart-wizard.component.scss'],
  standalone: true,
  imports: [CoreModule],
})
export class ChartWizardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() action = Actions.CREATE;
  @Input() board: Board;
  @Input() options: EChartsOption;
  @Input() initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );

  private componentRef: ComponentRef<any>;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    readonly modalContainerService: ModalContainerService,
    private widgetService: WidgetService,
    readonly customOverlayRef: CustomOverlayRef,
  ) {}

  ngOnInit() {
    this.createComponent();
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        WidgetFactory.getWizardComponent(this.widget.type),
      );

    this.componentRef = viewContainerRef.createComponent(
      dynamicComponentFactory,
    );
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.options = this.options;
    this.componentRef.instance.action = this.action;
    this.componentRef.instance.initialState = this.initialState;
    this.componentRef.instance.prevNavigation.subscribe(() => this.onPrev());
    this.componentRef.instance.save.subscribe((widget: Widget) =>
      this.onSave(widget),
    );
  }

  public onPrev(): void {
    if (this.board) {
      this.modalContainerService.navigate$.next({
        component: BoardSettingsComponent,
        inputs: {
          board: this.board,
        },
      });
    } else {
      this.modalContainerService.navigate$.next({
        component: CreateWidgetTypeComponent,
      });
    }
  }

  private onSave(widget: Widget): void {
    this.widgetService
      .createWidget(widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({
            refresh: true,
            widget: response,
            board: this.board,
          });
        },
      );
  }
}
