<itq-nav-button
  class="w-full"
  [materialStyle]="'stroked'"
  [size]="Sizes.BLOCK"
  [openDirection]="openDirection"
  [openPosition]="openPosition"
  [template]="presetsTemplate"
>
  <div class="flex items-center justify-between w-full space-x-2">
    @if (dates) {
      @if (dates?.label !== DateQueryType.Custom) {
        <span class="font-normal">{{ DateQueryLabel[dates?.label] }}</span>
      } @else {
        @if (dates?.start && dates?.end) {
          <span class="font-normal"
            >{{ dates?.start | convertLocalTimezone: 'DD-MM-yyyy' }}
            -
            {{ dates?.end | convertLocalTimezone: 'DD-MM-yyyy' }}
          </span>
        } @else {
          <span class="font-normal text-metadata">start date - end date </span>
        }
      }
    } @else {
      <span class="font-normal text-metadata">start date - end date </span>
    }

    <i class="border-l border-border pl-2 ph ph-calendar-blank text-lg"></i>
  </div>
</itq-nav-button>
<ng-template #presetsTemplate>
  <itq-date-range-presets [dates]="dates"></itq-date-range-presets>
</ng-template>
