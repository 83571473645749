import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { ApiConfigService } from '@intorqa-ui/api';
import {
  CustomOverlayService,
  KeycloakSessionService,
  SharedService,
} from '@intorqa-ui/core';
import * as jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import posthog from 'posthog-js';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'itq-portal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public showLoader = true;
  public raisedAlertId: string;
  public researchAlertId: string;
  private subscriptions = new Subscription();

  constructor(
    public sharedService: SharedService,
    private keycloakService: KeycloakService,
    readonly customOverlayService: CustomOverlayService,
    private router: Router,
    readonly activatedRoute: ActivatedRoute,
    private keycloakSessionService: KeycloakSessionService,
    @Inject(ApiConfigService) private config,
  ) {}

  ngOnInit(): void {
    this.bindLoaderSubscription();
    this.intializePosthog();
    this.bindRoutingSubscription();
    this.keycloakSessionService.startSessionMonitoring();
  }

  private bindRoutingSubscription(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationStart ||
              event instanceof NavigationEnd,
          ),
        )
        .subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.showLoader = true;
          } else {
            this.showLoader = false;
          }
        }),
    );
  }

  private intializePosthog(): void {
    if (this.config?.posthog?.apiKey && this.config?.posthog?.isEnabled) {
      posthog.init(this.config?.posthog?.apiKey, {
        api_host: this.config?.posthog?.apiHost,
      });
    }

    this.keycloakService.loadUserProfile().then((response: KeycloakProfile) => {
      if (posthog && this.config?.posthog?.isEnabled) {
        posthog.identify(response.id);
        this.keycloakService.getToken().then((token: string) => {
          const decodedToken = jwt_decode.jwtDecode(token) as any;
          const organisation = decodedToken.organisation;
          posthog.people.set({
            email: response.email,
            organisation,
            login: new Date().toISOString(),
          });
        });
      }
    });
  }

  private bindLoaderSubscription(): void {
    this.subscriptions.add(
      this.sharedService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
