import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@intorqa-ui/core';
import { Tag } from '@portal/tags/models/tag';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';

@Pipe({
  name: 'formatDates',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  constructor(private momentService: MomentService) {}

  transform(data: Array<any>): Array<any> {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          createdDate: this.momentService
            .toLocalTimezone(moment(item.createdDate))
            .format('DD-MM-yyyy'),
          updatedDate: this.momentService
            .toLocalTimezone(moment(item.updatedDate))
            .format('DD-MM-yyyy'),
        },
      };
    });
  }
}

@Pipe({
  name: 'isTagEnabled',
  pure: false,
})
export class IsTagEnabledPipe implements PipeTransform {
  constructor(private keycloakService: KeycloakService) {}

  transform(tag: Tag): boolean {
    const isSuperAdmin = this.keycloakService.isUserInRole('super-admin');
    if (tag.categoryId && isSuperAdmin) {
      return true;
    }
    if (
      tag.username ===
        this.keycloakService.getKeycloakInstance().profile.username ||
      isSuperAdmin
    ) {
      return true;
    }
    return false;
  }
}
