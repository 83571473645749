import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  CastFormGroupPipe,
  CoreModule,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  EventBusService,
  FAwesomeModule,
  InputComponent,
  Sizes,
  SvgComponent,
  TextAreaComponent,
} from '@intorqa-ui/core';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { Board } from '@portal/boards/models/board';
import { BoardService } from '@portal/boards/services/board.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { UserService } from '@portal/shared/services/user.service';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-board-header',
  templateUrl: './board-header.component.html',
  styleUrls: ['./board-header.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    FAwesomeModule,
    MatTooltipModule,
    SvgComponent,
    FormsModule,
    TextAreaComponent,
  ],
})
export class BoardHeaderComponent implements OnInit {
  @Input() board: Board;
  @Input() form: FormGroup;

  readonly Sizes = Sizes;

  public boardsDescription: string;
  private subscriptions = new Subscription();
  public editingDescription = false;

  @ViewChildren(InputComponent) itqInputComponents: QueryList<InputComponent>;

  constructor(
    private boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    private snackBar: MatSnackBar,
    private eventBusService: EventBusService,
    readonly widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly cdr: ChangeDetectorRef,
    readonly keycloakService: KeycloakService,
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.bindLoadBoardSubscription();
    this.loadUserInfo().then(() => {
      this.registerEventBusEvents();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
    this.unRegisterEventBusUpdateBoard();
  }

  private registerEventBusEvents(): void {
    this.unRegisterEventBusEvents();
    this.registerEventBusUpdateCurrentBoard();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusUpdateCurrentBoard();
  }

  private loadUserInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.keycloakService
        .getKeycloakInstance()
        .loadUserInfo()
        .then((userInfo: any) => {
          this.userService.userInfo = userInfo;
          this.registerEventBusUpdateBoard();
        });
    });
  }

  private bindLoadBoardSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe((params) => {
        const board = this.boardService.findBoardById(params.id);
        if (board) {
          this.setBoardData(board);
        }
      }),
    );
    this.subscriptions.add(
      this.boardService.changeBoard$.subscribe((board: Board) => {
        this.setBoardData(board);
      }),
    );
  }

  private setBoardData(board: Board): void {
    this.form.get('boardHeader.name').setValue(board.name);
    this.form.get('boardHeader.description').setValue(board.description);
    this.registerEventBusEvents();
  }

  private addControls(): void {
    this.form.addControl(
      'boardHeader',
      new FormGroup({
        name: new FormControl(this.board.name),
        description: new FormControl(this.board.description),
      }),
    );
  }

  public onDeleteBoard(): void {
    this.customOverlayService.openCustom(
      {
        title: 'Delete board?',
        message: `Are you sure you want to permanently delete the <strong>${this.board?.name}</strong> board?`,
        size: '4x',
        icon: ['far', 'question-circle'],
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.boardService.deleteBoard(this.board.id).subscribe(() => {
            this.snackBar.open('Your board has been deleted!', 'Close', {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            });
          });
        }
      },
    );
  }

  public onToggleDefault(): void {
    this.board.defaultBoard = true;
    this.boardService
      .updateBoard(this.board.id, {
        default: true,
      })
      .subscribe();
  }

  public onUpdateName(): void {
    this.board.name = this.form.get('boardHeader.name').value;
    this.boardService
      .updateBoard(this.board.id, {
        name: this.board.name,
      })
      .subscribe();
  }

  public onUpdateDescription(): void {
    this.board.description = this.form.get('boardHeader.description').value;
    this.boardService
      .updateBoard(this.board.id, {
        description: this.board.description,
      })
      .subscribe();
  }

  private registerEventBusUpdateBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_BOARD}`,
      this.updateBoardCallback(),
    );
  }

  private unRegisterEventBusUpdateBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_BOARD}`,
      this.updateBoardCallback(),
    );
  }

  private registerEventBusUpdateCurrentBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateCurrentBoardCallback(),
    );
  }

  private unRegisterEventBusUpdateCurrentBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateCurrentBoardCallback(),
    );
  }

  private updateBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard };
        address: string;
        type: string;
      },
    ) => {
      if (msg.body.board.defaultBoard && this.board.id !== msg.body.board.id) {
        this.board = { ...this.board, defaultBoard: false };
      }
    };
  }

  private updateCurrentBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard };
        address: string;
        type: string;
      },
    ) => {
      const board = new Board(
        msg.body.board.id,
        msg.body.board.name,
        msg.body.board.description,
        msg.body.board.defaultBoard,
        msg.body.board.filter,
        msg.body.board.widgetIds,
        msg.body.board.updatedDate || 0,
      );
      if (this.board.id === msg.body.board.id) {
        this.board = board;
        this.form.get('boardHeader.name').setValue(board.name);
        this.form.get('boardHeader.description').setValue(board.description);
      } else {
        if (msg.body.board.defaultBoard) {
          this.board = { ...this.board, defaultBoard: false };
        }
      }
    };
  }

  public onEditDescription(): void {
    this.editingDescription = true;
    this.cdr.detectChanges();
    const descriptionInput = document.querySelector(
      'textarea',
    ) as HTMLTextAreaElement;
    descriptionInput.focus();
  }
}
