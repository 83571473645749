<div class="flex flex-col w-full">
  <div class="flex w-full">
    <itq-input
      class="pb-2 w-[250px]"
      [icon]="'search'"
      [placeholder]="'Add search term...'"
      [type]="'text'"
      [(ngModel)]="query"
      (search)="onSearch()"
      (clear)="onSearch()"
    ></itq-input>
  </div>
  <div class="flex w-full border-t border-border pt-[8px] max-h-[400px]">
    <mat-nav-list
      class="w-[275px] mr-4 overflow-auto"
      [style.padding-right]="'8px !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of linkTypesDataSource"
        [style.background-color]="
          item === selectedLinkType ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': item === selectedLinkType
          }"
        >
          <span>{{
            item === 'Channel' || item === 'Actor' ? item + 's' : item
          }}</span>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down': item !== selectedLinkType,
              'ph-caret-right ph-bold': item === selectedLinkType
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <div
      class="flex flex-col border-l w-[350px] ml-[8px] pl-[8px] border-border overflow-auto"
    >
      @if (tagsDataSource?.length > 0) {
        <itq-mat-button
          *ngFor="let item of tagsDataSource"
          [size]="Sizes.LG"
          [padding]="Sizes['X-SM']"
          [type]="'button'"
          [style]="field === item.id ? 'primary' : undefined"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onSelectionChange(item)"
        >
          <div class="flex justify-between items-center w-full">
            <div
              class="flex items-center space-x-2 overflow-hidden justify-between w-full"
            >
              <div class="flex items-center space-x-2 font-normal">
                <span
                  class="whitespace-nowrap overflow-hidden text-ellipsis"
                  [innerHTML]="item.name | highlightSearch: query"
                ></span>
                @if (item.description) {
                  <i
                    class="ph ph-info-circle"
                    [matTooltip]="item.description"
                  ></i>
                }
              </div>

              @if (field === item.id) {
                <i class="ph ph-check-circle text-lg"></i>
              }
            </div>
          </div>
        </itq-mat-button>
      } @else {
        @if (tagsDataSource) {
          <itq-panel-info
            class="p-7"
            [label]="'No tags found!'"
            [message]="'Please update your search term of choose a new field.'"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        }
      }
    </div>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
