<div class="flex flex-col w-full">
  @for (item of displayTypes; track item.id) {
    <itq-mat-button
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      (clickEvent)="onChangeDisplay(item)"
    >
      <div
        [ngClass]="{
          'fill-primary text-primary': item.id === selectedDisplayType.id,
          'items-center flex w-full justify-between space-x-7': true
        }"
      >
        <div class="flex items-center space-x-2">
          @if (item.icon) {
            <fa-icon [icon]="item.icon"></fa-icon>
          } @else if (item.phosphorIcon) {
            <i [ngClass]="['ph', 'text-lg', item.phosphorIcon]"></i>
          } @else {
            <div class="flex items-center justify-center">
              <itq-svg [icon]="item.svgIcon" class="w-5 h-5"></itq-svg>
            </div>
          }
          <span class="whitespace-nowrap pt-[4px] font-normal">{{
            item.tooltip
          }}</span>
        </div>
        @if (item.id === selectedDisplayType.id) {
          <i class="ph ph-check-circle text-lg"></i>
        }
      </div>
    </itq-mat-button>
  }
</div>
