import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  Event,
  EventBusService,
  FAwesomeModule,
  IEventData,
  OverlayService,
  QueryFilters,
  SearchFieldType,
  Sizes,
  Utils,
} from '@intorqa-ui/core';
import { EVENT_TIMELINE_CHART_TYPES } from '@portal/boards/const/event-timeline.const';
import { Board } from '@portal/boards/models/board';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { EventsTimelineComponent } from '@portal/events/components/events-timeline/events-timeline.component';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { SortDescendingPipe } from '@portal/shared/pipes/events.pipe';
import { ChartService } from '@portal/shared/services/chart.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { ChartExploreEventTimelineComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-event-timeline/chart-explore-event-timeline.component';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ECharts } from 'echarts';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ChartComponent } from '../../../../../shared/components/chart/chart.component';
import { WidgetDisplayTypesComponent } from '../../../../../widgets/components/widget-display-types/widget-display-types.component';
import { WidgetHeaderComponent } from '../../../../../widgets/components/widget-header/widget-header.component';
import { ModalContainerComponent } from '../../../modal-container/modal-container.component';
import { WidgetActionsComponent } from '../widget-actions/widget-actions.component';
import html2canvas from 'html2canvas';

@Component({
  selector: 'itq-widget-event-timeline',
  templateUrl: './widget-event-timeline.component.html',
  styleUrls: ['./widget-event-timeline.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    EventsTimelineComponent,
    WidgetHeaderComponent,
    WidgetDisplayTypesComponent,
    SortDescendingPipe,
    WidgetActionsComponent,
  ],
  providers: [EventsService],
})
export class WidgetEventTimelineComponent implements OnInit {
  @Input() segment: any;
  @Input() set widget(value: Widget) {
    this.eventTimelineWidget = value as EventTimeline;
  }
  get widget(): EventTimeline {
    return this.eventTimelineWidget;
  }
  @Input() action: Actions;
  @Input() board: Board;
  @Input() query: string;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;
  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  public dataSource: IEventData;
  public showLoader = true;
  public chartInstance: ECharts;
  public displayTypes = EVENT_TIMELINE_CHART_TYPES;
  public selectedDisplayType: IDisplayType;
  private subscriptions = new Subscription();
  public initialState: QueryFilters;
  public eventTimelineWidget: EventTimeline;
  public count: number;

  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly Align = Align;

  constructor(
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly cdr: ChangeDetectorRef,
    readonly eventBusService: EventBusService,
    readonly boardService: BoardService,
    readonly chartService: ChartService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    readonly overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.bindReloadWidgetSubscription();
    this.bindDrilldownEventSubscription();
    this.registerEventBusEvents();
    this.selectedDisplayType = this.eventTimelineWidget.getDisplayType();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindDrilldownEventSubscription(): void {
    this.subscriptions.add(
      this.eventsService.drilldown$.subscribe((event: Event) => {
        const segment = {
          name: moment(event.date).format('DD/MM/YYYY'),
          seriesIndex: 0,
        };
        this.onExplore(segment);
      }),
    );
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: EventTimeline; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.eventTimelineWidget.widgetId
          ) {
            this.initialState = params.initialState;
            this.getWidgetData();
          }
        },
      ),
    );
  }

  public onExplore(segment?: any): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartExploreEventTimelineComponent,
          inputs: {
            widget: cloneDeep(this.eventTimelineWidget),
            initialState: this.initialState.cloneDeep(),
            segment,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onEdit(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartWizardComponent,
          inputs: {
            widget: cloneDeep(this.eventTimelineWidget),
            action: Actions.EDIT,
            initialState: this.initialState.cloneDeep(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDeleteWidget(): void {
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [this.eventTimelineWidget.widgetId],
      })
      .subscribe();
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.eventTimelineWidget.options = params.options;
    this.eventTimelineWidget.chartType = params.type;
    this.widgetService.updateWidget(this.eventTimelineWidget).subscribe();
  }

  private getWidgetData(): void {
    this.showLoader = true;
    this.initialState.addQueryColumn({
      searchField: 'typeId',
      searchValues: this.widget.dataSource?.map(
        (eventTypeId: string) => eventTypeId,
      ),
      searchFieldType: SearchFieldType.ID,
    });
    if (this.initialState.query) {
      this.initialState.addQueryColumn({
        searchField: 'name',
        searchValues: [this.initialState.query],
        searchFieldType: SearchFieldType.TEXT,
      });
      this.initialState.addQueryColumn({
        searchField: 'description',
        searchValues: [this.initialState.query],
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.initialState.removeQueryColumn('name');
      this.initialState.removeQueryColumn('description');
    }

    this.eventsService
      .getEvents(
        this.initialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IEventData) => {
        this.dataSource = response;
        this.count = response.totalCount;
        this.chartService.reloadChart$.next(this.widget.widgetId);
        this.showLoader = false;
      });
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.eventTimelineWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.eventTimelineWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.widgetService.delete$.next(this.eventTimelineWidget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.eventTimelineWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.eventTimelineWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: EventTimeline;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: EventTimeline;
        address: string;
        type: string;
      },
    ) => {
      this.eventTimelineWidget = WidgetFactory.createWidget(
        msg.body,
      ) as EventTimeline;
      this.selectedDisplayType = this.eventTimelineWidget.getDisplayType();
      this.initialState.resetPagination();
      this.getWidgetData();
    };
  }

  public onExportWidget(): void {
    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    hiddenDiv.style.top = '-9999px';
    hiddenDiv.style.width =
      this.chartContainer.nativeElement.clientWidth + 'px';
    hiddenDiv.innerHTML = this.chartContainer.nativeElement.innerHTML;
    document.body.appendChild(hiddenDiv);

    html2canvas(hiddenDiv).then((canvas: any) => {
      Utils.exportImage(
        canvas.toDataURL('image/png'),
        this.eventTimelineWidget.name,
      );
      document.body.removeChild(hiddenDiv);
    });
  }
}
