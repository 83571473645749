<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'details'">
    <itq-form-row>
      <itq-form-element>
        <span class="mb-1 required">Name:</span>
        <itq-input
          [required]="true"
          [formControlName]="'name'"
          [placeholder]="'Please add your profile name...'"
        ></itq-input>
        <mat-error
          *ngIf="
            form.controls?.details['controls']?.name?.touched &&
            form.controls?.details['controls']?.name?.dirty &&
            form.controls?.details['controls']?.name?.errors?.required
          "
        >
          Vendor name is required
        </mat-error>
        <mat-error
          *ngIf="
            form.controls?.details['controls']?.name?.dirty &&
            form.controls?.details['controls']?.name?.touched &&
            form.controls?.details['controls']?.name?.errors
              ?.duplicateProfileName
          "
        >
          Vendor name already exists
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row>
      <itq-form-element>
        <span class="mb-1">Description:</span>
        <itq-input
          [formControlName]="'description'"
          [placeholder]="'Please add your profile description...'"
        ></itq-input>
      </itq-form-element>
    </itq-form-row>
  </ng-container>

  <ng-container [formGroupName]="'metadata'">
    <itq-form-row *ngIf="priority">
      <itq-form-element>
        <span class="mb-1">Priority:</span>
        <itq-dropdown
          [formControlName]="priority.id"
          [dataSource]="priorityDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row *ngIf="cheatStatus">
      <itq-form-element>
        <span class="mb-1">Cheat Status:</span>
        <itq-dropdown
          [formControlName]="cheatStatus.id"
          [dataSource]="cheatStatusDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>
