<div class="flex flex-col w-full">
  <div class="flex w-full">
    <itq-input
      class="pb-2 w-[250px]"
      [icon]="'search'"
      [placeholder]="'Add search term...'"
      [type]="'text'"
      [(ngModel)]="query"
      (search)="onSearch()"
      (clear)="onSearch()"
    ></itq-input>
  </div>
  <div class="flex w-full border-t border-border pt-[8px] max-h-[400px]">
    <mat-nav-list
      class="w-[275px] overflow-auto"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of userCategoriesDataSource"
        [style.background-color]="
          item.name === selectedUserCategory ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item.name)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': item.name === selectedUserCategory
          }"
        >
          <span>{{ item.name }}</span>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down': item.name !== selectedUserCategory,
              'ph-caret-right ph-bold': item.name === selectedUserCategory
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <div
      class="flex flex-col border-l w-[350px] ml-[8px] pl-[8px] border-border overflow-auto"
    >
      @if (tagsDataSource?.length > 0) {
        <itq-mat-button
          *ngFor="let item of tagsDataSource"
          [size]="Sizes.LG"
          [padding]="Sizes['X-SM']"
          [type]="'button'"
          [style]="field === item.id ? 'primary' : undefined"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onSelectionChange(item)"
        >
          <div class="flex justify-between items-center w-full">
            <div class="flex items-center space-x-2 justify-between w-full">
              <div
                [ngClass]="{
                  'flex items-center space-x-2 overflow-hidden': true,
                  'font-medium': field === item.id,
                  'font-normal': field !== item.id
                }"
              >
                <i class="ph ph-tag text-lg"></i>
                <span
                  class="whitespace-nowrap overflow-hidden text-ellipsis flex-1"
                  [innerHTML]="item.name | highlightSearch: query"
                ></span>
                @if (item.description) {
                  <i
                    class="ph ph-info-circle"
                    [matTooltip]="item.description"
                  ></i>
                }
              </div>

              @if (item.count) {
                <span>({{ item.count }})</span>
              }
            </div>
          </div>
        </itq-mat-button>
      } @else {
        @if (tagsDataSource) {
          <itq-panel-info
            class="p-7"
            [label]="'No values found!'"
            [message]="'Please update your search term of choose a new field.'"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        }
      }
    </div>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
