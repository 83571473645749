<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="flex w-full flex-col h-full"
>
  <div class="flex items-center p-9 border-b border-border justify-between">
    <div class="flex items-center flex-col">
      <div class="flex items-center justify-start w-full space-x-2">
        <i
          [ngClass]="{
            'ph text-3xl': true,
            'ph-pencil': event?.id,
            'ph-plus': !event?.id
          }"
        ></i>
        <h1 class="text-28 font-medium pt-[4px]">
          {{ event?.id ? 'Edit ' : 'Create ' }} Event
        </h1>
      </div>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      pbo
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
  <div class="flex flex-col p-7 bg-background justify-between flex-1">
    <div class="flex flex-col">
      <div class="flex mb-4 flex-col">
        <span class="mb-1 text-13 font-medium required">TEAM</span>
        <itq-dropdown
          formControlName="team"
          [dataSource]="teamsDataSource"
          [clearSelection]="false"
          [dataFields]="{ name: 'name', value: 'id' }"
          [placeholder]="'Team...'"
          (changeValue)="onTeamChange()"
        ></itq-dropdown>
        @if (
          form.controls['team']?.touched &&
          form.controls['team']?.hasError('required')
        ) {
          <mat-error class="text-13 pt-1">Team is required </mat-error>
        }
      </div>
      <div class="flex mb-4 flex-col">
        <span class="mb-1 text-13 font-medium required">EVENT TYPE</span>
        <itq-dropdown
          formControlName="typeId"
          [dataSource]="typeDataSource"
          [clearSelection]="false"
          [dataFields]="{ name: 'name', value: 'id' }"
          [placeholder]="'Type...'"
        ></itq-dropdown>
        @if (
          form.controls['dataSource']?.touched &&
          form.controls['dataSource']?.hasError('required')
        ) {
          <mat-error class="text-13 pt-1">Type is required </mat-error>
        }
      </div>
      <div class="flex mb-4 flex-col">
        <span class="mb-1 text-13 font-medium required">EVENT NAME</span>
        <itq-input
          class="w-full"
          [type]="'text'"
          [formControlName]="'name'"
          [placeholder]="'Name...'"
        ></itq-input>
        @if (
          form.controls['name']?.touched &&
          form.controls['name']?.hasError('required')
        ) {
          <mat-error class="text-13 pt-1">Name is required </mat-error>
        }
      </div>
      <div class="flex mb-4 flex-col">
        <span class="mb-1 text-13 font-medium required">EVENT DATE</span>
        <itq-date-picker
          [placeholder]="'Date...'"
          formControlName="date"
        ></itq-date-picker>
        @if (
          form.controls['date']?.touched &&
          form.controls['date']?.hasError('required')
        ) {
          <mat-error class="text-13 pt-1">Date is required </mat-error>
        }
      </div>
      <div class="flex flex-col">
        <span class="mb-1 text-13 font-medium">EVENT DETAIL</span>
        <itq-text-area
          class="flex h-[100px]"
          [placeholder]="'Event detail...'"
          formControlName="description"
        ></itq-text-area>
      </div>
    </div>
  </div>
  <div class="flex justify-end p-7 border-t border-border">
    <itq-mat-button
      [disabled]="!form.valid"
      [padding]="Sizes.SM"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [type]="'submit'"
    >
      @if (event?.id) {
        UPDATE
      } @else {
        SAVE
      }
    </itq-mat-button>
  </div>
</form>
