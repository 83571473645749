import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  EventBusService,
  FAwesomeModule,
  SharedService,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { BoardService } from '@portal/boards/services/board.service';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { ChartService } from '@portal/shared/services/chart.service';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { ChartComponent } from '../../../../../shared/components/chart/chart.component';
import { WidgetDisplayTypesComponent } from '../../../../../widgets/components/widget-display-types/widget-display-types.component';
import { WidgetHeaderComponent } from '../../../../../widgets/components/widget-header/widget-header.component';
import { Widget } from '@portal/boards/models/widgets/widget';
@Component({
  selector: 'itq-widget-actions',
  templateUrl: './widget-actions.component.html',
  styleUrls: ['./widget-actions.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    ChartComponent,
    WidgetHeaderComponent,
    WidgetDisplayTypesComponent,
    TableChartComponent,
    MatListModule,

    SvgComponent,
  ],
})
export class WidgetActionsComponent implements OnInit {
  @Input() displayTypes: Array<IDisplayType>;
  @Input() selectedDisplayType: IDisplayType;
  @Input() widget: Widget;

  @Output() edit = new EventEmitter<void>();
  @Output() explore = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() export = new EventEmitter<void>();
  @Output() changeDisplay = new EventEmitter<IDisplayType>();

  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly Align = Align;

  public selectedSection: string;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly eventBusService: EventBusService,
    readonly boardService: BoardService,
    readonly chartService: ChartService,
    readonly sharedService: SharedService,
  ) {}

  ngOnInit(): void {}

  public onNavChange(section: string): void {
    if (section === this.selectedSection) {
      this.selectedSection = undefined;
    } else {
      this.selectedSection = section;
    }
    this.cdr.detectChanges();
    this.sharedService.resizeDropdown$.next();
  }

  public onEdit(): void {
    this.edit.emit();
  }

  public onExplore(): void {
    this.explore.emit();
  }

  public onDeleteWidget(): void {
    this.delete.emit();
  }

  public onExportWidget(): void {
    this.export.emit();
  }

  public onChangeDisplay(displayType: IDisplayType): void {
    this.changeDisplay.emit(displayType);
  }
}
