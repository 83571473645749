<div #button class="flex flex-col w-full">
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [ngClass]="{ invalid }"
    [type]="'button'"
    [padding]="padding"
    [style]="color"
    [disabled]="disabled"
    [materialStyle]="materialStyle"
    (clickEvent)="onOpen()"
  >
    <div class="flex w-full items-center justify-between h-full">
      @if (icon) {
        <fa-icon [icon]="['far', icon | castIconName]"></fa-icon>
      }
      <div class="wrapper_text font-normal">
        @if (initialState) {
          @if (selection && selection[dataFields?.name]) {
            {{ selection[dataFields?.name] }}
          } @else {
            <span class="font-normal text-gray-400">{{ placeholder }} </span>
          }
        } @else {
          @if (dataSource | getLabel: dataFields : selection) {
            {{ dataSource | getLabel: dataFields : selection }}
          } @else {
            <span class="font-normal text-gray-400">{{ placeholder }} </span>
          }
        }
      </div>
      <div class="flex items-center h-full">
        @if (showLoader) {
          <itq-loader [type]="'controls'"></itq-loader>
        }
        @if (clearSelection && selection) {
          <itq-mat-button
            class="btn--delete"
            [materialStyle]="'basic'"
            [type]="'button'"
            (clickEvent)="onClear($event)"
          >
            <i class="ph ph-x text-xl fill-current"></i>
          </itq-mat-button>
        }
        <i
          [ngClass]="{
            'border-l border-border pl-2 ml-2 ph text-lg': true,
            'text-gray-400': !selection,
            'ph-caret-up': isOpen,
            'ph-caret-down': !isOpen
          }"
        ></i>
      </div>
    </div>
  </itq-mat-button>
</div>
<ng-template #templateBody>
  <div
    #menu
    [ngClass]="{
      'menu__container z-10 mt-2 w-[auto] rounded-md bg-white block': true,
      hidden: !isOpen
    }"
  >
    <itq-dropdown-results
      [emptyDataSourceMessage]="emptyDataSourceMessage"
      [dataSource]="dataSource"
      [selection]="selection"
      [showLoader]="showLoader"
      [dataFields]="dataFields"
      [initialState]="initialState"
      [selection]="selection"
      (dataBound)="onDataBound($event)"
      (changeValue)="onChangeValue($event)"
    ></itq-dropdown-results>
  </div>
</ng-template>
