<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'boardToolbar'">
    <div class="flex justify-between items-center flex-1 mx-7">
      <div class="flex items-center space-x-2">
        <itq-mat-button
          class="hidden md:flex"
          [materialStyle]="'stroked'"
          [type]="'button'"
          (clickEvent)="onRefresh()"
        >
          <itq-label
            [size]="'text-lg'"
            [label]="'REFRESH'"
            [icon]="'ph-arrows-clockwise'"
            [bold]="true"
          ></itq-label>
        </itq-mat-button>
        <itq-input
          class="md:w-[250px] lg:w-[300px]"
          [icon]="'search'"
          [placeholder]="'Search this dashboard...'"
          formControlName="query"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>

        <itq-date-range
          class="date_range__control"
          [dates]="this.initialState?.where"
          (search)="onChangeDate($event)"
        ></itq-date-range>
      </div>
      <div class="flex space-x-2">
        @if (
          userService.userPreferences.defaultEcosystemId | hasEventsEnabled
        ) {
          <itq-mat-button
            [materialStyle]="'flat'"
            [style]="'primary'"
            [type]="'button'"
            (clickEvent)="onRecordEvent()"
          >
            <itq-label
              [size]="'text-lg'"
              [label]="'RECORD EVENT'"
              [icon]="'ph-plus'"
              [bold]="true"
            ></itq-label>
          </itq-mat-button>
        }
        <itq-mat-button
          [materialStyle]="'flat'"
          [style]="'primary'"
          [type]="'button'"
          (clickEvent)="onAddWidget()"
        >
          <itq-label
            [size]="'text-lg'"
            [label]="'CREATE WIDGET'"
            [icon]="'ph-plus'"
            [bold]="true"
          ></itq-label>
        </itq-mat-button>
      </div>
    </div>
  </ng-container>
</ng-container>
