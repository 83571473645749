import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  CoreModule,
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  LoaderComponent,
} from '@intorqa-ui/core';
@Component({
  selector: 'itq-iubenda',
  templateUrl: './iubenda.component.html',
  styleUrls: ['./iubenda.component.scss'],
  standalone: true,
  imports: [CommonModule, CoreModule],
})
export class IubendaComponent implements OnInit {
  public url: SafeHtml;
  public showLoader = true;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.url) {
      fetch(this.config.data.url)
        .then((response) => response.json())
        .then((data) => {
          this.showLoader = false;
          this.url = this.domSanitizer.bypassSecurityTrustHtml(data.content);
        })
        .catch((error) => {
          this.showLoader = false;
          console.error('Error fetching data:', error);
        });
    }
  }
}
