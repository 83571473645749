<div class="flex justify-between w-full flex-col">
  <div class="flex">
    <div class="flex items-center flex-1">
      <itq-pill
        class="font-medium rounded-full h-[28px]"
        [ngStyle]="{
          backgroundColor: '#98b5cc'
        }"
        [item]="{ name: notification.alertTypeName }"
        [type]="PillType.CUSTOM"
      >
        <itq-label
          [label]="notification.alertTypeName"
          [icon]="'ph-bell'"
          class="text-white font-normal"
        ></itq-label>
      </itq-pill>

      <div class="flex flex-col px-4 text-xs">
        <div class="font-medium text-sm">{{ notification.headline }}</div>
        @if (notification.message) {
          <div class="flex flex-col text-metadata">
            <span>
              {{ notification.message }}
            </span>
          </div>
        }
      </div>
    </div>
    <div class="flex">
      @if (
        notification?.triggerMetadata?.action !==
          TransactionNotificationType.TAG_DELETED &&
        notification?.triggerMetadata?.action !==
          TransactionNotificationType.PROFILE_DELETED
      ) {
        <itq-mat-button
          [type]="'button'"
          [size]="Sizes.SM"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [disabled]="
            notification?.ecosystemId !==
            userService.userPreferences.defaultEcosystemId
          "
          [matTooltip]="
            notification?.ecosystemId !==
            userService.userPreferences.defaultEcosystemId
              ? 'You can\' edit this tag, because the tag belongs to a different ecosystem'
              : TransactionNotificationTypeText[
                  notification?.triggerMetadata?.action
                ]
          "
          (clickEvent)="onLoadAction()"
        >
          <i
            [ngClass]="[
              'ph',
              'text-lg',
              notification?.triggerMetadata?.action | getTransactionalAction
            ]"
          ></i>
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [size]="Sizes.SM"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
          (clickEvent)="onToggleRead()"
        >
          <fa-icon
            [icon]="['far', notification.read ? 'check' : 'eye']"
          ></fa-icon>
        </itq-mat-button>
      }
    </div>
  </div>
  @if (notification?.triggerMetadata?.addition) {
    <div
      class="text-xs flex pt-4"
      [innerHTML]="notification?.triggerMetadata?.addition"
    ></div>
  }
</div>
