<form
  class="flex flex-col h-full"
  [formGroup]="form"
  (ngSubmit)="form.valid && onSubmit()"
>
  <div class="flex flex-col flex-1">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <i class="ph ph-sliders-horizontal text-5xl"></i>
          <h1 class="text-28 font-medium">Filter events</h1>
        </div>
        <div class="text-xl justify-start w-full">
          Please add some filters to narrow your search results
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <i class="ph ph-x text-2xl"></i
      ></itq-mat-button>
    </div>
    <div class="flex flex-col p-7 bg-background flex-1 space-y-4">
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          NAME
        </div>
        <itq-input
          [icon]="'search'"
          [placeholder]="'Filter by name...'"
          formControlName="name"
          (search)="onAddFilter('name', SearchFieldType.TEXT)"
        ></itq-input>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          CREATED DATE
        </div>

        <itq-date-range
          [dates]="
            (columnFilters | getColumnFilter: 'createdDate')?.searchValues[0]
          "
          (search)="onChangeDate('createdDate', $event)"
        ></itq-date-range>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          UPDATED BY
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="updatedBy"
          [placeholder]="'Filter by updated by...'"
          [dataSource]="updatedByDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
          (changeValue)="onAddFilter('updatedBy', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          TEAM
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="team"
          [placeholder]="'Filter by team...'"
          [dataSource]="teamsDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
          (changeValue)="onAddFilter('team', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          EVENT TYPE
        </div>

        <itq-dropdown
          class="w-full"
          [clearSelection]="false"
          formControlName="type"
          [placeholder]="'Filter by event type...'"
          [dataSource]="typesDataSource"
          [dataFields]="{ name: 'name', value: 'id' }"
          (changeValue)="onAddFilter('typeId', SearchFieldType.TEXT)"
        ></itq-dropdown>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          EVENT DATE
        </div>

        <itq-date-range
          (search)="onChangeDate('date', $event)"
        ></itq-date-range>
      </div>
      <div class="flex flex-col space-y-1">
        <div
          class="text-13 font-medium w-full flex items-center justify-between"
        >
          DETAILS
        </div>
        <itq-input
          [icon]="'search'"
          [placeholder]="'Filter by details...'"
          formControlName="description"
          (search)="onAddFilter('description', SearchFieldType.TEXT)"
        ></itq-input>
      </div>
    </div>
  </div>
  <footer
    class="flex justify-between px-7 py-4 bg-white border-t border-border space-x-2"
  >
    <itq-mat-button
      [disabled]="columnFilters?.length === 0"
      [materialStyle]="'stroked'"
      [type]="'button'"
      (clickEvent)="onClearFilters()"
    >
      <itq-label
        [label]="'CLEAR FILTERS'"
        [icon]="'ph-funnel-x'"
        [bold]="true"
      ></itq-label>
    </itq-mat-button>
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [disabled]="!form.valid"
    >
      APPLY FILTERS
    </itq-mat-button>
  </footer>
</form>
