import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  DateRangeComponent,
  FAwesomeModule,
  IPresetQuery,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { TopOptions } from '@portal/boards/const/widget.const';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagPickerComponent } from '@portal/tags/components/tag-picker/tag-picker.component';
import { Tag } from '@portal/tags/models/tag';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ICustomTag } from 'projects/portal/src/app/tags/interfaces/tag.interface';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';

@Component({
  selector: 'itq-top-actors-metrics',
  templateUrl: './top-actors-metrics.component.html',
  styleUrls: ['./top-actors-metrics.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    DateRangeComponent,
    TagPickerComponent,
  ],
})
export class TopActorsMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.topActor = widget as TopActor;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public tagsDataSource: { items: Array<ICustomTag>; totalCount: number };
  public topDataSource = TopOptions;
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public topActor: TopActor;
  public selectedUserCategory: string;

  readonly Sizes = Sizes;

  constructor(
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    this.getTagById();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
  }

  public getTagById(): void {
    if (this.topActor?.dataSource?.length > 0) {
      this.tagService
        .getTagById(this.topActor.dataSource[0])
        .subscribe((tag: Tag) => {
          this.selectedUserCategory = tag.userTagCategory;
          this.form.patchValue({
            dataSource: {
              name: tag.name,
              id: tag.tagId,
            },
          });
        });
    }
  }

  public onSearch(params: QueryFilters): void {
    this.initialState.query = params.query;
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, [Validators.required]),
    );
    this.form.addControl('top', new FormControl(10, [Validators.required]));
  }

  public onChangeDataSources(tag: ICustomTag): void {
    this.topActor.dataSource = [tag.id];
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      reloadData: true,
    });
  }

  public onChangeTop(top: number): void {
    this.topActor.top = top;
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.widgetService.loader$.next(true);
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      dates: this.dates,
      reloadData: true,
    });
  }
}
