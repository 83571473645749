<div class="flex w-full items-center justify-between flex-col">
  <div
    class="flex items-center bg-white rounded-md border border-border w-full p-7"
  >
    <div class="flex items-center flex-1 space-x-4">
      <i class="ph ph-calendar-dots text-6xl"></i>
      <span class="font-medium text-28 pt-[6px]">Events</span>
      @if (dataSource?.totalCount) {
        <itq-pill
          class="pl-4 h-[36px]"
          [class]="
            'rounded-2xl px-4 py-2  shadow-none font-medium bg-primary-over'
          "
          [type]="PillType.CUSTOM"
          >{{ dataSource.totalCount | number }}</itq-pill
        >
      }
    </div>
    <div class="flex flex-1">
      This area contains all the events that have been created for this
      ecosystem.<br />
      Here you can search for the event you need, create new events or delete
      events that are no longer required.
    </div>
  </div>
</div>
<div class="flex justify-between my-7 w-full items-center">
  <itq-mat-button
    [materialStyle]="
      initialState?.columnFilters?.length > 0 ? 'flat' : 'stroked'
    "
    [style]="initialState?.columnFilters?.length > 0 ? 'primary' : undefined"
    [type]="'button'"
    (clickEvent)="onLoadFilters()"
  >
    <div class="flex items-center space-x-2">
      <itq-label
        [label]="'FILTERS'"
        [icon]="'ph-sliders-horizontal'"
        [bold]="true"
      ></itq-label>
      @if (initialState?.columnFilters?.length > 0) {
        <span
          class="bg-white rounded-full w-[20px] h-[20px] items-center flex justify-center pt-[2px] text-sm font-medium"
        >
          {{ initialState?.columnFilters?.length }}</span
        >
      }
    </div>
  </itq-mat-button>
  <itq-mat-button
    [materialStyle]="'flat'"
    [style]="'primary'"
    [type]="'button'"
    (clickEvent)="onCreateEvent()"
  >
    <itq-label
      [label]="'CREATE EVENT'"
      [icon]="'ph-plus'"
      [bold]="true"
    ></itq-label>
  </itq-mat-button>
</div>
@if (dataSource) {
  <div class="flex overflow-hidden flex-1">
    <itq-virtual-table
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items | formatEvents"
      [trackBy]="'id'"
      [emptyMessage]="'No events found!'"
      (dataBound)="onDataBound()"
    ></itq-virtual-table>
  </div>
}

<ng-template #actionsButtonTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-nav-button
      [materialStyle]="'stroked'"
      [template]="actionsTemplate"
      [matTooltip]="'More actions'"
      [matTooltipPosition]="'below'"
    >
      <div class="flex justify-center w-full">
        <i class="ph ph-dots-three ph-bold"></i>
      </div>
    </itq-nav-button>
  </div>
  <ng-template #actionsTemplate>
    <div class="flex flex-col w-full">
      <itq-mat-button
        [size]="Sizes.LG"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onEdit(row)"
      >
        <div class="flex space-x-2 items-center">
          <i class="ph ph-pencil text-lg"></i>
          <span class="whitespace-nowrap font-normal pt-[4px]">Edit </span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.LG"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onDelete(row)"
      >
        <div class="flex space-x-2 items-center">
          <i class="ph ph-trash text-lg"></i>
          <span class="whitespace-nowrap font-normal pt-[4px]">Delete </span>
        </div>
      </itq-mat-button>
    </div>
  </ng-template>
</ng-template>

<ng-template #updatedByTemplate let-row>
  <itq-avatar [tooltip]="row.createdBy">
    {{ row.updatedBy | getUsernameInitials }}
  </itq-avatar>
</ng-template>

<ng-template #teamTemplate let-row>
  @if (row.team === 'Security Team') {
    <itq-pill
      [class]="'rounded-2xl px-4 py-2 shadow-none  bg-link text-white h-[36px]'"
      [type]="PillType.CUSTOM"
      >{{ row.team }}</itq-pill
    >
  } @else {
    <itq-pill
      [class]="
        'rounded-2xl px-4 py-2 shadow-none  bg-danger text-white h-[36px]'
      "
      [type]="PillType.CUSTOM"
      >{{ row.team }}</itq-pill
    >
  }
</ng-template>
