import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  AlertTypes,
  CoreModule,
  FAwesomeModule,
  InfiniteScrollDirective,
  PriorityColor,
  QueryFilters,
} from '@intorqa-ui/core';
import {
  GroupNotification,
  Notification,
  ResearchNotification,
  TagMatchNotification,
  ThresholdNotification,
  TransactionalNotification,
} from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { NotificationsResearchComponent } from '../notifications-research/notifications-research.component';
import { NotificationsTagMatchComponent } from '../notifications-tag-match/notifications-tag-match.component';
import { NotificationsTagThresholdComponent } from '../notifications-tag-threshold/notifications-tag-threshold.component';
import { NotificationsTransactionalComponent } from '../notifications-transactional/notifications-transactional.component';
import { IDrilldown } from '@portal/drilldown/interfaces/drilldown.interface';

@Component({
  selector: 'itq-notifications-timeline',
  templateUrl: './notifications-timeline.component.html',
  styleUrls: ['./notifications-timeline.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    FAwesomeModule,
    InfiniteScrollDirective,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsTimelineComponent {
  @Input() raisedAlertId: string;
  @Input() initialState: QueryFilters;
  @Input() dataSource: { items: Array<GroupNotification>; totalCount: number };

  @Output() drilldown = new EventEmitter<IDrilldown>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  @HostBinding('style.borderRight') borderRight: string;
  @HostBinding('class') class: string;

  readonly PriorityColor = PriorityColor;

  @ViewChild('listSearch') listSearch: any;

  @ViewChildren('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainers: QueryList<ViewContainerRef>;

  constructor(
    readonly componentFactoryResolver: ComponentFactoryResolver,
    readonly notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.createComponents();
      if (this.initialState.page === 1) {
        this.listSearch.nativeElement.scrollTop = 0;
      }
    }
  }

  public onScroll(): void {
    const totalPages = Math.ceil(
      this.dataSource.totalCount / this.initialState.pageSize,
    );
    const isLastPage = this.initialState.page >= totalPages;

    if (
      this.initialState &&
      this.listSearch?.nativeElement?.scrollTop > 0 &&
      !isLastPage
    ) {
      this.initialState.page++;
      this.onDataBound(this.initialState);
    }
  }

  public createComponents(): void {
    this.dynamicComponentContainers.changes.subscribe(
      (containers: QueryList<ViewContainerRef>) => {
        // Clear all containers
        let count = 0;
        containers.toArray().forEach((container) => container.clear());

        // Create a new dynamic component for each container
        this.dataSource?.items.forEach((item) => {
          item.notifications.forEach(
            (
              notification:
                | TagMatchNotification
                | ThresholdNotification
                | TransactionalNotification
                | ResearchNotification,
            ) => {
              if (notification.alertTypeName === AlertTypes.TAG_MATCH) {
                let tagMatchComponentFactory: ComponentFactory<NotificationsTagMatchComponent>;
                tagMatchComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTagMatchComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(tagMatchComponentFactory);
                componentRef.instance.notification =
                  notification as TagMatchNotification;
                componentRef.instance.drilldown.subscribe(
                  (drilldown: IDrilldown) => {
                    this.drilldown.emit(drilldown);
                  },
                );
              } else if (notification.alertTypeName === AlertTypes.THRESHOLD) {
                let thresholdComponentFactory: ComponentFactory<NotificationsTagThresholdComponent>;
                thresholdComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTagThresholdComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(thresholdComponentFactory);

                componentRef.instance.notification =
                  notification as ThresholdNotification;
              } else if (notification.alertTypeName === AlertTypes.SYSTEM) {
                let transactionalComponentFactory: ComponentFactory<NotificationsTransactionalComponent>;
                transactionalComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTransactionalComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(transactionalComponentFactory);

                componentRef.instance.notification =
                  notification as TransactionalNotification;
              } else {
                let researchComponentFactory: ComponentFactory<NotificationsResearchComponent>;
                researchComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsResearchComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(researchComponentFactory);

                componentRef.instance.notification =
                  notification as ResearchNotification;
              }

              count += 1;
            },
          );
        });
      },
    );
  }

  public onDataBound(params: QueryFilters): void {
    this.notificationsService.getNotifications$.next(params);
  }
}
