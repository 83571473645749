<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('vendors')?.value,
        'justify-start': !form?.get('vendors')?.value
      }"
    >
      VENDORS
      @if (form?.get('vendors')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearVendor()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'vendors'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick a vendor...'"
      [dataSource]="vendorsDataSource"
      [initialState]="vendorsInitialState"
      (dataBound)="onGetVendors()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('customerSpecific')?.value,
        'justify-start': !form?.get('customerSpecific')?.value
      }"
    >
      CUSTOMER SPECIFIC
      @if (form?.get('customerSpecific')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCustomerSpecific()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'customerSpecific'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick customer specific...'"
      [dataSource]="customerSpecificDataSource"
      [initialState]="customerSpecificInitialState"
      (dataBound)="onGetCustomerSpecific()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('game')?.value,
        'justify-start': !form?.get('game')?.value
      }"
    >
      GAME
      @if (form?.get('game')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearGame()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'game'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick game...'"
      [dataSource]="gameDataSource"
      [initialState]="gameInitialState"
      (dataBound)="onGetGame()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
</div>
