import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  DateRangeComponent,
  IPresetQuery,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { TopOptions } from '@portal/boards/const/widget.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { Widget } from '@portal/boards/models/widgets/widget';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagPickerComponent } from '@portal/tags/components/tag-picker/tag-picker.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-tag-analysis-metrics',
  templateUrl: './tag-analysis-metrics.component.html',
  styleUrls: ['./tag-analysis-metrics.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    DateRangeComponent,
    CommonModule,
    TagPickerComponent,
  ],
})
export class TagAnalysisMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagAnalysisWidget = widget as TagAnalysis;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  private _tagsDataSource: Array<ICustomTag>;
  public selectedUserCategory: string;

  public get tagsDataSource(): Array<ICustomTag> {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: Array<ICustomTag>) {
    this._tagsDataSource =
      this.initialState.page > 1 ? [...this.tagsDataSource, ...value] : value;
  }

  public dataSlicerDataSource: Array<IFilterField>;

  public topDataSource = TopOptions;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public dataSlicerInitialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public tagAnalysisWidget: TagAnalysis;

  readonly Sizes = Sizes;

  constructor(
    private categoryService: CategoryService,
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    if (this.tagAnalysisWidget.widgetId) {
      this.getTagById();
      this.onGetDataSlicer();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('dataPoints');
    this.form.removeControl('top');
  }

  public getTagById(): void {
    this.tagService
      .getTagById(this.tagAnalysisWidget.dataSource[0])
      .subscribe((tag: Tag) => {
        this.selectedUserCategory = tag.userTagCategory;
        this.form.patchValue({
          dataSource: {
            name: tag.name,
            id: tag.tagId,
          },
        });
      });
  }

  public onGetDataSlicer(): void {
    this.categoryService
      .getSlicerFields(
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.dataSlicerDataSource = response
          ?.map((field: IFilterField) => ({
            ...field,
            ...{ disabled: !field.hasAnyMatches },
          }))
          .sort((a, b) => {
            if (a.hasAnyMatches === b.hasAnyMatches) {
              return a.label.localeCompare(b.label);
            }
            return a.hasAnyMatches === false ? 1 : -1;
          });
        if (this.tagAnalysisWidget.dataSlicer) {
          this.form.patchValue({
            dataPoints: this.tagAnalysisWidget.dataSlicer,
          });
          this.form.get('dataPoints').enable();
        }
      });
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, [Validators.required]),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl(
        {
          value: [],
          disabled: !this.tagAnalysisWidget?.dataSlicer,
        },
        [Validators.required],
      ),
    );
    this.form.addControl(
      'top',
      new FormControl(this.tagAnalysisWidget.top, [Validators.required]),
    );
  }

  public onChangeTop(): void {
    this.tagAnalysisWidget.top = this.form.controls.top.value;
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  public onChangeDataSources(tag: ICustomTag): void {
    this.selectedUserCategory = tag.userTagCategory;
    this.form.get('dataPoints').enable();
    this.form.get('dataPoints').setValue(undefined);
    this.tagAnalysisWidget.dataSlicer = undefined;
    this.tagAnalysisWidget.dataSource = [
      this.form.controls.dataSource.value?.id,
    ];
    this.updateFormStatus();
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  private updateFormStatus(): void {
    if (
      this.form.get('dataPoints').value &&
      this.form.get('dataSource').value
    ) {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
  }

  public onChangeDataSlicer(): void {
    const dataSlicer = this.dataSlicerDataSource.find(
      (field: IFilterField) =>
        field.label === this.form.controls.dataPoints.value?.label,
    );
    this.tagAnalysisWidget.dataSlicer = {
      label: dataSlicer.label,
      type: dataSlicer.type,
    };
    this.updateFormStatus();
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}
