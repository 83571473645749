<div class="flex flex-col" [formGroup]="form">
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Data source</h2>
  <ng-container [formGroupName]="'eventType'">
    <div class="flex mb-4 mt-4 flex-col flex-1">
      <div
        [ngClass]="{
          hidden:
            !securityTeamDataSource || securityTeamDataSource?.length === 0,
          'flex flex-col flex-1': true
        }"
      >
        <div class="mb-2 text-13 font-medium justify-between items-center flex">
          SECURITY TEAM
          <mat-slide-toggle
            [formControlName]="'selectAllSecurity'"
            (change)="onToggleSecurity($event)"
          >
            <span class="font-medium">SelectAll</span>
          </mat-slide-toggle>
        </div>
        <mat-selection-list
          formControlName="securityTeam"
          class="max-h-[336px] overflow-auto border border-border rounded-md security__team"
          (selectionChange)="onChangeSecurityEvents()"
        >
          <mat-list-option
            class="border-border border-t first:border-t-0"
            *ngFor="let item of securityTeamDataSource"
            [value]="item"
          >
            <div class="flex justify-between w-full items-center space-x-2">
              <span class="flex-1 text-ellipsis overflow-hidden">
                {{ item.name }}
              </span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div
      [ngClass]="{
        hidden: !cheaterTeamDataSource || cheaterTeamDataSource?.length === 0,
        'flex flex-col flex-1': true
      }"
    >
      <div class="mb-2 text-13 font-medium justify-between items-center flex">
        CHEATER TEAM
        <mat-slide-toggle
          [formControlName]="'selectAllCheater'"
          (change)="onToggleCheater($event)"
        >
          <span class="font-medium">SelectAll</span>
        </mat-slide-toggle>
      </div>
      <mat-selection-list
        formControlName="cheaterTeam"
        class="max-h-[336px] overflow-auto border border-border rounded-md cheater__team"
        (selectionChange)="onChangeCheaterEvents()"
      >
        <mat-list-option
          class="border-border border-t first:border-t-0"
          *ngFor="let item of cheaterTeamDataSource"
          [value]="item"
        >
          <div class="flex justify-between w-full items-center space-x-2">
            <span class="flex-1 text-ellipsis overflow-hidden">
              {{ item.name }}
            </span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </ng-container>
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex mb-4 flex-col flex-1">
    <span class="mb-1 text-13 font-medium">
      <div
        class="mb-1 text-13 font-medium justify-between w-full flex items-center"
      >
        CORRELATE TAG
      </div></span
    >
    <itq-nav-button
      #dataSourceButton
      class="w-full"
      [size]="Sizes.BLOCK"
      [materialStyle]="'stroked'"
      [template]="dataSourceTemplate"
    >
      <span class="font-normal flex items-center w-full justify-between">
        @if (form?.get('dataCorrelation')?.value) {
          <span>{{ form?.get('dataCorrelation')?.value?.name }}</span>
        } @else {
          <span class="text-gray-400">Pick a tag...</span>
        }
        <i
          [ngClass]="{
            'border-l border-border pl-1 ml-2 ph text-lg': true,
            'ph-caret-down': !dataSourceButton.isOpen,
            'ph-caret-right': dataSourceButton.isOpen
          }"
        ></i>
      </span>
    </itq-nav-button>
    <ng-template #dataSourceTemplate>
      <itq-tag-picker
        class="flex"
        formControlName="dataCorrelation"
        [selectedUserCategory]="selectedUserCategory"
        [dates]="dates"
        [field]="
          this.eventTimelineWidget?.dataCorrelation &&
          this.eventTimelineWidget?.dataCorrelation[0]
        "
        (selectionChange)="onChangeDataCorrelation()"
      ></itq-tag-picker>
    </ng-template>
  </div>
  <div class="flex mb-4 flex-col flex-1">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [dates]="this.dates"
      (search)="onChangeDate($event)"
    ></itq-date-range>

    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data points is required </mat-error>
    }
  </div>
</div>
