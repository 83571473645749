<form [formGroup]="form" class="flex overflow-hidden w-full h-[100%]">
  @if (!raisedAlertId && !researchAlertId) {
    <div class="flex w-full">
      <div
        class="flex justify-between flex-col border-r border-border bg-white"
      >
        <div class="flex flex-col h-full" [ngStyle]="{ width: '300px' }">
          <div
            class="flex justify-between px-7 py-4 border-border border-b items-center h-[69px] bg-white"
          >
            <span class="font-medium">Matching content</span>
            <itq-pill
              [type]="PillType.CUSTOM"
              [class]="'secondary rounded-md h-[36px]'"
              >{{ notificationsCount || 0 | number }}</itq-pill
            >
          </div>
          <div class="flex flex-col py-4 px-7">
            <h2 class="flex font-medium pb-2 text-xl">Search</h2>
            <p class="pb-3">
              Enter phrase or keywords to narrow your result set
            </p>
            <itq-input
              [formControlName]="'query'"
              [icon]="'search'"
              [placeholder]="'Search alerts...'"
              (search)="onGetNotifications()"
              (clear)="onGetNotifications()"
            ></itq-input>
            <h2 class="flex font-medium pb-2 text-xl pt-7">Filters</h2>
            <div class="flex mb-4 flex-col">
              <span class="mb-1 text-13 font-medium">TYPE</span>
              <itq-dropdown
                formControlName="type"
                [clearSelection]="true"
                [dataSource]="typesDataSource"
                [clearSelection]="true"
                [dataFields]="{ name: 'label', value: 'id' }"
                [placeholder]="'Type...'"
                (changeValue)="onGetNotifications()"
                (clear)="onGetNotifications()"
              ></itq-dropdown>
            </div>
            <div class="flex mb-4 flex-col">
              <span class="mb-1 text-13 font-medium">PRIORITY</span>
              <itq-dropdown
                formControlName="priority"
                [clearSelection]="true"
                [dataSource]="priorityDataSource"
                [dataFields]="{ name: 'name', value: 'value' }"
                [placeholder]="'Priority...'"
                (changeValue)="onGetNotifications()"
                (clear)="onGetNotifications()"
              ></itq-dropdown>
            </div>
            <div class="flex mb-4 flex-col">
              <span class="mb-1 text-13 font-medium">DATE</span>

              <itq-date-range
                class="w-full"
                [dates]="this.initialState?.where"
                (search)="onChangeDates($event)"
              ></itq-date-range>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-1 flex-col bg-background">
        <div class="py-4 px-7 bg-white border-b border-border">
          <div class="flex space-x-7 justify-between">
            <itq-button-thumbnail
              [formControlName]="'showAll'"
              [dataSource]="buttonDataSource"
              (change)="onGetNotifications()"
            ></itq-button-thumbnail>
            @if (unreadCount() > 0) {
              <itq-mat-button
                [type]="'button'"
                [materialStyle]="'stroked'"
                [padding]="Sizes.SM"
                (clickEvent)="onMarkAllRead()"
              >
                <div class="flex items-center">
                  <fa-icon [icon]="['far', 'check']"></fa-icon>MARK ALL AS READ
                </div>
              </itq-mat-button>
            }
          </div>
        </div>
        <itq-notifications-hub
          [form]="form"
          [initialState]="initialState"
          [query]="form?.get('query')?.value"
        ></itq-notifications-hub>
      </div>
    </div>
  } @else {
    @if (researchAlertId) {
      <itq-notification-research-detail
        [form]="form"
        [researchAlertId]="researchAlertId"
        (showNotifications)="onShowNotifications()"
      ></itq-notification-research-detail>
    } @else {
      <itq-raised-other-matches
        [raisedAlertId]="raisedAlertId"
        (showNotifications)="onShowNotifications()"
      ></itq-raised-other-matches>
    }
  }
</form>
<ng-template #unreadTemplate> UNREAD ({{ unreadCount() || 0 }}) </ng-template>
@if (showLoader) {
  <itq-loader></itq-loader>
}
