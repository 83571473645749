<div class="flex w-full">
  <div class="flex flex-col min-w-[200px] max-h-[440px] overflow-auto">
    @for (item of customPresets; track item.label) {
      @if (item?.label !== DateQueryType.Custom) {
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [size]="Sizes.LG"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="selectRange(item)"
        >
          <div
            [ngClass]="{
              'flex w-full justify-between': true,
              'text-primary':
                (item.label | getSelected: dates) &&
                item?.label !== DateQueryType.Custom,
              'font-medium':
                (item.label | getSelected: dates) &&
                item?.label === DateQueryType.Custom
            }"
          >
            <span class="whitespace-nowrap font-normal pt-[4px]">
              {{ DateQueryLabel[item.label] }}
            </span>
            @if (item.label | getSelected: dates) {
              <i class="ph ph-check-circle text-lg"></i>
            }
          </div>
        </itq-mat-button>
      } @else {
        <div
          [ngClass]="{
            ' w-full justify-between min-h-[48px] flex items-center px-4 hover:bg-background cursor-pointer': true,
            'text-primary':
              (DateQueryType.Custom | getSelected: dates) &&
              DateQueryType.Custom !== DateQueryType.Custom,
            'font-medium':
              (DateQueryType.Custom | getSelected: dates) &&
              DateQueryType.Custom === DateQueryType.Custom
          }"
          (click)="onSelectCustomRange(item)"
        >
          <span class="whitespace-nowrap"> Custom </span>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down':
                (DateQueryType.Custom | getSelected: dates) ? true : false,
              'ph-caret-right ph-bold': !(
                DateQueryType.Custom | getSelected: dates
              )
                ? true
                : false
            }"
          ></i>
        </div>
      }
    }
  </div>
  @if (dates?.label === DateQueryType.Custom) {
    <div
      class="flex flex-col flex-grow w-[420px] border-l border-border ml-[8px]"
      (click)="$event.stopPropagation()"
    >
      <mat-calendar
        class="flex-1"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [selected]="dateRange"
        (selectedChange)="rangeChanged($event)"
      ></mat-calendar>
      <div class="flex justify-end space-x-2 submit__container">
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [align]="Align.START"
          (clickEvent)="onCancel()"
          ><span class="font-medium">CANCEL</span>
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [disabled]="!(dateRange.start?.isValid() && dateRange.end?.isValid())"
          [materialStyle]="'flat'"
          [align]="Align.START"
          [style]="'primary'"
          (clickEvent)="onApply()"
          ><span class="font-medium">APPLY</span>
        </itq-mat-button>
      </div>
    </div>
  }
</div>
