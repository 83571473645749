import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  DateQueryType,
  DateRangeHelper,
  FAwesomeModule,
  Sizes,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { UserService } from '@portal/shared/services/user.service';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ModalContainerService } from '../modal-container/modal-container.service';
import { WidgetTypeThumbnailListComponent } from '../widget-type-thumbnail-list/widget-type-thumbnail-list.component';
import { SvgComponent } from '../../../../../../core/src/lib/components/svg/svg.component';

@Component({
  selector: 'itq-board-settings',
  templateUrl: './board-settings.component.html',
  styleUrls: ['./board-settings.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    FAwesomeModule,
    MatTooltipModule,
    WidgetTypeThumbnailListComponent,
    SvgComponent,
  ],
})
export class BoardSettingsComponent implements OnInit {
  @Input() board = new Board(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly Sizes = Sizes;

  public form: FormGroup;

  constructor(
    readonly boardService: BoardService,
    readonly customOverlayService: CustomOverlayService,
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly customOverlayRef: CustomOverlayRef,
    private router: Router,
    private modalContainerService: ModalContainerService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = new FormGroup({
      name: new FormControl(this.board.name, [Validators.required]),
      description: new FormControl(this.board.description),
    });
  }

  public onSubmit(): void {
    this.createBoard();
  }

  private createBoard(widget?: Widget): void {
    let preset = DateRangeHelper.findPresetByLabel(DateQueryType.LastMonth);
    this.boardService
      .createBoard(
        {
          name: this.form.get('name').value,
          description: this.form.get('description').value,
          default: this.board.defaultBoard,
          filter: {
            date: {
              label: DateQueryType.LastMonth,
              start: preset?.start.valueOf(),
              end: preset?.end.valueOf(),
            },
          },
          widgetIds: widget?.widgetId ? [widget.widgetId] : [],
        },
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe();
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDeleteBoard(): void {
    this.router.navigateByUrl(`/boards/${this.board.id}`);
  }

  public onAdd(type: AnalysisTypes): void {
    this.modalContainerService.navigate$.next({
      component: ChartWizardComponent,
      inputs: {
        board: new Board(
          undefined,
          this.form.get('name').value,
          this.form.get('description').value,
          this.board.defaultBoard,
          undefined,
          undefined,
          undefined,
        ),
        widget: WidgetFactory.createWidgetByType(
          type,
          this.userService.userPreferences.defaultEcosystemId,
        ),
        action: Actions.CREATE,
      },
    });
  }
}
