@if (item.isFilterable) {
  <div class="search__container">
    @if (item.searchComponent === ComponentType.MULTIPLE_DROPDOWN) {
      <itq-multiple-dropdown
        [dataSource]="item.dataSource"
        [(ngModel)]="item.searchValue"
        [dataFields]="{ name: 'name', value: 'value' }"
        (changeValue)="onMultipleDropdownChange(item)"
        (dataBound)="onDataBound(item)"
      ></itq-multiple-dropdown>
    }
    @if (item.searchComponent === ComponentType.INPUT) {
      <itq-input
        [type]="item.searchComponentType || 'text'"
        [(ngModel)]="item.searchValue"
        [placeholder]="'Search...'"
        (search)="onApplySearch(item)"
        (clear)="onClearSearch(item)"
      ></itq-input>
    }
    @if (item.searchComponent === ComponentType.DROPDOWN) {
      <itq-dropdown
        [(ngModel)]="item.searchValue"
        [dataSource]="item.dataSource"
        [dataFields]="{ name: 'name', value: 'value' }"
        (dataBound)="onDataBound(item)"
        (changeValue)="onChangeValue(item, $event)"
        (clear)="onChangeValue(item, undefined)"
      ></itq-dropdown>
    }
    @if (item.searchComponent === ComponentType.DATE) {
      <div class="date__picker_container">
        <itq-date-picker
          [(ngModel)]="item.searchValue"
          (changeValue)="onChangeDate(item)"
        ></itq-date-picker>
      </div>
    }
  </div>
}
