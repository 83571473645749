<div class="field__container">
  <div class="flex items-center">
    <h1>Screenshot</h1>
    <itq-mat-button
      [materialStyle]="'basic'"
      [matTooltip]="'View full screen'"
      [padding]="Sizes.SM"
      [type]="'button'"
      (clickEvent)="onExpand()"
    >
      <i class="ph ph-arrows-out text-lg"></i>
    </itq-mat-button>
  </div>
  <div [innerHTML]="note.value"></div>
</div>
<div class="field__container" *ngIf="note.textNote">
  <h1>Text notes</h1>
  <div [innerHTML]="note.textNote | urlify"></div>
</div>
