@if (document.emitURL) {
  <div class="flex flex-col">
    <div class="flex mb-4 flex-col">
      <span class="mb-1 text-13 font-medium">LINK</span>
      <a
        class="text-link text-xs break-all visited:text-link hover:text-link hover:underline font-medium"
        rel="noreferrer"
        [attr.href]="document.emitURL"
        target="_blank"
        [innerHTML]="document.emitURL"
      ></a>
    </div>
  </div>
}
@if (document?.emitExtensionFields?.length > 0) {
  <itq-document-extension-fields
    class="space-y-4"
    [query]="query"
    [drilldownSegment]="drilldownSegment"
    [document]="document"
    (drilldown)="onDrilldownDiscord($event)"
  >
  </itq-document-extension-fields>
}
@if (contentLinks?.length > 0) {
  <div class="flex my-4 flex-col">
    <div class="mb-1 text-13 font-medium">
      <fa-icon
        [matTooltip]="
          'Links that have been automatically extracted from the text of this post'
        "
        [matTooltipPosition]="'above'"
        [icon]="['far', 'info-circle']"
      ></fa-icon>
      EXTRACTED LINKS
    </div>
    <ul>
      <li *ngFor="let link of contentLinks">
        <a
          class="text-link text-xs"
          [href]="link.href"
          target="_blank"
          [innerHTML]="link.href | highlightSearch: query"
        ></a>
      </li>
    </ul>
  </div>
}
