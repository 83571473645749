<div class="flex w-full max-h-[350px] min-h-[200px]">
  <mat-nav-list
    class="w-[275px] mr-4"
    [style.padding-right]="'8px !important'"
    [style.padding-bottom]="'0 !important'"
    [style.padding-top]="'0 !important'"
  >
    @for (item of sectionsDataSource; track item) {
      <mat-list-item
        [style.background-color]="
          item === selectedSection ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': item === selectedSection
          }"
        >
          <span>{{ item }}</span>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down ph-bold': item === selectedSection ? true : false,
              'ph-caret-right ': !(item === selectedSection) ? true : false
            }"
          ></i>
        </div>
      </mat-list-item>
    }
  </mat-nav-list>
  <div
    class="flex flex-col border-l w-[275px] ml-[8px] pl-[8px] border-border overflow-auto"
  >
    <itq-mat-button
      *ngFor="let item of fieldsDataSource"
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [style]="field === item.id ? 'primary' : undefined"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onSelectionChange(item)"
    >
      <div class="flex justify-between items-center w-full font-normal">
        <div
          [ngClass]="{
            'opacity-40': item.hasAnyMatches === false ? true : false,
            'flex items-center space-x-2': true
          }"
        >
          <span>{{ item.label }}</span>
          @if (item.description) {
            <i class="ph ph-info text-xl" [matTooltip]="item.description"></i>
          }
        </div>
        @if (field === item.id) {
          <i class="ph ph-check-circle text-lg"></i>
        }
      </div>
    </itq-mat-button>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
