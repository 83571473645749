<itq-nav-button
  [template]="userTemplate"
  [materialStyle]="'basic'"
  [matTooltip]="'User settings'"
  [matTooltipPosition]="'below'"
>
  <div class="flex justify-center items-center">
    <i class="ph ph-user-circle text-white text-2xl"></i>
  </div>
</itq-nav-button>
<ng-template #userTemplate>
  <ul class="user-menu-content flex flex-col rounded-md text-right">
    <li class="user bg-background py-2 rounded-md" *ngIf="userProfile">
      <span class="name block"
        >{{ userProfile.firstName }} {{ userProfile.lastName }}</span
      >
      <span class="email block">{{ userProfile.email }}</span>
      <span class="type label-secondary"
        >{{ role
        }}<span class="type-super-admin" *ngIf="isSuperAdmin">
          (Intorqa Admin)</span
        ></span
      >
    </li>
    <li class="border-t border-border w-full">
      <button type="button" class="btn btn-link" (click)="accountManagement()">
        My account
      </button>
    </li>

    <li class="border-t border-border w-full">
      <a
        class="uppercase h-[36px] hover:text-primary flex justify-end items-center"
        target="_blank"
        href="https://www.iubenda.com/privacy-policy/93453309"
        >Privacy Policy</a
      >
    </li>

    <li>
      <a
        class="uppercase h-[36px] hover:text-primary flex justify-end items-center"
        target="_blank"
        href="https://www.iubenda.com/privacy-policy/93453309/cookie-policy"
        >Cookie Policy</a
      >
    </li>

    <li>
      <a
        class="uppercase h-[36px] hover:text-primary flex justify-end items-center"
        target="_blank"
        href="https://www.iubenda.com/terms-and-conditions/93453309"
      >
        Terms and Conditions</a
      >
    </li>
    <li class="border-t border-border w-full">
      <button class="btn btn-link" type="button" (click)="logOut()">
        Sign out
      </button>
    </li>
  </ul>
</ng-template>
