import { Pipe, PipeTransform } from '@angular/core';
import { IQueryColumn } from '@intorqa-ui/core';

@Pipe({
  name: 'getColumnFilter',
  standalone: true,
})
export class GetColumnFilterPipe implements PipeTransform {
  transform(data: Array<IQueryColumn>, columnName: string): IQueryColumn {
    return data.find((column) => column.searchField === columnName);
  }
}
