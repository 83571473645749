<ng-container *ngIf="!showLoader">
  <div
    class="flex items-center p-7 border-b border-border justify-between bg-white"
  >
    <div class="flex items-center flex-col">
      <div class="flex items-center justify-start w-full space-x-2">
        <i class="ph ph-database text-3xl"></i>
        <h1 class="text-28 font-medium pt-[5px]">Setup endpoint</h1>
      </div>
      <div class="text-xl justify-start w-full">
        Connect our data to your systems for additional insight
      </div>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>

  <div class="flex flex-1 flex-col bg-background p-7">
    @if (apiKeyPrefix && !apiKey) {
      <div class="flex items-center space-x-2 mb-7">
        <span class="font-medium">API KEY PREFIX:</span>
        <itq-pill
          [class]="
            'rounded-2xl px-4 py-2 shadow-none font-medium bg-primary-over uppercase h-[36px]'
          "
          [type]="PillType.CUSTOM"
          >{{ apiKeyPrefix }}</itq-pill
        >
      </div>
    }
    @if (apiKey) {
      <section
        class="flex flex-col border border-border p-4 bg-white rounded-md mb-8"
      >
        <h2 class="flex font-medium pb-7 text-xl">Account Credentials</h2>

        <div class="flex flex-col">
          <div class="mb-4 flex items-center space-x-2">
            <span class="font-medium">API KEY: </span><span>{{ apiKey }}</span>
            <itq-mat-button
              [type]="'button'"
              [materialStyle]="'stroked'"
              [padding]="Sizes.NONE"
              [size]="Sizes['X-SM']"
              [cdkCopyToClipboard]="apiKey"
              (click)="$event.stopImmediatePropagation()"
              (mousedown)="copy('key')"
            >
              <i class="ph ph-copy text-lg"></i>
            </itq-mat-button>
          </div>
          <div class="mb-4 flex items-center space-x-2">
            <span class="font-medium">API PREFIX: </span
            ><span>{{ apiKeyPrefix }}</span>
          </div>
        </div>
        <div
          class="justify-start items-center space-x-2 flex bg-warning rounded-full py-2 px-4"
        >
          <i class="ph ph-warning text-lg"></i>
          <span
            >Please save this key securely as it cannot be recovered by
            Intorqa</span
          >
        </div>
      </section>
    }
    <div
      class="flex flex-col pt-4 border border-border p-4 bg-white rounded-md"
    >
      <h2 class="flex font-medium pb-7 text-xl">API Settings</h2>
      <div class="flex mb-4 flex-col">
        <div class="flex items-center space-x-2">
          <itq-mat-button
            [type]="'button'"
            [materialStyle]="'stroked'"
            [padding]="Sizes.NONE"
            [size]="Sizes['X-SM']"
            [matTooltip]="'Copy url'"
            [cdkCopyToClipboard]="apiKey"
            (click)="$event.stopImmediatePropagation()"
            [cdkCopyToClipboard]="
              externalApiBase +
              '/key/' +
              (this.apiKey ? this.apiKey : '%yourKey%')
            "
            (clickEvent)="copy('url')"
          >
            <i class="ph ph-copy text-lg"></i>
          </itq-mat-button>
          <span class="font-medium">BASE URL:</span>
        </div>
        <span [innerHTML]="baseUrl"></span>
      </div>
      <div class="flex mb-4">
        <span class="font-medium mr-2">HTTP METHOD: </span> GET
      </div>
      <div class="flex mb-4 flex-col">
        <div class="flex items-center space-x-2">
          <itq-mat-button
            [type]="'button'"
            [matTooltip]="'Copy as curl'"
            [padding]="Sizes.NONE"
            [size]="Sizes['X-SM']"
            [materialStyle]="'stroked'"
            (click)="$event.stopImmediatePropagation()"
            [cdkCopyToClipboard]="curlCommand"
            (click)="copy('curl')"
          >
            <i class="ph ph-copy text-lg"></i> </itq-mat-button
          ><span class="font-medium">CURL COMMAND:</span>
        </div>
        <span [innerHTML]="curlCommand"></span>
      </div>
      <p class="flex mb-2"><strong>QUERY STRING PARAMS: </strong></p>
      <p><span class="font-medium">page:</span> number | default = 1</p>
      <p>
        <span class="font-medium">pageSize:</span>: number | min = 1 | max = 100
        | default = 100
      </p>
      <p>
        <span class="font-medium">fromEpochSeconds:</span>: date | default = 0
        (01-01-1970)
      </p>
      <p>
        <span class="font-medium">toEpochSeconds:</span>: date | default = (now)
      </p>
    </div>
  </div>
  <footer class="flex px-7 py-4 bg-white border-t border-border w-full">
    @if (!apiKey && apiKeyPrefix) {
      <div class="flex justify-between w-full">
        <div
          class="justify-start items-center space-x-2 flex bg-warning rounded-full py-2 px-4"
        >
          <i class="ph ph-warning text-lg"></i>
          <span>Creating a new one will delete the old one</span>
        </div>
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'flat'"
          [size]="Sizes.BLOCK"
          [style]="'primary'"
          (clickEvent)="onResetKey()"
        >
          RESET KEY
        </itq-mat-button>
      </div>
    } @else {
      <div class="flex justify-end w-full">
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'flat'"
          [size]="Sizes.BLOCK"
          [style]="'primary'"
          (clickEvent)="onGenerateKey()"
        >
          GENERATE KEY
        </itq-mat-button>
      </div>
    }
  </footer>
</ng-container>

@if (showLoader) {
  <itq-loader></itq-loader>
}
