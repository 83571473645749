<div class="flex m-4">
  <itq-nav-button
    class="flex-1"
    [openDirection]="{ vertical: 'down', horizontal: 'right' }"
    [openPosition]="'outside'"
    [materialStyle]="'flat'"
    [color]="'primary'"
    [template]="addTemplate"
  >
    <itq-label [label]="'NEW'" [icon]="'ph-plus'" [bold]="true"></itq-label>
  </itq-nav-button>
</div>
<h1 class="font-medium ml-4 text-17">BOARDS</h1>
<itq-input
  class="p-4"
  [(ngModel)]="query"
  [placeholder]="'Search boards...'"
  [icon]="'search'"
></itq-input>
<ejs-treeview
  class="overflow-auto board__navigation_container"
  #treeviewObj
  id="treeelement"
  [fields]="dataSource | adaptTreeDataSource: query"
  [allowDragAndDrop]="query || query === ''"
  (created)="onCreate()"
  (nodeEdited)="onEditedNode($event)"
  (nodeEditing)="onEditingNode()"
  (nodeDragStart)="dragStart($event)"
  (nodeDragStop)="dragStop($event)"
  (nodeDragging)="nodeDrag($event)"
  (nodeDropped)="nodeDropped()"
  (nodeSelecting)="onSelecting($event)"
  (nodeSelected)="onLoadBoard($event)"
>
  <ng-template #nodeTemplate="" let-data>
    <div
      [ngClass]="[
        data.isGroup ? 'cursor-default' : 'cursor-pointer',
        'flex',
        'items-center',
        'flex-1',
        'justify-between'
      ]"
    >
      <span
        class="overflow-hidden text-ellipsis max-w-[134px] pt-[2px]"
        [innerHTML]="data?.name | highlightSearch: query"
      ></span>
      <itq-nav-button
        [materialStyle]="'basic'"
        [openDirection]="{
          vertical: 'down',
          horizontal: 'right'
        }"
        [openPosition]="'outside'"
        [template]="data.isGroup ? groupMoreTemplate : boardMoreTemplate"
        [templateData]="data"
      >
        <div class="flex justify-center w-full">
          <i class="ph ph-dots-three ph-bold"></i>
        </div>
      </itq-nav-button>
    </div> </ng-template
></ejs-treeview>
<ng-template #addTemplate>
  <div class="flex flex-col w-full">
    <itq-mat-button
      class="w-full"
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onCreateBoard()"
    >
      <div class="flex justify-center items-center space-x-2">
        <itq-svg [icon]="'dashboard'" class="w-4 h-4"></itq-svg>
        <span class="whitespace-nowrap pt-[2px] font-normal">New board</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.LG"
      [type]="'button'"
      [align]="Align.START"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      (clickEvent)="onCreateGroup()"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-folder text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">New group</span>
      </div>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #groupMoreTemplate let-node>
  <div class="flex flex-col w-full">
    <itq-mat-button
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="renameGroup(node)"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-pencil text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Rename group</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [disabled]="node | containsDefaultBoard"
      [materialStyle]="'basic'"
      (clickEvent)="onDeleteGroup(node)"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-trash text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Delete group</span>
      </div>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #boardMoreTemplate let-node>
  <div class="flex flex-col">
    <itq-mat-button
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onRenameBoard(node)"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-pencil text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Rename board</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [disabled]="node.defaultBoard"
      [materialStyle]="'basic'"
      (clickEvent)="onSetAsDefault(node)"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-star text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal"
          >Set as default</span
        >
      </div>
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.LG"
      [padding]="Sizes['X-SM']"
      [disabled]="node.defaultBoard"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onDeleteBoard(node)"
    >
      <div class="flex justify-center items-center space-x-2">
        <i class="ph ph-trash text-lg"></i>
        <span class="whitespace-nowrap pt-[4px] font-normal">Delete board</span>
      </div>
    </itq-mat-button>
  </div>
</ng-template>
