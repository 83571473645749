import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  Align,
  AvatarComponent,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  Event,
  EventTeams,
  FAwesomeModule,
  GetUsernameInitialsPipe,
  IEventData,
  IEventType,
  IQueryColumn,
  LabelComponent,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  TableColumn,
  VirtualScrollService,
  VirtualTableComponent,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import {
  FormatEventsPipe,
  HasEventsEnabledPipe,
} from '@portal/shared/pipes/events.pipe';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { Subscription } from 'rxjs';
import { EventWizardComponent } from '../event-wizard/event-wizard.component';
import { EventsFiltersComponent } from '../events-filters/events-filters.component';

@Component({
  selector: 'itq-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    FormatEventsPipe,
    MatTooltipModule,
    AvatarComponent,
    GetUsernameInitialsPipe,
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    VirtualTableComponent,
    LabelComponent,
  ],
  providers: [HasEventsEnabledPipe],
})
export class EventsComponent implements OnInit {
  private _dataSource: IEventData;

  get dataSource(): IEventData {
    return this._dataSource;
  }

  set dataSource(value: IEventData) {
    if (this.initialState.page === 1) {
      this._dataSource = value;
    } else {
      this._dataSource.items = [...this._dataSource.items, ...value.items];
    }
  }
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    [],
  );
  public tableColumns: TableColumn[];
  public typesDataSource: Array<IEventType>;
  private subscriptions = new Subscription();

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly EventTeams = EventTeams;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;

  @ViewChild('teamTemplate') teamTemplate: TemplateRef<unknown>;
  @ViewChild('updatedByTemplate') updatedByTemplate: TemplateRef<unknown>;

  constructor(
    readonly customOverlayService: CustomOverlayService,
    readonly sharedService: SharedService,
    readonly virtualScrollService: VirtualScrollService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    readonly snackBar: MatSnackBar,
    private readonly hasEventsEnabledPipe: HasEventsEnabledPipe,
    private readonly router: Router,
    readonly ecosystemService: EcosystemsService,
  ) {}

  ngOnInit() {
    this.onDataBound();
    this.bindChangeEcosystemSubscription();
  }

  ngAfterViewInit(): void {
    this.initColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onLoadFilters(): void {
    const initialState = JSON.parse(JSON.stringify(this.initialState));
    const overlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventsFiltersComponent,
          inputs: {
            columnFilters: initialState.columnFilters,
          },
        },
      },
      closeBtnClass: 'hidden',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    overlay.afterClosed.subscribe(
      (response: { refresh: boolean; columnFilters: Array<IQueryColumn> }) => {
        if (response?.refresh) {
          this.sharedService.loader$.next(true);
          this.initialState.columnFilters = response.columnFilters;
          this.initialState.resetPagination();
          this.onDataBound();
        }
      },
    );
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }),
    );
  }

  public initColumns(): void {
    this.tableColumns = [
      {
        name: 'Created',
        dataKey: 'createdDate',
        isSortable: true,
        width: '100px',
        isFilterable: true,
      },
      {
        name: 'By',
        dataKey: 'updatedBy',
        isSortable: true,
        position: 'center',
        width: '180px',
        customRender: true,
        template: this.updatedByTemplate,
      },
      {
        name: 'Name',
        dataKey: 'name',
        isSortable: true,
        width: 'fit',
      },
      {
        name: 'Team',
        dataKey: 'team',
        isSortable: true,
        position: 'center',
        width: '200px',
        customRender: true,
        template: this.teamTemplate,
      },
      {
        name: 'Type',
        dataKey: 'typeName',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Date',
        dataKey: 'date',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Details',
        dataKey: 'description',
        isSortable: true,
      },
      {
        name: '',
        dataKey: 'settings',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '47px',
      },
    ];
  }

  public onSearch(): void {
    this.initialState.resetPagination();
    this.onDataBound();
  }

  public onDataBound(): void {
    const isEventsEnabled = this.hasEventsEnabledPipe.transform(
      this.userService.userPreferences.defaultEcosystemId,
    );
    if (isEventsEnabled) {
      this.sharedService.loader$.next(true);
      this.eventsService
        .getEvents(
          this.initialState,
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IEventData) => {
          this.dataSource = response;
          this.sharedService.loader$.next(false);
        });
    } else {
      this.router.navigate(['/boards']);
    }
  }

  public onEdit(item: Event): void {
    const event = this.eventsService.findEventById(item.id);
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
          inputs: {
            event,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.snackBar.open('Your event has been updated!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.initialState.resetPagination();
        this.onDataBound();
      }
    });
  }

  public onDelete(item: Event): void {
    this.eventsService.delete(item.id).subscribe(() => {
      this.snackBar.open('Your event has been deleted!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.initialState.resetPagination();
      this.onDataBound();
    });
  }

  public onCreateEvent(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.snackBar.open('Your event has been created!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.initialState.resetPagination();
        this.onDataBound();
      }
    });
  }
}
