<div #button class="flex flex-col w-full">
  <itq-mat-button
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [padding]="padding"
    [disabled]="disabled"
    [ngClass]="{ invalid }"
    [style]="color"
    [materialStyle]="materialStyle"
    (clickEvent)="onOpen()"
  >
    <div class="flex justify-between items-center w-full">
      <div class="flex items-center flex-1 overflow-hidden" #buttonWrapper>
        @if (selections?.length > 0) {
          <itq-tags-list
            class="overflow-hidden"
            [dataSource]="selections"
            [dataFields]="dataFields"
            [maxWidth]="getMaxWidth()"
            [type]="PillType.BUTTON"
            (removeValue)="onRemoveItem($event)"
          ></itq-tags-list>
        } @else {
          <span
            class="font-normal text-gray-400 text-ellipsis overflow-hidden whitespace-nowrap"
            >{{ placeholder }}
          </span>
        }
      </div>
      @if (showLoader) {
        <itq-loader [type]="'controls'"></itq-loader>
      }
      <i
        [ngClass]="{
          'border-l border-border pl-2 ml-2 ph text-lg': true,
          'text-gray-400': selections?.length === 0,
          'ph-caret-up': open,
          'ph-caret-down': !open
        }"
      ></i>
    </div>
  </itq-mat-button>
</div>
<ng-template #templateBody>
  <div
    #menu
    [ngClass]="{
      'menu__container z-10 mt-2 w-[auto] rounded-md bg-white block': true,
      hidden: !open
    }"
    (click)="$event.stopImmediatePropagation()"
  >
    <itq-qb-multiple-dropdown-results
      [dataSource]="dataSource"
      [initialState]="initialState"
      [selections]="selections"
      [dataFields]="dataFields"
      (dataBound)="onDataBound($event)"
      (changeValue)="onChangeValue($event)"
    >
    </itq-qb-multiple-dropdown-results>
  </div>
</ng-template>
