import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  Event,
  FAwesomeModule,
  IconType,
  IEventData,
  MomentService,
  QueryFilters,
  SearchFieldType,
  SvgComponent,
  UserTagCategory,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { EventWizardComponent } from '@portal/events/components/event-wizard/event-wizard.component';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { HasEventsEnabledPipe } from '@portal/shared/pipes/events.pipe';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { EventsService } from '@portal/shared/services/events.service';
import { LibraryService } from '@portal/shared/services/library.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagWizardComponent } from '@portal/tags/tag-wizard/tag-wizard.component';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import moment from 'moment';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { LibraryThumbnailComponent } from '../library-thumbnail/library-thumbnail.component';
import { ThumbnailTitle } from '../library-thumbnail/library-thumbnail.enum';
import { ILibraryItem } from './../library-thumbnail/library-thumbnail.interface';

@Component({
  selector: 'itq-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    LibraryThumbnailComponent,
    HasEventsEnabledPipe,
    SvgComponent,
  ],
})
export class LibraryComponent implements OnInit {
  public vendorsDataSource: Array<ILibraryItem>;
  public tagsDataSource: Array<ILibraryItem>;
  public eventsDataSource: Array<ILibraryItem>;
  private tagsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  private vendorsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  private eventsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  public vendorsTotalCount: number;
  public tagsTotalCount: number;
  public eventsTotalCount: number;
  private subscriptions = new Subscription();
  public showLoader = true;

  readonly IconType = IconType;
  readonly ThumbnailTitle = ThumbnailTitle;

  constructor(
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly profileService: ProfileService,
    readonly router: Router,
    readonly customOverlayService: CustomOverlayService,
    readonly libraryService: LibraryService,
    readonly eventsService: EventsService,
    readonly ecosystemService: EcosystemsService,
    readonly momentService: MomentService,
  ) {}

  ngOnInit() {
    this.onDataBound();
    this.bindChangeEcosystemSubscription();
  }

  private onDataBound(): void {
    this.showLoader = true;
    this.onGetTotalCounts(),
      forkJoin([
        this.onGetTags(),
        this.onGetVendors(),
        this.onGetEvents(),
      ]).subscribe(() => {
        this.showLoader = false;
      });
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.onDataBound();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onGetTotalCounts(): void {
    forkJoin([
      this.tagService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.profileService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.eventsService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
    ]).subscribe((response: [number, number, number]) => {
      this.tagsTotalCount = response[0];
      this.vendorsTotalCount = response[1];
      this.eventsTotalCount = response[2];
    });
  }

  public onDataBoundVendors(query?: string): void {
    this.onGetVendors(query).subscribe();
  }

  public onDataBoundEvents(query?: string): void {
    this.onGetEvents(query).subscribe();
  }

  public onDataBoundTags(query?: string): void {
    this.onGetTags(query).subscribe();
  }

  public onGetTags(query?: string): Observable<void> {
    return this.tagService
      .getTags(
        this.tagsInitialState,
        query,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map(
          (response: {
            items: Array<Tag>;
            page: number;
            pageSize: number;
            totalCount: number;
          }) => {
            this.tagsDataSource = response.items.map((tag: Tag) => ({
              id: tag.tagId,
              title: tag.name,
              description: this.momentService
                .toLocalTimezone(moment(tag.createdDate))
                .format('DD-MM-yyyy'),
            }));
          },
        ),
      );
  }

  public onGetVendors(query?: string): Observable<void> {
    if (query) {
      this.vendorsInitialState.addQueryColumn({
        searchValues: [query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.vendorsInitialState.removeQueryColumn('name');
    }
    return this.profileService
      .getProfiles(
        this.vendorsInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IProfileData) => {
          this.vendorsDataSource = response.items.map((vendor: Profile) => ({
            id: vendor.profileId,
            title: vendor.name,
            description: this.momentService
              .toLocalTimezone(moment(vendor.createdDate))
              .format('DD-MM-yyyy'),
          }));
        }),
      );
  }

  public onGetEvents(query?: string): Observable<Array<ILibraryItem>> {
    if (query) {
      this.eventsInitialState.addQueryColumn({
        searchValues: [query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.eventsInitialState.removeQueryColumn('name');
    }
    return this.eventsService
      .getEvents(
        this.eventsInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IEventData) => {
          this.eventsDataSource = response.items.map((item: Event) => ({
            id: item.id,
            title: item.name,
            description: this.momentService
              .toLocalTimezone(moment(item.date))
              .format('DD-MM-yyyy'),
          }));
          return this.eventsDataSource;
        }),
      );
  }

  public onLoadVendors(): void {
    this.router.navigateByUrl('/manager/vendors');
  }

  public onLoadTags(): void {
    this.router.navigateByUrl('/manager/tags');
  }

  public onLoadEvents(): void {
    this.router.navigateByUrl('/manager/events');
  }

  public onCreateTag(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: TagWizardComponent,
          inputs: {
            tag: new Tag(
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              UserTagCategory.Standard,
              undefined,
              undefined,
              undefined,
            ),
            action: Actions.CREATE,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'dynamic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetTags().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }

  public onCreateEvent(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetEvents().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }

  public onCreateVendor(profileTypeId: string): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      undefined,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileTypeId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );

    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            profile,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetVendors().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }
}
