<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <itq-widget-header
    [widget]="widget"
    [widgetMoreTemplate]="widgetMoreTemplate"
    [count]="count"
  ></itq-widget-header>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border p-4"
    #chartContainer
  >
    <itq-chart
      #chart
      [id]="tagTrendsWidget?.widgetId"
      [data]="dataSource"
      [settings]="tagTrendsWidget?.options"
      [type]="tagTrendsWidget?.chartType"
      (updateRef)="onUpdateRef($event)"
      (drilldown)="onExplore($event)"
    >
    </itq-chart>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate>
  <itq-widget-actions
    [selectedDisplayType]="selectedDisplayType"
    [widget]="tagTrendsWidget"
    [displayTypes]="displayTypes"
    (edit)="onEdit()"
    (explore)="onExplore($event)"
    (delete)="onDeleteWidget()"
    (export)="onExportWidget()"
    (changeDisplay)="onChangeDisplay($event)"
  ></itq-widget-actions>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count | number }}
  </button>
</ng-template>
