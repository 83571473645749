<div class="flex items-center px-7 flex-col">
  <div class="flex justify-between py-4 items-center w-full">
    <div class="flex items-center w-full">
      @if (iconType === IconType.FONT_AWESOME) {
        <fa-icon [icon]="['far', icon | castIconName]" [size]="'xl'"></fa-icon>
      } @else if (iconType === IconType.PHOSPHOR) {
        <i [ngClass]="['ph', 'text-xl ph-bold', icon]"></i>
      } @else {
        <itq-svg class="w-6 h-6 mr-1 fill-current" [icon]="icon"></itq-svg>
      }
      <span class="text-xl px-2 font-medium uppercase pt-[2px]">{{
        title
      }}</span>
      @if (totalCount) {
        ({{ totalCount }})
      }
    </div>
    @if (title === ThumbnailTitle.VENDORS) {
      <itq-nav-button
        [padding]="Sizes.NONE"
        [materialStyle]="'flat'"
        [align]="Align.CENTER"
        [color]="'primary'"
        [template]="addTemplate"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
      </itq-nav-button>
    } @else {
      <itq-mat-button
        [padding]="Sizes.NONE"
        [materialStyle]="'flat'"
        [style]="'primary'"
        [type]="'button'"
        (clickEvent)="onCreate()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
      </itq-mat-button>
    }
  </div>
  <itq-input
    class="w-full"
    [type]="'text'"
    [icon]="'search'"
    [(ngModel)]="query"
    formControlzName="searchTerm"
    [placeholder]="'Search&nbsp;' + title + '...'"
    (search)="onSearch()"
    (clear)="onSearch()"
  ></itq-input>
</div>

<div class="flex flex-col w-full flex-1">
  @if (dataSource?.length > 0) {
    <div
      class="flex px-7 border-b border-border pt-7 pb-4 items-center w-full justify-between font-medium"
    >
      <span> NAME</span>
      <span> {{ dateLabel }}</span>
    </div>
    <ul class="flex flex-col w-full flex-1">
      @for (item of dataSource; track item.id) {
        <li class="flex justify-between border-b border-border h-[48px] w-full">
          <div class="flex px-7 justify-between items-center w-full">
            <span
              class="overflow-hidden text-ellipsis whitespace-nowrap flex-1"
              [innerHTML]="item.title | highlightSearch: query"
            ></span
            ><span>{{ item.description }}</span>
          </div>
        </li>
      }
    </ul>
  } @else if (dataSource) {
    <itq-panel-info
      class="flex-1 p-7"
      [label]="'No&nbsp;' + title + ' found'"
      [message]="
        'No results found. Please update your search term and try again.'
      "
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  }
  <itq-mat-button
    class="mx-7 my-4"
    [size]="Sizes.BLOCK"
    [materialStyle]="'flat'"
    [style]="'primary'"
    [type]="'button'"
    (clickEvent)="onActionClick()"
  >
    ALL&nbsp;<span class="uppercase">{{ title }}</span>
  </itq-mat-button>
</div>
@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #addTemplate>
  <div class="flex flex-col w-full">
    @for (item of typesDataSource; track item.id) {
      <itq-mat-button
        [size]="Sizes.LG"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onCreate(item.id)"
      >
        <div class="flex items-center space-x-2">
          <i [ngClass]="['ph', 'text-lg', ProfileTypeIcon[item.name]]"></i>
          <span class="whitespace-nowrap font-normal pt-[4px]">{{
            item.name
          }}</span>
        </div>
      </itq-mat-button>
    }
  </div>
</ng-template>
