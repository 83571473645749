import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  FAwesomeModule,
  FieldSections,
  FieldTypes,
  IPresetQuery,
  QueryFilters,
  UserTagCategory,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { ValuePickerComponent } from '@portal/tags/components/value-picker/value-picker.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-timeline-customerspecific-filters.component',
  templateUrl: './timeline-customerspecific-filters.component.html',
  styleUrls: ['./timeline-customerspecific-filters.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    CommonModule,
    ValuePickerComponent,
  ],
})
export class TimelineCustomerSpecificFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() userTagCategory: string;
  @Input() fields: Array<IFilterField>;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<Query>();

  public sourcesDataSource: Array<ICustomTag>;
  public vendorsDataSource: Array<ICustomTag>;
  public cohortsDataSource: Array<ICustomTag>;
  public actorField: IFilterField;
  public channelField: IFilterField;
  private subscriptions = new Subscription();
  public cohortInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public sourcesInitialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public vendorsInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public queryModel = new Query([], 'and');

  constructor(
    public widgetService: WidgetService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    readonly tagService: TagService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.sourcesInitialState.where = this.initialState.where;
    this.cohortInitialState.where = this.initialState.where;
    this.vendorsInitialState.where = this.initialState.where;
    this.bindChangeDateSubscription();
    this.addControls();
    this.actorField = this.getField('Actor', FieldSections.FILTER_TO);
    this.channelField = this.getField('Channel', FieldSections.FILTER_TO);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  public onGetCohorts(): void {
    this.categoryService
      .getCategoryValues(
        this.cohortInitialState.query,
        this.cohortInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Cohort,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.cohortInitialState.page > 1
          ? this.cohortsDataSource[this.cohortsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.cohortsDataSource = response.items;
        },
      );
  }

  public onGetVendors(): void {
    this.categoryService
      .getCategoryValues(
        this.vendorsInitialState.query,
        this.vendorsInitialState,
        this.queryModel.convertToBackEndQuery(),
        UserTagCategory.Vendor,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.vendorsInitialState.page > 1
          ? this.vendorsDataSource[this.vendorsDataSource.length - 1].name
          : undefined,
        undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.vendorsDataSource = response.items;
        },
      );
  }

  public onGetSources(): void {
    this.tagService
      .getFieldValues(
        this.sourcesInitialState,
        this.queryModel.convertToBackEndQuery(),
        'source',
        this.userService.userPreferences.defaultEcosystemId,
        this.sourcesInitialState.page > 1
          ? this.sourcesDataSource[this.sourcesDataSource.length - 1].name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.sourcesDataSource = response.items;
        },
      );
  }

  private bindChangeDateSubscription(): void {
    this.subscriptions.add(
      this.widgetService.changeDate$.subscribe((dates: IPresetQuery) => {
        this.initialState.where = dates;
        this.sourcesInitialState.where = dates;
        this.vendorsInitialState.where = dates;
        this.cohortInitialState.where = dates;
        this.initialState.resetPagination();
        this.sourcesInitialState.resetPagination();
        this.vendorsInitialState.resetPagination();
        this.cohortInitialState.resetPagination();
        this.cdr.detectChanges();
      }),
    );
  }

  private addControls(): void {
    this.form.addControl('actor', new FormControl(undefined));
    this.form.addControl('channel', new FormControl(undefined));
    this.form.addControl('source', new FormControl(undefined));
    this.form.addControl('vendors', new FormControl(undefined));
    this.form.addControl('cohort', new FormControl(undefined));
  }

  public onDataBound(): void {
    this.buildQuery();
    this.dataBound.emit(this.queryModel);
  }

  private buildQuery(): void {
    this.queryModel = new Query([], 'and');
    const actorField = this.getField('Actor', FieldSections.FILTER_TO);
    const channelField = this.getField('Channel', FieldSections.FILTER_TO);
    const sourceField = this.getField('Source', FieldSections.FILTER_TO);
    const vendorsField = this.getField('Vendors', FieldSections.FILTER_TO);
    const cohortField = this.getField('Cohort', FieldSections.FILTER_TO);
    if (this.form?.get('actor').value) {
      this.queryModel.addRule({
        entity: actorField.field,
        field: actorField.label,
        operator: 'in',
        value: this.form.get('actor').value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('channel').value) {
      this.queryModel.addRule({
        entity: channelField.field,
        field: channelField.label,
        operator: 'in',
        value: this.form
          .get('channel')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('source').value) {
      this.queryModel.addRule({
        entity: sourceField.field,
        field: sourceField.label,
        operator: 'in',
        value: this.form.get('source').value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('vendors').value) {
      this.queryModel.addRule({
        entity: vendorsField.field,
        field: vendorsField.label,
        operator: 'in',
        value: this.form
          .get('vendors')
          .value.map((item: ICustomTag) => item.id),
      });
    }
    if (this.form?.get('cohort').value) {
      this.queryModel.addRule({
        entity: cohortField.field,
        field: cohortField.label,
        operator: 'in',
        value: this.form.get('cohort').value.map((item: ICustomTag) => item.id),
      });
    }
  }

  public onClearVendor(): void {
    this.form.get('vendor').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearActors(): void {
    this.form.get('actor').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearChannels(): void {
    this.form.get('channel').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearSource(): void {
    this.form.get('source').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }

  public onClearCohort(): void {
    this.form.get('cohort').setValue(undefined);
    this.dataBound.emit(this.queryModel);
  }
}
