import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  Ecosystem,
  FAwesomeModule,
  IconType,
  LabelComponent,
  QueryFilters,
  SharedService,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { BoardService } from '@portal/boards/services/board.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { SearchComponent } from '../../../search/components/search/search.component';

@Component({
  selector: 'itq-default-navigation',
  templateUrl: './default-navigation.component.html',
  styleUrls: ['./default-navigation.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    CommonModule,
    RouterModule,
    SvgComponent,
    FormsModule,

    LabelComponent,
  ],
})
export class DefaultNavigationComponent implements OnInit {
  readonly Sizes = Sizes;
  readonly IconType = IconType;

  private subscriptions = new Subscription();
  public ecosystemDataSource: Array<Ecosystem> = [];
  public ecosystemId: string;
  private interval: NodeJS.Timeout;
  public isOpen = false;

  constructor(
    public sharedService: SharedService,
    private customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    readonly keycloakService: KeycloakService,
    readonly ecosystemsService: EcosystemsService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.ecosystemDataSource = this.ecosystemsService.ecosystems;
    this.ecosystemId = this.userService.userPreferences.defaultEcosystemId;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions.unsubscribe();
  }

  public onToggleSidebar(): void {
    this.sharedService.toggleSidebar$.next();
  }

  public onCreateSearch(): void {
    const initialState = new QueryFilters(
      30,
      1,
      this.boardService.board?.filter?.date || {
        label: DateQueryType.LastMonth,
      },
      undefined,
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: SearchComponent,
          inputs: {
            initialState: initialState,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  removeRouteParam() {
    // Get the current route
    let currentRoute = this.router.url.split('/');
    let lastSegment = currentRoute[currentRoute.length - 1];

    // Check if the last segment is a UUID
    let uuidRegex =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(lastSegment)) {
      currentRoute.pop(); // Remove the last segment (id)

      // Navigate to the same route without the id
      this.router.navigate(currentRoute, { replaceUrl: true });
    }
  }

  public onChangeEcosystem(): void {
    this.userService.setDefaultEcosystem(this.ecosystemId).subscribe(() => {
      this.removeRouteParam();
      this.ecosystemsService.changeEcosystem$.next();
    });
  }
}
