import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRangeComponent,
  DynamicPlaceholderDirective,
  FAwesomeModule,
  FieldSections,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { ApiSettingsComponent } from '@portal/widgets/components/api-settings/api-settings.component';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import { WidgetService } from '@portal/widgets/services/widget.service';
import moment from 'moment';
import { Rule } from 'ngx-angular-query-builder';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';

@Component({
  selector: 'itq-chart-explore-timeline',
  templateUrl: './chart-explore-timeline.component.html',
  styleUrls: ['./chart-explore-timeline.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,
    MapLabelPipe,

    MatSlideToggleModule,
    LabelComponent,
  ],
})
export class ChartExploreTimelineComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() widget: Timeline;
  @Input() initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );

  public form: FormGroup;
  public _dataSource: IData;
  public queryModel = new Query([], 'and');
  public showLoader = false;
  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource = {
        result: [...this._dataSource.result, ...value.result],
        count: value.count,
      };
    } else {
      this._dataSource = value;
    }
  }
  private subscription = new Subscription();
  public count = 0;
  public fields: Array<IFilterField>;
  private componentRef: ComponentRef<any>;
  public tag: Tag;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  readonly PillType = PillType;

  constructor(
    private modalContainerService: ModalContainerService,
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly chartService: ChartService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly profileService: ProfileService,
    private componentFactoryResolver: ComponentFactoryResolver,
    readonly widgetService: WidgetService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.onGetTag();
    this.createForm();
    this.bindRemoveSegmentSubscription();
  }

  private onGetTag(): void {
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((response: Tag) => {
        this.tag = response;
        this.onGetFields();
      });
  }

  private onGetFields(): void {
    this.categoryService
      .getUserCategoryFieldsByType(
        this.tag.userTagCategory,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.createComponent();
        this.onDataBound(this.initialState);
      });
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        TagFactory.getExploreFiltersComponent(this.tag.userTagCategory),
      );

    this.componentRef = viewContainerRef.createComponent(
      dynamicComponentFactory,
    );
    this.componentRef.instance.queryModel = this.queryModel;
    this.componentRef.instance.initialState = this.initialState;
    this.componentRef.instance.form = this.form;
    this.componentRef.instance.userTagCategory = this.tag.userTagCategory;
    this.componentRef.instance.fields = this.fields;
    this.componentRef.instance.dataBound.subscribe((response: Query) => {
      this.queryModel = response;
      this.onDataBound(this.initialState);
    });
    this.showLoader = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.document = undefined;
      }),
    );
  }

  public onShowApi(): void {
    this.customOverlayService.open({
      data: {
        timeline: this.widget,
      },
      type: CustomOverlayType['slide-right'],
      component: ApiSettingsComponent,
      closeBtnClass: 'hidden',
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
      },
    });
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordActorComponent,
      inputs: {
        actor: extensionField,
      },
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownSubChannelComponent,
      inputs: {
        subChannel,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
      },
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(this.initialState.query),
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onRefresh(): void {
    this.initialState.page = 1;
    this.onDataBound(this.initialState);
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  private addQueryRules(): Array<Rule> {
    const userTagCategoryField = this.getField(
      this.tag.userTagCategory,
      FieldSections.FILTER_TO,
    );
    const contentField = this.getField('All Text', FieldSections.SEARCH_WITHIN);
    this.queryModel.rules = [];
    this.queryModel.rules.push({
      entity: userTagCategoryField.field,
      field: userTagCategoryField.id,
      operator: 'in',
      value: this.widget.dataSource,
    });
    if (this.form?.get('query')?.value) {
      this.queryModel.addRule({
        entity: contentField.field,
        field: contentField.id,
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    } else {
      this.queryModel.removeRuleByField('content');
    }
    return this.queryModel.rules;
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.queryModel.rules = this.addQueryRules();
      if (this.queryModel.hasRules()) {
        this.tagService
          .execute(
            this.initialState,
            this.queryModel.convertToBackEndQuery(),
            this.userService.userPreferences.defaultEcosystemId,
          )
          .subscribe((response: IData) => {
            this.dataSource = response;
            this.count = this.dataSource.count;
            this.showLoader = false;
          });
      }
    }
  }

  public onClearDate(): void {
    this.initialState.where = undefined;
    this.onSearch();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.widgetService.changeDate$.next(dates);
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.showLoader = true;
    this.document = undefined;
    this.onDataBound(this.initialState);
  }
}
