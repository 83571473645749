import { Pipe, PipeTransform } from '@angular/core';
import { GetUsernameInitialsPipe, MomentService } from '@intorqa-ui/core';
import { INoteDataSource } from '@portal/profiles/interfaces/profile-note.interface';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';
import moment from 'moment';

@Pipe({
  name: 'transformNotes',
  standalone: true,
})
export class TransformNotesPipe implements PipeTransform {
  constructor(
    private notesService: NotesService,
    private momentService: MomentService,
    private getUserNameInitialsPipe: GetUsernameInitialsPipe,
  ) {}

  transform(data: Array<ProfileNote>): Array<INoteDataSource> {
    if (!data || data?.length === 0) return [];
    return data?.map((item: any) => {
      const type = this.notesService.getTypeById(item.typeId);
      return {
        id: item.id,
        type: type?.name,
        subType: item.subType,
        value: item.value,
        rawValue: item.rawValue,
        documentId: item.documentId,
        rawTextNote: item.rawTextNote,
        textNote: item.textNote,
        updatedDate: this.momentService
          .toLocalTimezone(moment(item.updatedDate))
          .format('DD-MM-yyyy'),
        updatedBy: this.getUserNameInitialsPipe.transform(item.updatedBy),
      };
    });
  }
}
