import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Actions,
  Align,
  CustomOverlayService,
  CustomOverlayType,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { IConnectionsData } from '@portal/profiles/interfaces/profile-connection.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileConnection } from '@portal/profiles/models/profile-connection';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddConnectionsComponent } from '@portal/shared/components/add-connections/add-connections.component';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-profiles-connections',
  templateUrl: './profiles-connections.component.html',
  styleUrls: ['./profiles-connections.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesConnectionsComponent,
    },
  ],
})
export class ProfilesConnectionsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() profileAction: Actions;
  @Input() connections: Array<ProfileConnection>;
  @Input() action = Actions.CREATE;

  public tableColumns: Array<TableColumn> = [];
  public dataSource: IConnectionsData = { items: [], totalCount: 0 };
  private selection: Array<ProfileConnection>;
  private touched = false;
  private disabled = false;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public totalCount: number;
  public isFilterable: boolean;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly Align = Align;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<unknown>;

  constructor(
    public profileService: ProfileService,
    public connectionsService: ConnectionsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    if (this.connections?.length > 0) {
      this.connectionsService.connections = {
        items: this.connections,
        totalCount: this.connections.length,
      };
      this.dataSource = cloneDeep(this.connectionsService.connections);
    }
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.form.controls.connections?.setValue(response);
          this.dataSource = cloneDeep(response);
          this.profileService.loader$.next(false);
        },
      );

    if (
      this.profileAction === Actions.EDIT ||
      this.profileAction === Actions.EXPLORE
    ) {
      this.onDataBound(this.initialState, false);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileConnection>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Updated date',
        dataKey: 'updatedDate',
        searchField: 'updatedDate',
        isSortable: true,
        width: '180px',
        customRender: true,
        template: this.dateTemplate,
      },
      {
        name: 'Updated by',
        dataKey: 'updatedBy',
        searchField: 'updatedBy',
        isSortable: true,
        position: 'center',
        customRender: true,
        template: this.ownerTemplate,
        width: '180px',
        isFilterable: true,
      },
      {
        name: 'Connection type',
        dataKey: 'typeName',
        searchField: 'typeId',
        width: '200px',
        isSortable: true,
      },
      {
        name: 'Connected profile',
        dataKey: 'targetProfileName',
        searchField: 'targetProfileName',
        width: 'fit',
        httpBinding: true,
        isSortable: true,
      },
      {
        name: 'Description',
        dataKey: 'description',
        searchField: 'description',
        width: 'fit',
        isSortable: true,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
  }

  public onDeleteConnection(connection: ProfileConnection): void {
    this.connectionsService
      .removeConnections(this.profile.profileId, [connection])
      .subscribe();
  }

  public onEditConnection(connection: ProfileConnection): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddConnectionsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.EDIT,
            connection: connection,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddConnection(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddConnectionsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            connection: new ProfileConnection(
              Utils.generateUUID(),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
            ),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters, showLoader = true): void {
    this.profileService.loader$.next(showLoader);
    this.connectionsService
      .getConnections(this.profile.profileId, params)
      .subscribe();
  }

  public onExit(): void {
    this.connectionsService.showAddConnections$.next(undefined);
    this.connectionsService.showConnectionsSettings$.next(false);
  }
}
