import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Sizes } from '../../enums/shared.enum';
import { QueryFilters } from '../../models/query-filters';
import { PillType } from '../pill/pill.enum';

@Component({
  selector: 'itq-multiple-dropdown',
  templateUrl: './multiple-dropdown.component.html',
  styleUrls: ['./multiple-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultipleDropdownComponent,
    },
  ],
})
export class MultipleDropdownComponent implements OnInit, ControlValueAccessor {
  @ViewChild('button', { static: true }) button: ElementRef;
  @ViewChild('buttonWrapper') buttonWrapper: ElementRef;
  @ViewChild('templateBody') templateBody: TemplateRef<unknown>;
  @ViewChild('menu') menu: ElementRef;

  @Input() disabled = false;
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() placeholder = 'Please select an item...';
  @Input() materialStyle = 'stroked';
  @Input() isSearchable = true;
  @Input() padding = Sizes['X-SM'];
  @Input() color: string;
  @Input() query: string;
  @Input() invalid: boolean;
  @Input() includeEmpty = false;
  @Input() initialState: QueryFilters;
  @Input() openDirection: 'up' | 'down' = 'down';

  @Output() addOnEmpty = new EventEmitter<string>();
  @Output() changeValue = new EventEmitter<void>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  private touched = false;
  public open = false;
  public selections: Array<any> = [];
  private overlayRef: OverlayRef;
  public showLoader: boolean;

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (
      this.open &&
      !this.button.nativeElement.contains(event.target) &&
      !this.menu?.nativeElement.contains(event.target)
    ) {
      this.closeDropdown();
    }
  }

  constructor(
    private renderer: Renderer2,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    readonly cdr: ChangeDetectorRef,
  ) {}

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: Array<any> | undefined): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'resize', () => {
      if (this.open) {
        this.adjustDropdownPosition();
        this.checkBoundaries();
        this.updatePosition();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
      if (this.initialState && this.open && !this.overlayRef) {
        this.openDropdown();
      }
    }
  }

  adjustDropdownPosition(): void {
    if (this.overlayRef) {
      const buttonRect = this.button.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.menu.nativeElement,
        'minWidth',
        `${buttonRect.width}px`,
      );
      this.overlayRef.updatePosition();
    }
  }

  public onOpen(): void {
    this.open = !this.open;
    if (this.open) {
      if (this.initialState) {
        this.showLoader = true;
        this.dataBound.emit(this.initialState);
      } else {
        this.openDropdown();
      }
    } else {
      this.closeDropdown();
    }
  }

  openDropdown(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      return;
    }
    const buttonRect = this.button.nativeElement.getBoundingClientRect();
    const overlayConfig = new OverlayConfig({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(this.templateBody, this.viewContainerRef);
    this.overlayRef.attach(portal);
    this.cdr.detectChanges();
    this.renderer.setStyle(
      this.menu.nativeElement,
      'minWidth',
      `${buttonRect.width}px`,
    );
    this.checkBoundaries();
    this.updatePosition();
  }

  private checkBoundaries(): void {
    this.openDirection = 'down';
    const dropdownBoundingRect =
      this.button.nativeElement.getBoundingClientRect();

    if (
      dropdownBoundingRect.top + this.menu?.nativeElement?.clientHeight >
      window.innerHeight
    ) {
      this.openDirection = 'up';
    }
  }

  private getContainerPosition(): {
    left: number;
    top: number;
  } {
    const dropdownBoundingRect =
      this.button.nativeElement.getBoundingClientRect();

    const margin = 15;

    return {
      left: dropdownBoundingRect.left,
      top:
        this.openDirection === 'up'
          ? dropdownBoundingRect.top -
            this.menu.nativeElement.clientHeight -
            margin
          : dropdownBoundingRect.bottom,
    };
  }

  private updatePosition(): void {
    const position = this.getContainerPosition();
    this.overlayRef.updatePositionStrategy(
      this.overlay
        .position()
        .global()
        .left(`${position.left}px`)
        .top(`${position.top}px`),
    );
  }

  closeDropdown(): void {
    console.log('click');
    if (this.overlayRef) {
      this.open = false;
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  public getMaxWidth(): number {
    return this.buttonWrapper?.nativeElement?.offsetWidth;
  }

  public onDataBound(params: QueryFilters): void {
    if (params) {
      this.dataBound.emit(params);
    }
  }

  public onRemoveItem(value: any): void {
    this.selections = this.selections.filter((item: any) => {
      return item[this.dataFields.value] !== value[this.dataFields.value];
    });
    this.onChange(this.selections?.length > 0 ? this.selections : undefined);
    this.changeValue.emit();
  }

  public onChangeValue(selections: any): void {
    this.selections = selections;
    this.onChange(this.selections?.length > 0 ? this.selections : undefined);
    this.changeValue.emit();
  }

  public getSelection(): any {
    if (this.selections) {
      const selection = this.dataSource?.find((item: any) => {
        return item[this.dataFields.value] === this.selections;
      });
      if (selection) {
        return selection[this.dataFields.name];
      }
      return undefined;
    }
    return undefined;
  }
}
