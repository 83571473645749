export enum Sizes {
  NONE = 'none',
  'X-SM' = 'x-sm',
  'XX-SM' = 'xx-sm',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  BLOCK = 'block',
  'XX-LG' = 'xx-lg',
}

export enum Directions {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum Align {
  START = 'start',
  END = 'end',
  CENTER = 'center',
}
export enum Actions {
  CREATE = 'create',
  EXPLORE = 'explore',
  EDIT = 'edit',
  DELETE = 'delete',
  EXPORT = 'export',
  DRILLDOWN = 'drilldown',
  CLONE = 'clone',
}
