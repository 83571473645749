import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  FieldTypes,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  UserTagCategory,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
  ITagMetadata,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-all-tags-datasource',
  templateUrl: './all-tags-datasource.component.html',
  styleUrls: ['./all-tags-datasource.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    MatTooltipModule,
  ],
})
export class AllTagsDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;
  @Input() dates: IPresetQuery;

  @Output() changeDataValue = new EventEmitter<Array<string>>();
  @Output() changeDataType = new EventEmitter<{
    label: string;
    type: string;
  }>();

  public initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private subscriptions = new Subscription();
  public userCategoriesDataSource: Array<IFilterField>;
  public dataPointsDataSource: Array<ICustomTag> = [];

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    public widgetService: WidgetService,
    readonly userService: UserService,
    readonly tagService: TagService,
    private categoryService: CategoryService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.createForm();
    this.onGetUserCategories();
    this.loadMetadata();
    this.onGetTags();
    this.bindReloadWidgetDatesSubscription();
    this.bindChangeDatesSubscription();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataPoints');
    this.form.removeControl('dataSlicer');
    this.subscriptions.unsubscribe();
  }

  public onGetUserCategories(): void {
    this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.userCategoriesDataSource = response
          ?.filter(
            (field: IFilterField) =>
              field.type === FieldTypes.USER_TAG_CATEGORY,
          )
          .map((field: IFilterField) => ({
            ...field,
            ...{ disabled: !field.hasAnyMatches },
          }))
          .sort((a, b) => {
            if (a.hasAnyMatches === b.hasAnyMatches) {
              return a.label.localeCompare(b.label);
            }
            return a.hasAnyMatches === false ? 1 : -1;
          });
        this.cdr.detectChanges();
        this.form.get('dataSlicer').setValue(this.widget.dataSlicer?.label);
      });
  }

  private onGetTags(): void {
    if (this.widget?.dataSlicer) {
      this.categoryService
        .getCategoryValues(
          undefined,
          this.initialState,
          new Query().convertToBackEndQuery(),
          this.widget.dataSlicer.label,
          this.userService.userPreferences.defaultEcosystemId,
          FieldTypes.USER_TAG_CATEGORY,
          this.initialState.page > 1
            ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1]
                .name
            : undefined,
        )
        .subscribe(
          (response: { items: Array<ICustomTag>; totalCount: number }) => {
            this.dataPointsDataSource = response.items;
          },
        );
    }
  }

  public onRemoveTag(tag: ICustomTag): void {
    const currentValues = this.form.get('dataPoints').value || [];
    const updatedValues = currentValues.filter(
      (item: ICustomTag) => item.id !== tag.id,
    );
    this.form.get('dataPoints').setValue(updatedValues);
    this.form.markAsDirty();
    this.onDataBound();
  }

  private bindChangeDatesSubscription(): void {
    this.subscriptions.add(
      this.widgetService.changeDate$.subscribe((params: IPresetQuery) => {
        this.initialState.where = params;
        this.onGetUserCategories();
        this.onGetTags();
      }),
    );
  }

  private bindReloadWidgetDatesSubscription(): void {
    this.subscriptions.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagComparison | TimeSeries;
          dates?: IPresetQuery;
        }) => {
          this.widget = params.widget;
          if (params.dates) {
            this.initialState.where = params.dates;
          }
        },
      ),
    );
  }

  private loadMetadata(): void {
    if (this.widget.dataValues?.length > 0) {
      this.tagService
        .getTagsMetadata(this.widget.dataValues)
        .subscribe((response: Array<ITagMetadata>) => {
          const dataValues = response.map((item: ITagMetadata) => ({
            name: item.tagName,
            id: item.tagId,
          }));
          if (dataValues) {
            this.form.controls.dataPoints.setValue(dataValues);
          }
        });
    }
  }

  public createForm(): void {
    this.form.addControl(
      'dataSlicer',
      new FormControl(this.widget.dataSlicer, [Validators.required]),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl({ value: undefined, disabled: !this.widget.widgetId }, [
        Validators.required,
      ]),
    );
  }

  private updateFormStatus(): void {
    if (this.form.get('dataPoints').value) {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
  }

  public onDataBound(): void {
    this.updateFormStatus();
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value?.map((item: ICustomTag) => item.id) ||
        [],
    );
  }

  public onChangeDataPoints(): void {
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value?.map((item: ICustomTag) => item.id) ||
        [],
    );
  }

  public onChangeDataSlicer(): void {
    this.form.get('dataPoints').setValue(undefined);
    this.form.get('dataPoints').enable();
    const field = this.userCategoriesDataSource.find(
      (category: IFilterField) =>
        category.label === this.form.get('dataSlicer').value,
    );
    this.widget.dataSlicer = {
      label: field.label,
      type: field.type,
    };
    this.onGetTags();
    this.changeDataType.emit(this.widget.dataSlicer);
  }
}
