import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Actions, PillType, QueryFilters, Sizes } from '@intorqa-ui/core';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { NetworkChart } from '@portal/boards/models/charts/network-chart';
import { IConnectionsData } from '@portal/profiles/interfaces/profile-connection.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { ChartService } from '@portal/shared/services/chart.service';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { ECharts } from 'echarts';
import { Subscription } from 'rxjs';
import { ProfilesExploreComponent } from '../../profiles-explore.component';

@Component({
  selector: 'itq-connections-network',
  templateUrl: './connections-network.component.html',
  styleUrls: ['./connections-network.component.scss'],
})
export class ConnectionsNetworkComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() profileAction: Actions;
  @Input() action: Actions;
  @Input() showConnectionSettings: boolean;

  public chart: NetworkChart;
  public data: IConnectionsData;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public chartInstance: ECharts;

  readonly Actions = Actions;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly ChartType = ChartType;

  constructor(
    private connectionsService: ConnectionsService,
    readonly profileService: ProfileService,
    readonly cdr: ChangeDetectorRef,
    readonly profilesService: ProfileService,
    readonly chartService: ChartService,
    private modalContainerService: ModalContainerService,
  ) {}

  ngOnInit(): void {
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.data = response;
          this.cdr.detectChanges();
          this.chartService.reloadChart$.next(this.profile.profileId);
          this.profilesService.loader$.next(false);
        },
      );
    this.chart = new NetworkChart();
    this.profilesService.loader$.next(true);
    this.connectionsService
      .getConnections(this.profile.profileId, this.initialState)
      .subscribe();
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
  }

  public onToggleSettings(): void {
    this.connectionsService.showConnectionsSettings$.next(true);
  }

  public onExport(): void {
    this.chartService.exportChart(
      `${this.profile.name}_connections`,
      this.chartInstance,
    );
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onDrilldown(params: any): void {
    if (params?.data?.id !== this.profile.profileId) {
      this.profileService
        .getProfileById(params.data.profileId)
        .subscribe((response: Profile) => {
          this.modalContainerService.navigate$.next({
            component: ProfilesExploreComponent,
            inputs: {
              profile: response,
              icon: 'file-invoice',
              action: Actions.EXPLORE,
            },
          });
        });
    }
  }
}
