<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="flex h-full flex-col w-full"
>
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center space-x-7">
      <div class="flex space-x-2 items-center">
        <i
          [ngClass]="{
            'ph text-3xl ph-bold': true,
            'ph-pencil': action === Actions.EDIT,
            'ph-plus': action === Actions.CREATE
          }"
        ></i>
        <h1 class="text-28 font-medium pt-[4px]">
          {{ action === Actions.EDIT ? 'Edit widget' : 'Create widget' }}
        </h1>
      </div>
      <itq-pill
        [class]="
          'rounded-2xl px-4 py-2  shadow-none font-medium bg-primary-over uppercase h-[36px]'
        "
        [type]="PillType.CUSTOM"
        >{{ widget.type | mapLabel }}</itq-pill
      >
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="w-[300px] flex-col flex border-r border-border pb-7">
      <div
        class="flex w-full border-b border-border items-center px-7 py-3 justify-between"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ (count | number) || 0 }}</itq-pill
        >
      </div>
      <div class="px-7 flex flex-col flex-1 overflow-hidden">
        <itq-timeline-metrics
          [widget]="widget"
          [form]="form"
          [dates]="this.initialState?.where"
        ></itq-timeline-metrics>
      </div>
    </div>
    <div class="flex flex-1 items-center flex-col overflow-hidden w-[900px]">
      <div class="flex flex-1 flex-col w-full overflow-hidden">
        <div class="flex flex-col bg-background flex-1 overflow-hidden">
          <div class="flex w-full flex-col flex-1 overflow-hidden items-center">
            @if (widget.hasMetrics()) {
              <itq-documents-feed
                class="overflow-hidden w-full"
                [data]="dataSource"
                [initialState]="initialState"
                (scrollEvent)="onDataBound($event)"
                (shareEmail)="onShareEmail($event)"
                (drilldown)="onDrilldown($event)"
                (createProfile)="onCreateProfile($event)"
              ></itq-documents-feed>
            } @else {
              <itq-panel-action
                class="flex items-center w-[650px] h-full"
                [label]="widget | selectMetricsLabel"
                [description]="
                  'You can change the display type by clicking one of the tabs above'
                "
              ></itq-panel-action>
            }
          </div>
          <div
            class="flex justify-start items-baseline py-4 pb-7 mx-7 space-x-4 border-t border-border"
          >
            <div class="flex flex-col flex-1">
              <span class="mb-1 text-13 font-medium">NAME</span>
              <itq-input
                [formControlName]="'name'"
                [placeholder]="'Timeline name'"
                (ngModelChange)="this.widget.name = $event"
              ></itq-input>
              @if (
                form.controls['name']?.touched &&
                form.controls['name']?.hasError('required')
              ) {
                <mat-error> Name is required </mat-error>
              }
            </div>
            <div class="flex flex-col flex-1">
              <span class="mb-1 text-13 font-medium">DESCRIPTION</span>
              <itq-input
                class="flex-1"
                [formControlName]="'description'"
                [placeholder]="'Timeline description'"
                (ngModelChange)="this.widget.description = $event"
              ></itq-input>
            </div>
          </div>
        </div>
      </div>
      <div
        [ngClass]="{
          'flex  w-full px-7 py-4 border-t border-border': true,
          'justify-between': action === Actions.CREATE,
          'justify-end': action !== Actions.CREATE
        }"
      >
        @if (action === Actions.CREATE) {
          <itq-mat-button
            [materialStyle]="'flat'"
            [style]="'primary'"
            [type]="'button'"
            (clickEvent)="onPrev()"
          >
            <itq-label
              [size]="'text-lg'"
              [label]="'PREV'"
              [icon]="'ph-caret-left'"
              [bold]="true"
            ></itq-label>
          </itq-mat-button>
        }

        <itq-mat-button
          [materialStyle]="'flat'"
          [style]="'primary'"
          [type]="'submit'"
        >
          SAVE
        </itq-mat-button>
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader> </itq-loader>
}
