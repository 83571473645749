export enum UserTagCategory {
  Vendor = 'Vendor',
  'Significant Actor' = 'Significant Actor',
  Game = 'Game',
  Standard = 'Standard',
  'Cheat Feature' = 'Cheat Feature',
  'Customer Specific' = 'Customer Specific',
  'Profile Tag' = 'Profile Tag',
  'Actor Roles' = 'Actor Roles',
  Cohort = 'Cohort',
  Glitch = 'Glitch',
  Asset = 'Asset',
  Utilities = 'Utilities',
}

export enum Fields {
  'Actor Roles' = 'Actor Roles',
}

export enum SystemTagCategory {
  'Cheat Tools' = 'Cheat Tools',
}

export enum FieldTypes {
  FIELD = 'field',
  SYSTEM_TAG_CATEGORY = 'systemTagCategory',
  USER_TAG_CATEGORY = 'userTagCategory',
}

export enum FieldSections {
  SEARCH_WITHIN = 'Search within',
  FILTER_TO = 'Filter to',
}
