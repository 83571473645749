import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CoreModule,
  DateQueryType,
  DateRangeComponent,
  DateRangeHelper,
  FAwesomeModule,
  IButtonThumbnail,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { forkJoin, Subscription } from 'rxjs';
import { NotificationResearchDetailComponent } from './components/notification-research-detail/notification-research-detail.component';
import { NotificationsHubComponent } from './components/notifications-hub/notifications-hub.component';
import { RaisedOtherMatchesComponent } from './components/raised-other-matches/raised-other-matches.component';
import { Priority_DataSource } from './const/alerts.const';
import { AlertType } from './models/alert-type';
import { ResearchNotification } from './models/notifications';
import { AlertsService } from './services/alerts.service';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'itq-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    NotificationsHubComponent,
    FAwesomeModule,
    NotificationResearchDetailComponent,
    RaisedOtherMatchesComponent,
    DateRangeComponent,
  ],
})
export class NotificationsComponent implements OnInit {
  @Input() raisedAlertId: string;
  @Input() researchAlertId: string;

  public form: FormGroup;
  public buttonDataSource: Array<IButtonThumbnail>;
  public unreadCount = signal(undefined);
  public showLoader = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public notificationsCount: number;
  public typesDataSource: Array<AlertType>;
  private subscriptions = new Subscription();
  public notification: ResearchNotification;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  readonly priorityDataSource = Priority_DataSource;
  readonly PillType = PillType;
  readonly IconType = IconType;
  readonly Sizes = Sizes;

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  constructor(
    readonly notificationsService: NotificationsService,
    readonly elementRef: ElementRef,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService,
    readonly elRef: ElementRef,
  ) {}

  ngOnInit() {
    let preset = DateRangeHelper.findPresetByLabel(DateQueryType.LastMonth);
    this.initialState.where = {
      label: DateQueryType.LastMonth,
      start: preset?.start.valueOf(),
      end: preset?.end.valueOf(),
    };
    this.bindUnreadCountSubscrition();
    this.initForm();
    this.getNotificationTypes();
    this.bindGetNotificationsSubscription();
    if (!this.researchAlertId && !this.raisedAlertId) {
      this.onDataBound();
    }
  }

  ngAfterViewInit(): void {
    this.buttonDataSource = [
      {
        id: 'read',
        tooltip: 'ALL',
      },
      {
        id: 'unread',
        tooltip: 'UNREAD',
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindGetNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.getNotifications$.subscribe(
        (response: QueryFilters) => {
          this.showLoader = true;
          this.initialState = response;
          this.onDataBound();
        },
      ),
    );
  }

  private getNotificationTypes(): void {
    this.subscriptions.add(
      this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
        this.typesDataSource = response;
      }),
    );
  }

  private initForm(): void {
    this.form = new FormGroup({});
    if (!this.researchAlertId && !this.raisedAlertId) {
      this.addControls();
    }
  }

  private addControls(): void {
    this.form.addControl('showAll', new FormControl('read'));
    this.form.addControl('type', new FormControl(undefined));
    this.form.addControl('query', new FormControl(''));
    this.form.addControl('priority', new FormControl(undefined));
  }

  private bindUnreadCountSubscrition(): void {
    this.subscriptions.add(
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount.set(response);
          this.buttonDataSource = this.buttonDataSource?.map(
            (item: IDisplayType) => {
              if (item.id === 'unread') {
                if (this.unreadCount() === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            },
          );
        },
      ),
    );
  }
  public onGetNotifications(): void {
    this.initialState.query = this.form.get('query').value;
    this.initialState.resetPagination();
    this.onDataBound();
  }

  public onChangeDates(params: IPresetQuery): void {
    this.initialState.resetPagination();
    this.initialState.where = params;
    this.onDataBound();
  }

  public onDataBound(): void {
    this.showLoader = true;

    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        this.initialState,
        this.form.get('showAll').value === 'unread' ? false : undefined,
        this.form.get('priority').value,
        this.form.get('type').value,
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(
        this.initialState,
        this.form.get('priority').value,
        this.form.get('type').value,
      );
    forkJoin([getNotificationsObservable, getUnreadCountObservable]).subscribe(
      (response) => {
        this.notificationsCount = response[0].totalCount;
        this.showLoader = false;
      },
    );
  }

  public onMarkAllRead(): void {
    this.showLoader = true;
    this.notificationsService.markAllAsRead().subscribe(() => {
      this.showLoader = false;
    });
  }

  public onShowNotifications(): void {
    this.researchAlertId = undefined;
    this.raisedAlertId = undefined;
    this.addControls();
    this.onDataBound();
  }
}
