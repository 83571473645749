<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <itq-widget-header
    [widget]="widget"
    [widgetMoreTemplate]="widgetMoreTemplate"
    [count]="count"
  ></itq-widget-header>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border p-4"
    #chartContainer
  >
    @if (tagComparisonWidget?.chartType === ChartType.TABLE) {
      <itq-table-chart
        class="overflow-hidden"
        [dataSource]="dataSource"
        [tableColumns]="tableColumns"
        [segment]="segment"
        [trackBy]="'category'"
      ></itq-table-chart>
    } @else {
      <itq-chart
        #chart
        [id]="tagComparisonWidget?.widgetId"
        [data]="dataSource"
        [settings]="tagComparisonWidget?.options"
        [type]="tagComparisonWidget?.chartType"
        (updateRef)="onUpdateRef($event)"
        (drilldown)="onExplore($event)"
      >
      </itq-chart>
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate>
  <itq-widget-actions
    [selectedDisplayType]="selectedDisplayType"
    [widget]="tagComparisonWidget"
    [displayTypes]="displayTypes"
    (edit)="onEdit()"
    (explore)="onExplore($event)"
    (delete)="onDeleteWidget()"
    (export)="onExportWidget()"
    (changeDisplay)="onChangeDisplay($event)"
  ></itq-widget-actions>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count | number }}
  </button>
</ng-template>
