<div class="flex w-full max-h-[350px] overflow-hidden">
  <div class="flex flex-col">
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        [style.padding-right]="'0.5rem !important'"
        [style.padding-left]="'0.5rem !important'"
        [style.background-color]="
          selectedSection === 'tag' ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange('tag')"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': selectedSection === 'tag'
          }"
        >
          <div class="flex items-center space-x-2">
            <i
              [ngClass]="{
                'ph-bold': selectedSection === 'tag',
                'ph ph-tag text-lg': true
              }"
            ></i>
            <span class="whitespace-nowrap pt-[4px]">Tag actions</span>
          </div>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down': selectedSection !== 'tag' ? true : false,
              'ph-caret-right ph-bold': selectedSection === 'tag' ? true : false
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <div class="flex flex-col w-[275px]">
      <itq-mat-button
        [padding]="Sizes['X-SM']"
        [size]="Sizes.LG"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onDeleteWidget()"
      >
        <div class="flex space-x-2">
          <i class="ph ph-trash text-lg"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">
            Remove widget
          </span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.LG"
        [cdkCopyToClipboard]="tagShareLink"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onShareLink()"
      >
        <div class="flex space-x-2">
          <i class="ph ph-link text-lg"></i>
          <span class="whitespace-nowrap pt-[4px] font-normal">
            Share link
          </span>
        </div>
      </itq-mat-button>
    </div>
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        [style.padding-right]="'0.5rem !important'"
        [style.padding-left]="'0.5rem !important'"
        [style.background-color]="
          selectedSection === 'create' ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange('create')"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': selectedSection === 'create'
          }"
        >
          <div class="flex items-center space-x-2">
            <i
              [ngClass]="{
                'ph-bold': selectedSection === 'create',
                'ph ph-plus text-lg': true
              }"
            ></i>
            <span class="whitespace-nowrap"> Create new widget </span>
          </div>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down': selectedSection !== 'create' ? true : false,
              'ph-caret-right ph-bold':
                selectedSection === 'create' ? true : false
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  @if (selectedSection === 'create') {
    <div
      class="flex flex-col border-l w-[200px] pl-[8px] ml-[8px] border-border"
    >
      @for (item of transformTypesDataSource; track item.id) {
        <itq-mat-button
          [size]="Sizes.LG"
          [padding]="Sizes['X-SM']"
          [type]="'button'"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onCreateWidget(item.id)"
        >
          <div class="flex items-center space-x-1 w-full">
            @if (item.iconType === IconType.SVG) {
              <div class="flex justify-center w-[24px]">
                <itq-svg [icon]="item.icon" class="w-3 h-3"></itq-svg>
              </div>
            } @else {
              <fa-icon [icon]="['far', item.icon]"></fa-icon>
            }
            <span class="pt-[4px] font-normal whitespace-nowrap">
              {{ item.name }}
            </span>
          </div>
        </itq-mat-button>
      }
    </div>
  }
  @if (selectedSection === 'tag') {
    <div class="flex flex-col border-l pl-[8px] ml-[8px] border-border">
      <itq-timeline-tag-actions
        [widget]="widget"
        [taggingStatus]="taggingStatus"
        [count]="count"
        [dates]="initialState?.where"
      ></itq-timeline-tag-actions>
    </div>
  }

  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</div>
