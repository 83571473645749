<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center space-x-4">
      <div class="flex space-x-2 items-center">
        <i class="ph ph-binoculars text-5xl"></i>
        <h1 class="text-28 font-medium">
          {{ channel }}
        </h1>
      </div>
      <itq-pill
        [class]="
          'rounded-2xl px-4 py-2 ml-2 shadow-none font-medium bg-primary-over uppercase h-[36px]'
        "
        [type]="PillType.CUSTOM"
        >CHANNEL</itq-pill
      >
    </div>
    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <itq-label
          [size]="'text-lg'"
          [icon]="'ph-arrows-clockwise'"
          [label]="'REFRESH'"
        ></itq-label>
      </itq-mat-button>
      @if (allowDrilldown) {
        <itq-mat-button
          [materialStyle]="'stroked'"
          (clickEvent)="onCreateTag()"
        >
          <itq-label
            [size]="'text-lg'"
            [icon]="'ph-plus'"
            [label]="'CONVERT TO TAG'"
          ></itq-label>
        </itq-mat-button>
      }
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <i class="ph ph-x text-2xl"></i
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ count | number }}</itq-pill
        >
      </div>

      <div class="flex flex-col px-7 pt-4 pb-7 overflow-auto">
        <h2 class="flex font-medium pb-2 text-xl">Search</h2>
        <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
        <itq-input
          class="w-full pb-4"
          [icon]="'search'"
          [placeholder]="'Search...'"
          formControlName="query"
          (search)="onChangeSearch()"
          (clear)="onChangeSearch()"
        ></itq-input>
        <h2 class="flex font-medium pb-4 text-xl">Filters</h2>
        <div class="flex flex-col">
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('actor')?.value,
                'justify-start': !form?.get('actor')?.value
              }"
            >
              ACTOR
              @if (form?.get('actor')?.value?.length > 0) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearActors()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-value-picker
              [queryModel]="this.queryModel"
              [fields]="fields"
              [dates]="this.initialState.where"
              [formControlName]="'actor'"
              [field]="this.actorField"
              (valueChange)="onDataBound(this.initialState)"
            ></itq-value-picker>
          </div>
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('subChannel')?.value,
                'justify-start': !form?.get('subChannel')?.value
              }"
            >
              SUB CHANNEL
              @if (form?.get('subChannel')?.value?.length > 0) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearSubChannel()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-value-picker
              [queryModel]="this.queryModel"
              [dates]="this.initialState.where"
              [fields]="fields"
              [formControlName]="'subChannel'"
              [field]="this.subChannelField"
              (valueChange)="onDataBound(this.initialState)"
            ></itq-value-picker>
          </div>
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('utilities')?.value,
                'justify-start': !form?.get('utilities')?.value
              }"
            >
              UTILITIES
              @if (form?.get('utilities')?.value?.length > 0) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearUtilities()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-multiple-dropdown
              [placeholder]="'Select utilities...'"
              formControlName="utilities"
              [initialState]="utilitiesInitialState"
              [dataSource]="utilitiesDataSource"
              [dataFields]="{ name: 'name', value: 'id' }"
              (changeValue)="onChangeUtilities()"
              (dataBound)="onGetUtilities($event)"
            ></itq-multiple-dropdown>
          </div>
          <div class="flex flex-col">
            <div class="mb-1 text-13 font-medium w-full flex items-center">
              DATE RANGE
            </div>
            <itq-date-range
              class="w-full"
              [dates]="this.initialState?.where"
              (search)="onChangeDate($event)"
            ></itq-date-range>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 border-r border-border flex-col">
      <div class="flex flex-1 overflow-hidden">
        <div class="flex flex-col bg-background border-r border-border flex-1">
          <div
            class="flex flex-col flex-1 h-full w-full sm:max-w-[550px] md:max-w-[650px] lg:min-w-[700px]"
          >
            @if (dataSource) {
              <itq-documents-feed
                class="h-full flex flex-col overflow-hidden"
                [data]="dataSource"
                [initialState]="initialState"
                [query]="form?.get('query')?.value"
                [allowDrilldown]="allowDrilldown"
                [drilldownSegment]="channel"
                (shareEmail)="onShareEmail($event)"
                (scrollEvent)="onDataBound($event)"
                (drilldown)="onDrilldown($event)"
                (createProfile)="onCreateProfile($event)"
              ></itq-documents-feed>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

@if (showLoader) {
  <itq-loader></itq-loader>
}
