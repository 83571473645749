import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import {
  Actions,
  Align,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  IconType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ProfileTypeIcon } from '@portal/profiles/enums/profile.enum';
import { INotesData } from '@portal/profiles/interfaces/profile-note.interface';
import { ILinksData } from '@portal/profiles/interfaces/profile-tags.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileConnection } from '@portal/profiles/models/profile-connection';
import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { NotesService } from '@portal/profiles/services/notes.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ProfilesExploreComponent } from '../profiles-explore/profiles-explore.component';
import { CreateProfileActions } from './profiles-wizard.enum';
@Component({
  selector: 'itq-profiles-wizard',
  templateUrl: './profiles-wizard.component.html',
  styleUrls: ['./profiles-wizard.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
  ],
})
export class ProfilesWizardComponent implements OnInit {
  @Input() initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  @Input() linkTag: LinkTag;
  @Input() profile: Profile;
  @Input() connections: Array<ProfileConnection>;
  @Input() notes: Array<ProfileNote>;

  public form: FormGroup;
  public showAddTags = true;
  private showAddNotesSubscription: Subscription;
  private showAddConnectionsSubscription: Subscription;
  private notesSubscription: Subscription;
  private showLinkTagsSubscription: Subscription;
  public showLinkTags: boolean;
  public action: Actions;

  readonly Actions = Actions;
  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly CreateProfileActions = CreateProfileActions;

  @ViewChild('stepper')
  stepper: MatStepper;

  constructor(
    private snackBar: MatSnackBar,
    public profileService: ProfileService,
    private customOverlayRef: CustomOverlayRef,
    private noteService: NotesService,
    public linkTagsService: LinkTagsService,
    public connectionsService: ConnectionsService,
    private customOverlayService: CustomOverlayService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.noteService.getNoteTypes(this.profile.profileTypeId).subscribe();
    this.createForm();

    this.showLinkTagsSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.form.removeControl('addTags');
        }
        this.showLinkTags = response;
      },
    );
    this.showAddConnectionsSubscription =
      this.connectionsService.showAddConnections$.subscribe(
        (response: Actions) => {
          if (!response) {
            this.form.removeControl('addConnections');
          }
          this.action = response;
        },
      );
    this.showAddNotesSubscription = this.noteService.showAddNotes$.subscribe(
      (response: Actions) => {
        if (!response) {
          this.form.removeControl('addNotes');
        }
        this.action = response;
      },
    );

    this.notesSubscription = this.noteService.notes$.subscribe(() => {
      this.notesSubscription.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.showAddNotesSubscription.unsubscribe();
    this.notesSubscription.unsubscribe();
    this.showAddConnectionsSubscription.unsubscribe();
    this.showLinkTagsSubscription.unsubscribe();
    this.linkTagsService.links = { items: [], totalCount: undefined };
    this.connectionsService.connections = { items: [], totalCount: undefined };
    this.noteService.notes = { items: [], totalCount: undefined };
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public markDetailsAsTouched(): void {
    this.form.get('details').markAllAsTouched();
    this.form.get('metadata').markAllAsTouched();
  }

  private createForm(): void {
    this.form = new FormGroup({
      links: new FormControl(undefined, undefined, [
        this.linkTagsService.validateLinks(),
      ]),
      connections: new FormControl(undefined),
      notes: new FormControl(undefined),
    });
  }

  private saveProfile(): Observable<Profile> {
    const detailsGroup = this.form.get('details') as FormGroup;
    const metadata = this.getMetadata();
    return this.profileService.save({
      ecosystemId: this.userService.userPreferences.defaultEcosystemId,
      name: detailsGroup.controls.name.value,
      profileTypeId: this.profile.profileTypeId,
      description: detailsGroup.controls.description.value,
      metadata: metadata,
    });
  }

  private getMetadata(): Array<ProfileMetadata> {
    const metadataGroup = this.form.get('metadata') as FormGroup;
    let result: Array<ProfileMetadata> = [];
    Object.keys(metadataGroup.controls).forEach((item: string) => {
      if (metadataGroup.controls[item].value) {
        const typeMetadata = this.profileService.typeMetadata.find(
          (typeMetadata: ProfileTypeMetadata) => {
            return item === typeMetadata.id;
          },
        );
        result.push(
          new ProfileMetadata(
            typeMetadata.id,
            typeMetadata.name,
            JSON.stringify(
              typeof metadataGroup.controls[item].value === 'number'
                ? metadataGroup.controls[item].value.toString()
                : metadataGroup.controls[item].value,
            ),
            typeMetadata.component,
            typeMetadata.groupName,
          ),
        );
      }
    });
    return result;
  }

  private connectProfiles(
    profile: Profile,
    connections: Array<ProfileConnection>,
  ): Observable<Array<ProfileConnection>> {
    if (this.connectionsService.connections?.items?.length === 0) {
      return of(undefined);
    } else {
      return this.connectionsService.addConnections(profile, connections);
    }
  }

  public onSubmit(action: CreateProfileActions): void {
    this.profileService.loader$.next(true);
    this.saveProfile().subscribe((response: Profile) => {
      forkJoin([
        this.connectProfiles(
          response,
          this.connectionsService.connections?.items,
        ),
        this.addNotes(response, this.noteService.notes.items),
        this.linkTags(response.profileId, this.linkTagsService.links.items),
      ]).subscribe(() => {
        this.snackBar.open(
          'Your profile has been created, and is available for immediate use!',
          'Close',
          {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          },
        );
        this.profileService.loader$.next(false);
        if (action === CreateProfileActions.CREATE_NEW) {
          this.onReset();
          this.profileService.getProfiles$.next();
        } else if (action === CreateProfileActions.LOAD) {
          this.customOverlayRef.close({ refresh: true });
          this.onView(response);
        } else {
          this.customOverlayRef.close({ refresh: true });
        }
      });
    });
  }

  public onView(profile: Profile): void {
    if (profile) {
      const typeName = this.profileService.getProfileTypeById(
        profile.profileTypeId,
      )?.name;
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: ProfilesExploreComponent,
            inputs: {
              profile,
              icon: ProfileTypeIcon[typeName],
              action: Actions.EXPLORE,
            },
          },
        },
        closeBtnStyle: 'basic',
        closeBtnClass: 'hidden',
        type: CustomOverlayType['almost-full'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    }
  }

  private onReset(): void {
    this.connectionsService.resetConnections();
    this.noteService.resetNotes();
    this.linkTagsService.resetLinks();
    this.profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      undefined,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      this.profile.profileTypeId,
      this.profile.profileTypeName,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    this.stepper.reset();
    this.linkTagsService.showLinkTag$.next(false);
    this.connectionsService.showAddConnections$.next();
    this.noteService.showAddNotes$.next();
    this.linkTagsService.reset$.next();
    this.connectionsService.reset$.next();
    this.noteService.reset$.next();
    this.form.get('details').reset();
    this.form.get('metadata').reset();
    this.form.get('connections').reset();
    this.form.get('links').reset();
    this.form.get('notes').reset();
  }

  private addNotes(
    profile: Profile,
    notes: Array<ProfileNote>,
  ): Observable<INotesData> {
    if (this.noteService.notes.items?.length === 0) {
      return of(undefined);
    } else {
      return this.noteService.addNotes(profile, profile, notes);
    }
  }

  private linkTags(
    profileId: string,
    tags: Array<LinkTag>,
  ): Observable<ILinksData> {
    if (tags?.length === 0) {
      return of(undefined);
    } else {
      return this.linkTagsService.linkTags(profileId, tags);
    }
  }
}
