<form
  [formGroup]="form"
  class="flex h-full flex-col w-full md:min-w-[750px] lg:min-w-[910px] xl:min-w-[1100px] 2xl:min-w-[1400px]"
>
  <div
    class="flex items-center p-7 border-b border-border justify-between w-full"
  >
    <div class="flex justify-between w-full items-center">
      <div class="flex items-center space-x-2">
        <i class="ph ph-magnifying-glass ph-bold text-3xl"></i>
        <h1 class="text-28 font-medium pt-[4px]">Search</h1>
      </div>
      <div class="flex space-x-2">
        <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
          <itq-label
            [label]="'REFRESH'"
            [icon]="'ph-arrows-clockwise'"
            [bold]="true"
          ></itq-label>
        </itq-mat-button>
        <itq-mat-button
          [disabled]="query?.hasEmptyRules() || !query?.hasRules()"
          [materialStyle]="'stroked'"
          (clickEvent)="onCreateTag()"
        >
          <itq-label
            [label]="'CONVERT TO TAG'"
            [icon]="'ph-plus'"
            [bold]="true"
          ></itq-label>
        </itq-mat-button>
        <itq-mat-button
          [matTooltip]="'Close'"
          [materialStyle]="'basic'"
          [type]="'button'"
          (clickEvent)="onClose()"
        >
          <i class="ph ph-x text-2xl"></i
        ></itq-mat-button>
      </div>
    </div>
  </div>
  <div
    class="flex w-full bg-background rounded-md flex-1 h-full overflow-auto"
    cdkScrollable
  >
    <div class="flex min-w-[1180px] w-full">
      <div class="flex flex-col flex-[2] overflow-auto">
        <div
          class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
        >
          <span class="font-medium pr-2">Build your search query:</span>
          <div class="flex space-x-2">
            @if (query?.hasRules()) {
              <itq-mat-button
                [materialStyle]="'stroked'"
                [type]="'button'"
                (clickEvent)="onResetQuery()"
              >
                <itq-label
                  [label]="'RESET QUERY'"
                  [icon]="'ph-funnel-x'"
                  [bold]="true"
                ></itq-label>
              </itq-mat-button>
            }
            <itq-date-range
              class="flex"
              [dates]="this.initialState?.where"
              (search)="onChangeDate($event)"
            ></itq-date-range>
          </div>
        </div>

        <div
          class="flex justify-between flex-col border-border border-b relative overflow-hidden flex-1"
        >
          @if (fields?.length > 0) {
            <itq-query-builder
              class="px-7 py-4 overflow-auto"
              [initialState]="initialState"
              [userTagCategory]="userTagCategory"
              [queryModel]="query"
              [fieldsDataSource]="fields"
              [formControlName]="'query'"
              [scrollableContainer]="scrollableContainer"
              (dataBound)="onDataBound($event)"
            ></itq-query-builder>
          }
        </div>
      </div>
      <div class="flex flex-col overflow-hidden border-l border-border flex-1">
        <div
          class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
        >
          <span class="font-medium pr-2">Matching content</span>
          <itq-pill
            [type]="PillType.CUSTOM"
            [class]="'secondary rounded-md h-[36px]'"
            >{{ (dataSource?.count | number) || 0 }}</itq-pill
          >
        </div>
        <div class="flex flex-1 overflow-hidden">
          @if (query?.hasRules()) {
            <itq-documents-feed
              class="h-full flex flex-col overflow-hidden w-full"
              [data]="dataSource"
              [initialState]="initialState"
              (shareEmail)="onShareEmail($event)"
              (scrollEvent)="onScroll($event)"
              (drilldown)="onDrilldown($event)"
              (createProfile)="onCreateProfile($event)"
            ></itq-documents-feed>
          } @else {
            <div class="flex w-full justify-center items-center">
              <itq-panel-action
                class="my-7 flex w-full px-7"
                [label]="'No filters selected!'"
                [description]="
                  'Type in the search box and add some filters for targeted results...'
                "
              ></itq-panel-action>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader></itq-loader>
}
