<ng-container *ngFor="let groupedDay of events | groupByDate; let i = index">
  <div
    [ngClass]="{
      'flex w-full event-item': true,
      'max-w-[300px] flex-col': orientation === TimelineOrientation.HORIZONTAL
    }"
  >
    <div
      [ngClass]="{
        'flex flex-1 justify-end cursor-pointer': true,
        'items-center': orientation === TimelineOrientation.VERTICAL,
        'items-end': orientation === TimelineOrientation.HORIZONTAL
      }"
    >
      <ng-container
        *ngIf="
          groupedDay?.events
            | groupByTeam: EventTeams.SECURITY_TEAM as secrityTeamEvents
        "
      >
        @if (secrityTeamEvents?.length > 0) {
          <div
            [ngClass]="{
              'flex flex-1 items-center w-full overflow-auto mb-4  rounded-md shadow-main-container  border-link border-l-8 max-w-[300px] min-w-[250px] cursor-pointer': true,
              'my-2': orientation === TimelineOrientation.VERTICAL,
              'mx-2': orientation === TimelineOrientation.HORIZONTAL,
              'bg-background': segment?.name !== groupedDay?.date,
              'bg-primary': segment?.name === groupedDay?.date
            }"
          >
            <div class="flex p-2 w-full h-full">
              <itq-event
                class="flex w-full"
                [events]="secrityTeamEvents"
                [allowDrilldown]="allowDrilldown"
              ></itq-event>
            </div>
          </div>
        }
      </ng-container>
    </div>
    <div
      [ngClass]="{
        'justify-center flex': true,
        'flex-col mx-2':
          orientation === TimelineOrientation.VERTICAL ? true : false
      }"
    >
      <div
        [ngClass]="{
          'flex w-full h-full items-center': true,
          'flex-col':
            orientation === TimelineOrientation.VERTICAL ? true : false,
          'justify-center':
            orientation === TimelineOrientation.HORIZONTAL ? true : false
        }"
      >
        <div
          [ngClass]="{
            'bg-border': true,
            'flex-1 h-[2px]': orientation === TimelineOrientation.HORIZONTAL,
            'flex-50-50-0 w-[2px]': orientation === TimelineOrientation.VERTICAL
          }"
        ></div>
        <div
          [ngClass]="{
            'flex items-center flex-col': true
          }"
        >
          <span
            [ngClass]="{
              'px-2': true
            }"
          >
            {{ groupedDay?.date }}
          </span>
          <div
            [ngClass]="{
              'flex py-4 justify-center items-center relative h-[10px]': true,
              'w-[10px]': orientation === TimelineOrientation.VERTICAL,
              'w-[35px]': orientation === TimelineOrientation.HORIZONTAL
            }"
          >
            <div class="absolute w-[1rem] h-[1rem] bg-primary rounded-lg"></div>
          </div>
          @if (orientation === TimelineOrientation.HORIZONTAL) {
            <span
              [ngClass]="{
                'px-2 invisible': true
              }"
            >
              {{ groupedDay?.date }}
            </span>
          }
        </div>
        <div
          [ngClass]="{
            'bg-border line last-line': true,
            'flex-1 h-[2px]': orientation === TimelineOrientation.HORIZONTAL,
            'flex-50-50-0 w-[2px]': orientation === TimelineOrientation.VERTICAL
          }"
        ></div>
      </div>
    </div>
    <div
      [ngClass]="{
        'flex flex-1 justify-start cursor-pointer': true,
        'items-center': orientation === TimelineOrientation.VERTICAL,
        'items-start': orientation === TimelineOrientation.HORIZONTAL
      }"
    >
      <ng-container
        *ngIf="
          groupedDay?.events
            | groupByTeam: EventTeams.CHEATER_TEAM as cheaterTeamEvents
        "
      >
        @if (cheaterTeamEvents?.length > 0) {
          <div
            [ngClass]="{
              'flex flex-1 items-center w-full overflow-auto rounded-md shadow-main-container border-danger border-l-8 max-w-[300px] min-w-[250px] cursor-pointer': true,
              'my-2': orientation === TimelineOrientation.VERTICAL,
              'mx-2': orientation === TimelineOrientation.HORIZONTAL,
              'bg-background': !segment || segment?.name !== groupedDay?.date,
              'bg-primary': segment?.name === groupedDay?.date
            }"
          >
            <div class="flex p-4 w-full h-full">
              <itq-event
                class="flex w-full"
                [events]="cheaterTeamEvents"
                [allowDrilldown]="allowDrilldown"
              ></itq-event>
            </div>
          </div>
        }
      </ng-container>
    </div>
  </div>
</ng-container>
