<itq-default-navigation></itq-default-navigation>
<main role="main" class="flex justify-between">
  <router-outlet></router-outlet>
  <itq-navigation-aside
    class="flex h-full flex-col space-y-4 border-l border-border py-4 items-center"
  ></itq-navigation-aside>

  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</main>
