<div class="flex flex-col w-full mb-7">
  <div class="flex items-center space-x-2">
    <i [ngClass]="['ph', 'ph-note', 'text-5xl']"></i>
    <h1 class="title pt-[8px]">
      {{ note | getNoteValue }}
    </h1>
  </div>
</div>
<div class="view_note__container">
  <ng-template dynamicPlaceholder></ng-template>
</div>
<div class="view_note__footer">
  <itq-mat-button [materialStyle]="'basic'" (clickEvent)="onClose()">
    <span class="whitespace-nowrap">CLOSE</span>
  </itq-mat-button>
</div>
