import { CdkScrollable, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
  SharedService,
  UserTagCategory,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { QueryBuilderComponent } from '../query-builder/query-builder.component';

@Component({
  selector: 'itq-tag-wizard-filters',
  templateUrl: './tag-wizard-filters.component.html',
  styleUrls: ['./tag-wizard-filters.component.scss'],
  standalone: true,
  imports: [
    QueryBuilderComponent,
    CoreModule,
    DocumentsFeedComponent,
    CommonModule,
    DateRangeComponent,

    FAwesomeModule,
    ReactiveFormsModule,
    ScrollingModule,
    LabelComponent,
  ],
})
export class TagWizardFiltersComponent implements OnInit {
  @Input() initialState: QueryFilters;
  @Input() form: FormGroup;
  @Input() tag: Tag;
  @Input() queryModel: QueryBuilderModel;
  @Input() fields: Array<IFilterField>;

  @ViewChild(CdkScrollable) scrollableContainer: CdkScrollable;

  private _dataSource: IData;
  public showLoader = false;
  public currentBreakpoint: string;

  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource = {
        result: [...this._dataSource.result, ...value.result],
        count: value.count,
      };
    } else {
      this._dataSource = value;
    }
  }

  readonly PillType = PillType;
  readonly UserTagCategory = UserTagCategory;

  constructor(
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoryService: CategoryService,
    readonly profileService: ProfileService,
    readonly sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.addControls();
    this.onDataBound(this.queryModel);
  }

  private addControls(): void {
    this.form.addControl(
      'filters',
      new FormGroup({
        query: new FormControl(
          this.queryModel?.hasRules() ? this.queryModel : undefined,
          [Validators.required, this.isQueryValid()],
        ),
      }),
    );
  }

  private isQueryValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isQueryValid = this.queryModel.hasRules();
      return isQueryValid ? null : { invalidQuery: true };
    };
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(queryModel: QueryBuilderModel): void {
    this.queryModel = queryModel;
    if (this.queryModel?.hasRules()) {
      this.tagService.loader$.next(true);
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.dataSource = response;
          this.tagService.loader$.next(false);
        });
    } else {
      this.dataSource = undefined;
    }
  }

  public onScroll(params: QueryFilters): void {
    this.initialState = params;
    this.onDataBound(this.queryModel);
  }

  public onChangeDate(params: IPresetQuery): void {
    this.initialState.where = params;
    this.onDataBound(this.queryModel);
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      default:
        break;
    }
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordActorComponent,
          inputs: {
            actor: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownSubChannelComponent,
          inputs: {
            subChannel,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            allowDrilldown: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onResetQuery(): void {
    this.initializeQuery();
    this.onDataBound(this.queryModel);
  }

  private initializeQuery(): void {
    this.queryModel = new QueryBuilderModel(
      [
        {
          entity: 'content',
          field: 'All Text_Search within',
          operator: 'contains',
          value: '',
        },
      ],
      'and',
    );
  }
}
