<form class="flex flex-col w-full h-full" [formGroup]="form">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'plus']" [size]="'2x'"></fa-icon>
      <h1 class="text-28 font-medium pl-4">Create profile</h1>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
  <div class="flex px-7 py-4 flex-1 bg-background">
    <mat-stepper
      orientation="vertical"
      (selectionChange)="action = undefined"
      #stepper
    >
      <mat-step
        state="info"
        [stepControl]="form?.controls?.details"
        [errorMessage]="
          form?.get('details.name')?.hasError('required')
            ? 'Name is a required field'
            : 'Vendor name already exists'
        "
      >
        <ng-template matStepLabel>Tell us about your profile</ng-template>
        <itq-profile-wizard-details
          class="left__stepper"
          [linkTag]="linkTag"
          [profile]="profile"
          [form]="form"
        ></itq-profile-wizard-details>
        <div class="stepper_footer justify-end">
          <button
            [disabled]="!form?.controls?.details?.valid"
            type="button"
            mat-button
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step
        state="metadata"
        [optional]="form.get('metadata') | isOptional"
        [stepControl]="form.get('metadata')"
        [errorMessage]="'Metadata is a required field'"
      >
        <ng-template matStepLabel>Add descriptive data</ng-template>
        <itq-profiles-wizard-metadata
          class="left__stepper"
          [profile]="profile"
          [form]="form"
        ></itq-profiles-wizard-metadata>
        <div class="stepper_footer">
          <button mat-button matStepperPrevious type="button">Previous</button>
          <button
            [disabled]="!form?.controls?.metadata?.valid"
            type="button"
            mat-button
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step
        state="content"
        label="Link tags"
        [optional]="true"
        [stepControl]="
          form?.controls.links?.touched &&
          form?.controls.links?.errors?.invalidDefaultTab
            ? form?.controls?.links
            : null
        "
        [errorMessage]="'You need to set a default tab'"
      >
        <ng-template matStepLabel>Link this profile to tags</ng-template>
        <itq-profiles-links-wizard
          [formControlName]="'links'"
          [profile]="profile"
          [linkTag]="linkTag"
          [form]="form"
        ></itq-profiles-links-wizard
        ><mat-error
          class="error_container"
          *ngIf="
            form?.controls.links?.touched &&
            form?.controls.links?.errors?.invalidDefaultTab
          "
        >
          You need to set a default tab
        </mat-error>

        <div class="stepper_footer" *ngIf="!showLinkTags">
          <button mat-button matStepperPrevious type="button">Previous</button>
          <button
            *ngIf="
              linkTagsService.links?.items?.length === 0;
              else nextTemplate
            "
            type="button"
            mat-button
            matStepperNext
          >
            Skip
          </button>
          <ng-template #nextTemplate>
            <button mat-button matStepperNext type="button">Next</button>
          </ng-template>
        </div>
      </mat-step>
      <mat-step state="links" [optional]="true">
        <ng-template matStepLabel>Connect to other vendors</ng-template>
        <ng-template matStepContent>
          <itq-profiles-connections-wizard
            [formControlName]="'connections'"
            [action]="action"
            [profile]="profile"
            [connections]="connections"
            [form]="form"
          ></itq-profiles-connections-wizard>

          <div class="stepper_footer" *ngIf="!action">
            <button mat-button matStepperPrevious type="button">
              Previous
            </button>
            <button
              *ngIf="
                connectionsService.connections?.items?.length === 0;
                else nextTemplate
              "
              type="button"
              mat-button
              matStepperNext
            >
              Skip
            </button>
            <ng-template #nextTemplate>
              <button mat-button matStepperNext type="button">Next</button>
            </ng-template>
          </div>
        </ng-template>
      </mat-step>
      <mat-step state="notes" [optional]="true">
        <ng-template matStepLabel>Notes</ng-template>
        <ng-template matStepContent>
          <itq-profiles-notes-wizard
            [formControlName]="'notes'"
            [profile]="profile"
            [notes]="notes"
            [form]="form"
          ></itq-profiles-notes-wizard>
          <div class="stepper_footer justify-start" *ngIf="!action">
            <button mat-button matStepperPrevious>Previous</button>
          </div>
        </ng-template>
      </mat-step>
      <ng-template matStepperIcon="info">
        <i class="ph ph-info-circle text-lg"></i>
      </ng-template>
      <ng-template matStepperIcon="content">
        <i class="ph ph-tag text-lg"></i>
      </ng-template>
      <ng-template matStepperIcon="metadata">
        <i class="ph ph-text-align-left text-lg"></i>
      </ng-template>
      <ng-template matStepperIcon="links">
        <i class="ph ph-tree-view text-lg"></i>
      </ng-template>
      <ng-template matStepperIcon="notes">
        <i class="ph ph-note text-lg"></i>
      </ng-template>
    </mat-stepper>
  </div>
  <footer class="flex justify-end p-4 border-t border-border">
    <itq-nav-button
      [disabled]="
        !this.form.controls.details?.valid ||
        !this.form.controls.metadata.valid ||
        !this.form.controls.connections.valid ||
        !this.form.controls.links.valid ||
        !this.form.controls.notes.valid
      "
      [template]="actionsTemplate"
      [materialStyle]="'flat'"
      [color]="'primary'"
      [padding]="Sizes.MD"
      >SAVE</itq-nav-button
    >
  </footer>
  <ng-template #actionsTemplate>
    <div class="flex flex-col">
      <itq-mat-button
        [size]="Sizes.LG"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onSubmit(CreateProfileActions.LOAD)"
      >
        <span class="whitespace-nowrap font-normal">Save & Load</span>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.LG"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onSubmit(CreateProfileActions.CLOSE)"
      >
        <span class="whitespace-nowrap font-normal">Save & Close</span>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.LG"
        [padding]="Sizes.SM"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onSubmit(CreateProfileActions.CREATE_NEW)"
      >
        <span class="whitespace-nowrap font-normal">Save & Create New</span>
      </itq-mat-button>
    </div>
  </ng-template>
</form>
