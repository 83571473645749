<aside #sidebar id="default-sidebar" class="sidebar-container">
  <div class="sidebar__header flex flex-1 mr-7">
    <itq-mat-button
      [ngClass]="{ '2xl:hidden': true, hidden: router.url === '/library' }"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onToggleSidebar()"
    >
      <i class="ph ph-list text-3xl text-white"></i>
    </itq-mat-button>
    <a
      [ngClass]="{
        'logo 2xl:ml-4 items-center flex justify-center font-medium font-mont': true,
        'ml-4': router.url === '/library'
      }"
      [routerLink]="['/']"
    >
      <itq-svg class="w-9 h-9 mr-1 fill-primary" [icon]="'logo'"></itq-svg>
      <span class="text-white ml-1 text-base hidden xl:flex">Intorqa</span>
    </a>
  </div>
  <div class="flex items-center">
    <div class="flex items-center mr-7">
      <span class="text-white pr-2 font-medium">ECOSYSTEM:</span>
      <itq-dropdown
        [(ngModel)]="ecosystemId"
        [dataSource]="ecosystemDataSource"
        [width]="'250'"
        [clearSelection]="false"
        [dataFields]="{ name: 'name', value: 'id' }"
        (changeValue)="onChangeEcosystem()"
      ></itq-dropdown>
    </div>
    <nav class="sidebar__body flex-1">
      <ul class="justify-center text-15 space-x-4 2xl:space-x-7">
        <li class="flex justify-center">
          <a
            class="font-medium sidebar__icon items-center"
            [routerLinkActive]="'active'"
            [routerLink]="['/boards']"
          >
            <itq-svg
              class="w-6 h-6 mr-1 fill-current"
              [icon]="'boards'"
            ></itq-svg>
            <span class="whitespace-nowrap pt-[2px]">BOARDS</span>
          </a>
        </li>
        <li class="flex justify-center">
          <a
            class="font-medium sidebar__icon items-center"
            [routerLinkActive]="'active'"
            [routerLink]="['/library']"
          >
            <itq-label
              [label]="'LIBRARY'"
              [icon]="'ph-diamonds-four'"
              [size]="'text-2xl'"
            ></itq-label>
          </a>
        </li>
        @if (this.keycloakService.isUserInRole('saas-profiles')) {
          <li class="flex justify-center">
            <a
              class="font-medium sidebar__icon items-center"
              [routerLinkActive]="'active'"
              [routerLink]="['/manager/vendors']"
            >
              <itq-label
                [label]="'VENDORS'"
                [icon]="'ph-storefront'"
                [size]="'text-2xl'"
              ></itq-label>
            </a>
          </li>
        }
      </ul>
    </nav>
  </div>
  <div class="sidebar__footer flex-1 justify-end ml-7 space-x-2">
    <itq-mat-button
      [type]="'button'"
      [style]="'secondary'"
      [materialStyle]="'flat'"
      (clickEvent)="onCreateSearch()"
    >
      <itq-label
        [label]="'SEARCH'"
        [size]="'text-lg'"
        [bold]="true"
        [icon]="'ph-magnifying-glass'"
      ></itq-label>
    </itq-mat-button>

    <itq-user-account class="mr-2"></itq-user-account>
  </div>
</aside>
