import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { ValueFiltersComponent } from '@portal/tags/components/value-filters/value-filters.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';

@Component({
  selector: 'itq-value-picker',
  templateUrl: './value-picker.component.html',
  styleUrl: './value-picker.component.scss',
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    ValueFiltersComponent,
    FormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValuePickerComponent,
    },
  ],
})
export class ValuePickerComponent {
  @Input() dates: IPresetQuery;
  @Input() field: IFilterField;
  @Input() fields: Array<IFilterField>;

  private _queryModel: QueryBuilderModel;

  @Input()
  set queryModel(value: Query) {
    this._queryModel = value?.convertToQueryBuilderModel();
  }

  get queryModel(): QueryBuilderModel {
    return this._queryModel;
  }

  @Output() valueChange = new EventEmitter<void>();

  private touched = false;
  private disabled = false;
  public selections: Array<ICustomTag> = [];
  public preFilter: string;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor(
    readonly categoryService: CategoryService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.initialState.where = this.dates;
  }

  onChange = (items: Array<ICustomTag>) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ICustomTag>): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dates) {
      this.initialState.where = this.dates;
    }
    if (changes?.field?.currentValue) {
      this.onGetPreFilter();
    }
  }

  private onGetPreFilter(): void {
    this.preFilter = this.field ? this.field.preFilter : undefined;
  }

  public onRemoveValue(tag: ICustomTag): void {
    this.selections = this.selections.filter(
      (item: ICustomTag) => item.id !== tag.id,
    );
    this.onChange(this.selections);
    this.valueChange.emit();
  }

  public onFilterValuesSelectionChange(values: Array<ICustomTag>): void {
    this.selections = values;
    this.onChange(values);
    this.valueChange.emit();
  }
}
