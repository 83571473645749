<form [formGroup]="form" class="flex flex-col h-full">
  <ng-container [formGroupName]="'addTags'">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <fa-icon
            [size]="'2x'"
            [icon]="['far', action === Actions.CREATE ? 'plus' : 'pencil']"
          ></fa-icon>
          <h1 class="text-28 font-medium">
            {{ action === Actions.CREATE ? 'Link tag' : 'Edit link' }}
          </h1>
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <i class="ph ph-x text-2xl"></i
      ></itq-mat-button>
    </div>
    <div class="flex flex-col p-7 bg-background flex-1">
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">LINK</span>
        <itq-nav-button
          #dataSourceButton
          class="w-full"
          [size]="Sizes.BLOCK"
          [materialStyle]="'stroked'"
          [template]="dataSourceTemplate"
        >
          <span class="font-normal flex items-center w-full justify-between">
            @if (form?.get('addTags.linkType')?.value) {
              <span>{{ form?.get('addTags.linkType')?.value.name }}</span>
            } @else {
              <span class="text-gray-400">Pick a link...</span>
            }
            <fa-icon
              class="border-l border-border pl-1 ml-2"
              [icon]="
                dataSourceButton.isOpen
                  ? ['far', 'chevron-up']
                  : ['far', 'chevron-down']
              "
            ></fa-icon>
          </span>
        </itq-nav-button>
        <ng-template #dataSourceTemplate>
          <itq-link-tags-type
            class="flex"
            formControlName="linkType"
            [field]="link.tagId"
            [field]="link.tagId"
            [selectedLinkType]="link.type"
            (selectionChange)="onChangeLink($event)"
          ></itq-link-tags-type>
        </ng-template>
        @if (
          form.controls['addTags.linkType']?.touched &&
          form.controls['addTags.linkType']?.hasError('required')
        ) {
          <mat-error class="text-13 pt-1">Link is a required field</mat-error>
        }
      </div>

      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">DESCRIPTION</span>
        <itq-input
          [formControlName]="'description'"
          [placeholder]="'Please add a description...'"
          (search)="onChangeDescription()"
        ></itq-input>
      </div>

      <div class="flex flex-col">
        <mat-slide-toggle
          class="px-4 py-2 rounded-full bg-primary-over border border-primary mb-4"
          [color]="'primary'"
          [formControlName]="'default'"
          (change)="onToggleChange()"
          >Default tag</mat-slide-toggle
        >
      </div>
    </div>

    <footer class="flex justify-end px-7 py-4 bg-white border-t border-border">
      @if (action === Actions.CREATE) {
        <itq-nav-button
          [materialStyle]="'flat'"
          [color]="'primary'"
          [padding]="Sizes.MD"
          [disabled]="!this.form?.get('addTags').valid"
          [template]="saveTemplate"
          >SAVE</itq-nav-button
        >
        <ng-template #saveTemplate>
          <div class="flex flex-col w-full">
            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.LG"
              [align]="Align.START"
              (clickEvent)="onCreate()"
            >
              <itq-label
                [label]="'Create new'"
                [icon]="'ph-plus'"
                class="font-normal"
              ></itq-label>
            </itq-mat-button>
            <itq-mat-button
              [padding]="Sizes['X-SM']"
              [materialStyle]="'basic'"
              [size]="Sizes.LG"
              [align]="Align.START"
              (clickEvent)="onAdd()"
            >
              <itq-label
                [label]="'Add to profile'"
                [icon]="'ph-plus'"
                class="font-normal"
              ></itq-label>
            </itq-mat-button>
          </div>
        </ng-template>
      } @else {
        <itq-mat-button
          [disabled]="!this.form?.get('addTags')?.valid"
          [materialStyle]="'flat'"
          [style]="'primary'"
          (clickEvent)="onUpdate()"
        >
          <span class="whitespace-nowrap">UPDATE</span>
        </itq-mat-button>
      }
    </footer>
  </ng-container>
</form>
