import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'itq-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
    provideMomentDateAdapter(MY_FORMATS),
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() disabled = false;
  @Input() placeholder = 'Choose a date...';

  @Output() changeValue = new EventEmitter<moment.Moment>();

  private touched = false;
  public dateValue: moment.Moment;

  constructor() {}

  ngOnInit() {}

  onChange = (items: moment.Moment) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(value: moment.Moment): void {
    this.dateValue = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    const momentDate = moment(event.value);
    this.onChange(momentDate);
    this.changeValue.emit(momentDate);
  }

  public onClear(): void {
    this.dateValue = undefined;
    this.onChange(undefined);
    this.changeValue.emit(undefined);
  }
}
