<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'boardHeader'">
    <div
      class="rounded-md border border-border bg-white flex w-full items-center justify-center px-7 py-5"
    >
      <div class="flex items-center flex-1 space-x-2">
        <itq-svg [icon]="'board'" class="w-12 h-12"></itq-svg>

        <itq-input
          class="board__name flex-1"
          [formControlName]="'name'"
          [matTooltip]="'Please click to edit'"
          [placeholder]="'Please type to add board name'"
          (blurEvent)="onUpdateName()"
        ></itq-input>
        @if (board?.defaultBoard) {
          <i class="ph ph-star text-xl text-primary"></i>
        } @else {
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Set as default'"
            (clickEvent)="onToggleDefault()"
          >
            <i class="ph ph-star text-xl"></i>
          </itq-mat-button>
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Delete Board'"
            (clickEvent)="onDeleteBoard()"
          >
            <i class="ph ph-trash text-xl"></i>
          </itq-mat-button>
        }
      </div>
      @if (!editingDescription) {
        <div
          [ngStyle]="{ minHeight: '36px' }"
          [matTooltip]="'Please click to edit'"
          class="hover:border hover:border-border mx-2 rounded-md flex-1 ml-4 line-clamp-3 items-center flex"
          (click)="onEditDescription()"
        >
          @if (form?.get('boardHeader.description')?.value) {
            <span class="items-center px-2 line-clamp-2 max-h-[45px]">
              {{ form?.get('boardHeader.description')?.value }}
            </span>
          } @else {
            <span class="items-center text-gray-500 text-opacity-75 p-2 flex">
              Please type to add board description
            </span>
          }
        </div>
      }
      <itq-text-area
        [rows]="3"
        [name]="'description'"
        [ngClass]="{
          'flex-1 board__description': true,
          visible: editingDescription,
          'invisible absolute': !editingDescription
        }"
        [formControlName]="'description'"
        placeholder="Please type to add board description"
        (blur)="onUpdateDescription(); editingDescription = false"
      ></itq-text-area>
    </div>
  </ng-container>
</ng-container>
