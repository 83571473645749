import { CurrencyPrefix } from '@intorqa-ui/core';
import { MetadataFields } from '../enums/profiles-metadata.enum';

export const Cheat_Reputation_Map = [
  {
    name: 'Very Poor',
    value: 1,
  },
  {
    name: 'Poor',
    value: 2,
  },
  {
    name: 'Poor',
    value: 3,
  },
  {
    name: 'Poor',
    value: 4,
  },
  {
    name: 'Medium',
    value: 5,
  },
  {
    name: 'Medium',
    value: 6,
  },
  {
    name: 'Medium',
    value: 7,
  },
  {
    name: 'High',
    value: 8,
  },
  {
    name: 'High',
    value: 9,
  },
  {
    name: 'Very High',
    value: 10,
  },
];

export const VISIBLE_COLUNNS = [
  {
    fieldName: 'Cheat',
    columns: [
      {
        name: 'Key Features',
        dataField: 'Cheat Features',
        dataKey: 'Cheat Features',
        width: '300px',
        position: 'left',
        isSortable: true,
        isFilterable: true,
      },
      {
        name: 'Priority',
        dataField: 'Priority',
        dataKey: 'Priority',
        width: '100px',
        position: 'left',
        isSortable: true,
        isFilterable: true,
      },
      {
        name: 'Reputation',
        dataField: 'Cheat Reputation Score',
        dataKey: 'Cheat Reputation Score',
        isSortable: true,
        width: '120px',
        position: 'left',
        isFilterable: true,
      },
      {
        name: 'Status',
        dataField: 'Cheat Status',
        dataKey: 'Cheat Status',
        width: '90px',
        isSortable: true,
        position: 'left',
        isFilterable: true,
      },
      {
        name: `Price Per Month (${CurrencyPrefix.USD})`,
        dataField: MetadataFields.PRICE_PER_MONTH,
        dataKey: MetadataFields.PRICE_PER_MONTH,
        width: '100px',
        isSortable: true,
        position: 'left',
        isFilterable: false,
      },
      {
        name: `Recent Posts`,
        dataField: MetadataFields.RECENT_POSTS,
        dataKey: MetadataFields.RECENT_POSTS,
        width: '100px',
        position: 'left',
        isFilterable: false,
      },
      {
        dataField: 'Pricing currency',
        dataKey: 'Pricing currency',
        isSortable: true,
        show: false,
        isFilterable: true,
      },
    ],
  },
  {
    fieldName: 'Cheat Provider',
    columns: [
      {
        name: 'Location',
        dataKey: 'Inferred Location',
        dataField: 'Inferred Location',
        isSortable: true,
        isFilterable: true,
        width: '150px',
        position: 'left',
      },
      {
        name: 'Market Status',
        dataKey: 'Market Status',
        dataField: 'Market Status',
        isFilterable: true,
        isSortable: true,
        width: '170px',
        position: 'left',
      },
      {
        name: 'Role',
        dataField: 'Role',
        dataKey: 'Role',
        isSortable: true,
        isFilterable: true,
        width: '120px',
        position: 'left',
      },
    ],
  },
  {
    fieldName: 'Actor',
    columns: [
      {
        name: 'Location',
        dataKey: 'Inferred Location',
        dataField: 'Inferred Location',
        isFilterable: true,
        isSortable: true,
      },
      {
        width: '100px',
        dataKey: 'Role',
        name: 'Role',
        dataField: 'Role',
        isSortable: 'Role',
        isFilterable: true,
      },
    ],
  },
];
