import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRangeComponent,
  FAwesomeModule,
  FieldSections,
  IPresetQuery,
  LabelComponent,
  PillType,
  QueryFilters,
  UserTagCategory,
} from '@intorqa-ui/core';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';

import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { IWidgetType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { ECharts } from 'echarts';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { DrilldownActorComponent } from '../drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '../drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '../drilldown-context/drilldown-context.component';
import { DrilldownSubChannelComponent } from '../drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownDiscordActorComponent } from '../drilldown-discord-actor/drilldown-discord-actor.component';
import { TagService } from '@portal/tags/services/tag.service';

@Component({
  selector: 'itq-drilldown-replies',
  templateUrl: './drilldown-replies.component.html',
  styleUrls: ['./drilldown-replies.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,

    LabelComponent,
  ],
})
export class DrilldownRepliesComponent implements OnInit {
  @Input() channel: IExtensionField;
  @Input() allowDrilldown = true;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;

  public form: FormGroup;
  public widgetType: IWidgetType;

  public queryModel: Query;
  private _dataSource: IData;

  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource.result = this._dataSource.result.concat(value.result);
    } else {
      this._dataSource = value;
    }
  }
  public fields: Array<IFilterField>;
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );
  public chartInstance: ECharts;
  private subscription = new Subscription();
  public count = 0;
  public showLoader = true;

  readonly PillType = PillType;

  constructor(
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoriesService: CategoryService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadFields();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadFields(): void {
    this.categoriesService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.onDataBound(this.initialState);
      });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(''),
    });
  }

  public onCreateTag(): void {
    const queryBuilderModel = this.queryModel.convertToQueryBuilderModel();
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: TagFactory.createTagByType(UserTagCategory.Standard.toString()),
        queryModel: queryBuilderModel,
        dates: this.initialState.where,
        action: Actions.CREATE,
      },
    });
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      default:
        break;
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordActorComponent,
      inputs: {
        actor: extensionField,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownSubChannelComponent,
      inputs: {
        subChannel,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        allowDrilldown: this.allowDrilldown,
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    this.initialState = params;
    this.addQueryRules();
    if (this.queryModel.hasRules()) {
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.dataSource = response;
          this.count = this.dataSource.count;
          this.showLoader = false;
        });
    }
  }

  private addQueryRules(): void {
    const contentField = this.getField('All Text', FieldSections.SEARCH_WITHIN);

    this.queryModel = new Query([
      {
        entity: 'replyTo',
        field: 'Reply To',
        operator: 'in',
        value: [this.channel.data?.id],
      },
    ]);

    if (this.form?.get('query')?.value) {
      this.queryModel.addRule({
        entity: contentField.field,
        field: contentField.id,
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    }
  }

  private getField(label: string, section: string): IFilterField {
    return this.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }

  public onSearch(): void {
    this.onDataBound(this.initialState);
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
