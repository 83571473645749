@if (data) {
  <p class="pb-7">This tag cannot be deleted as it is being used by:</p>
  <div
    class="dependency__container flex items-center"
    *ngIf="data?.tags?.length > 0"
  >
    <span><strong>The following tags: </strong></span>
    <mat-chip-set>
      <mat-chip *ngFor="let item of data?.tags">
        {{ item.name }}
      </mat-chip>
    </mat-chip-set>
  </div>

  <div
    class="dependency__container flex items-center"
    *ngIf="data?.boards?.length > 0"
  >
    <span class="dependency__label"
      ><strong>The following boards: </strong></span
    >
    <mat-chip-set>
      <mat-chip *ngFor="let item of data?.boards">
        {{ item.name }}
      </mat-chip>
    </mat-chip-set>
  </div>

  <div
    class="dependency__container flex items-center"
    *ngIf="data?.widgets?.length > 0"
  >
    <span><strong>The following widgets: </strong></span>
    <mat-chip-set>
      <mat-chip *ngFor="let item of data?.widgets">
        {{ item.name }}
      </mat-chip>
    </mat-chip-set>
  </div>

  <div
    class="dependency__container flex items-center"
    *ngIf="data?.profiles?.length > 0"
  >
    <span><strong>The following vendors: </strong></span>
    <mat-chip-set>
      <mat-chip *ngFor="let item of data?.profiles">
        {{ item.name }}
      </mat-chip>
    </mat-chip-set>
  </div>
}
