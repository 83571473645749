<itq-form [formGroup]="form" (submitForm)="onUpdate()">
  <mat-stepper
    orientation="vertical"
    [selectedIndex]="action === ProfileEditAction.DETAILS ? 0 : 1"
  >
    <mat-step
      state="info"
      [stepControl]="form?.controls?.details"
      [errorMessage]="
        form?.get('details.name')?.hasError('required')
          ? 'Name is a required field'
          : 'Vendor name already exists'
      "
    >
      <ng-template matStepLabel>Tell us about your profile</ng-template>
      <itq-profile-wizard-details
        class="left__stepper"
        [action]="action"
        [profile]="profile"
        [form]="form"
      ></itq-profile-wizard-details>
      <div class="stepper_footer justify-end">
        <button
          [disabled]="!form?.controls?.details?.valid"
          type="button"
          mat-button
          matStepperNext
        >
          Next
        </button>
      </div>
    </mat-step>
    <mat-step
      state="metadata"
      [stepControl]="form?.controls?.metadata"
      [errorMessage]="'Metadata is a required field'"
    >
      <ng-template matStepLabel>Add descriptive data</ng-template>
      <itq-profiles-wizard-metadata
        class="left__stepper"
        [profile]="profile"
        [editAction]="editMetadataAction"
        [form]="form"
      ></itq-profiles-wizard-metadata>
      <div class="stepper_footer">
        <button mat-button matStepperPrevious type="button">Previous</button>
      </div>
    </mat-step>
  </mat-stepper>
  <div class="edit_metadata__footer">
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      (clickEvent)="onCancel()"
    >
      CANCEL
    </itq-mat-button>
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
    >
      UPDATE
    </itq-mat-button>
  </div>
</itq-form>
