import moment from 'moment';
import { DateQueryType } from '../enums/date-range.enum';
import { IDateRange, IPreset } from '../interfaces/date-range.interface';

export class DateRangeHelper implements IDateRange {
  constructor(public preset: IPreset) {}

  public static getDatePresets(): Array<IPreset> {
    return [
      {
        label: DateQueryType.Last15Minutes,
        start: moment().subtract(15, 'minutes'),
        end: moment(),
      },
      {
        label: DateQueryType.LastHour,
        start: moment().subtract(1, 'hour'),
        end: moment(),
      },
      {
        label: DateQueryType.Last12Hours,
        start: moment().subtract(12, 'hours'),
        end: moment(),
      },
      {
        label: DateQueryType.LastDay,
        start: moment().subtract(24, 'hours'),
        end: moment(),
      },
      {
        label: DateQueryType.LastWeek,
        start: moment().subtract(1, 'week'),
        end: moment(),
      },
      {
        label: DateQueryType.LastFortnight,
        start: moment().subtract(2, 'weeks'),
        end: moment(),
      },
      {
        label: DateQueryType.LastMonth,
        start: moment().startOf('day').subtract(1, 'month'),
        end: moment().endOf('day'),
      },
      {
        label: DateQueryType.LastQuarter,
        start: moment().startOf('day').subtract(1, 'quarter'),
        end: moment().endOf('day'),
      },
      {
        label: DateQueryType.LastYear,
        start: moment().startOf('day').subtract(1, 'year'),
        end: moment().endOf('day'),
      },
      {
        label: DateQueryType.LastThreeYears,
        start: moment().startOf('day').subtract(3, 'year'),
        end: moment().endOf('day'),
      },
      {
        label: DateQueryType.Custom,
        start: undefined,
        end: undefined,
      },
    ];
  }

  public static getDateQueryText(label: DateQueryType): DateQueryType {
    let result: DateQueryType;
    Object.keys(DateQueryType).forEach((key: DateQueryType) => {
      if (DateQueryType[key] === label) {
        result = key;
      }
    });

    return result;
  }

  public static findPresetByLabel(label: DateQueryType): IPreset {
    const presets = this.getDatePresets();
    return presets.find((item: IPreset) => item.label === label);
  }
}
