<ng-container [formGroup]="form">
  <div class="notes__toolbar">
    <itq-pill
      [type]="PillType.CUSTOM"
      [class]="'secondary rounded-md h-[36px]'"
      >{{ dataSource?.totalCount | number }}</itq-pill
    >
    <div class="toolbar__wrapper">
      <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="onToggle(); isFilterable = !isFilterable"
      >
        <fa-icon class="mr-1" [icon]="['far', 'filter-list']"></fa-icon>
        <span class="whitespace-nowrap">FILTERS</span>
      </itq-button-toggle>
      <itq-mat-button
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onAddNote()"
      >
        <div class="flex items-center">
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="whitespace-nowrap">ADD NOTE</span>
        </div>
      </itq-mat-button>
    </div>
  </div>
  <div class="flex flex-col">
    <itq-virtual-table
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [showFilters]="isFilterable"
      [dataSource]="dataSource?.items | transformNotes"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any notes yet!'"
      [emptyMessageDescription]="'To add a note click the button below.'"
      (dataBound)="onDataBound($event)"
      (rowClick)="onViewNote($event)"
    ></itq-virtual-table>

    <ng-template #valueTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawValue | urlify"
        ></span>
      </div>
    </ng-template>

    <ng-template #notesTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawTextNote | urlify"
        ></span>
        <fa-icon *ngIf="row.documentId" [icon]="['far', 'file']"></fa-icon>
      </div>
    </ng-template>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <div class="flex justify-center w-full">
          <i class="ph ph-dots-three ph-bold"></i>
        </div>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex flex-col w-full">
          <itq-mat-button
            [materialStyle]="'basic'"
            [size]="Sizes.LG"
            [align]="Align.START"
            (clickEvent)="onViewNote(row)"
          >
            <itq-label
              [label]="'View'"
              [icon]="'ph-binoculars'"
              class="font-normal"
            ></itq-label>
          </itq-mat-button>
          <itq-mat-button
            [materialStyle]="'basic'"
            [size]="Sizes.LG"
            [align]="Align.START"
            (clickEvent)="onEditNote(row)"
          >
            <itq-label
              [label]="'Edit'"
              [icon]="'ph-pencil'"
              class="font-normal"
            ></itq-label>
          </itq-mat-button>
          <itq-mat-button
            [size]="Sizes.LG"
            [align]="Align.START"
            [materialStyle]="'basic'"
            (clickEvent)="onDeleteNote(row)"
          >
            <itq-label
              [label]="'Delete'"
              [icon]="'ph-trash'"
              class="font-normal"
            ></itq-label>
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
  </div>
</ng-container>
