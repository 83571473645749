import { Component, Input, OnInit } from '@angular/core';
import {
  CoreModule,
  CustomOverlayRef,
  DateRangeComponent,
  EventTeams,
  GetColumnFilterPipe,
  IEventType,
  IPresetQuery,
  IQueryColumn,
  LabelComponent,
  SearchFieldType,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '@portal/shared/services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { EventsService } from '@portal/shared/services/events.service';

@Component({
  selector: 'itq-events-filters',
  templateUrl: './events-filters.component.html',
  styleUrls: ['./events-filters.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    ReactiveFormsModule,
    LabelComponent,
    DateRangeComponent,
    MatTooltipModule,
    GetColumnFilterPipe,
  ],
})
export class EventsFiltersComponent implements OnInit {
  @Input() columnFilters: Array<IQueryColumn> = [];

  public form: FormGroup;
  public updatedByDataSource: Array<{ name: string; value: string }>;
  public teamsDataSource: Array<{ name: string; value: string }>;
  public typesDataSource: Array<IEventType>;

  readonly SearchFieldType = SearchFieldType;

  constructor(
    readonly userService: UserService,
    public keycloakService: KeycloakService,
    private customOverlayRef: CustomOverlayRef,
    private eventsService: EventsService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.getUsers();
    this.getEventTypes();
    this.teamsDataSource = [
      { value: EventTeams.CHEATER_TEAM, name: 'Cheater Team' },
      { value: EventTeams.SECURITY_TEAM, name: 'Security Team' },
    ];
  }

  public getEventTypes(): void {
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.typesDataSource = response;
      });
  }

  private getUsers(): void {
    this.eventsService.getUsers().subscribe((response: Array<string>) => {
      this.updatedByDataSource = response.map((item: string) => ({
        name: item,
        value: item,
      }));
    });
  }

  private initForm(): void {
    const getColumn = (field: string) =>
      this.columnFilters?.find((column) => column.searchField === field);

    const nameColumn = getColumn('name');
    const updatedByColumn = getColumn('updatedBy');
    const teamColumn = getColumn('team');
    const typeId = getColumn('typeId');
    const description = getColumn('description');
    this.form = new FormGroup({
      name: new FormControl(nameColumn?.searchValues[0]),
      updatedBy: new FormControl(updatedByColumn?.searchValues[0]),
      team: new FormControl(teamColumn?.searchValues[0]),
      typeId: new FormControl(typeId?.searchValues[0]),
      description: new FormControl(description?.searchValues[0]),
    });
  }

  public onChangeDate(searchField: string, params: IPresetQuery): void {
    const createdDateColumn = this.columnFilters.find(
      (column) => column.searchField === searchField,
    );

    if (createdDateColumn) {
      createdDateColumn.searchValues = [params];
    } else {
      this.columnFilters.push({
        searchField: searchField,
        searchValues: [params],
        searchFieldType: SearchFieldType.DATE,
      });
    }
  }

  public onAddFilter(
    searchField: string,
    searchFieldType: SearchFieldType,
  ): void {
    const nameValue = this.form.get(searchField).value;
    const nameColumn = this.columnFilters?.find(
      (column) => column.searchField === searchField,
    );

    if (nameValue) {
      if (nameColumn) {
        nameColumn.searchValues = [nameValue];
      } else {
        this.columnFilters.push({
          searchField: searchField,
          searchValues: [nameValue],
          searchFieldType,
        });
      }
    } else if (nameColumn) {
      this.columnFilters = this.columnFilters.filter(
        (column) => column.searchField !== searchField,
      );
    }
  }

  public onSubmit(): void {
    this.customOverlayRef.close({
      refresh: true,
      columnFilters: this.columnFilters,
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onClearFilters(): void {
    this.form.reset();
  }
}
