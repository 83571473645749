import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'itq-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LabelComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() bold: boolean;
  @Input() size = '';

  constructor() {}
}
