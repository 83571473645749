import { Injectable } from '@angular/core';
import {
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
} from '@intorqa-ui/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class KeycloakSessionService {
  private sessionCheckInterval: any;

  constructor(
    private keycloakService: KeycloakService,
    private customOverlayService: CustomOverlayService,
  ) {}

  /**
   * Starts monitoring the token expiration and sets up a recurring check.
   */
  startSessionMonitoring(): void {
    this.sessionCheckInterval = setInterval(() => {
      this.checkTokenExpiration();
    }, 30000); // Check every 30 second
  }

  /**
   * Checks if the token is about to expire and triggers the alert if necessary.
   */
  private checkTokenExpiration(): void {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();

    if (keycloakInstance.tokenParsed) {
      const tokenExpiration = keycloakInstance.tokenParsed['exp']; // Token expiration time in seconds
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const timeUntilExpiration = tokenExpiration - currentTime;
      console.log(timeUntilExpiration);
      // If the token is about to expire in 30 seconds, prompt the user
      if (timeUntilExpiration === 30) {
        this.promptSessionRenewal();
      }
    }
  }

  /**
   * Prompts the user to renew their session or logs them out if they don't respond.
   */
  private promptSessionRenewal(): void {
    clearInterval(this.sessionCheckInterval); // Stop further checks while waiting for user response
    let userConfirmed = false;
    this.customOverlayService.openCustom(
      {
        title: 'Renew Session',
        message: `Your session is about to expire. Do you want to renew it?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        userConfirmed = result;

        if (userConfirmed) {
          this.renewSession();
        } else {
          this.logout();
        }
      },
    );

    // If the user doesn't respond within 30 seconds, log them out
    setTimeout(() => {
      if (!userConfirmed) {
        this.logout();
      }
    }, 30000); // 30 seconds
  }

  /**
   * Renews the session by refreshing the token.
   */
  private renewSession(): void {
    this.keycloakService
      .updateToken(30) // Refresh the token if it will expire in the next 30 seconds
      .then((refreshed) => {
        if (refreshed) {
          console.log('Session renewed successfully.');
          this.startSessionMonitoring(); // Restart the monitoring process
        } else {
          console.warn('Token is still valid.');
        }
      })
      .catch((error) => {
        console.error('Failed to refresh token:', error);
        this.logout();
      });
  }

  /**
   * Logs the user out.
   */
  private logout(): void {
    this.keycloakService.logout();
  }
}
