<div class="flex flex-col overflow-hidden">
  <div class="flex justify-between p-3 space-x-1">
    <div class="flex items-center overflow-hidden relative space-x-1">
      <span
        class="xl:pl-5 text-17 font-medium text-md overflow-ellipsis overflow-hidden whitespace-nowrap"
        [matTooltip]="widget?.description"
      >
        {{ widget?.name }}
      </span>
    </div>
    <div class="flex items-center space-x-1">
      @if (count > 0) {
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary h-[36px] rounded-md'"
          >{{ count | number }}</itq-pill
        >
      }
      <itq-nav-button
        [padding]="Sizes['X-SM']"
        [template]="widgetMoreTemplate"
        [templateData]="ProfileDrildownScope.TAG"
        [materialStyle]="'basic'"
        [matTooltip]="'Widget options'"
        [matTooltipPosition]="'above'"
      >
        <i class="ph ph-gear-six text-xl"></i>
      </itq-nav-button>
    </div>
  </div>
</div>

<ng-template #widgetMoreTemplate let-data>
  <itq-timeline-widget-actions
    [widget]="widget"
    [dates]="initialState?.where"
    [board]="board"
    [count]="count"
    [taggingStatus]="taggingStatus"
    (delete)="onDeleteWidget()"
  ></itq-timeline-widget-actions>
</ng-template>
