import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'itq-panel-action',
  templateUrl: './panel-action.component.html',
  styleUrls: ['./panel-action.component.scss'],
})
export class PanelActionComponent implements OnInit {
  @Input() label = '';
  @Input() description = '';

  constructor() {}

  ngOnInit(): void {}
}
