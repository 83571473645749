<div class="flex w-full max-h-[338px] overflow-hidden">
  <div class="flex flex-col">
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of sectionsDataSource"
        [style.background-color]="
          item.action === selectedSection?.action ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-medium': item.action === selectedSection?.action
          }"
        >
          <div class="flex items-center space-x-2">
            <i
              [ngClass]="{
                'ph ph-plus text-lg': true,
                'ph-bold': item.action === selectedSection?.action
              }"
            ></i>
            <span class="whitespace-nowrap pt-[4px]">{{ item.name }}</span>
          </div>
          <i
            [ngClass]="{
              ph: true,
              'ph-caret-down': item.action !== selectedSection?.action,
              'ph-caret-right ph-bold': item.action === selectedSection?.action
            }"
          ></i>
        </div>
      </mat-list-item>
    </mat-nav-list>
    @if (drilldownSegment !== fieldValue) {
      <div class="flex flex-col w-[275px]">
        <itq-mat-button
          [size]="Sizes.LG"
          [type]="'button'"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onExplore()"
        >
          <div class="flex items-center space-x-2">
            <i class="ph ph-binoculars text-lg"></i>
            <span class="whitespace-nowrap pt-[4px] font-normal"
              >Explore
              {{
                field === ProfileDrildownScope.ACTOR ? 'Actor' : 'Channel'
              }}</span
            >
          </div>
        </itq-mat-button>
      </div>
    }
  </div>
  @if (selectedSection?.action === 'create') {
    <div
      class="flex-1 border-l w-[275px] pl-[8px] ml-[8px] border-border overflow-auto"
    >
      <itq-mat-button
        *ngFor="let item of vendorTypesDataSurce"
        [size]="Sizes.LG"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onCreateProfile(item)"
      >
        <div class="flex items-center space-x-2">
          <i [ngClass]="['ph', 'text-lg', ProfileTypeIcon[item.name]]"></i>
          <span class="whitespace-nowrap font-normal pt-[4px]">{{
            item.name
          }}</span>
        </div>
      </itq-mat-button>
    </div>
  } @else if (selectedSection?.action === 'update') {
    <div
      class="flex flex-col border-border border-l"
      [style.margin-left]="'8px !important'"
    >
      <div class="flex items-center p-2 pt-1">
        <itq-input
          class="w-full"
          [(ngModel)]="query"
          [placeholder]="'Search...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
      </div>
      <mat-selection-list
        [style.margin-left]="'8px !important'"
        class="flex overflow-auto flex-1 w-[275px] min-h-[250px]"
        [(ngModel)]="selections"
        (selectionChange)="onAddProfile()"
      >
        <mat-list-option
          class="hover:bg-background-over rounded-md"
          *ngFor="let item of vendorsDataSource?.items"
          [value]="item"
        >
          <div class="flex items-center space-x-2">
            <span
              class="overflow-hidden text-ellipsis"
              [matTooltip]="item.name"
              [innerHTML]="item.name | highlightSearch: query"
            ></span>
            @if (item.description) {
              <i class="ph ph-info-circle" [matTooltip]="item.description"></i>
            }
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  }
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
