<form [formGroup]="form" class="flex flex-col h-full">
  <ng-container [formGroupName]="'addNote'">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <fa-icon
            [size]="'2x'"
            [icon]="['far', action === Actions.CREATE ? 'plus' : 'pencil']"
          ></fa-icon>
          <h1 class="text-28 font-medium">
            {{ action === Actions.CREATE ? 'Add note' : 'Edit note' }}
          </h1>
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <i class="ph ph-x text-2xl"></i
      ></itq-mat-button>
    </div>
    <div class="bg-background p-7 flex-1">
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">NOTE TYPE</span>
        <itq-dropdown
          [formControlName]="'type'"
          [clearSelection]="false"
          [dataSource]="typesDataSource"
          [dataFields]="{ name: 'name', value: 'id' }"
          (changeValue)="onChangeType($event)"
        ></itq-dropdown>
        <mat-error
          *ngIf="
            form?.get('addNote.type')?.touched &&
            form?.get('addNote.type')?.invalid
          "
        >
          Note type is required
        </mat-error>
      </div>
      <ng-template dynamicPlaceholder></ng-template>
    </div>
    <footer class="flex justify-end px-7 py-4 bg-white border-t border-border">
      <ng-container *ngIf="action === Actions.CREATE; else updateTemplate">
        <itq-nav-button
          [materialStyle]="'flat'"
          [color]="'primary'"
          [padding]="Sizes.MD"
          [disabled]="!this.form?.get('addNote')?.valid"
          [template]="saveTemplate"
          >SAVE</itq-nav-button
        >
        <ng-template #saveTemplate>
          <div class="flex flex-col w-full">
            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.LG"
              [align]="Align.START"
              (clickEvent)="onCreate()"
            >
              <itq-label
                [label]="'Add another note'"
                [icon]="'ph-plus'"
                class="font-normal"
              ></itq-label>
            </itq-mat-button>

            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.LG"
              [align]="Align.START"
              (clickEvent)="onAdd()"
            >
              <itq-label
                [label]="'Add to profile'"
                [icon]="'ph-plus'"
                class="font-normal"
              ></itq-label>
            </itq-mat-button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #updateTemplate>
        <itq-mat-button
          [disabled]="!this.form?.get('addNote')?.valid"
          [materialStyle]="'flat'"
          [style]="'primary'"
          (clickEvent)="onUpdate()"
        >
          <span class="whitespace-nowrap">UPDATE</span>
        </itq-mat-button>
      </ng-template>
    </footer>
  </ng-container>
</form>
