<div
  class="flex justify-between h-[48px] w-full items-center px-4 cursor-pointer"
  matRipple
>
  <div class="flex items-center w-full space-x-1">
    <i [ngClass]="[icon, 'ph', 'text-lg']"></i>
    <span class="whitespace-nowrap pt-[2px]">{{ label }}</span>
  </div>
  <div
    [ngClass]="{
      hidden: !active,
      'w-full h-[4px] bg-primary absolute bottom-0 left-0': true
    }"
  ></div>
</div>
