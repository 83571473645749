import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  Actions,
  AlertTypes,
  CoreModule,
  UserTagCategory,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { BoardService } from '@portal/boards/services/board.service';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IUserCategory } from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { KeycloakService } from 'keycloak-angular';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'itq-tag-wizard-save',
  templateUrl: './tag-wizard-save.component.html',
  styleUrls: ['./tag-wizard-save.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSlideToggleModule,
  ],
})
export class TagWizardSaveComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() boardIds: Array<string>;
  @Input() tag: Tag;
  @Input() action: Actions;

  readonly UserTagCategory = UserTagCategory;
  readonly AlertTypes = AlertTypes;
  readonly Actions = Actions;

  public boardsDataSource: Array<Board>;
  public systemCategoriesDataSource: Array<IUserCategory> = [];
  public userCategoriesDataSource: Array<IUserCategory> = [];

  constructor(
    readonly boardService: BoardService,
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
    readonly keycloakService: KeycloakService,
  ) {}

  ngOnInit() {
    this.addControls();
    this.loadBoards();
    this.onGetTagTypes();
    if (this.keycloakService.isUserInRole('super-admin')) {
      this.getSystemCategories();
    }
  }

  private getSystemCategories(): void {
    this.categoryService
      .getSystemCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: IUserCategory[]) => {
        this.systemCategoriesDataSource = response;
        this.form.get('save.categoryId').setValue(this.tag.categoryId);
      });
  }

  private onGetTagTypes(): void {
    if (!this.tag?.categoryId) {
      this.categoryService
        .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
        .subscribe((response: IUserCategory[]) => {
          this.userCategoriesDataSource = response;
          this.form
            .get('save.userTagCategory')
            .setValue(this.tag.userTagCategory);
        });
    }
  }

  private addControls(): void {
    this.form.addControl(
      'save',
      new FormGroup({
        name: new FormControl(
          this.tag?.name,
          [Validators.required],
          [this.validateDuplicatName()],
        ),
        description: new FormControl(this.tag?.description),
        boardIds: new FormControl([]),
        sharedTag: new FormControl({
          value: this.tag.sharedTag || this.tag.categoryId ? true : false,
          disabled: this.tag.categoryId ? true : false,
        }),
      }),
    );
    const saveFormGroup = this.form.get('save') as FormGroup;
    if (!this.tag?.categoryId) {
      saveFormGroup.addControl(
        'userTagCategory',
        new FormControl(this.tag?.userTagCategory, [Validators.required]),
      );
      if (this.keycloakService.isUserInRole('super-admin')) {
        saveFormGroup.addControl(
          'system',
          new FormControl(this.tag?.categoryId ? 'yes' : 'no', [
            Validators.required,
          ]),
        );
      }
    } else {
      if (this.action === Actions.EDIT) {
        if (this.keycloakService.isUserInRole('super-admin')) {
          saveFormGroup.addControl(
            'system',
            new FormControl({ value: 'yes', disabled: true }, [
              Validators.required,
            ]),
          );
          saveFormGroup.addControl(
            'categoryId',
            new FormControl({ value: this.tag.categoryId, disabled: true }, [
              Validators.required,
              this.validateCategories(),
            ]),
          );
        }
      }
    }
  }

  public onChangeUserCategory(): void {
    this.tag.userTagCategory = this.form.get('userTagCategory').value;
  }

  private validateCategories(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (control?.value || this.form.get('save.userTagCategory')?.value) {
        return undefined;
      } else {
        return { categoryNotSet: true };
      }
    };
  }

  private validateDuplicatName(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control?.value) {
        if (control.value?.trim() === '') {
          return of({ requiredField: true });
        }
        if (this.action === Actions.EDIT) {
          if (this.tag.name === control.value) {
            return of(null);
          }
        }
        if (
          this.form.get('save.categoryId')?.value ||
          this.form.get('save.userTagCategory')?.value
        ) {
          return this.tagService
            .validateName({
              name: control?.value,
              ecosystemId: this.userService.userPreferences.defaultEcosystemId,
              userTagCategory: this.form.get('save.userTagCategory')?.value,
              categoryId: this.form.get('save.categoryId')?.value,
              sharedTag: this.form.get('save.shared')?.value,
            })
            .pipe(
              map((isValid: boolean) => {
                return isValid ? null : { duplicateName: true };
              }),
            );
        } else {
          return of(null);
        }
      } else {
        return of(null);
      }
    };
  }

  private loadBoards(): void {
    this.boardService
      .getBoards(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<Board>) => {
        this.boardsDataSource = response;
        if (this.boardIds?.length > 0) {
          this.setBoards();
        }
      });
  }

  private setBoards(): void {
    if (this.boardsDataSource?.length > 0) {
      const boards = this.boardIds.map((item: string) =>
        this.boardsDataSource?.find((board: Board) => board.id === item),
      );
      this.form.get('save.boardIds').setValue(boards);
    }
  }

  public onChangeSystem(event: MatRadioChange): void {
    const saveFormGroup = this.form.get('save') as FormGroup;
    if (event.value === 'yes') {
      saveFormGroup.get('sharedTag').disable();
      saveFormGroup.get('sharedTag').setValue(true);
      saveFormGroup.removeControl('userTagCategory');
      saveFormGroup.addControl('categoryId', new FormControl());
      this.form
        .get('save.categoryId')
        .setValidators([Validators.required, this.validateCategories()]);
      this.form.get('save.categoryId').updateValueAndValidity();
    } else {
      saveFormGroup.get('sharedTag').enable();
      saveFormGroup.removeControl('categoryId');
      saveFormGroup.addControl('userTagCategory', new FormControl());
      this.form
        .get('save.userTagCategory')
        .setValidators([Validators.required, this.validateCategories()]);
    }
  }
}
