<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
>
  <itq-timeline-widget-header
    [count]="data?.count"
    [widget]="widget"
    [board]="board"
    [taggingStatus]="taggingStatus"
    [initialState]="initialState"
    (delete)="onDeleteWidget()"
    (explore)="onExplore()"
  ></itq-timeline-widget-header>
  @if (taggingStatus === TaggingStatus.IN_PROGRESS) {
    <itq-pill
      [type]="PillType.CUSTOM"
      [matTooltip]="'Tagging in progress, please refresh to see new content'"
      [class]="
        'rounded-2xl px-4 py-2 shadow-none bg-primary-over uppercase h-[36px] absolute left-1/2 transform -translate-x-1/2 z-10 border border-primary'
      "
    >
      <div class="flex items-center space-x-2">
        <itq-loader class="relative-important" [type]="'controls'"></itq-loader>
        <span>TAGGING POSTS</span>
      </div></itq-pill
    >
  }
  <div
    class="flex flex-1 flex-col overflow-hidden w-full border-t border-border"
    #chartContainer
  >
    <div class="flex flex-1 overflow-hidden">
      @if (
        widget &&
        (taggingStatus === TaggingStatus.COMPLETED ||
          (taggingStatus === TaggingStatus.IN_PROGRESS && data?.count > 0))
      ) {
        <itq-documents-feed
          #documentsFeedRef
          class="h-full flex flex-col overflow-hidden w-full"
          [data]="data"
          [initialState]="initialState"
          [query]="initialState?.query"
          (createProfile)="onCreateProfile($event)"
          (shareEmail)="onShareEmail($event)"
          (scrollEvent)="onDataBound($event)"
          (drilldown)="onDrilldown($event)"
        ></itq-documents-feed>
      } @else {
        @if (taggingStatus === TaggingStatus.IN_PROGRESS) {
          <itq-panel-info
            class="bg-white p-7 rounded-md"
            [label]="noContentTitle"
            [message]="noContentMessage"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        }
      }
    </div>
    <footer class="flex p-4 border-t border-border">
      <itq-mat-button
        [matTooltip]="'Scroll to top'"
        [disabled]="
          data?.count === 0 ||
          (taggingStatus === TaggingStatus.IN_PROGRESS && data?.count === 0)
        "
        [padding]="Sizes.NONE"
        [type]="'button'"
        [materialStyle]="'stroked'"
        (mousedown)="scrollToTop()"
      >
        <fa-icon [icon]="['far', 'chevron-up']" [size]="'lg'"></fa-icon>
      </itq-mat-button>

      <itq-mat-button
        class="flex-1 ml-2"
        [size]="Sizes.BLOCK"
        [disabled]="
          data?.count === 0 ||
          (taggingStatus === TaggingStatus.IN_PROGRESS && data?.count === 0)
        "
        [type]="'button'"
        [materialStyle]="'flat'"
        [style]="'primary'"
        (clickEvent)="onExplore()"
      >
        EXPLORE
      </itq-mat-button>
    </footer>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
