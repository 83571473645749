import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { ValueFiltersComponent } from '@portal/tags/components/value-filters/value-filters.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';
import { Rule } from 'ngx-angular-query-builder';

@Component({
  selector: 'itq-rule-value',
  templateUrl: './rule-value.component.html',
  styleUrl: './rule-value.component.scss',
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    CommonModule,
    ValueFiltersComponent,
    FormsModule,
  ],
})
export class RuleValueComponent {
  @Input() initialState: QueryFilters;
  @Input() rule: Rule;
  @Input() disabled: boolean;
  @Input() queryModel: QueryBuilderModel;

  @Input() dataSource: Array<IFilterField>;
  @Output() removeValue = new EventEmitter<{
    rule: Rule;
    value: ICustomTag;
  }>();
  @Output() valuesChange = new EventEmitter<{
    values: Array<ICustomTag>;
    rule: Rule;
  }>();

  @ViewChild('buttonWrapper') buttonWrapper: ElementRef;

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor() {}

  public onRemoveValue(rule: Rule, value: ICustomTag): void {
    this.removeValue.emit({ rule, value });
  }

  public onGetPrefilter(rule: Rule): string {
    return this.dataSource.find((item: IFilterField) => item.id === rule.field)
      .preFilter;
  }

  public onFilterValuesSelectionChange(
    values: Array<ICustomTag>,
    rule: Rule,
  ): void {
    this.valuesChange.emit({ values, rule });
  }

  public getMaxWidth(): number {
    return this.buttonWrapper?.nativeElement?.offsetWidth;
  }
}
