<div class="flex justify-between">
  @if (iconType === IconType.SVG) {
    <itq-svg [icon]="icon" class="w-10 h-10"></itq-svg>
  } @else if (iconType === IconType.PHOSPHOR) {
    <i [ngClass]="['ph', 'text-5xl', icon]"></i>
  } @else {
    <fa-icon [size]="'3x'" [icon]="['fal', icon | castIconName]"></fa-icon>
  }
  <itq-mat-button
    [padding]="Sizes.NONE"
    [materialStyle]="'flat'"
    [style]="'primary'"
    [type]="'button'"
    [disabled]="disabled"
    (clickEvent)="onAdd()"
  >
    <fa-icon [icon]="['far', 'plus']"></fa-icon>
  </itq-mat-button>
</div>
<div class="font-medium text-15 pt-3">{{ title }}</div>
<div class="text-border font-normal text-13 text-metadata">
  {{ description }}
</div>
