<itq-nav-button
  #valueButton
  class="w-full"
  [materialStyle]="'stroked'"
  [disabled]="disabled"
  [size]="Sizes.BLOCK"
  [template]="valuesTemplate"
  [templateData]="rule"
>
  @if (rule.value?.length > 0) {
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center flex-1 overflow-hidden" #buttonWrapper>
        <itq-tags-list
          class="overflow-hidden"
          [dataSource]="rule.value"
          [dataFields]="{ name: 'name', value: 'id' }"
          [maxWidth]="getMaxWidth()"
          [type]="PillType.BUTTON"
          (removeValue)="onRemoveValue(rule, $event)"
        ></itq-tags-list>
      </div>
      <i
        [ngClass]="{
          'border-l border-border pl-2 ml-2 ph text-lg': true,
          'ph-caret-up': valueButton.isOpen,
          'ph-caret-down': !valueButton.isOpen
        }"
      ></i>
    </div>
  } @else {
    <div
      class="text-gray-400 font-normal justify-between flex items-center w-full"
    >
      Choose an option
      <i
        [ngClass]="{
          'border-l border-border pl-2 ml-2 ph text-lg': true,
          'ph-caret-up': valueButton.isOpen,
          'ph-caret-down': !valueButton.isOpen
        }"
      ></i>
    </div>
  }
</itq-nav-button>

<ng-template #valuesTemplate let-rule>
  <itq-value-filters
    class="flex"
    [(ngModel)]="rule.value"
    [field]="rule.field"
    [queryModel]="queryModel.clone()"
    [fields]="this.dataSource"
    [preFilter]="onGetPrefilter(rule)"
    [dates]="this.initialState.where"
    (selectionChange)="onFilterValuesSelectionChange($event, rule)"
  ></itq-value-filters>
</ng-template>
