import { Pipe, PipeTransform } from '@angular/core';
import { TransactionNotificationType } from '@intorqa-ui/core';

@Pipe({
  name: 'getTransactionalAction',
  standalone: true,
})
export class GetActionPipe implements PipeTransform {
  transform(action: TransactionNotificationType): string {
    switch (action) {
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
      case TransactionNotificationType.TAG_CREATED:
        return 'ph-binoculars';
      case TransactionNotificationType.TAG_EDITED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
        return 'ph-pencil';
      default:
        return '';
    }
  }
}
