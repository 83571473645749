import { Component, Input, OnInit } from '@angular/core';
import {
  CoreModule,
  CustomOverlayRef,
  FAwesomeModule,
  IQueryColumn,
  LabelComponent,
  SearchFieldType,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IUserCategory } from '@portal/tags/interfaces/tag.interface';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'itq-tags-filters',
  templateUrl: './tags-filters.component.html',
  styleUrls: ['./tags-filters.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    FAwesomeModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,

    MatSlideToggleModule,
    LabelComponent,
  ],
})
export class TagsFiltersComponent implements OnInit {
  @Input() columnFilters: Array<IQueryColumn> = [];

  public form: FormGroup;
  public userCategoriesDataSource: Array<IUserCategory>;
  public sharedOptions: Array<{ value: string; label: string }>;
  public systemOptions: Array<{ value: string; label: string }>;
  public alertsOptions: Array<{ value: string; label: string }>;

  readonly SearchFieldType = SearchFieldType;

  constructor(
    readonly userService: UserService,
    public keycloakService: KeycloakService,
    readonly categoryService: CategoryService,
    private customOverlayRef: CustomOverlayRef,
  ) {
    this.sharedOptions = [
      { value: 'All', label: ' Show all tags' },
      { value: 'Private', label: 'Show only private tags' },
      { value: 'Shared', label: 'Show only shared tags' },
    ];
    this.alertsOptions = [
      { value: 'All', label: ' Show all tags' },
      { value: 'WithAlerts', label: 'Show only tags with alerts' },
      { value: 'WithoutAlerts', label: 'Show only tags without alerts' },
    ];
    this.systemOptions = [
      { value: 'All', label: ' Show all tags' },
      { value: 'System', label: 'Show only system tags' },
      { value: 'User', label: 'Show only user tags' },
    ];
  }

  ngOnInit() {
    this.initForm();
    this.getUserCategories();
  }

  private getUserCategories(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IUserCategory>) => {
        this.userCategoriesDataSource = response;
        const getColumn = (field: string) =>
          this.columnFilters?.find((column) => column.searchField === field);
        const userTagCategoryColumn = getColumn('userTagCategory');
        this.form
          .get('userTagCategory')
          .setValue(userTagCategoryColumn?.searchValues[0]);
      });
  }

  private initForm(): void {
    const getColumn = (field: string) =>
      this.columnFilters?.find((column) => column.searchField === field);
    const sharedColumn = getColumn('sharedTag');
    const systemColumn = getColumn('systemTag');
    const hasAlertsColumn = getColumn('hasAlerts');
    this.form = new FormGroup({
      sharedTag: new FormControl(sharedColumn?.searchValues[0] || 'All'),
      userTagCategory: new FormControl(undefined),
    });
    if (this.keycloakService.isUserInRole('super-admin')) {
      this.form.addControl(
        'systemTag',
        new FormControl(systemColumn?.searchValues[0] || 'All'),
      );
    }
    if (this.keycloakService.isUserInRole('saas-alerts')) {
      this.form.addControl(
        'hasAlerts',
        new FormControl(hasAlertsColumn?.searchValues[0] || 'All'),
      );
    }
  }

  public onAddFilter(
    searchField: string,
    searchFieldType: SearchFieldType,
  ): void {
    const nameValue = this.form.get(searchField).value;
    const nameColumn = this.columnFilters?.find(
      (column) => column.searchField === searchField,
    );
    if (nameValue) {
      if (nameColumn) {
        if (nameValue === 'All') {
          this.columnFilters = this.columnFilters.filter(
            (column) => column.searchField !== searchField,
          );
        } else {
          nameColumn.searchValues = [nameValue];
        }
      } else {
        this.columnFilters.push({
          searchField: searchField,
          searchValues: [nameValue],
          searchFieldType,
        });
      }
    } else if (nameColumn) {
      this.columnFilters = this.columnFilters.filter(
        (column) => column.searchField !== searchField,
      );
    }
  }

  public onSubmit(): void {
    this.customOverlayRef.close({
      refresh: true,
      columnFilters: this.columnFilters,
    });
  }

  public onClearType(): void {
    this.form.get('userTagCategory').setValue(undefined);
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onClearFilters(): void {
    this.form.get('sharedTag').setValue('All');
    this.form.get('hasAlerts').setValue('All');
    this.form.get('systemTag').setValue('All');
  }
}
