import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public loader$ = new Subject<boolean>();
  public toggleSidebar$ = new Subject<void>();
  public resizeDropdown$ = new Subject<void>();

  private currentBreakpointSubject = new BehaviorSubject<string>('default');
  public currentBreakpoint$ = this.currentBreakpointSubject.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        '(min-width: 640px)', // Tailwind sm
        '(min-width: 768px)', // Tailwind md
        '(min-width: 1024px)', // Tailwind lg
        '(min-width: 1280px)', // Tailwind xl
        '(min-width: 1536px)', // Tailwind 2xl
      ])
      .pipe(
        map((result) => {
          if (result.breakpoints['(min-width: 1536px)']) {
            return '2xl';
          } else if (result.breakpoints['(min-width: 1280px)']) {
            return 'xl';
          } else if (result.breakpoints['(min-width: 1024px)']) {
            return 'lg';
          } else if (result.breakpoints['(min-width: 768px)']) {
            return 'md';
          } else if (result.breakpoints['(min-width: 640px)']) {
            return 'sm';
          } else {
            return 'default';
          }
        }),
      )
      .subscribe((currentBreakpoint) => {
        this.currentBreakpointSubject.next(currentBreakpoint);
      });
  }
}
