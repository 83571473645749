<div class="flex flex-col w-full py-4">
  <div class="flex px-4 pb-4 w-full items-center">
    <itq-mat-button
      class="mr-2"
      [type]="'button'"
      [padding]="Sizes.NONE"
      [materialStyle]="'stroked'"
      (clickEvent)="onNavigate()"
    >
      <i class="ph ph-caret-left text-lg"></i>
    </itq-mat-button>
    <span class="text-[17px] font-medium">LIBRARY</span>
  </div>
  <ul>
    <li
      class="flex justify-center h-[48px] items-center border-b border-t border-border"
    >
      <itq-library-sidebar-item
        class="flex w-full h-full"
        [url]="'/manager/tags'"
        [totalCount]="tagsTotalCount"
        [title]="'Tags'"
        [icon]="'ph-tag'"
        [iconType]="IconType.PHOSPHOR"
      ></itq-library-sidebar-item>
    </li>
    <li
      class="flex justify-center h-[48px] items-center border-b border-border"
    >
      <itq-library-sidebar-item
        class="flex w-full h-full"
        [url]="'/manager/vendors'"
        [totalCount]="vendorsTotalCount"
        [title]="'Vendors'"
        [icon]="'ph-storefront'"
        [iconType]="IconType.PHOSPHOR"
      ></itq-library-sidebar-item>
    </li>
    @if (userService.userPreferences.defaultEcosystemId | hasEventsEnabled) {
      <li
        class="flex justify-center h-[48px] items-center border-b border-border"
      >
        <itq-library-sidebar-item
          class="flex w-full h-full"
          [url]="'/manager/events'"
          [totalCount]="eventsTotalCount"
          [title]="'Events'"
          [icon]="'ph-calendar-dots'"
          [iconType]="IconType.PHOSPHOR"
        ></itq-library-sidebar-item>
      </li>
    }
  </ul>
</div>
