<div class="flex items-center p-7 border-b border-border justify-between">
  <div class="flex items-center">
    <fa-icon [icon]="['far', 'file']" [size]="'2x'"></fa-icon>
    <h1 class="text-28 font-medium pl-4">Matching documents</h1>
  </div>
  <div class="flex space-x-2">
    <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
      <div class="flex items-center justify-center space-x-2">
        <i class="ph ph-arrows-clockwise text-lg"></i>
        <span class="whitespace-nowrap">REFRESH</span>
      </div>
    </itq-mat-button>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <i class="ph ph-x text-2xl"></i
    ></itq-mat-button>
  </div>
</div>
<div class="flex flex-1 overflow-hidden">
  <div class="flex relative flex-1 border-border border-l min-w-[500px]">
    <itq-documents-feed
      class="h-full flex flex-col overflow-hidden"
      [data]="matchesDataSource"
      [initialState]="initialState"
      [query]="initialState?.query"
      (shareEmail)="onShareEmail($event)"
      (createProfile)="onCreateProfile($event)"
      (scrollEvent)="onDataBoundOtherMatches($event)"
      (drilldown)="onDrilldown($event)"
    ></itq-documents-feed>
  </div>
</div>
