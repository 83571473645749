import {
  ChartType,
  ChartOrientation,
  PieMode,
} from '@portal/widgets/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const TAG_ANALYSIS_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'VerticalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
    },
    phosphorIcon: 'ph-chart-bar',
    tooltip: 'Vertical Bar',
  },
  {
    id: 'HorizontalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.HORIZONTAL,
    },
    phosphorIcon: 'ph-chart-bar-horizontal',
    tooltip: 'Horizontal Bar',
  },
  {
    id: 'Pie',
    type: ChartType.PIE,
    options: {
      mode: PieMode.PIE,
    },
    phosphorIcon: 'ph-chart-pie',
    tooltip: 'Pie',
  },
  {
    id: 'Doughnut',
    type: ChartType.PIE,
    options: {
      mode: PieMode.DOUGHTNUT,
    },
    phosphorIcon: 'ph-chart-pie-slice',
    tooltip: 'Doughnut',
  },
  {
    id: 'Table',
    type: ChartType.TABLE,
    phosphorIcon: 'ph-table',
    options: {},
    tooltip: 'Table',
  },
];
