import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Actions,
  Align,
  ComponentType,
  CustomOverlayService,
  CustomOverlayType,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { DocumentItem } from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import {
  INoteDataSource,
  INotesData,
} from '@portal/profiles/interfaces/profile-note.interface';
import { NoteType } from '@portal/profiles/models/note-type';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddNotesComponent } from '@portal/shared/components/add-notes/add-notes.component';
import { UserService } from '@portal/shared/services/user.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PostNotesComponent } from '../post-notes/post-notes.component';

@Component({
  selector: 'itq-profiles-notes',
  templateUrl: './profiles-notes.component.html',
  styleUrls: ['./profiles-notes.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesNotesComponent,
    },
  ],
})
export class ProfilesNotesComponent implements OnInit, AfterViewInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() action: Actions;
  @Input() showTitle = true;

  public dataSource: INotesData;
  public tableColumns: Array<TableColumn> = [];
  public notesSubscription: Subscription;
  private selection: Array<ProfileNote>;
  private users: Array<{ name: string; value: string }>;
  private subTypes: Array<{ name: string; value: string }> = [];
  private touched = false;
  private disabled = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public isFilterable = false;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly Align = Align;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('notesTemplate')
  notesTemplate: TemplateRef<unknown>;
  @ViewChild('valueTemplate')
  valueTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;

  constructor(
    private notesService: NotesService,
    readonly documentService: DocumentItemService,
    readonly customOverlayService: CustomOverlayService,
    readonly profilesService: ProfileService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.notesSubscription = this.notesService.notes$.subscribe(
      (response: INotesData) => {
        this.dataSource = cloneDeep(response);
      },
    );
    this.onLoadTypes();
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.notesSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileNote>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onToggle(): void {
    this.initTableColumns();
  }

  private onLoadUsers(): void {
    this.notesService
      .getUsers(this.profile.profileId)
      .subscribe((response: Array<string>) => {
        this.users = response.map((item: string) => ({
          name: item,
          value: item,
        }));
        this.initTableColumns();
      });
  }

  public onLoadTypes(): void {
    this.notesService.getNoteTypes(this.profile.profileTypeId).subscribe(() => {
      this.onLoadUsers();
      this.getSubTypes();
      this.onDataBound(this.initialState);
    });
  }

  private initTableColumns(): void {
    const types = this.notesService.noteTypes.map((item: NoteType) => ({
      name: item.name,
      value: item.id,
    }));
    this.tableColumns = [
      {
        name: 'Updated date',
        dataKey: 'updatedDate',
        searchField: 'updatedDate',
        isSortable: true,
        width: '180px',
        sticky: true,
        isFilterable: true,
        searchFieldType: SearchFieldType.DATE,
        searchComponent: ComponentType.DATE,
      },
      {
        name: 'Updated by',
        dataKey: 'updatedBy',
        searchField: 'updatedBy',
        isSortable: true,
        position: 'center',
        customRender: true,
        template: this.ownerTemplate,
        width: '120px',
        isFilterable: true,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: this.users,
      },
      {
        name: 'DocumentId',
        dataKey: 'documentId',
        width: '150px',
        show: false,
      },
      {
        name: 'Type',
        dataKey: 'type',
        searchField: 'typeId',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: types,
      },
      {
        name: 'Sub type',
        dataKey: 'subType',
        searchField: 'subType',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: this.subTypes,
      },
      {
        name: 'Value',
        dataKey: 'rawValue',
        searchField: 'rawValue',
        isSortable: true,
        width: 'fit',
        isFilterable: true,
        customRender: true,
        template: this.valueTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Notes',
        dataKey: 'rawTextNote',
        searchField: 'textNote',
        isSortable: true,
        width: 'fit',
        isFilterable: true,
        customRender: true,
        template: this.notesTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
  }

  private getSubTypes(): void {
    this.notesService.noteTypes.forEach((item: NoteType) => {
      if (item.subTypes?.length > 0) {
        this.subTypes = [
          ...this.subTypes,
          ...item.subTypes.map((subItem: string) => ({
            name: subItem,
            value: subItem,
          })),
        ];
      }
    });
  }

  public onDeleteNote(note: ProfileNote): void {
    this.notesService
      .removeNotes(this.profile.profileId, [note.id])
      .subscribe();
  }

  public onAddNote(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            note: new ProfileNote(
              Utils.generateUUID(),
              this.profile.profileId,
              undefined,
              undefined,
              undefined,
              undefined,
              this.userService.userPreferences.defaultEcosystemId,
            ),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.onDataBound(this.initialState);
      }
    });
  }

  public onEditNote(noteDataSource: INoteDataSource): void {
    const note = this.notesService.getNoteById(noteDataSource.id);
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesComponent,
          inputs: {
            profile: this.profile,
            action: Actions.EDIT,
            note: cloneDeep(note),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.onDataBound(this.initialState);
      }
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.profilesService.loader$.next(true);
    this.notesService.getNotes(this.profile.profileId, params).subscribe(() => {
      this.profilesService.loader$.next(false);
    });
  }

  public onViewNote(noteDataSource: ProfileNote): void {
    const note = this.notesService.getNoteById(noteDataSource.id);
    if (note.documentId) {
      this.documentService
        .getDocument(note.documentId)
        .subscribe((response: DocumentItem) => {
          this.customOverlayService.open({
            data: {
              componentConfig: {
                component: PostNotesComponent,
                inputs: {
                  document: response,
                  note: cloneDeep(note),
                },
              },
            },
            closeBtnClass: 'hidden',
            closeBtnStyle: 'basic',
            type: CustomOverlayType['slide-right'],
            component: ModalContainerComponent,
            disposeOnNavigation: true,
          });
        });
    } else {
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: PostNotesComponent,
            inputs: {
              note: cloneDeep(note),
            },
          },
        },
        closeBtnClass: 'hidden',
        closeBtnStyle: 'basic',
        type: CustomOverlayType['slide-right'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    }
  }
}
