import { TimelineStandardFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-standard-filters/timeline-standard-filters.component';
import { ITag } from '../../tags/interfaces/tag.interface';
import { Tag } from '../../tags/models/tag';
import { TimelineVendorFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-vendor-filters/timeline-vendor-filters.component';
import { UserTagCategory } from '@intorqa-ui/core';
import { Type } from '@angular/core';
import { TimelineProfileTagFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-profiletag-filters/timeline-profiletag-filters.component';
import { TimelineCustomerSpecificFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-customerspecific-filters/timeline-customerspecific-filters.component';
import { TimelineGameFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-game-filters/timeline-game-filters.component';
import { TimelineCohortFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-cohort-filters/timeline-cohort-filters.component';
import { TimelineCheatFeaturesFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-cheatfeatures-filters/timeline-cheatfeatures-filters.component';
import { TimelineGlitchFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-glitch-filters/timeline-glitch-filters.component';
import { TimelineAssetFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-asset-filters/timeline-asset-filters.component';
import { TimelineUtilitiesFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-utilities-filters/timeline-utilities-filters.component';
import { TimelineSignificantActorsFiltersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/components/timeline-significantactor-filters/timeline-significantactor-filters.component';

export class TagFactory {
  static createTag(tag: ITag): Tag {
    return new Tag(
      tag.tagId,
      tag.description,
      tag.createdDate,
      tag.updatedDate,
      tag.name,
      tag.query,
      tag.sharedTag,
      tag.username,
      tag.categoryId,
      tag.userTagCategory,
      tag.lastTaggingTime,
      tag.alertTypeId,
      tag.ecosystemId,
    );
  }

  static createTagByType(type: string): Tag {
    return new Tag(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      type,
      undefined,
      undefined,
      undefined,
    );
  }

  static getExploreFiltersComponent(
    userTagCategory: string,
  ): Type<
    | TimelineStandardFiltersComponent
    | TimelineVendorFiltersComponent
    | TimelineProfileTagFiltersComponent
    | TimelineCustomerSpecificFiltersComponent
    | TimelineGameFiltersComponent
    | TimelineSignificantActorsFiltersComponent
    | TimelineCohortFiltersComponent
    | TimelineCheatFeaturesFiltersComponent
    | TimelineGlitchFiltersComponent
    | TimelineAssetFiltersComponent
    | TimelineUtilitiesFiltersComponent
  > {
    if (userTagCategory === UserTagCategory.Standard) {
      return TimelineStandardFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Vendor) {
      return TimelineVendorFiltersComponent;
    } else if (userTagCategory === UserTagCategory['Profile Tag']) {
      return TimelineProfileTagFiltersComponent;
    } else if (userTagCategory === UserTagCategory['Customer Specific']) {
      return TimelineCustomerSpecificFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Game) {
      return TimelineGameFiltersComponent;
    } else if (userTagCategory === UserTagCategory['Significant Actor']) {
      return TimelineSignificantActorsFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Cohort) {
      return TimelineCohortFiltersComponent;
    } else if (userTagCategory === UserTagCategory['Cheat Feature']) {
      return TimelineCheatFeaturesFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Glitch) {
      return TimelineGlitchFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Asset) {
      return TimelineAssetFiltersComponent;
    } else if (userTagCategory === UserTagCategory.Utilities) {
      return TimelineUtilitiesFiltersComponent;
    }
  }
}
