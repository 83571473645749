import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[infiniteScroll]',
  standalone: true,
})
export class InfiniteScrollDirective {
  @Output() scrolledToEnd = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const scroll = fromEvent(this.el.nativeElement, 'scroll');
    const resultScroll = scroll.pipe(debounceTime(100), distinctUntilChanged());

    resultScroll.subscribe(() => {
      const element = this.el.nativeElement;
      const isScrollEnd =
        element.offsetHeight + element.scrollTop >= element.scrollHeight - 1;
      if (isScrollEnd) {
        this.scrolledToEnd.emit();
      }
    });
  }
}
