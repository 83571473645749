import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  Align,
  CoreModule,
  FAwesomeModule,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

@Component({
  selector: 'itq-widget-display-types',
  templateUrl: './widget-display-types.component.html',
  styleUrls: ['./widget-display-types.component.scss'],
  standalone: true,
  imports: [CoreModule, SvgComponent, FAwesomeModule, CommonModule],
})
export class WidgetDisplayTypesComponent implements OnInit {
  @Input() displayTypes: Array<IDisplayType>;
  @Input() selectedDisplayType: IDisplayType;

  @Output() changeDisplay = new EventEmitter<IDisplayType>();

  readonly Align = Align;
  readonly Sizes = Sizes;

  constructor() {}

  ngOnInit() {}

  public onChangeDisplay(params: IDisplayType): void {
    this.changeDisplay.emit(params);
  }
}
