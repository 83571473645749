import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SharedService } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { BoardNavigationComponent } from '../board-navigation/board-navigation.component';

@Component({
  selector: 'itq-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.scss'],
  standalone: true,
  imports: [BoardNavigationComponent, MatSidenavModule, RouterModule],
})
export class BoardsComponent implements OnInit {
  public subscriptions = new Subscription();
  public currentBreakpoint: string;

  @ViewChild('sidenav') sidenav: MatDrawer;

  constructor(readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.bindResizeEvent();
    this.bindToggleSidebarSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindToggleSidebarSubscription(): void {
    this.subscriptions.add(
      this.sharedService.toggleSidebar$?.subscribe(() => {
        this.sidenav?.toggle();
      }),
    );
  }

  private bindResizeEvent(): void {
    this.subscriptions.add(
      this.sharedService.currentBreakpoint$.subscribe((breakpoint) => {
        this.currentBreakpoint = breakpoint;
      }),
    );
  }
}
