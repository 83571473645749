<itq-mat-button
  class="absolute top-[14px] right-7 z-10"
  [matTooltip]="'Close'"
  [materialStyle]="'basic'"
  [type]="'button'"
  (clickEvent)="onClose()"
>
  <i class="ph ph-x text-2xl"></i
></itq-mat-button>
<div
  class="py-4 px-7 overflow-auto w-[450px] flex flex-col border-r border-border"
>
  <div class="flex flex-col mb-7">
    <div class="flex items-baseline justify-between">
      <div class="flex flex-col flex-1 items-start">
        <div class="flex items-center">
          <i class="ph ph-binoculars text-5xl"></i>
          <h1 class="text-28 font-medium pl-4">
            {{ profile.name }}
          </h1>
        </div>
        @if (profile?.description) {
          <p [innerHTML]="profile.description"></p>
        }
      </div>

      <itq-mat-button
        *ngIf="action === Actions.EXPLORE"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onEdit(ProfileEditAction.DETAILS, undefined)"
      >
        <i class="ph ph-pencil"></i>
      </itq-mat-button>
    </div>
  </div>
  <ng-container *ngIf="action === Actions.EXPLORE; else editProfile">
    <mat-accordion class="basic" [multi]="true">
      <mat-expansion-panel [hideToggle]="false" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title
            ><fa-icon [icon]="['far', 'info-circle']"></fa-icon
            >Info</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="mb-3">
          <span class="font-medium">Vendor Type:</span>
          <span class="ml-2">{{ profileTypeName }}</span>
        </div>
        <div
          class="mb-3"
          *ngIf="
            profile?.metadata
              | getMetadataField: MetadataFields.PRIORITY as priorityMetadata
          "
        >
          <span class="font-medium">Priority:</span>
          <span class="ml-2">{{ priorityMetadata.values | jsonParse }}</span>
        </div>
        <div
          class="mb-3"
          *ngIf="
            profile?.metadata
              | getMetadataField
                : MetadataFields.CHEAT_STATUS as cheatStatusMetadata
          "
        >
          <span class="font-medium">Cheat Status:</span>
          <span class="ml-2">{{ cheatStatusMetadata.values | jsonParse }}</span>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [hideToggle]="false" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title
            ><fa-icon [icon]="['far', 'info-circle']"></fa-icon
            >Metrics</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="mb-3 flex items-center">
          <span class="font-medium">Posts in last 7 days:</span>
          <div class="ml-2 h-12 w-full flex relative">
            @if (profile?.activity) {
              <itq-pill
                [class]="'secondary rounded-md h-[36px]'"
                [type]="PillType.CUSTOM"
                >{{ profile?.activity }}</itq-pill
              >
            }
            @if (profile?.activity > 0 && activityData) {
              <itq-chart
                [id]="'profile-metadata'"
                [settings]="options"
                [type]="ChartType.LINE"
                [data]="activityData"
              ></itq-chart>
            } @else {
              <itq-loader
                *ngIf="profile?.activity > 0"
                [type]="'controls'"
              ></itq-loader>
            }
          </div>
        </div>
        <div class="mb-3 flex items-center">
          <span class="font-medium">Vs Previous week:</span>
          <span class="ml-2 trend__container">
            <div
              class="flex justify-center flex-col items-center"
              *ngIf="profile?.trend !== undefined; else updatingTemplate"
              [ngStyle]="{ color: profile?.trend | getTrendColor }"
            >
              <fa-icon
                [size]="'lg'"
                [icon]="['far', profile?.trend | getTrendIcon]"
              ></fa-icon>
              <span class="trend__label">{{ profile?.trend }}%</span>
            </div>
            <ng-template #updatingTemplate>
              <div class="flex relative justify-center">
                <fa-icon [icon]="['far', 'dash']"></fa-icon>
              </div>
            </ng-template>
          </span>
        </div>
      </mat-expansion-panel>
      <ng-container *ngFor="let item of metadata">
        <mat-expansion-panel
          [hideToggle]="
            (profile?.metadata | getMetadataItemByGroupName: item.name)
              ? false
              : true
          "
          [expanded]="profile?.metadata | getMetadataItemByGroupName: item.name"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              ><fa-icon [icon]="['far', MetadataIcons[item.name]]"></fa-icon
              >{{ item.name }}</mat-panel-title
            >
            <mat-panel-description
              ><itq-mat-button
                *ngIf="action === Actions.EXPLORE"
                [type]="'button'"
                [materialStyle]="'basic'"
                (clickEvent)="onEdit(ProfileEditAction.METADATA, item.name)"
              >
                <i class="ph ph-pencil"></i> </itq-mat-button
            ></mat-panel-description>
          </mat-expansion-panel-header>
          <itq-explore-metadata
            [metadata]="profile?.metadata"
            [metadataGroup]="item"
          ></itq-explore-metadata>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
  <ng-template #editProfile>
    <div class="edit_metadata__body flex-1 flex">
      <itq-edit-profile
        [editMetadataAction]="editMetadataAction"
        [action]="editAction"
        [form]="form"
        [profileAction]="action"
        [profile]="profile"
        [closeOnUpdate]="closeOnUpdate"
        (closeEditMode)="onCloseEditMode($event)"
      ></itq-edit-profile>
    </div>
  </ng-template>
</div>
<form class="wrapper__right pt-4 overflow-hidden" [formGroup]="form">
  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    #tabGroup
    animationDuration="0ms"
    (selectedTabChange)="onTabChange()"
  >
    <mat-tab class="tags_tab">
      <ng-template mat-tab-label>
        <div class="content_tab__label space-x-2">
          <div class="content_tab__wrapper space-x-2">
            <ng-container *ngIf="selectedTag; else linkTagTemplate">
              <div class="space-x-2 flex items-center">
                <i class="ph ph-database text-lg"></i>
                <span class="pt-[2px]">
                  {{ selectedTag?.tagName }}
                </span>
              </div>
              <itq-mat-button
                *ngIf="
                  tagsDataSource?.items?.length === 1 &&
                  (selectedTag?.type !== 'Vendor' ||
                    selectedTag?.type !== 'Channel')
                "
                [materialStyle]="'basic'"
                [padding]="Sizes.NONE"
                [matTooltip]="'Edit tag'"
                (clickEvent)="onEditTag(selectedTag)"
              >
                <i class="ph ph-pencil"></i>
              </itq-mat-button>
            </ng-container>
            <ng-template #linkTagTemplate>
              <ng-container *ngIf="tagsDataSource; else loaderTemplate">
                <div class="space-x-1 flex items-center">
                  <i class="ph ph-database text-lg"></i>
                  <span class="pt-[2px]"> TAGS </span>
                </div>
              </ng-container>
              <ng-template #loaderTemplate>
                <itq-loader [type]="'controls'"></itq-loader>
              </ng-template>
            </ng-template>
          </div>
          <itq-nav-button
            *ngIf="selectedTag && tagsDataSource?.items?.length > 1"
            [padding]="Sizes.NONE"
            [align]="Align.CENTER"
            [materialStyle]="'basic'"
            [template]="tagActionsTemplate"
          >
            <i class="ph ph-caret-down text-lg"></i>
          </itq-nav-button>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-profiles-tags
          class="overflow-hidden"
          [form]="form"
          [profile]="profile"
          [action]="action"
          [showLinkTags]="showLinkTags"
          [link]="selectedTag"
        ></itq-profiles-tags>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="items-center flex space-x-1">
          <i class="ph ph-tree-view text-lg"></i>
          <span class="pt-[2px]">Connections</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-connections-network
          class="px-7"
          [showConnectionSettings]="showConnectionSettings"
          [profile]="profile"
          [profileAction]="action"
          [form]="form"
        ></itq-connections-network>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="items-center flex space-x-1">
          <i class="ph ph-note text-lg"></i>
          <span class="pt-[2px]">Notes</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-profiles-notes
          class="px-7"
          [profile]="profile"
          [form]="form"
        ></itq-profiles-notes>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</form>
<ng-template #tagActionsTemplate>
  <ul class="tags__actions_container">
    <li *ngFor="let item of tagsDataSource?.items">
      <itq-mat-button
        class="flex-1"
        [size]="Sizes.LG"
        [matTooltip]="'Load tag'"
        [materialStyle]="'basic'"
        [align]="Align.START"
        (clickEvent)="onChangeTag(item)"
      >
        <div class="flex items-center justify-between">
          <span
            [ngClass]="{
              'text-primary': selectedTag?.id === item.id,
              'font-normal': true
            }"
          >
            {{ item?.tagName }}
          </span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        *ngIf="item.type !== 'Channel' && item.type !== 'Vendor'"
        [size]="Sizes.LG"
        [materialStyle]="'basic'"
        [matTooltip]="'Edit tag'"
        (clickEvent)="onEditTag(item)"
      >
        <i class="ph ph-pencil text-lg"></i>
      </itq-mat-button>
    </li>
  </ul>
</ng-template>

@if (showLoader) {
  <itq-loader></itq-loader>
}
