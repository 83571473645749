import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordActorComponent } from '@portal/drilldown/components/drilldown-discord-actor/drilldown-discord-actor.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import { DrilldownSubChannelComponent } from '@portal/drilldown/components/drilldown-subchannel/drilldown-subchannel.component';
import { DrilldownScope } from '@portal/drilldown/interfaces/drilldown.enum';
import {
  IActorDrilldown,
  IChannelDrilldown,
  IContextDrilldown,
  IDiscordActorDrilldown,
  IDrilldown,
  IRepliesDrilldown,
  ISubChannelDrilldown,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { GroupByDatePipe } from '@portal/notifications/pipes/notifications.pipe';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-notifications-explore',
  templateUrl: './notifications-explore.component.html',
  styleUrls: ['./notifications-explore.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    MatTooltipModule,
    FAwesomeModule,
    DocumentsFeedComponent,
  ],
  providers: [NotificationsService, GroupByDatePipe, SharedService],
})
export class NotificationsExploreComponent implements OnInit {
  @Input() raisedAlertId: string;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  private _matchesDataSource: IData;

  public get matchesDataSource(): IData {
    return this._matchesDataSource;
  }

  public set matchesDataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._matchesDataSource = {
        result: this._matchesDataSource.result.concat(value.result),
        count: value.count,
      };
    } else {
      this._matchesDataSource = value;
    }
  }
  private subscriptions = new Subscription();
  public form: FormGroup;
  public showLoader = false;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly widgetService: WidgetService,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService,
    readonly userService: UserService,
    readonly documentService: DocumentItemService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly modalContainerService: ModalContainerService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.bindCreateProfileSubscription();
    this.bindOtherMatchesSubscription();
    this.loadMatches();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindOtherMatchesSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadOtherMatches$.subscribe(
        (response: { filters: QueryFilters; raisedAlertId: string }) => {
          this.raisedAlertId = response.raisedAlertId;
          this.showLoader = true;
          this.loadMatches();
        },
      ),
    );
  }

  private bindCreateProfileSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.createProfile$.subscribe(
        (profileDrilldown: ProfileDrilldown) => {
          this.onCreateProfile(profileDrilldown);
        },
      ),
    );
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const inputs = this.profileService.getProfileDrilldownInputs(
      profileDrilldown,
      this.userService.userPreferences.defaultEcosystemId,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBoundOtherMatches(params: QueryFilters): void {
    this.initialState = params;
    this.loadMatches();
  }

  private loadMatches(): void {
    this.notificationsService
      .getMatches(
        new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
        this.raisedAlertId,
      )
      .subscribe(
        (matches: { items: Array<DocumentItem>; totalCount: number }) => {
          this.documentService.toggleDocument$.next({
            documentId: undefined,
            isExpanded: false,
          });
          this.matchesDataSource = {
            result: matches.items,
            count: matches.totalCount,
          };
          this.showLoader = false;
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDrilldown(drilldown: IDrilldown): void {
    switch (drilldown.scope) {
      case DrilldownScope.ACTOR:
        const actorSegment = drilldown as IActorDrilldown;
        this.drilldownActor(actorSegment.value);
        break;
      case DrilldownScope.CHANNEL:
        const channelSegment = drilldown as IChannelDrilldown;
        this.drilldownChannel(channelSegment.value);
        break;
      case DrilldownScope.SUB_CHANNEL:
        const subChannelSegment = drilldown as ISubChannelDrilldown;
        this.drilldownSubChannel(subChannelSegment.value);
        break;
      case DrilldownScope.DISCORD_ACTOR:
        const discordActorDrilldown = drilldown as IDiscordActorDrilldown;
        this.drilldownDiscordActor(discordActorDrilldown.value);
        break;
      case DrilldownScope.REPLIES:
        const repliesSegment = drilldown as IRepliesDrilldown;
        this.drilldownReplies(repliesSegment.value);
        break;
      case DrilldownScope.CONTEXT:
        const contextSegment = drilldown as IContextDrilldown;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextDrilldown): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
      },
    });
  }

  private drilldownDiscordActor(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordActorComponent,
      inputs: {
        actor: extensionField,
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
      },
    });
  }

  private drilldownSubChannel(subChannel: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownSubChannelComponent,
      inputs: {
        subChannel,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
      },
    });
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.loadMatches();
  }
}
