<div class="flex justify-between w-full">
  <div class="flex">
    @if (notification.tagEdited) {
      <div class="flex items-center mr-4">
        <fa-icon
          [size]="'2xl'"
          [matTooltip]="'This tag has changed since the alert was flagged'"
          [icon]="['far', 'circle-exclamation']"
          [ngStyle]="{ color: '#ffb74d' }"
        ></fa-icon>
      </div>
    }
    <div class="flex items-center">
      <itq-pill
        class="font-medium rounded-full h-[28px]"
        [ngStyle]="{
          backgroundColor: PriorityColor[this.notification.priority]
        }"
        [item]="{ name: notification.alertTypeName }"
        [type]="PillType.CUSTOM"
      >
        <itq-label
          [label]="notification.alertTypeName"
          [icon]="'ph-bell'"
          class="text-white font-normal"
        ></itq-label>
      </itq-pill>
    </div>
    <div class="flex flex-col px-4 text-xs">
      <div class="flex font-medium text-sm">
        {{ notification?.tagName }} has matched
        {{ Condition_Notification[notification?.condition] }}
        {{ notification?.count }} in
        {{ notification?.period | getPeriodLabel }}
      </div>
      <div class="text-metadata space-x-2">
        @if (notification.message) {
          <span>{{ notification.message }}</span>
        }
        <span
          >({{
            notification.createdDate
              | convertLocalTimezone: 'DD-MM-yyyy - hh:mm'
          }})</span
        >
      </div>
    </div>
  </div>
  <div class="flex">
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      [size]="Sizes.SM"
      [disabled]="
        notification?.ecosystemId !==
        this.userService.userPreferences.defaultEcosystemId
      "
      [matTooltip]="
        notification?.ecosystemId !==
        this.userService.userPreferences.defaultEcosystemId
          ? 'This tag belongs to a different ecosystem'
          : undefined
      "
      [padding]="Sizes['X-SM']"
      [matTooltip]="'Explore tag'"
      (clickEvent)="onExploreTag()"
    >
      <i class="ph ph-binoculars text-lg"></i>
    </itq-mat-button>
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      [size]="Sizes.SM"
      [padding]="Sizes['X-SM']"
      [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
      (clickEvent)="onToggleRead()"
    >
      <fa-icon [icon]="['far', notification.read ? 'check' : 'eye']"></fa-icon>
    </itq-mat-button>
  </div>
</div>
