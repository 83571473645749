import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  FAwesomeModule,
  InfiniteScrollDirective,
  QueryFilters,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { DocumentItemTileComponent } from '@portal/document/component/document-item-tile/document-item-tile.component';
import {
  DocumentItem,
  IData,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { IDrilldown } from '@portal/drilldown/interfaces/drilldown.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-documents-feed',
  templateUrl: './documents-feed.component.html',
  styleUrls: ['./documents-feed.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    DocumentItemTileComponent,
    CommonModule,
    FAwesomeModule,
    InfiniteScrollDirective,
  ],
})
export class DocumentsFeedComponent implements OnInit {
  @Input() initialState: QueryFilters;
  @Input() activeDocumentId: string;
  @Input() data: IData;
  @Input() query: string;
  @Input() profile: Profile;
  @Input() drilldownSegment: string;
  @Input() count: number;
  @Input() allowDrilldown = true;

  @Output() scrollEvent = new EventEmitter<QueryFilters>();
  @Output() drilldown = new EventEmitter<IDrilldown>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();
  @Output() shareEmail = new EventEmitter<DocumentItem>();

  public form: FormGroup;
  public parentDocument: DocumentItem;

  @ViewChild('documentContainer') documentContainer: ElementRef;

  constructor(
    readonly customOverlayService: CustomOverlayService,
    readonly virtualScrollService: VirtualScrollService,
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly documentService: DocumentItemService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue !== changes?.data?.previousValue) {
      if (this.initialState.page === 1 && this.documentContainer) {
        this.documentContainer.nativeElement.scrollTop = 0;
      }
    }
  }

  public onShareEmail(document: DocumentItem): void {
    this.shareEmail.emit(document);
  }

  public onShowParent(document: DocumentItem): void {
    this.parentDocument = document;
  }

  public onHideParent(): void {
    this.parentDocument = undefined;
  }

  public onScroll(): void {
    const totalPages = Math.ceil(this.data.count / this.initialState.pageSize);
    const isLastPage = this.initialState.page >= totalPages;

    if (
      this.initialState &&
      this.documentContainer?.nativeElement?.scrollTop > 0 &&
      !isLastPage
    ) {
      this.initialState.page++;
      this.scrollEvent.emit(this.initialState);
    }
  }

  public onDrilldown(drilldown: IDrilldown): void {
    this.drilldown.emit(drilldown);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    this.createProfile.emit(profileDrilldown);
  }
}
