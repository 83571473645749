<div class="flex flex-col" [formGroup]="form">
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('cheatTools')?.value,
        'justify-start': !form?.get('cheatTools')?.value
      }"
    >
      CHEAT TOOLS
      @if (form?.get('cheatTools')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCheatTools()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'cheatTools'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick cheat tools...'"
      [dataSource]="cheatToolsDataSource"
      [initialState]="cheatToolsInitialState"
      (dataBound)="onGetCheatTools()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('significantActors')?.value,
        'justify-start': !form?.get('significantActors')?.value
      }"
    >
      SIGNIFICANT ACTORS
      @if (form?.get('significantActors')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearSignificantActors()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'significantActors'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick significant actors...'"
      [dataSource]="significatActorsDataSource"
      [initialState]="significatActorsInitialState"
      (dataBound)="onGetSignificantActors()"
      (changeValue)="onDataBound()"
    >
    </itq-multiple-dropdown>
  </div>

  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('cheatFeatures')?.value,
        'justify-start': !form?.get('cheatFeatures')?.value
      }"
    >
      CHEAT FEATURES
      @if (form?.get('cheatFeatures')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCheatFeatures()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'cheatFeatures'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick cheat feature...'"
      [dataSource]="cheatFeaturesDataSource"
      [initialState]="cheatFeaturesInitialState"
      (dataBound)="onGetCheatFeatures()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('customerSpecific')?.value,
        'justify-start': !form?.get('customerSpecific')?.value
      }"
    >
      CUSTOMER SPECIFIC
      @if (form?.get('customerSpecific')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearCustomerSpecific()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'customerSpecific'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick customer specific...'"
      [dataSource]="customerSpecificDataSource"
      [initialState]="customerSpecificInitialState"
      (dataBound)="onGetCustomerSpecific()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
  <div class="flex flex-col mb-4">
    <div
      [ngClass]="{
        'mb-1 text-13 font-medium w-full flex items-center': true,
        'justify-between': form?.get('game')?.value,
        'justify-start': !form?.get('game')?.value
      }"
    >
      GAME
      @if (form?.get('game')?.value?.length > 0) {
        <button
          class="text-link hover:underline"
          [type]="'button'"
          (click)="onClearGame()"
        >
          CLEAR
        </button>
      }
    </div>
    <itq-multiple-dropdown
      [formControlName]="'game'"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Pick game...'"
      [dataSource]="gameDataSource"
      [initialState]="gameInitialState"
      (dataBound)="onGetGame()"
      (changeValue)="onDataBound()"
    ></itq-multiple-dropdown>
  </div>
</div>
