@if (board) {
  <itq-board-header
    class="p-7 flex flex-col"
    [board]="board"
    [form]="form"
  ></itq-board-header>

  <itq-board-toolbar
    [board]="board"
    [form]="form"
    [initialState]="initialState"
    (search)="onSearch($event)"
    (refresh)="onReloadDashboard()"
  ></itq-board-toolbar>
}

<div class="max-w-full p-7 w-full flex flex-1">
  @if (currentBreakpoint === 'xl' || currentBreakpoint === '2xl') {
    <ktd-grid
      #grid
      [cols]="cols"
      [rowHeight]="rowHeight"
      [compactType]="'horizontal'"
      [scrollableParent]="element"
      [layout]="layout"
      [scrollSpeed]="10"
      [preventCollision]="false"
      (layoutUpdated)="onLayoutUpdated($event)"
      [gap]="15"
    >
      @if (widgets?.length > 0) {
        @for (item of layout; track item.id; let i = $index) {
          <ktd-grid-item
            class="z-0"
            [id]="item.id"
            [draggable]="true"
            dragStartThreshold="0"
            [resizable]="true"
          >
            <itq-widget
              class="widget"
              [board]="board"
              [widget]="getWidget(i)"
            ></itq-widget>
            <div
              ktdGridDragHandle
              class="btn--drag select-none"
              [matTooltip]="'Drag widget'"
            >
              <i class="ph ph-dots-six-vertical text-xl"></i>
            </div>
          </ktd-grid-item>
        }
      } @else {
        @if (widgets?.length === 0) {
          <itq-panel-info
            class="bg-white p-7 flex rounded-md"
            [label]="'You don\'t have any widgets yet!!'"
            [description]="
              'Now that you have a dashboard, it\'s time to start adding widgets. 🎉'
            "
            [message]="
              'Click + Create Widget to start adding them to your dashboard.'
            "
            [icon]="'hands-helping'"
          ></itq-panel-info>
        }
      }
    </ktd-grid>
  } @else {
    @if (widgets?.length > 0) {
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
        @for (item of widgets; track item.widgetId; let i = $index) {
          <itq-widget
            class="widget h-[500px] w-full"
            [board]="board"
            [widget]="getWidget(i)"
          ></itq-widget>
        }
      </div>
    } @else {
      @if (widgets?.length === 0) {
        <itq-panel-info
          class="bg-white p-7 flex rounded-md"
          [label]="'You don\'t have any widgets yet!!'"
          [description]="
            'Now that you have a dashboard, it\'s time to start adding widgets. 🎉'
          "
          [message]="
            'Click + Create Widget to start adding them to your dashboard.'
          "
          [icon]="'hands-helping'"
        ></itq-panel-info>
      }
    }
  }
</div>
