<itq-modal-header [icon]="config?.data?.icon" [size]="config?.data?.size">
</itq-modal-header>
<itq-modal-body
  [title]="config?.data?.title"
  [message]="config?.data?.message"
  [component]="config.data?.component"
  [data]="config.data?.data"
>
</itq-modal-body>
<itq-modal-footer [ngSwitch]="config?.dialog?.type">
  <ng-container *ngSwitchCase="DialogTypes.CONFIRM">
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      (clickEvent)="onDismiss()"
    >
      NO
    </itq-mat-button>
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'flat'"
      [style]="'primary'"
      (clickEvent)="onConfirm()"
    >
      YES
    </itq-mat-button>
  </ng-container>
</itq-modal-footer>
