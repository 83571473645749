<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <div class="flex flex-col mb-4 w-full">
      <span class="mb-1 text-13 font-medium">UPLOAD FILE OR PASTE IMAGE</span>
      <quill-editor
        class="w-full"
        #typeEditor
        [styles]="{
          height: '100%',
          minHeight: '400px',
          width: '100%',
          maxHeight: '400px'
        }"
        [placeholder]="'Insert image here...'"
        [formControlName]="'value'"
        [modules]="editorModules"
        (onContentChanged)="onValueChanged()"
      ></quill-editor>
      <mat-error
        *ngIf="form.controls?.value?.touched && form.controls?.value?.invalid"
      >
        File field is required
      </mat-error>
    </div>

    <itq-form-row>
      <div class="flex flex-col mb-4 w-full">
        <span class="mb-1 text-13 font-medium">TEXT NOTES</span>
        <quill-editor
          [modules]="textNoteModules"
          [styles]="{
            height: '100%',
            minHeight: '400px',
            width: '100%',
            maxHeight: '400px'
          }"
          [formControlName]="'description'"
          (onContentChanged)="onContentChanged()"
        ></quill-editor>
      </div>
    </itq-form-row>
  </ng-container>
</ng-container>
