import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  IPresetQuery,
  OverlayService,
  QueryFilters,
  Sizes,
  UserTagCategory,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'itq-field-filters',
  templateUrl: './field-filters.component.html',
  styleUrls: ['./field-filters.component.scss'],
  standalone: true,
  imports: [MatListModule, CommonModule, CoreModule, MatTooltipModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FieldFiltersComponent,
    },
  ],
})
export class FieldFiltersComponent implements OnInit {
  @Input() field: string;
  @Input() dates: IPresetQuery;
  @Input() disabled = false;
  @Input() queryModel: QueryBuilderModel;

  @Output() selectionChange = new EventEmitter<IFilterField>();

  public fieldsDataSource: Array<IFilterField> = [];
  public selectedSection: string;
  public sectionsDataSource: Array<string>;
  public showLoader = true;
  public selection: any;
  private touched = false;
  public fields: Array<IFilterField>;
  public initialState = new QueryFilters(
    1,
    100,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly Align = Align;
  readonly Sizes = Sizes;

  constructor(
    readonly userService: UserService,
    readonly overlayService: OverlayService,
    readonly categoryService: CategoryService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  onChange = (items: string) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.initialState.where = this.dates;
    this.onGetFields();
  }

  private onGetFields(): void {
    this.showLoader = true;
    this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategory.Standard,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
        this.queryModel.convertToBackEndQuery(),
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.sectionsDataSource = [
          ...new Set(this.fields.map((item: IFilterField) => item.section)),
        ];
        if (this.field) {
          this.selectedSection = this.fields.find(
            (item: IFilterField) => item.id === this.field,
          )?.section;
        } else {
          this.selectedSection = this.sectionsDataSource[0];
        }
        this.fieldsDataSource = cloneDeep(this.fields)
          ?.filter(
            (item: IFilterField) => item.section === this.selectedSection,
          )
          .sort((a, b) => {
            if (a.hasAnyMatches === b.hasAnyMatches) {
              return a.label.localeCompare(b.label);
            }
            return a.hasAnyMatches === false ? 1 : -1;
          });

        this.showLoader = false;
        this.cdr.detectChanges();
      });
  }

  onSelectionChange(item: IFilterField): void {
    this.onChange(item.label);
    this.selectionChange.emit(item);
    this.overlayService.closeOverlay();
  }

  public onNavChange(section: string): void {
    this.selectedSection = section;
    this.fieldsDataSource = cloneDeep(this.fields)
      ?.filter((item: IFilterField) => item.section === this.selectedSection)
      .sort((a, b) => {
        if (a.hasAnyMatches === b.hasAnyMatches) {
          return a.label.localeCompare(b.label);
        }
        return a.hasAnyMatches === false ? 1 : -1;
      });
  }
}
