<div class="flex w-full">
  @if (currentBreakpoint === '2xl') {
    <itq-board-navigation
      class="border-r border-border flex flex-col w-[300px]"
    ></itq-board-navigation>
    <router-outlet></router-outlet>
  } @else {
    <mat-drawer-container [hasBackdrop]="false" class="w-full">
      <mat-drawer #sidenav [mode]="'over'">
        <itq-board-navigation
          class="border-r border-border flex flex-col w-[300px] h-full"
        ></itq-board-navigation>
      </mat-drawer>
      <mat-drawer-content>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  }
</div>
