<div class="flex flex-col h-full" [formGroup]="form">
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Data source</h2>
  <div class="flex flex-col overflow-hidden">
    <span class="mb-1 text-13 font-medium">TAG</span>
    <itq-nav-button
      #dataSourceButton
      class="w-full"
      [size]="Sizes.BLOCK"
      [materialStyle]="'stroked'"
      [template]="dataSourceTemplate"
    >
      <span class="font-normal flex items-center w-full justify-between">
        @if (form?.get('dataSource')?.value) {
          <span>{{ form?.get('dataSource')?.value.name }}</span>
        } @else {
          <span class="text-gray-400">Pick a tag...</span>
        }
        <i
          [ngClass]="{
            'border-l border-border pl-1 ml-2 ph text-lg': true,
            'ph-caret-down': !dataSourceButton.isOpen,
            'ph-caret-right': dataSourceButton.isOpen
          }"
        ></i>
      </span>
    </itq-nav-button>
    <ng-template #dataSourceTemplate>
      <itq-tag-picker
        class="flex"
        formControlName="dataSource"
        [selectedUserCategory]="selectedUserCategory"
        [dates]="initialState.where"
        [field]="
          this.topicClusteringWidget?.dataSource &&
          this.topicClusteringWidget?.dataSource[0]
        "
        (selectionChange)="onChangeDataSources($event)"
      ></itq-tag-picker>
    </ng-template>

    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
    }
  </div>
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [dates]="this.dates"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
  <h2 class="flex font-medium pt-7 pb-2 text-xl">Settings</h2>
  <div class="flex mb-4 flex-col">
    <span class="mb-1 text-13 font-medium">THEME</span>
    <mat-radio-group
      [formControlName]="'theme'"
      (change)="onChangeTheme($event)"
    >
      <mat-radio-button [value]="'intorqa'">Intorqa</mat-radio-button>
      <mat-radio-button [value]="'black'">Black and white</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
